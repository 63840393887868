import React from 'react';
import Skeleton from 'react-loading-skeleton';

export default function DocketCounter({ data, searchParams, setSearchParams, type }) {
    const currentParams = Object.fromEntries([...searchParams]);
    const isShowingAllData = currentParams.status !== 'Pending' && currentParams.status !== 'Hold' && currentParams.status !== 'Cancelled';

    return (
        <>
            <div className='desktop'>
                <div className="d-flex mt-3 gap-4 overFlowXauto">
                    <div className="pointer docketCounterStyle">
                        <div className={`card widget widget-stats ${isShowingAllData && 'count-box-select'}`}>
                            <div className="card-body position-relative">
                                <div className="widget-stats-container d-flex">
                                    <div className="widget-stats-icon widget-stats-icon-primary">
                                        <i className="material-icons-outlined">push_pin</i>
                                    </div>
                                    <div className="widget-stats-content flex-fill" onClick={() => setSearchParams('')}>
                                        <span className="widget-stats-title">Total Docket</span>
                                        <span className="widget-stats-amount">
                                            {data ? data.total : <Skeleton width={100} />}
                                        </span>
                                    </div>
                                    {type === 'corporate' ? (
                                        <div className="widget-stats-indicator widget-stats-indicator-positive align-self-start align-rb" onClick={() => setSearchParams('status=Resolved')}>
                                            <i className="material-icons">done</i>
                                            {data ? data.closed : <Skeleton width={100} />} Closed
                                        </div>
                                    ) : (
                                        <div className="widget-stats-indicator widget-stats-indicator-positive align-self-start align-rb" onClick={() => setSearchParams('status=Resolved')}>
                                            <i className="material-icons">done</i>
                                            {data ? data.resolved : <Skeleton width={50} />} Resolved
                                        </div>
                                        
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pointer docketCounterStyle" onClick={() => setSearchParams('status=Pending')}>
                        <div className={`card widget widget-stats ${currentParams.status === 'Pending' && 'count-box-select'}`}>
                            <div className="card-body">
                                <div className="widget-stats-container d-flex">
                                    <div className="widget-stats-icon widget-stats-icon-warning">
                                        <i className="material-icons-outlined">remove_red_eye</i>
                                    </div>
                                    <div className="widget-stats-content flex-fill">
                                        <span className="widget-stats-title">Pending</span>
                                        <span className="widget-stats-amount">
                                            {data ? data.pending : <Skeleton width={100} />}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pointer docketCounterStyle" onClick={() => setSearchParams('status=Hold')}>
                        <div className={`card widget widget-stats ${currentParams.status === 'Hold' && 'count-box-select'}`}>
                            <div className="card-body">
                                <div className="widget-stats-container d-flex">
                                    <div className="widget-stats-icon widget-stats-icon-danger">
                                        <i className="material-icons-outlined">back_hand</i>
                                    </div>
                                    <div className="widget-stats-content flex-fill">
                                        <span className="widget-stats-title">Hold</span>
                                        <span className="widget-stats-amount">
                                            {data ? data.hold : <Skeleton width={100} />}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pointer docketCounterStyle" onClick={() => setSearchParams('status=Cancelled')}>
                        <div className={`card widget widget-stats ${currentParams.status === 'Cancelled' && 'count-box-select'}`}>
                            <div className="card-body">
                                <div className="widget-stats-container d-flex">
                                    <div className="widget-stats-icon widget-stats-icon-danger">
                                        <i className="material-icons-outlined">close</i>
                                    </div>
                                    <div className="widget-stats-content flex-fill">
                                        <span className="widget-stats-title">Cancelled</span>
                                        <span className="widget-stats-amount">
                                            {data ? data.cancelled : <Skeleton width={100} />}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobile mt-3'>
                <div className='row m-0'>
                    <div className="shadow-sm mx-1 bg-white rounded col p-2" onClick={() => setSearchParams('')}>
                        <div className='mobileDocket'>
                            <h5>Total</h5>
                            <h3 className='text-dark'>
                                {data ? data.total : <Skeleton width={50} />}
                            </h3>
                        </div>
                    </div>
                    <div className="shadow-sm mx-1 bg-white rounded col p-2" onClick={() => setSearchParams('status=Pending')}>
                        <div className='mobileDocket'>
                            <h5>Pending</h5>
                            <h3 className='text-info'>
                                {data ? data.pending : <Skeleton width={50} />}
                            </h3>
                        </div>
                    </div>
                    <div className="shadow-sm mx-1 bg-white rounded col p-2" onClick={() => setSearchParams('status=Hold')}>
                        <div className='mobileDocket'>
                            <h5>HOLD</h5>
                            <h3 className='text-warning'>
                                {data ? data.hold : <Skeleton width={50} />}
                            </h3>
                        </div>
                    </div>
                    <div className="shadow-sm mx-1 bg-white rounded col p-2" onClick={() => setSearchParams('status=Resolved')}>
                        <div className='mobileDocket'>
                            <h5>Resolved</h5>
                            <h3 className='text-success'>
                                {data ? data.resolved : <Skeleton width={50} />}
                            </h3>
                        </div>
                    </div>
                    <div className="shadow-sm mx-1 bg-white rounded col p-2" onClick={() => setSearchParams('status=Cancelled')}>
                        <div className='mobileDocket'>
                            <h5>CANCELLED</h5>
                            <h3 className='text-danger'>
                                {data ? data.cancelled : <Skeleton width={50} />}
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
