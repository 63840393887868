import React from 'react'
import Modal from 'react-modal'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {  useDocket, useDocketUpdate } from '../../hooks/docket';
import DocketUpdateForm from '../Forms/DocketUpdateForm';
import { isEmptyArr } from '../../utils';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '700px',
    },
    overlay: { zIndex: 1000 }
};
export default function DocketChangeModal({ modal, setModal, currentDocketData, setCurrentDocketData }) {
    let navigate = useNavigate();
    const { handleDocketAction, docketActionIsLoading } = useDocket('PATCH');
    // const { handleDocketCloseAction, docketCloseActionIsLoading } = useCloseDocket('PATCH');
    const { handleDocketUpdate, isDocketUpdating } = useDocketUpdate(); // Using for File Uploading (Axios)
    // console.log('currentDocketData', currentDocketData)

    const handleSubmit = async (values, { resetForm }) => {
        const { files, vehicle1, vehicle2 } = values
        // return
        let res;
        if (values.status === 'Update') {
            res = await handleDocketAction({
                "id": currentDocketData.code,
                "action": `Update`,
                "remarks": `${values.remarks}`,
                "is_activity": values.is_activity,
                "send_mail": values.send_mail,
                // LM UPDATE
                "vehicle1": values.vehicle1,
                "vehicle1_start_km": values.vehicle1_start_km,
                "vehicle1_to_pop": values.vehicle1_to_pop,
                "vehicle2": values.vehicle2,
                "vehicle2_start_km": values.vehicle2_start_km,
                "vehicle2_to_pop": values.vehicle2_to_pop,
            });
        } else {
            const formData = new FormData();
            files && files.forEach(file => {
                formData.append("files", file);
            });
            for (let key in values) {
                if (key === 'files') { continue; }
                if (key === 'vehicle1') { continue; }
                if (key === 'vehicle2') { continue; }
                formData.append(key, `${values[key]}`);
            }
            vehicle1 && formData.append("vehicle1", vehicle1);
            vehicle2 && formData.append("vehicle2", vehicle2);
            // Handle Materials
            const resultList = values.materials.filter(item => item.field !== "" && item.field_id !== null)
            !isEmptyArr(values.materials) && formData.append("materials", JSON.stringify(resultList));
            formData.append("action", values.status);
            res = await handleDocketUpdate(formData, currentDocketData.code)

           
        }
        // console.log('save res', res)
        if (res.success) {
            toast.success(`#${currentDocketData?.code} - Action Performed.`);
            setModal(false)
            setCurrentDocketData({});
        } else {
            toast.error(res.detail)
        }
        // console.log('data --> ', res);
    }
    return (
        <Modal
            isOpen={modal}
            // onAfterOpen={afterOpenModal}
            // onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <div className="container-fluid">

                <div className="d-flex justify-content-between">
                    <h5 className="modal-title mb-3 text-info">Updating [{currentDocketData?.code}]  <code>{currentDocketData.alt_status}</code></h5>
                    <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setModal(false); setCurrentDocketData({}); }}></button>
                </div>
                <div className="modal-body" style={{ width: '100%' }}>

                    <DocketUpdateForm
                        isLoading={docketActionIsLoading || isDocketUpdating}
                        handleSubmit={handleSubmit}
                        data={currentDocketData}
                        setModal={setModal}
                    />
                </div>
            </div>
        </Modal>
    )
}
