import React from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import LoadingButton from '@mui/lab/LoadingButton';
// import { Link } from 'react-router-dom';
import TextField from '../../Common/Fields/TextField';
import { IS_ACTIVE_FILTER_OPTIONS_BOOL, USER_ROLE_OPTIONS } from '../Options/UserForm';
import { formatUserOptionsList, useUsersList } from '../../../hooks/user';
import { useUserGroupsList } from '../../../hooks/user/groups';
import { Typography } from '@mui/material';
import SelectField from '../../Common/Fields/SelectField';
import { formatSelectOptionsList } from '../../../hooks/circuit';
import DatePicker from '../../Common/Fields/DatePickerField';
// import Alert from '@mui/material/Alert';
// import { Stack } from '@mui/material';


const GENDER_OPTIONS = [
    { 'label': 'Male', value: 'male' },
    { 'label': 'Female', value: 'female' },
    { 'label': 'Others', value: 'others' },
]
export default function AccountUpdateForm({ data, isLoading, handleSubmit }) {
    const { usersData, usersIsLoading } = useUsersList(); // '?is_parent=false'
    const { groupsData, isGroupsLoading } = useUserGroupsList('');

    const phoneSchema = Yup.string().phone('IN', true);
    const validate = Yup.object({
        full_name: Yup.string()
            .min(6, 'Enter your leagal name.')
            .required('Enter your full name.'),
        email: Yup.string().email().required('Email is required.'),

        mobile: Yup.string().test('test-phone', 'Mobile not valid.', (value) => {
            if (value) return phoneSchema.isValidSync(value);
            return true;
        }),
    })
    return (
        <Formik
            initialValues={{
                full_name: data.full_name ?? '',
                username: data.username ?? '',
                email: data.email ?? '',
                mobile: data.mobile ?? '',
                gender: data.gender ?? '',
                date_of_birth: data.date_of_birth ?? '',
                date_of_join: data.date_of_join ?? '',

            }}
            validationSchema={validate}
            onSubmit={handleSubmit}
        >
            {formik => (
                <Form>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className='col-12'>
                                    <h3 className='text-center display-6 mb-4 text-primary' style={{ 'fontSize': '22px' }}>Account Details</h3>
                                </div>
                                <div className='row'>
                                    <div className="col-md-6 mb-2">

                                        <TextField label='Account Name *' type='text' name='full_name' placeholder='Your full name' />
                                    </div>
                                    <div className="col-md-6 mb-2">

                                        <TextField label='Username *' type='text' name='username' placeholder='Username' />
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <TextField label='Email *' type='email' name='email' placeholder='Your email address' />
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <TextField label='Mobile' type='text' name='mobile' placeholder='Your mobile number with (+91)' disabled={true}/>
                                    </div>

                                    <div className="col-md-6 mb-2 mt-3">
                                        {/* <Typography>Is Active</Typography> */}
                                        <SelectField
                                            name='gender'
                                            options={GENDER_OPTIONS}
                                            value={GENDER_OPTIONS.filter(function (option) {
                                                return option.value === formik.values.gender;
                                            })}
                                            onChange={(e) => formik.setFieldValue('gender', e.value)}
                                        />

                                    </div>
                                    <div className="col-md-6 mb-2 mt-3">
                                        <DatePicker label='DOB' type='text' name='date_of_birth' minDate='1970-01-01' maxDate='2005-01-01' placeholder='Date of Birth' />
                                    </div>
                                    <div className="col-md-6 mb-2 mt-3">
                                        <DatePicker label='DOJ' type='text' name='date_of_join' maxDate='2024-01-01' placeholder='Date of Join' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <LoadingButton
                        loading={isLoading}
                        variant="contained"
                        disableElevation
                        type="submit"
                        size="large"
                        color="success"
                    >
                        Update
                    </LoadingButton>
                    {/* <Link to="/" className="auth-forgot-password float-end">das</Link> */}
                </Form>)}
        </Formik>
    )
}
