import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { API_HOST_URL } from "../../config";
import { endpoints } from "../../config/endpoints";
import { fetchJson } from "../../libs/api";
import { getTokens } from "../../utils/shortcuts";

const VEHICLE_REPORTS_QUERY_KEY = "vehicle_reports";
const MATERIALS_USED_REPORTS_QUERY_KEY = "vehicle_reports";

export function useVehiclesKMReport(defaultParam = "", enabled = true) {
    const [param, setParam] = useState(null);
    const { isLoading, data } = useQuery(
        [VEHICLE_REPORTS_QUERY_KEY, { param: param, defaultParam }],
        async (key) => {
            const param = key.queryKey[1].param;
            const defaultParam = key.queryKey[1].defaultParam;
            try {
                const { accessToken } = getTokens();
                const param_url = param ? param : defaultParam;
                const res = await fetchJson(
                    `${API_HOST_URL}/${endpoints.report.vehicles_km}${param_url}`,
                    {
                        headers: { Authorization: `Bearer ${accessToken}` },
                    },
                    true,
                );
                return await res.json();
            } catch (err) {
                return { success: false, detail: err };
            }
        },
        {
            cacheTime: Infinity,
            staleTime: 30_000,
            enabled: enabled,
        },
    );



    return {
        vehiclesKmData: data,
        isKMDataLoading: isLoading,
        filterVehiclesReport: async (value) => setParam(value),
    };
}

export function useMaterialUsedReport(defaultParam = "", enabled = true) {
    const [param, setParam] = useState(null);
    const { isLoading, data } = useQuery(
        [VEHICLE_REPORTS_QUERY_KEY, { param: param, defaultParam }],
        async (key) => {
            const param = key.queryKey[1].param;
            const defaultParam = key.queryKey[1].defaultParam;
            try {
                const { accessToken } = getTokens();
                const param_url = param ? param : defaultParam;
                const res = await fetchJson(
                    `${API_HOST_URL}/${endpoints.report.materials_used}${param_url}`,
                    {
                        headers: { Authorization: `Bearer ${accessToken}` },
                    },
                    true,
                );
                return await res.json();
            } catch (err) {
                return { success: false, detail: err };
            }
        },
        {
            cacheTime: Infinity,
            staleTime: 30_000,
            enabled: enabled,
        },
    );



    return {
        materialsUsedData: data,
        isMatUsedLoading: isLoading,
        filterMaterialsUsed: async (value) => setParam(value),
    };
}
