import { IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { formatMaterialOptionsList, useFilterMaterials, useMaterialsList } from '../../../hooks/core/material';
import SelectField from '../../Common/Fields/SelectField';
import { isEmptyArr, makeSlug } from '../../../utils';
import AdditionalMaterial from './AdditionalMaterial';
import { useUser } from '../../../hooks/user';
// import { useState } from 'react';


export default function MaterialDetail({ formik, disabled }) {
    const { user } = useUser();
    const { materialsData, materialsIsLoading } = useMaterialsList(`?is_parent=true`);
    const { filterMaterials, isFilterIsLoading } = useFilterMaterials();
    
    const handleAddNewRow = () => {
        const allMaterials = [...formik.values.materials]
        allMaterials.push({
            field: '',
            field_id: null,
            subOptions: [],
            sub_field: '',
            sub_field_id: null,
            type: 'number',
            value: '',
            unit: '',
            editable: true,
        })
        // setMaterials(allMaterials)
        formik.setFieldValue('materials', allMaterials)
    }
    const handleRemoveRow = (id) => {
        const allMaterials = [...formik.values.materials]
        if (allMaterials.length > 0) { // no minimun row required
            allMaterials.splice(id, 1)
            // setMaterials(allMaterials)
            formik.setFieldValue('materials', allMaterials)
        }
    }
    const handleChangeValue = (text, id) => {
        const allMaterials = [...formik.values.materials]
        allMaterials[id].value = text
        // setMaterials(allMaterials)
        formik.setFieldValue('materials', allMaterials)
    }
    const handleChangeField = async (field_id, field, unit, id) => {
        const allMaterials = [...formik.values.materials]
        allMaterials[id].field = field
        allMaterials[id].field_id = field_id
        allMaterials[id].unit = unit
        const data = await filterMaterials(`?parent=${field_id}`)
        allMaterials[id].subOptions = data?.data
        allMaterials[id].sub_field = data.success ? '' : field
        formik.setFieldValue('materials', allMaterials)
    }
    const handleChangeSubField = async (field_id, field, unit, id) => {
        const allMaterials = [...formik.values.materials]
        allMaterials[id].sub_field = field
        allMaterials[id].sub_field_id = field_id
        allMaterials[id].unit = unit
        // setMaterials(allMaterials)
        formik.setFieldValue('materials', allMaterials)

        // console.log(formik.values.materials);

    }
    useEffect(() => {
        // formik.values.fiber_team_leader && handleChangeField(formik.values.fiber_team_leader)
    }, [])
    return (
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className='col-12'>
                        <h3 className='text-center display-6 mb-4 text-primary' style={{ 'fontSize': '22px' }}>MATERIALS</h3>
                    </div>
                    {/* {console.log(formik.values.materials)} */}
                    {formik.values.materials.map((val, i) =>
                        <div className='row mb-4' key={i}>
                            <div className='col-md-3 col-12'>
                                <Typography variant='caption'>Material {i+1} <span className='req'>*</span> {(val?.editable || user?.permissions?.dockets?.can_update_docket_materials) &&<small className='text-primary' role='button' onClick={() => handleRemoveRow(i)}> Remove</small>}</Typography>
                               
                                <SelectField
                                    name={makeSlug(`${val.field}-${i}`)}
                                    options={materialsData?.data.map(formatMaterialOptionsList)}
                                    value={materialsData?.data.map(formatMaterialOptionsList).filter(function (option) {
                                        return option.value === val.field_id;
                                    })}
                                    onChange={(e) => handleChangeField(e.value, e.name, e.unit, i)}
                                    isLoading={materialsIsLoading}
                                    isDisabled={!(val?.editable || user?.permissions?.dockets?.can_update_docket_materials)}
                                />
                            </div>
                            {!isEmptyArr(formik.values.materials[i].subOptions) &&
                                <div className='col-md-3 col-6'>
                                    <Typography variant='caption'>Child Material <span className='req'>*</span></Typography>
                                    <SelectField
                                        // name={`${val.field}-${i}`}
                                        name={makeSlug(`${val.field}-${i}`)}
                                        options={formik.values.materials[i].subOptions?.map(formatMaterialOptionsList)}
                                        value={formik.values.materials[i].subOptions?.map(formatMaterialOptionsList).filter(function (option) {
                                            return option.value === val.sub_field_id;
                                        })}
                                        onChange={(e) => handleChangeSubField(e.value, e.name, e.unit, i)}
                                        isLoading={isFilterIsLoading}
                                        isDisabled={!(val?.editable || user?.permissions?.dockets?.can_update_docket_materials)}
                                    />
                                </div>}
                            {val.sub_field &&
                                <div className='col-md-4 col-6'>
                                    <TextField
                                        type={val.type}
                                        label={`${val.sub_field} ${val.unit ? `(${val.unit})` : ''}` || 'Value'}
                                        name={makeSlug(`${val.field}-${i}`)}
                                        value={val.value}
                                        onChange={(e) => handleChangeValue(e.target.value, i) }
                                        variant='standard'
                                        error={val.value === ''}
                                        helperText={`${val.sub_field} is required`}
                                        disabled={!(val?.editable || user?.permissions?.dockets?.can_update_docket_materials)}
                                        size="small"
                                        margin="dense"
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start">
                                                    <IconButton disabled={!(val?.editable || user?.permissions?.dockets?.can_update_docket_materials)} onClick={() => handleRemoveRow(i)}>
                                                        <RemoveCircleOutlineOutlinedIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </div>}
                               
                        </div>
                    )}
                    {user?.permissions?.dockets?.can_add_docket_materials && <div className='d-flex justify-content-center d-flex align-items-center mb-2'>
                        <button type='button' className='btn btn-success btn-sm mt-2 btn-style-light' onClick={handleAddNewRow}>
                            <i className="material-icons-sharp">
                                add
                            </i>
                            add material</button>
                    </div>}
                </div>

                {user?.permissions?.dockets?.can_add_docket_materials && <AdditionalMaterial 
                    formik={formik}
                />}
            </div>
        </div>

    )
}
