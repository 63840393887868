import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import moment from "moment/moment";
import { useState } from "react";
import { API_HOST_URL } from "../../config";
import { endpoints } from "../../config/endpoints";
import { fetchJson } from "../../libs/api";
import { getTokens } from "../../utils/shortcuts";


const HIRER_QUERY_KEY = 'hirer'
// const DISTRICT_QUERY_KEY = 'districts'
const CIRCUITS_QUERY_KEY = 'circuits'
const CIRCUIT_QUERY_KEY = 'circuit'

// Get Hirer List
export function useHirerList() {
    const { isLoading, data } = useQuery([HIRER_QUERY_KEY], async () => {
        try {
            const { accessToken } = getTokens();
            const data = await fetchJson(`${API_HOST_URL}/${endpoints.circuit.hirer_list}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            })
            return data;
        } catch (err) {
            return {
                success: false,
                detail: err
            };
        }
    }, {
        cacheTime: Infinity,
        staleTime: 30_000, // ms
    })
    return { hirerData: data, HirerIsLoading: isLoading }
}

export const formatSelectOptionsList = (data) => {
    const label = data?.name ?? data?.title
    return {
        value: data?.id,
        label: label.toUpperCase() 
    }
}

// Get Circuit List
const getCircuits = async (key) => {
    const param = key.queryKey[1].param ?? null;
    const defaultParam = key.queryKey[1].defaultParam;
    try {
        const { accessToken } = getTokens();
        if (param) {
            const res = await fetchJson(`${API_HOST_URL}/${endpoints.circuit.circuit_list_lite}${param}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            }, true)
            return await res.json()
        } else if (defaultParam) {
            const res = await fetchJson(`${API_HOST_URL}/${endpoints.circuit.circuit_list_lite}${defaultParam}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            }, true)
            return await res.json()
        } else {
            const res = await fetchJson(`${API_HOST_URL}/${endpoints.circuit.circuit_list_lite}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            }, true)
            return await res.json()
        }

    } catch (err) {
        return {
            success: false,
            detail: err
        };
    }
}

export function useCircuitList(defaultParam = null, enabled = true) {
    const [param, setParam] = useState(null);
    const { isLoading, data } = useQuery([CIRCUITS_QUERY_KEY, { param: param, defaultParam }], getCircuits, {
        // cacheTime: 0,
        // staleTime: 0,
        cacheTime: Infinity,
        staleTime: 30_000, // ms
        enabled: enabled,
        // refetchOnMount: true, // ms
    })
    return {
        circuitsData: data,
        circuitsIsLoading: isLoading,
        handleParam: async (value) => setParam(value),
    }
}

// Get Circuit Detail
export function useCircuitDetail(id) {
    const { accessToken } = getTokens();
    const { isLoading, isError, data, status } = useQuery(
        [CIRCUIT_QUERY_KEY, id], async () => {
            try {
                const res = await fetchJson(`${API_HOST_URL}/${endpoints.circuit.circuit_list}/${id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                }, true)
                const data = await res.json()
                return data
            } catch (err) {
                return {
                    success: false
                }
            }
        }, {
        // cacheTime: Infinity,
        // staleTime: 30_000,
        cacheTime: 0,
        staleTime: 1,
        retry: 3,
        refetchOnMount: true, // ms
        enabled: !!id,
    })
    return { circuitData: data, circuitIsLoading: isLoading, isError, status }
}

// Save / Update Circuit
export function useCircuit(method) {
    const { accessToken } = getTokens();
    const queryClient = useQueryClient();
    const mutation = useMutation((data) => fetchJson(`${API_HOST_URL}/${endpoints.circuit.circuit_list}${method === 'PATCH' ? '/' + data.id : ''}`, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({ ...data, link_delivery_date: moment(data.link_delivery_date.toString()).format('YYYY-MM-DD') })
    }, true), {
        retry: 2,
    })
    return {
        handleCircuitAction: async (data) => {
            try {
                const res = await mutation.mutateAsync(data);
                const resData = await res.json()
                if (resData.success) {
                    await queryClient.invalidateQueries([CIRCUITS_QUERY_KEY])
                }
                return resData
               
            } catch (err) {
                return {
                    success: false,
                    detail: err
                }
            }
        },

        circuitActionIsLoading: mutation.isLoading,
    }
}


export function useFindCircuit() {
    const { accessToken } = getTokens();
    const mutation = useMutation((param) => fetchJson(`${API_HOST_URL}/${endpoints.circuit.circuit_find}${param}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    }, true))

    return {
        handleFindCircuit: async (q, type) => {
            try {
                const param = `?${type}=${q}`
                if (q) {
                    const res = await mutation.mutateAsync(param);
                    return await res.json();
                } else {
                    return {
                        success: false,
                        detail: 'No query param found...'
                    }
                }
            } catch (err) {
                return {
                    success: false,
                    error: 'something wents wrong.'
                }
            }
        },
        isFindCircuitLoading: mutation.isLoading,
    }
}