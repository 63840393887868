import { useState } from "react";
import coreContext from "./coreContext";
import { useDocketUnderReviewList } from "../../hooks/docket";
import { useDocketRequestList } from "../../hooks/lco/dockets";

const CoreStates = (props) => {
    const { docketsUnderReviewData, docketsUnderReviewIsLoading, handleParam } = useDocketUnderReviewList();
    // const { docketRequestsData, DocketRequestsIsLoading } = useDocketRequestList()
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const actions = {
        'toggleMobileMenu': () => setShowMobileMenu(!showMobileMenu),
    }
    const data = {
        review: {docketsUnderReviewData, docketsUnderReviewIsLoading, handleParam}
    }
    return (
        <coreContext.Provider value={{ showMobileMenu, actions, data }}>
            {props.children}
        </coreContext.Provider>
    )
}
export default CoreStates;