import { minusTAT, secondsToHMS } from "."
import { formatDateTime, formatDuration } from "./shortcuts"

export const export_docket_columns = (docketsData) => {

    return docketsData?.data?.map((item) => {
        // console.log('Item ->', item)
        const materials = item?.get_closed_info?.materials
        const materials_obj = {}

        // Generating Dynamic Field and Value for Materials
        materials && materials.map((val, i) => {
            // Construct the property name in materials_obj
            // If sub_field is different from field, concatenate them
            // Otherwise, just use field
            const propName = `${val.field} ${val.field === val.sub_field ? '' : `(${val.sub_field})`}`;
        
            // Parse the value to an integer and assign it to the constructed property
            materials_obj[propName] = parseInt(val.value);
        });
        return {
            "Docket Id": item.code,
            "Created at": formatDateTime(item.created_at, 'DD-MM-YYYY HH:mm:ss'),
            "Closed at": formatDateTime(item?.closed_at, 'DD-MM-YYYY HH:mm:ss'),
            "Customer ID": item?.get_circuit?.customer_id,
            "Circuit ID": item.get_circuit.code,
            "Hirer": item.get_hirer?.name,
            "Circuit Type": item.get_circuit.circuit_type,
            "Problem": item?.get_problem?.title,
            "Comment": item.comment,
            "Location A": item?.get_circuit?.location_a,
            "Location B": item?.get_circuit?.location_b,
            "Created By": item?.get_created_by?.name,
            "Docketed By": item.docketed_by,
            "Docketed By Contact": item.docketed_by_contact,
            'Confirmed By': item?.get_closed_info?.confirmed_by,
            'Workdone By': item?.get_closed_info?.get_workdone_by?.name,
            'Closed By': item?.get_closed_info?.get_closed_by?.name,
            'RFO': item?.get_closed_info?.get_rfo?.name,
            'Comments': item?.get_closed_info?.comments,
            "Status": item.status,

            "Fiber TL": item?.get_fiber_tl?.name,
            "Technician 1": item?.get_technician?.name,
            "Technician 2": item?.get_technician2?.name,
            "Technician 3": item?.get_technician3?.name,

            "Third Party Docket": item.third_party_docket_no,
            "Order ID": item.order_id,
            "Maintenance Docket": item.is_maintenance,
            "Docket Reopend": item.is_reopened,
            "Total TAT": formatDuration(item.total_tat),
            "Hold TAT": minusTAT(item.hold_tat),
            "Wait TAT": minusTAT(item.wait_tat),
            "Actual TAT": minusTAT(item.actual_tat),
            "Total KM": `${item.total_km ?? 0} KM`,

            'Vehicle 1': item?.get_closed_info?.get_vehicles?.vehicle1_name ?? '',
            'Vehicle 1 KM': item?.get_closed_info?.get_vehicles?.vehicle1_total_km ?? '',
            'V1 Start KM': item?.get_closed_info?.vehicle1_start_km ?? '',
            'V1 End KM': item?.get_closed_info?.vehicle1_end_km ?? '',
            'Vehicle 1 Return': item?.get_closed_info?.get_vehicles?.vehicle1_pop_km ?? '',
            'Vehicle 2': item?.get_closed_info?.get_vehicles?.vehicle2_name ?? '',
            'Vehicle 2 KM': item?.get_closed_info?.get_vehicles?.vehicle2_total_km ?? '',
            'V2 Start KM': item?.get_closed_info?.vehicle2_start_km ?? '',
            'V2 End KM': item?.get_closed_info?.vehicle2_end_km ?? '',
            'Vehicle 2 Return': item?.get_closed_info?.get_vehicles?.vehicle2_pop_km ?? '',
            'Vehicle 3': item?.get_closed_info?.get_vehicles?.vehicle3_name ?? '',
            'Vehicle 3 KM': item?.get_closed_info?.get_vehicles?.vehicle3_total_km ?? '',
            'V3 Start KM': item?.get_closed_info?.vehicle3_start_km ?? '',
            'V3 End KM': item?.get_closed_info?.vehicle3_end_km ?? '',
            'Vehicle 3 Return': item?.get_closed_info?.get_vehicles?.vehicle3_pop_km ?? '',

            ...materials_obj,
        }
    })
}