import React, { useEffect, useState } from 'react'
import CheckboxField from '../../Common/Fields/CheckboxField'
import { LoadingButton } from '@mui/lab'
import { Button } from '@mui/material'
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function DocketColumns({ isLoading, setModal, formik }) {
    const [select, updateSelect] = useState(false)
    
    const handleSelectAll = () => {
        updateSelect(!select)
        if (!select) {
            Object.entries(formik.values).forEach(values => {
                formik.setFieldValue(values[0], true)
            });
        } else {
            Object.entries(formik.values).forEach(values => {
                formik.setFieldValue(values[0], false)
            });
        }
    }
    return (
        <>
            <div className='row'>
                <div className="col-md-3">
                    <CheckboxField label='Circuit ID' name='circuit_id' options={['Circuit ID']} />
                </div>
                <div className="col-md-3">
                    <CheckboxField label='Location A' name='location_a' options={['Location A']} />
                    <CheckboxField label='Location B' name='location_b' options={['Location B']} />
                </div>
                <div className="col-md-3">
                    <CheckboxField label='Address A' name='address_a' options={['Address A']} />
                    <CheckboxField label='Address B' name='address_b' options={['Address B']} />
                </div>
                <div className="col-md-3">
                    <CheckboxField label='CustID 1' name='customer_id' options={['CustID 1']} />
                    <CheckboxField label='CustID 2' name='customer_id_alt' options={['CustID 2']} />
                </div>
            </div>
            <hr />
            <div className='row'>
                <div className="col-md-4">

                    <CheckboxField label='3rd Party Docket' name='third_party_docket_no' options={['3rd Party Docket']} />
                    <CheckboxField label='Ofc. Distance' name='ofc_distance' options={['Ofc. Distance']} />
                </div>
                <div className="col-md-4">
                    <CheckboxField label='Fiber TL' name='ftl' options={['FTL']} />
                    <CheckboxField label='Technician' name='technician' options={['Technician']} />
                </div>
                <div className="col-md-4">
                    <CheckboxField label='Hirer' name='hirer' options={['Hirer']} />
                    <CheckboxField label='Problem' name='problem' options={['Problem']} />
                </div>
            </div>
            <hr />
            <div className='row mb-4'>
                {/* <div className="col-md-3">
                    <CheckboxField label='Total TAT' name='tat_total' options={['Total TAT']} />
                    <CheckboxField label='Total KM' name='total_km' options={['Total KM']} />
                </div> */}
                {/* <div className="col-md-3">



                </div> */}
                <div className="col-md-3">
                    <CheckboxField label='Created At' name='created_at' options={['Created At']} />
                    <CheckboxField label='Last Updated' name='updated_at' options={['Last Updated']} />
                </div>
                <div className="col-md-3">
                    <CheckboxField label='Status' name='status' options={['Status']} />
                    <CheckboxField label='Actions' name='actions' options={['Actions']} />
                </div>

            </div>
            <div className='row mb-4'>
                <div className="col-md-3">
                    {/* <CheckboxField label='Select All' name='select_all' options={['Select All']} onChange={handleSelectAll} value={true} checked={true} default={true} /> */}
                    <FormControlLabel
                        control={<Checkbox checked={select} />}
                        label={'Select All'}
                        name='select_all'
                        value={true}
                        onChange={handleSelectAll}
                    />
                </div>
            </div>


            <LoadingButton
                loading={isLoading}
                type="submit"
                variant="contained"
                disableElevation
            >
                Apply
            </LoadingButton>
            <Button variant="outlined" sx={{ ml: 1 }} disableElevation onClick={() => setModal(false)}>Close</Button>
        </>
    )
}
