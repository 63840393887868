import React from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import LoadingButton from '@mui/lab/LoadingButton';
// import { Link } from 'react-router-dom';
import { Alert, Button, Typography } from '@mui/material';
import TextField from '../../Common/Fields/TextField';
import CheckboxField from '../../Common/Fields/CheckboxField';
import { useUser } from '../../../hooks/user';
// import Alert from '@mui/material/Alert';
// import { Stack } from '@mui/material';


export default function CorpDocketTicketForm({ data, isLoading, handleSubmit, setModal }) {
    const { user } = useUser();
    
    const validate = Yup.object({
        comment: Yup.string().required('Message is required.'),
        file: Yup
            .mixed()
            .test("fileType", "Unsupported File Format", (value) => {
                if (value && value.length > 0) {
                    for (let i = 0; i < value.length; i++) {
                        if (value[i].type !== "image/png" && value[i].type !== "image/jpg" && value[i].type !== "image/jpeg" && value[i].type !== "video/mp4") {
                            return false;
                        }
                    }
                }
                return true;
            })
            .test("fileSize", "File Size is too large", (value) => {
                if (value && value?.length > 0) {
                    for (let i = 0; i < value.length; i++) {
                        // console.log(`${value[i].name}`, `${value[i].size}`)
                        //  Byte Size
                        if (value[i].size > 20154227) {
                            // 2154227 - 2 MB
                            // 5154227 - 5 MB
                            // 15154227 - 15 MB
                            // 20154227 - 20 MB
                            // 25154227 - 25 MB
                            return false;
                        }
                    }
                }
                return true;
            }),
    })



    return (
        <Formik
            initialValues={{
                "comment": '',
                "is_public": false,
                // Send Mail
                "send_mail": false,
                // Files
                "file": null,
            }}
            validationSchema={validate}
            onSubmit={handleSubmit}
        >
            {formik => (

                <Form>
                    {/* {console.log(formik)} */}
                    <div className='row' id='modalFOrm'>
                        <div className="col-md-12 mb-4">
                            <TextField label='Message *' size="medium" type="text" name='comment' placeholder='Message' sx={{ mt: 2 }} />
                            <div className='row mt-2'>
                                <div className="col-md-12 mb-2">
                                    <Typography variant='caption'>Attachment Photo / Video </Typography>
                                    <input
                                        className='form-control'
                                        name="file"
                                        type="file"
                                        accept=".jpg, .jpeg, .png, .mp4, .mpeg"
                                        // multiple
                                        onChange={(event) => {
                                            formik.setFieldValue("file", Array.from(event.target.files));
                                        }}
                                    />
                                </div>
                            </div>
                            {user?.user_role !== 'CU' && <CheckboxField label='Make it Public' name='is_public' options={['Make it Public']} />}
                            {/* {formik.values.status === 'Update' && <CheckboxField label='Send Email' name='send_mail' options={['Send Email']} />} */}
                            
                            
                        </div>
                        {formik.submitCount >= 1 && formik.errors && Object.entries(formik.errors).map(([key, value]) =>
                            <Alert severity="error" sx={{ mt: 1, mb: 1 }} key={key}>{value}</Alert>
                        )}
                    </div>

                    <div className='d-flex justify-content-between align-items-center'>
                        <div>
                            <LoadingButton
                                loading={isLoading}
                                variant="contained"
                                type="submit"
                                disableElevation
                            >
                                Submit
                            </LoadingButton>
                            <Button variant="outlined" disableElevation onClick={() => setModal(false)}>Close</Button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}
