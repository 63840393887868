import { Navigate, Outlet } from 'react-router-dom';
import Layout from '../components/Layout';
import { useUser } from '../hooks/user';
// import Layout from '../components/Layout'

const ProtectedRoutes = () => {
    const { user, status, isLoading } = useUser();
	let auth = Boolean(user) && !isLoading;
    return (
        auth ?
            <Layout>
                <Outlet />
            </Layout>
            :
            status !== 'loading' && <Navigate to='/account/login' />
    )
}
export default ProtectedRoutes;