import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

export default function PageNotFoundPage() {
    return (
        <>
            <Helmet>
                <title>Docket App - 404 Not Found</title>
            </Helmet>
            <div className="app horizontal-menu app-error align-content-stretch d-flex flex-wrap">
                <div className="app-error-info">
                    <h5>Oops!</h5>
                    <span>It seems that the page you are looking for no longer exists.<br />
                        We will try our best to fix this soon.</span>
                    <Link to='/' className="btn btn-dark">Go to dashboard</Link>
                </div>
                <div className="app-error-background"></div>
            </div>
        </>

    )
}
