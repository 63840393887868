import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { formatSelectOptionsList, useHirerList } from '../../../hooks/circuit';
import { formatUserOptionsDetailList, formatUserOptionsList, useUsersList, useUsersListLite } from '../../../hooks/user';
import SelectField from '../../Common/Fields/SelectField';
import TextField from '../../Common/Fields/TextField';
import { useProblemsList } from '../../../hooks/docket';
import { useFilterUsers } from '../../../hooks/user/roles';

export default function ComplaintDetail({ formik, disabled }) {
    const { filterUsers, isFilterIsLoading } = useFilterUsers()
    const { hirerData, HirerIsLoading } = useHirerList();
    const { problemsData, problemsIsLoading } = useProblemsList();
    const docketed_by = useUsersListLite('');  // Users List
    const fiber_tl = useUsersList('?user_role=FTL');  // Fiber Team Leader *
    const [ftDatas, setFtDatas] = useState([]) // Fiber Technician *

    const handleFTLChange = async (value) => {
        // Fiber Team Leader Change
        formik.setFieldValue('fiber_team_leader', value)
        const data = await filterUsers(`?user_role=FT&parent_role=${value}`)
        // setFtDatas(data)
        // console.log('--->',data)
        data.success && setFtDatas(data.data)
    }

    useEffect(() => {
        formik.values.fiber_team_leader && handleFTLChange(formik.values.fiber_team_leader)
    }, [])
    return (
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className='col-12'>
                        <h3 className='text-center display-6 mb-4 text-primary' style={{ 'fontSize': '22px' }}>COMPLAINT DETAILS</h3>
                    </div>


                    <div className="col-md-3">
                        <Typography>Fiber Team Leader <span className='req'>*</span></Typography>

                        <SelectField
                            name='fiber_team_leader'
                            options={fiber_tl?.usersData?.data?.map(formatUserOptionsList)}
                            value={fiber_tl?.usersData?.data?.map(formatUserOptionsList).filter(function (option) {
                                return option.value === formik.values.fiber_team_leader;
                            })}
                            onChange={(e) => handleFTLChange(e.value)}
                            isLoading={fiber_tl?.usersIsLoading}
                            isDisabled={disabled}
                        />
                    </div>
                    <div className="col-md-3">
                        <Typography>Fiber Technician {formik.values.technician && <small className='text-primary' role='button' onClick={() => formik.setFieldValue('technician', null)}>Clear</small>}</Typography>

                        <SelectField
                            name='technician'
                            options={ftDatas.map(formatUserOptionsList)}
                            value={ftDatas.map(formatUserOptionsList).filter(function (option) {
                                return option.value === formik.values.technician;
                            })}
                            onChange={(e) => formik.setFieldValue('technician', e.value)}
                            isLoading={isFilterIsLoading && ftDatas.length === 0}
                            isDisabled={ftDatas.length === 0}
                        // isClearable={true}
                        />


                    </div>


                    <div className="col-md-3">
                        <Typography>Problem <span className='req'>*</span></Typography>

                        <SelectField
                            name='problem'
                            options={problemsData?.data?.map(formatSelectOptionsList)}
                            value={problemsData?.data?.map(formatSelectOptionsList).filter(function (option) {
                                return option.value === formik.values.problem;
                            })}
                            onChange={(e) => formik.setFieldValue('problem', e.value)}
                            isDisabled={disabled}
                            isLoading={problemsIsLoading}
                        />
                    </div>
                    <div className="col-md-3">
                        <Typography>Hirer Name <span className='req'>*</span></Typography>
                        <SelectField
                            name='hirer_name'
                            options={hirerData?.data?.map(formatSelectOptionsList)}
                            value={hirerData?.data?.map(formatSelectOptionsList).filter(function (option) {
                                return option.value === formik.values.hirer_name;
                            })}
                            onChange={(e) => formik.setFieldValue('hirer_name', e.value)}
                            isLoading={HirerIsLoading}
                        />
                    </div>
                    <div className='col-md-12 mb-3 mt-2'>
                        <Typography>Docketed By</Typography>
                        <SelectField
                            name='docketed_by_not_using'
                            options={docketed_by?.usersData?.data?.map(formatUserOptionsDetailList)}
                            value={docketed_by?.usersData?.data?.map(formatUserOptionsDetailList).filter(function (option) {
                                return option.account_name === formik.values.docketed_by;
                            })}
                            onChange={(e) => {
                                formik.setFieldValue('docketed_by', e.account_name)
                                formik.setFieldValue('docketed_by_contact', e.mobile)
                            }}
                            isLoading={docketed_by?.usersIsLoading}
                        />
                    </div>
                    <div className="col-md-3">
                        <TextField label='Docketed By *' type="text" name='docketed_by' placeholder='Docketed By' sx={{ mb: 3 }} />
                    </div>
                    <div className="col-md-3">
                        <TextField label='Docketed By Contact *' type="text" name='docketed_by_contact' placeholder='Docketed By Contact' sx={{ mb: 3 }} />
                    </div>
                    <div className="col-md-3">
                        <TextField label='3rd Party Docket ID' type="text" name='third_party_docket_no' placeholder='3rd Party Docket ID' sx={{ mb: 3 }} />
                    </div>
                    <div className="col-md-3">
                        <TextField label='Comments' type="text" name='comment' placeholder='Comment' sx={{ mb: 3 }} />
                    </div>
                </div>
            </div>
        </div>

    )
}
