import React from 'react'

export default function Alert({ title, text, type = 'primary' }) {
    const renderIcon = (type) => {
        if(type === 'success'){
            return 'done'
        } else if (type === 'info') {
            return 'info'
        }  else if (type === 'warning') {
            return 'report_problem'
        } else if (type === 'danger') {
            return 'dangerous'
        } else {
            return 'priority_high'
        }
    }
    return (
        <div className="alert alert-custom" role="alert">
            <div className={`custom-alert-icon icon-${type}`}>
                <i className="material-icons-outlined">{renderIcon(type)}</i>
            </div>
            <div className="alert-content">
                <span className="alert-title">{title}</span>
                <span className="alert-text">{text}</span>
            </div>
        </div>
    )
}
