import React from 'react'
import LCOCircuitsList from '../../components/LCO/CircuitsList'

export default function LCODashboardPage() {
  return (
    <div>

        <LCOCircuitsList />
    </div>
  )
}
