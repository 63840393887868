import React from 'react'
import Modal from 'react-modal'
import { useNavigate } from 'react-router-dom';
import FTLUpdateForm from '../Forms/FTLUpdateForm';
import { useSimpleDocketUpdate } from '../../hooks/docket';
import { toast } from 'react-toastify';
import { isEmptyArr } from '../../utils';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '700px',
    },
    overlay: { zIndex: 1000 }
};
export default function FiberTLChangeModal({ modal, setModal, docketCode, data, setPending }) {
    let navigate = useNavigate();
    // console.log(data)
    const { handleDocketUpdate, isDocketUpdating } = useSimpleDocketUpdate(); // Using for File Uploading (Axios)
    // const { handleDocketUpdate, isDocketUpdating } = useDocketUpdate();

    const handleSubmit = async (values, { resetForm }) => {
        const { files, vehicle1, vehicle2, technician, technician2 } = values;
        const formData = new FormData();
        files && files.forEach(file => {
            formData.append("files", file);
        });
        for (let key in values) {
            if (key === 'files') { continue; }
            if (key === 'vehicle1') { continue; }
            if (key === 'vehicle2') { continue; }
            if (key === 'technician') { continue; }
            if (key === 'technician2') { continue; }
            formData.append(key, `${values[key]}`);
        }
        vehicle1 && formData.append("vehicle1", vehicle1);
        vehicle2 && formData.append("vehicle2", vehicle2);
        technician && formData.append("technician", technician);
        technician2 && formData.append("technician2", technician2);

        if (setPending){
            // Set Docket as Pending State
            formData.append("status", 'Pending');
            formData.append("remarks", 'Assigned TL/Technician.');
        }
        // Handle Materials
        const resultList = values.materials.filter(item => item.field !== "" && item.field_id !== null)
        !isEmptyArr(values.materials) && formData.append("materials", JSON.stringify(resultList));
        formData.append("action", values.status);
        const res = await handleDocketUpdate(formData, docketCode)

        if (res.success) {
            toast.success(`${docketCode} - ${res.detail}`);

            setModal(false)
            if (setPending){
                navigate(`/docket/${docketCode}`)
            }
        } else {
            toast.error(res.detail)
        }
    }

    return (
        <Modal
            isOpen={modal}
            // onAfterOpen={afterOpenModal}
            // onRequestClose={closeModal}
            style={customStyles}
            contentLabel="TL Change Modal"
        >
            <div className="container-fluid">

                <div className="d-flex justify-content-between">
                    <h5 className="modal-title mb-3 text-info">Change Fiber Team Leader ({`${docketCode}`})</h5>
                    <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setModal(false); }}></button>
                </div>
                <div className="modal-body" style={{ width: '100%' }}>

                    <FTLUpdateForm
                        isLoading={isDocketUpdating}
                        handleSubmit={handleSubmit}
                        data={data}
                        setModal={setModal}
                    />
                </div>
            </div>
        </Modal>
    )
}
