import React from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import TextField from '../../Common/Fields/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { Link } from 'react-router-dom';
// import Alert from '@mui/material/Alert';
// import { Stack } from '@mui/material';


export default function OTPLoginForm({ isLoading, handleSubmit, btnLabel, mobile, setOtpSent }) {
    const validate = Yup.object({
        otp: Yup.string().required('Enter your OTP').matches(/^[0-9]+$/, "Must be only digits").min(6, 'OTP must be exactly 6 digits').max(6, 'OTP must be exactly 6 digits'),
        
    })
    return (
        <Formik
            initialValues={{
                mobile: mobile,
                otp: '',
            }}
            validationSchema={validate}
            onSubmit={handleSubmit}
        >
            <Form>
                <TextField label='Mobile' disabled={true} className='form-control' name='mobile' placeholder='Mobile Number' sx={{ mb: 1 }} />
                <a href='#' onClick={() => setOtpSent(false)} className="auth-forgot-password float-end">Change Mobile Number</a>
                <TextField label='OTP' className='form-control' name='otp' placeholder='Your 6 Digit OTP' sx={{ mb: 1 }} />


                <LoadingButton
                    loading={isLoading}
                    variant="contained"
                    type="submit"
                    disableElevation
                >
                    {btnLabel}
                </LoadingButton>
                <Link to="/account/login" className="auth-forgot-password float-end"> Back</Link>
            </Form>
        </Formik>
    )
}
