import { Container, Grid } from '@mui/material';
import React, { useState } from 'react'
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
// import PageBreadcrumbs from '../../components/Common/Breadcrumbs';
// import Loader from '../../components/Common/Loader';
// import DocketInformation from '../../components/Docket/Information';
// import DocketChangeModal from '../../components/Models/DocketChangeModal';
// import { useDocketDetail } from '../../hooks/docket';
// import { formatDateTime } from '../../utils/shortcuts';
// import { useUser } from '../../hooks/user';
// import FiberTLChangeModal from '../../components/Models/FiberTLChangeModal';
// import DocketHoldRequestModal from '../../components/Models/DocketHoldRequestModal';
// import DocketResumeRequestModal from '../../components/Models/DocketResumeRequestModal';
// import DocketCancelRequestModal from '../../components/Models/DocketCancelRequestModal';
import { toast } from 'react-toastify';
import PageBreadcrumbs from '../../../components/Common/Breadcrumbs';
import { useUser } from '../../../hooks/user';
import { useCorpateDocketList } from '../../../hooks/docket/corporate';
import Loader from '../../../components/Common/Loader';
import CorpDocketInformation from '../../../components/Corporate/CorpDocketInformation';
import Alert from '../../../components/Common/Alert';
// import { useCustomSMS } from '../../hooks/core/sms';
// import LCOCircuitInformation from '../../components/LCO/CircuitInformation';
// import DocketCancelModal from '../../components/LCO/DocketCancelModal';


export default function CorporateDocketDetail() {
    const { user } = useUser();
    const { id } = useParams();
    const { corporateDockets, isCorporateDocketsLoading, filterDocketsList } = useCorpateDocketList(`/${id}`);
    // console.log(corporateDockets)
    const [historyTab, setHistoryTab] = useState(false);
    const [currentDocketData, setCurrentDocketData] = useState({});
    // const { docketData, docketIsLoading } = useDocketDetail(id);
    // const { handleCustomSms, isSendingSms } = useCustomSMS()
    // console.log(docketData)
    // console.log('docket data -> ', docketData);

    if (!user?.permissions?.dockets?.can_view_corp_docket_ticket){
        return <Alert type='danger' title='Permission Error!' text='You do not have permission to view this page.' />
    }

    const renderDocketInformation = (data) => {
        // console.log(data)
        if (data.success) {
            // if (user?.user_role === 'LC') {
            // return <LCOCircuitInformation data={data?.data?.get_circuit} />
            // } else {
            return <CorpDocketInformation data={data.data} historyTab={historyTab} setHistoryTab={setHistoryTab} />
            // }
        } else {
            return <div className='alert alert-warning' role='alert'>
                Something wents wrong! <br /> <Link to='/docket/corporate' className='btn btn-sm btn-dark mt-2'>Back to Dockets</Link>
            </div>
        }
    }

    // const sendLocalConfirmationSMS = async (data) => {

    //     const smsTemplate = `Dear ${data?.docketed_by ?? 'Customer'} Docket No :${data?.code} Please check & confirm the present link's status via portal or call 033-40291140. Same will be auto closed after 1 hourMeghbela Broadband.`

    //     if (data?.docketed_by_contact) {
    //         const resp = await handleCustomSms({
    //             mobile: data?.docketed_by_contact,
    //             text: smsTemplate,
    //             content_id: '1707170322423985039'
    //         });
    //         if (resp.success) {
    //             toast.info('SMS has been sent to LCO.')
    //         } else {
    //             toast.error(resp.detail)
    //         }

    //     } else {
    //         toast.error('LCO number not found.')
    //     }

    // }
    return (
        <>
            {/* <Helmet>
                <title>Dockets - {`${docketData?.data?.code ?? 'Loading...'} ${docketData?.data?.is_maintenance ? 'Maintenance Docket' : ''} Information`}</title>
            </Helmet> */}
            <Container maxWidth="xxl" disableGutters>
                <PageBreadcrumbs
                    title={corporateDockets?.data?.code ?? 'Loading...'}
                    history={[{ name: 'Corporate Docket', to: '/docket/corporate' }]}
                    RightComp={() => {
                        return <>
                        
                        {user?.permissions?.dockets?.can_view_corp_docket_ticket && <button className="btn btn-dark btn-style-light" title='History' onClick={() => setHistoryTab(!historyTab)}>
                                    <i className="material-icons">settings_backup_restore</i>{historyTab ? '' : ''}</button>} 
                        </>
                    }}
                />

                <Grid container>
                    <Grid item xs={12} sx={{ p: 1 }}>
                        {isCorporateDocketsLoading && <Loader />}
                        {corporateDockets && !isCorporateDocketsLoading && renderDocketInformation(corporateDockets)}
                    </Grid>
                </Grid>
                {/* <DocketChangeModal
                    modal={docketChangeModal}
                    setModal={setDocketChangeModal}
                    currentDocketData={currentDocketData}
                    setCurrentDocketData={setCurrentDocketData}
                />
                <DocketHoldRequestModal
                    modal={holdReqModal}
                    setModal={setHoldReqModal}
                    currentDocketData={currentDocketData}
                    setCurrentDocketData={setCurrentDocketData}
                />
                <DocketResumeRequestModal
                    modal={holdResumeModal}
                    setModal={setResumeReqModal}
                    currentDocketData={currentDocketData}
                    setCurrentDocketData={setCurrentDocketData}
                />
                <DocketCancelRequestModal
                    modal={docketCancelReqModal}
                    setModal={setReqCancelModal}
                    currentDocketData={currentDocketData}
                    setCurrentDocketData={setCurrentDocketData}
                />
                <FiberTLChangeModal
                    modal={fiberTLChangeModal}
                    setModal={setfiberTLChangeModal}
                    docketCode={docketData?.data?.code}
                    data={docketData?.data}
                />
                <DocketCancelModal
                    modal={lcDocketCancelModal}
                    setModal={setLCCancelDocket}
                    currentData={currentDocketData}
                    setCurrentData={setCurrentDocketData}
                /> */}
            </Container>
        </>
    )
}
