import React, { useEffect, useState } from 'react'
// import TextAreaField from '../../Common/Fields/TextAreaField';
import TextField from '../../Common/Fields/TextField';
import { isObject } from 'formik';
import { useLocationsList } from '../../../hooks/core/location';
import SelectField from '../../Common/Fields/SelectField';
import { formatSelectOptionsList } from '../../../hooks/circuit';
const pinCode = require('india-pincode-search');

export default function LocationDetail({ formik }) {
    const [loadLoacations, setLoadLoactions] = useState(false)
    const { locationsData, locationsIsLoading } = useLocationsList('', loadLoacations);
    
    const handleFetchPinCode = (e, location_type) => {
        let pin = e.currentTarget.value
        let parsedData = pinCode.search(pin)[0]

        if (isObject(parsedData)) {
            console.log(parsedData)
            formik.setFieldValue(['city_' + location_type], parsedData?.city ?? 'N/A')
            formik.setFieldValue(['post_' + location_type], (parsedData?.office ?? 'N/A').toUpperCase())
            formik.setFieldValue(['district_' + location_type], (parsedData?.district ?? 'N/A').toUpperCase())
            formik.setFieldValue(['area_' + location_type], (parsedData?.village ?? 'N/A').toUpperCase())
        }
        formik.handleChange(e)
    }

    // Address A Dropdown Handle
    const circuit_type = formik.values.circuit_type;
    useEffect(() => {
        if (circuit_type === 'WAN' || circuit_type === 'Leased Line' || circuit_type === 'Maintenance' ){            
            setLoadLoactions(false)
        } else {
            setLoadLoactions(true)
        }
    }, [circuit_type])
    return (
        <div className="card">
            <div className="card-body stepFormDetails">
                <div className="row">
                    <div className='col-12'>
                        <h3 className='text-center display-6 mb-4 text-primary' style={{ 'fontSize': '22px' }}>LOCATION DETAILS</h3>
                    </div>
                    <div className="col-md-6">
                        <p className='text-center text-dark fw-bold'>Location A <br /><small className='fw-bold'>[BTS | POP | HUB]</small></p>
                        <div className='row'>
                            {loadLoacations ? <div className="col-9 mt-2">
                                {/* <Typography>Location A<span className='req'>*</span></Typography> */}
                                <SelectField
                                    name='location_a'
                                    options={locationsData?.data?.map(formatSelectOptionsList)}
                                    value={locationsData?.data?.map(formatSelectOptionsList).filter(function (option) {
                                        return option.label === formik.values.location_a;
                                    })}
                                    onChange={(e) => {
                                        formik.setFieldValue('location_a', e.label);
                                    }}
                                    isLoading={locationsIsLoading}
                                />
                            </div> :
                            <div className="col-9">
                                <TextField label='Location *' type="text" name='location_a' placeholder='BTS | POP | HUB' sx={{ mb: 3 }} />
                            </div>}
                            <div className="col-3">
                                <TextField
                                    label='Pin *'
                                    type="text"
                                    name='pin_a'
                                    placeholder='Pin A'
                                    sx={{ mb: 3 }}
                                    onChange={e => handleFetchPinCode(e, 'a')}
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-6">
                                <TextField label='Mobile' type="text" name='mobile_a' placeholder='Mobile' sx={{ mb: 3 }} />
                            </div>
                            <div className="col-6">
                                <TextField label='Port Details *' type="text" name='port_details_a' placeholder='Port Details' sx={{ mb: 3 }} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-12">
                                <TextField label='Address *' type="text" name='address_a' placeholder='Address' sx={{ mb: 3 }} />
                            </div>
                            <div className="col-4">
                                <TextField label='City' type="text" name='city_a' placeholder='City' sx={{ mb: 3 }} />
                            </div>
                            <div className="col-4">
                                <TextField label='Post' type="text" name='post_a' placeholder='Post' sx={{ mb: 3 }} />
                            </div>
                            <div className="col-4">
                                <TextField label='District' type="text" name='district_a' placeholder='District' sx={{ mb: 3 }} />
                            </div>
                            <div className="col-4">
                                <TextField label='Area' type="text" name='area_a' placeholder='Area' sx={{ mb: 3 }} />
                            </div>
                            <div className="col-4">
                                <TextField label='Latitude' type="text" name='latitude_a' placeholder='Latitude' sx={{ mb: 3 }} />
                            </div>
                            <div className="col-4">
                                <TextField label='Longitude' type="text" name='longitude_a' placeholder='Longitude' sx={{ mb: 3 }} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <p className='text-center text-dark fw-bold'>Location B <br /><small className='fw-bold'>[Customer End | Office]</small></p>

                        <div className='row'>
                            <div className="col-9">
                                <TextField label='Location *' type="text" name='location_b' placeholder='Customer End | Office' sx={{ mb: 3 }} />
                                {/* <TextAreaField
                                required={true}
                                label='Location'
                                placeholder="BTS | POP | HUB"
                                name='location_b'
                            /> */}
                            </div>
                            <div className="col-3">
                                <TextField label='Pin *' type="text" name='pin_b' onChange={e => handleFetchPinCode(e, 'b')} placeholder='Pin A' sx={{ mb: 3 }} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-6">
                                <TextField label='Mobile' type="text" name='mobile_b' placeholder='Mobile' sx={{ mb: 3 }} />
                            </div>
                            <div className="col-6">
                                <TextField label='Port Details *' type="text" name='port_details_b' placeholder='Port Details' sx={{ mb: 3 }} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-12">
                                <TextField label='Address *' type="text" name='address_b' placeholder='Address' sx={{ mb: 3 }} />
                            </div>
                            <div className="col-4">
                                <TextField label='City' type="text" name='city_b' placeholder='City' sx={{ mb: 3 }} />
                            </div>
                            <div className="col-4">
                                <TextField label='Post' type="text" name='post_b' placeholder='Post' sx={{ mb: 3 }} />
                            </div>
                            <div className="col-4">
                                <TextField label='District' type="text" name='district_b' placeholder='District' sx={{ mb: 3 }} />
                            </div>
                            <div className="col-4">
                                <TextField label='Area' type="text" name='area_b' placeholder='Area' sx={{ mb: 3 }} />
                            </div>
                            <div className="col-4">
                                <TextField label='Latitude' type="text" name='latitude_b' placeholder='Latitude' sx={{ mb: 3 }} />
                            </div>
                            <div className="col-4">
                                <TextField label='Longitude' type="text" name='longitude_b' placeholder='Longitude' sx={{ mb: 3 }} />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
