import React from 'react'
import Modal from 'react-modal'


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '700px',
    },
    overlay: { zIndex: 1000 }
};


export default function ImagePreviewModal({ modal, setModal, src, text }) {

    return (
        <Modal
            isOpen={modal}
            // onAfterOpen={afterOpenModal}
            // onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Image Preview Modal"
        >
            <div className="container-fluid">

                <div className="d-flex justify-content-between">
                    <h5 className="modal-title text-info">Image Preview</h5>
                    <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setModal(false); }}></button>
                </div>
                <div className="modal-body" style={{ width: '100%' }}>
                    <div className="container border border-primary shadow p-3 mb-5 bg-white rounded d-flex justify-content-center align-items-center" style={{ height: 300 }}>
                        <img src={src} alt="Attachment File" className="img-fluid" style={{ maxHeight: "100%", maxWidth: "100%", height: "auto", width: "auto" }} />
                        
                    </div>
                    <p className='text-center mt-3'>{text}</p>
                </div>
            </div>
        </Modal>
    )
}
