import { useQuery } from "@tanstack/react-query";
import { API_HOST_URL } from "../../config";
import { endpoints } from "../../config/endpoints";
import { fetchJson } from "../../libs/api";
import { getTokens } from "../../utils/shortcuts";
import { DOCKET_STATIC_COUNTS } from "./query_keys";
import { useState } from "react";


const DOCKET_STATICS_CHART = 'docket_count_chart'


// Get Docket Statics
export function useDocketStatics(defaultParam = '', enabled = true) {
    const [param, setParam] = useState(null);
    const { isLoading, data } = useQuery([DOCKET_STATIC_COUNTS, { param: param, defaultParam }], async (key) => {
        const param = key.queryKey[1].param;
        const defaultParam = key.queryKey[1].defaultParam;
        try {
            const { accessToken } = getTokens();
            const param_url = param ? param : defaultParam;
            const res = await fetchJson(`${API_HOST_URL}/${endpoints.docket.static}${param_url}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            }, true)
            return await res.json()

        } catch (err) {
            return { success: false, detail: err };
        }
    }, {
        cacheTime: 0,
        staleTime: 1,
        enabled: enabled,
    })
    return {
        docketStaticsData: data?.data, isDocketStaticsLoading: isLoading,
        handleStaticsParam: async (value) => setParam(value),
    }
}


// Get Docket Statics Chart
export function useDocketStaticsChart(defaultParam = '', enabled = true) {
    const [param, setParam] = useState(null);
    const { isLoading, data } = useQuery([DOCKET_STATICS_CHART, { param: param, defaultParam }], async (key) => {
        const param = key.queryKey[1].param;
        const defaultParam = key.queryKey[1].defaultParam;
        try {
            const { accessToken } = getTokens();
            const param_url = param ? param : defaultParam;
            const res = await fetchJson(`${API_HOST_URL}/${endpoints.docket.statics_chart}${param_url}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            }, true)
            return await res.json()

        } catch (err) {
            return { success: false, detail: err };
        }
    }, {
        cacheTime: 0,
        staleTime: 1,
        enabled: enabled,
    })
    return {
        docketsChartData: data?.data, isChartDataLoading: isLoading,
        handleStaticsParam: async (value) => setParam(value),
    }
}