import React from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import TextField from '../Common/Fields/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
// import { Link } from 'react-router-dom';
import { Alert, Button, Typography } from '@mui/material';
import SelectField from '../Common/Fields/SelectField';
import { DOCKET_LM_STATUS } from './Options/DocketForm';
import { Link } from 'react-router-dom';
import CheckboxField from '../Common/Fields/CheckboxField';
// import { formatVehicleOptionsList, useVehiclesList } from '../../hooks/core/vehicle';
import { useUser } from '../../hooks/user';
import VehiclesForm from '../DocketStatusModal/VehiclesForm';
import MaterialForm from '../DocketStatusModal/MaterialForm';
// import Alert from '@mui/material/Alert';
// import { Stack } from '@mui/material';



export default function DocketUpdateForm({ data, isLoading, handleSubmit, setModal }) {
    // const { vehiclesData, vehiclesIsLoading } = useVehiclesList();
    const { user } = useUser();
    const validate = Yup.object({
        status: Yup.string().required('Status is required.'),
        remarks: Yup.string().required('Remarks is required.'),

        vehicle1: Yup.string().nullable().when("status", {
            is: (val) => val === 'Hold',
            then: Yup.string().required('Vehicle 1 is required.').nullable()
        }),
        vehicle1_start_km: Yup.number().required('Start KM is required.'),
        vehicle1_end_km: Yup.number().required('End KM is required.'),
        vehicle2_start_km: Yup.number().required('Start KM is required.'),
        vehicle2_end_km: Yup.number().required('End KM is required.'),
        files: Yup
            .mixed()
            .test("fileType", "Unsupported File Format", (value) => {
                if (value && value.length > 0) {
                    for (let i = 0; i < value.length; i++) {
                        if (value[i].type !== "image/png" && value[i].type !== "image/jpg" && value[i].type !== "image/jpeg" && value[i].type !== "video/mp4") {
                            return false;
                        }
                    }
                }
                return true;
            })
            .test("fileSize", "File Size is too large", (value) => {
                if (value && value?.length > 0) {
                    for (let i = 0; i < value.length; i++) {
                        // console.log(`${value[i].name}`, `${value[i].size}`)
                        //  Byte Size
                        if (value[i].size > 20154227) {
                            // 2154227 - 2 MB
                            // 5154227 - 5 MB
                            // 15154227 - 15 MB
                            // 20154227 - 20 MB
                            // 25154227 - 25 MB
                            return false;
                        }
                    }
                }
                return true;
            }),
    })

    const STATUS_OPTIONS = [
        // { 'label': 'Only Update', value: 'Update' },
        ...(user?.permissions?.dockets?.can_share_update ? [{ label: 'Only Update', value: 'Update' }] : []),
        // { 'label': 'Hold', value: 'Hold' },
        ...(user?.user_role !== 'LC' ? [{ 'label': 'Hold', value: 'Hold' }] : []),
        ...(user?.permissions?.dockets?.can_cancel_docket ? [{ 'label': 'Cancelled', value: 'Cancelled' }] : []),
        // { 'label': 'Cancelled', value: 'Cancelled' },
    ]
    const STATUS_OPTIONS_ALT = [
        // { 'label': 'Resume', value: 'Resume' },
        ...(user?.user_role !== 'LC' ? [{ 'label': 'Resume', value: 'Resume' }] : []),
        // { 'label': 'Only Update', value: 'Update' },
        ...(user?.permissions?.dockets?.can_share_update ? [{ label: 'Only Update', value: 'Update' }] : []),
    ]


    return (
        <Formik
            initialValues={{
                "status": data.status,
                "remarks": '',

                // Vehicle States
                "vehicle1": null,
                "vehicle1_start_km": 0,
                "vehicle1_end_km": 0,
                "vehicle2": null,
                "vehicle2_start_km": 0,
                "vehicle2_end_km": 0,
                vehicle1_to_pop: data.vehicle1_to_pop ?? false,
                vehicle2_to_pop: data.vehicle2_to_pop ?? false,
                // Material Detail.
                // "materials": [{
                //     field: '',
                //     field_id: null,
                //     subOptions: [],
                //     sub_field: '',
                //     sub_field_id: null,
                //     type: 'number',
                //     value: 'new',
                //     unit: '',
                //     editable: false,
                // }],
                "materials": [],
                // Send Mail
                "send_mail": false,
                // Files
                "files": null,
                // Activity
                'is_activity': false
            }}
            validationSchema={validate}
            onSubmit={handleSubmit}
        >
            {formik => (

                <Form>
                    {/* {console.log(formik)} */}
                    <div className='' id='modalFOrm'>
                        <div className="row">
                            <div className={`${formik.values.is_activity ? 'col-md-6' : 'col-md-12'}`}>
                                <Typography variant='caption'>Status <span className='req'>*</span></Typography>
                                {data.status === 'Hold' ?
                                    <SelectField
                                        name='status'
                                        options={STATUS_OPTIONS_ALT}
                                        value={STATUS_OPTIONS_ALT.filter(function (option) {
                                            return option.value === formik.values.status;
                                        })}
                                        onChange={(e) => formik.setFieldValue('status', e.value)}
                                    /> : <SelectField
                                        name='status'
                                        options={STATUS_OPTIONS}
                                        value={STATUS_OPTIONS.filter(function (option) {
                                            return option.value === formik.values.status;
                                        })}
                                        onChange={(e) => {
                                            formik.handleBlur('status')
                                            formik.setFieldTouched('status')
                                            formik.setFieldValue('status', e.value)
                                        }}
                                    />}
                            </div>


                            {formik.values.status === 'Update' && formik?.touched?.status && formik.values.is_activity ? <>
                                {/* LM UPDATE FORM VIEW */}
                                <div className='col-md-6'>
                                    <Typography variant='caption'>LM Status <span className='req'>*</span></Typography>
                                    <SelectField
                                        name='remarks'
                                        options={DOCKET_LM_STATUS}
                                        value={DOCKET_LM_STATUS.filter(function (option) {
                                            return option.value === formik.values.remarks;
                                        })}
                                        onChange={(e) => {
                                            formik.setFieldValue('remarks', e.value)
                                        }}
                                    />
                                </div>
                            </> : <>
                                {/* DEFAULT FORM VIEW */}
                                <div className='col-md-12'>
                                    <TextField label='Remarks *' size="small" type="text" className='mt-3' name='remarks' placeholder='Remarks' sx={{ mt: 2 }} />
                                </div>

                            </>}
                            {formik.values.status === 'Update' && formik.values.is_activity && formik.values.remarks === 'Team on the way' && formik?.touched?.status && <>
                                {/* CLOSE VEHICLE FORM VIEW */}
                                <VehiclesForm formik={formik} hideEndKM={true} />

                            </>}

                            {formik.values.status === 'Hold' && formik?.touched?.status && <>
                                {/* HOLD VEHICLE FORM VIEW */}
                                <VehiclesForm formik={formik} />
                                {user?.permissions?.dockets?.can_add_docket_materials && <MaterialForm formik={formik} />}
                            </>}

                            {formik.values.status === 'Hold' | formik.values.status === 'Cancelled' && formik?.touched?.status ? <>
                                {/* HOLD OR CANCELLED FORM VIEW */}
                                <div className='row mt-2'>
                                    <div className="col-md-5 mb-2">
                                        <Typography variant='caption'>Reference Photo / Video </Typography>
                                        <input
                                            className='form-control'
                                            name="files"
                                            type="file"
                                            accept=".jpg, .jpeg, .png, .mp4, .mpeg"
                                            multiple
                                            onChange={(event) => {
                                                formik.setFieldValue("files", Array.from(event.target.files));
                                            }}
                                        />
                                    </div>
                                </div>
                            </> : ''}
                            <div className='col-12 mt-2'>
                                {formik.values.status === 'Update' && <CheckboxField label='LM Update' name='is_activity' options={['LM Update']} />}
                                {formik.values.status === 'Update' && <CheckboxField label='Send Email' name='send_mail' options={['Send Email']} />}
                            </div>

                        </div>
                        {formik.submitCount >= 1 && formik.errors && Object.entries(formik.errors).map(([key, value]) =>
                            <Alert severity="error" sx={{ mt: 1, mb: 1 }} key={key}>{value}</Alert>
                        )}
                    </div>

                    <div className='d-flex justify-content-between align-items-center'>
                        <div>
                            <LoadingButton
                                loading={!!isLoading}
                                variant="contained"
                                type="submit"
                                disableElevation
                                disabled={formik.values.status === 'Update' && formik.values.is_activity && formik.values.remarks === 'Team on the way' && !formik.values.vehicle1}
                            >
                                <small>Update</small>
                            </LoadingButton>
                            <Button variant="outlined" disableElevation onClick={() => setModal(false)}><small>Close</small></Button>
                        </div>

                        {user?.permissions?.dockets?.can_close_docket && <Link to={`/docket/close/${data.code}`} className='btn btn-warning btm-sm'><small>Close Docket</small></Link>}
                    </div>
                </Form>
            )}
        </Formik>
    )
}
