import React, { useCallback, useState } from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Button } from '@mui/material';
import CircuitDetail from './CircuitDetail';
import LocationDetail from './LocationDetail';
// import ContactDetail from './ContactDetail';
import FormSteper from '../../Common/FormSteper';
import OtherDetail from './OtherDetail';
import FiberDetail from './FiberDetail';
import ComplaintDetail from './ComplaintDetail';
import "yup-phone";
import ContactDetail from '../CircuitForm/ContactDetail';
// import { toast } from 'react-toastify';


export default function DocketForm({ data, docketData, isLoading, handleSubmit, initialForm, submitBtnLabel }) {
    // console.log('sdasdsd->',data)
    // Conditional Form State
    const [currentStep, setCurrentStep] = useState(initialForm ?? 1);
    const [steps] = useState([
        {
            id: 1,
            label: 'CIRCUIT DETAILS',
        },
        {
            id: 2,
            label: 'LOCATION DETAILS',
        },
        {
            id: 3,
            label: 'CONTACT DETAILS',
        },
        {
            id: 4,
            label: 'OTHER DETAILS',
        },
        {
            id: 5,
            label: 'FIBER DETAILS',
        },
        {
            id: 6,
            label: 'COMPLAINT DETAILS',
        },
    ])
    const lastStepIndex = steps.length;
    const isLastStep = lastStepIndex === currentStep;
    const onStepNext = useCallback(
        (event) => {
            event.preventDefault();
            setCurrentStep(() => Math.min(currentStep + 1, lastStepIndex));
        },
        [currentStep, lastStepIndex]
    );
    const onPrevClick = useCallback(
        (event) => {
            event.preventDefault();
            setCurrentStep(() => Math.max(currentStep - 1, 1));
        },
        [currentStep, setCurrentStep]
    );

    const phoneSchema = Yup.string().phone('IN', true);
    const validate = Yup.object({
        // CIRCUIT DETAILS 
        circuit_type: Yup.string().required('circuit: Select circuit type'),
        link_type: Yup.string().required(),
        status: Yup.string().required(),
        hirer_name: Yup.number().required(),
        customer_id: Yup.string().required('Customer ID is Required.'),

        // LOCATION DETAILS
        "location_a": Yup.string().required('Location A is required.'),
        // "pin_a": Yup.string().required('PIN A is required.'), // Temp Disabled
        "port_details_a": Yup.string().required('Port details A is required.'),
        "address_a": Yup.string().required('Address A is required.'),

        "location_b": Yup.string().required('Location B is required.'),
        // "pin_b": Yup.string().required('PIN B is required.'), // Temp Disabled
        "port_details_b": Yup.string().required('Port details B is required.'),
        "address_b": Yup.string().required('Address B is required.'),

        // "area": Yup.string().required('Area is Required.'),
        // "district": Yup.string().required('District is required.'),

        // CONTACT DETAILS
        // "lc_contact_persion": Yup.string().min(3, 'LC Contact person must be at least 3 characters.'),
        // "territory_incharge": Yup.string().required(),
        // "hub_incharge": Yup.string().required(),
        // "ac_manager": Yup.string().required(),
        // "fiber_vendor_team_leader": Yup.string().required(),
        // "fe_rf_team": Yup.string().required(), // Temp Disabled
        // "feasibility_done_by": Yup.string().required(),

        // OTHER DETAILS
        // "link_delivery_date": Yup.string().required('Link delivery date is required.').nullable(), // Temp Disabled

        // FIBER DETAILS
        // "ofc_color": Yup.string().required('Select OFC Color'),

        // lc_mobile_no: Yup.string().test('test-phone', 'Mobile not valid.', (value) => {
        //     if (value) return phoneSchema.isValidSync(value);
        //     return true;
        // }),


        // COMPLAINT DETAILS
        "docketed_by": Yup.string().label('Docketed by name').min(3, 'Docketed by must be at least 3 characters.').required('Docketed By Name is Required'),
        // "docketed_by_contact": Yup.string().phone("IN", true).required('Docketed by contact is required.').label('Docketed by contact'),
        "fiber_team_leader": Yup.number().required('Select Fiber Team Leader').transform((value) => (isNaN(value) ? undefined : value)).nullable(),
        "dhirer_name": Yup.number().required(),
        "problem": Yup.number().required('Select Problem').transform((value) => (isNaN(value) ? undefined : value)).nullable(),
        // "problem": Yup.string().required()

        comment: Yup.string().required('Comment is required.'),
    })

    const conditionalFormComponent = (formik) => {
        switch (currentStep) {
            case 1:
                // CIRCUIT DETAILS 
                return <CircuitDetail formik={formik} disabled={true} />;
            case 2:
                // LOCATION DETAILS
                return <LocationDetail formik={formik} disabled={true} />;
            case 3:
                // CONTACT DETAILS
                return <ContactDetail formik={formik} disabled={true} />;
            case 4:
                // OTHER DETAILS
                return <OtherDetail formik={formik} disabled={true} />;
            case 5:
                // FIBER DETAILS
                return <FiberDetail formik={formik} disabled={true} />;
            case 6:
                // COMPLAINT DETAILS
                return <ComplaintDetail formik={formik} disabled={false} />;
            default:
                // CIRCUIT DETAILS 
                return <CircuitDetail formik={formik} disabled={true} />;
        }
    };
    return (
        <Formik
            initialValues={{
                "circuit_type": data.circuit_type ?? '',
                "link_type": data.link_type ?? 'Primary',
                "status": data.status ?? 'Active',
                "hirer_name": data.hirer_name ?? null,
                "customer_id": data.customer_id ?? '',
                "third_party_circuit_id": data.third_party_circuit_id ?? '',

                // Location A
                "location_a": data.location_a ?? '',
                "pin_a": data.pin_a ?? 'NA',
                "mobile_a": data.mobile_a ?? '',
                "port_details_a": data.port_details_a ?? 'NA',
                "address_a": data.address_a ?? '',
                "city_a": data.city_a ?? 'NA',
                "post_a": data.post_a ?? 'NA',
                "area_a": data.area_a ?? 'NA',
                "district_a": data.district_a ?? 'NA',
                "latitude_a": data.latitude_a ?? 'NA',
                "longitude_a": data.longitude_a ?? 'NA',

                // Location B
                "location_b": data.location_b ?? '',
                "pin_b": data.pin_b ?? 'NA',
                "mobile_b": data.mobile_b ?? '',
                "port_details_b": data.port_details_b ?? 'NA',
                "address_b": data.address_b ?? '',
                "city_b": data.city_b ?? 'NA',
                "post_b": data.post_b ?? 'NA',
                "area_b": data.area_b ?? 'NA',
                "district_b": data.district_b ?? 'NA',
                "latitude_b": data.latitude_b ?? 'NA',
                "longitude_b": data.longitude_b ?? 'NA',

                // "area": data.area ?? '',
                // "district": data.district ?? null,

                // CONTACT DETAILS
                "lc_contact_persion": data.lc_contact_persion ?? '',
                "lc_mobile_no": data.lc_mobile_no ?? '',
                "technician": data.technician ?? null,
                "territory_incharge": data.territory_incharge ?? null,
                "hub_incharge": data.hub_incharge ?? null,
                "ac_manager": data.ac_manager ?? null,
                "service_assurance": data.service_assurance ?? '',
                "fiber_vendor_team_leader": data.fiber_vendor_team_leader ?? null,
                "fe_rf_team": data.fe_rf_team ?? null,
                "feasibility_done_by": data.feasibility_done_by ?? null,

                // OTHER DETAILS
                "bandwidth_mb_gig": data.bandwidth_mb_gig ?? '',
                "customer_type": data.customer_type ?? 'option1',
                "type_of_connectivity": data.type_of_connectivity ?? 'option1',
                "email": data.email ?? '',
                "ip_details": data.ip_details ?? '',
                "osm_id": data.osm_id ?? '',
                "indus_id": data.indus_id ?? '',
                "link_delivery_date": data.link_delivery_date ?? null,
                "office_name": data.office_name ?? '',

                // FIBER DETAILS
                "ofc_cable_drum_no": data.ofc_cable_drum_no ?? '',
                "ofc_color": data.ofc_color ?? null,
                "ofc_distance": data.ofc_distance ?? '',
                "pole_joint_no": data.pole_joint_no ?? '',
                "address": data.address ?? '',
                // "latitude": data.latitude ?? '',
                // "longitude": data.longitude ?? '',

                // Docket Create
                "fiber_team_leader": docketData.fiber_team_leader ?? null,
                "dtechnician": docketData.technician ?? null,
                "third_party_docket_no": docketData.third_party_docket_no ?? '',
                "problem": docketData.problem ?? null,
                "dhirer_name": data.hirer_name ?? null,
                "comment": docketData.comment ?? '',
                "docketed_by": docketData.docketed_by ?? '',
                "docketed_by_contact": docketData.docketed_by_contact ?? '',
                "dstatus": docketData.status ?? 'Pending',
                "circuit": data.id ?? null,
                "provider": data.provider ?? 1,
            }}
            validationSchema={validate}
            onSubmit={handleSubmit}
        >
            {formik => (
                <>
                    <FormSteper steps={steps} currentStep={currentStep} setCurrentStep={setCurrentStep} />
                    <Form>
                        {/* RENDER FORM */}
                        {conditionalFormComponent(formik)}

                        {/* SHOWING ERRORS */}
                        {formik.submitCount >= 1 && formik.errors && Object.entries(formik.errors).map(([key, value]) => {
                            if (value.includes("circuit:")) {
                                // setCurrentStep(1);
                            }
                            return <Alert severity="error" sx={{ mt: 1, mb: 1 }} key={key}>{value.replace('circuit:', '')}</Alert>

                        })}


                        <div className="d-flex justify-content-between">
                            {currentStep !== 1 && (
                                <Button
                                    variant="contained"
                                    disableElevation
                                    onClick={onPrevClick}
                                    sx={{ mr: 1 }}
                                    size="large"

                                >
                                    Previous
                                </Button>
                            )}
                            {isLastStep ?
                                <LoadingButton
                                    loading={isLoading}
                                    variant="contained"
                                    disableElevation
                                    type="submit"
                                    size="large"
                                    color="success"
                                >
                                    {submitBtnLabel}
                                </LoadingButton>
                                :
                                <Button
                                    variant="contained"
                                    disableElevation
                                    onClick={onStepNext}
                                    size="large"
                                >
                                    Next
                                </Button>
                            }
                        </div>

                    </Form>
                </>
            )}





            {/* <div className="row m-t-lg">
                        <div className="col-md-6">
                            <label for="settingsInputFirstName" className="form-label">First Name</label>
                            <input type="text" className="form-control" id="settingsInputFirstName" placeholder="John" />
                        </div>
                        <div className="col-md-6">
                            <label for="settingsInputLastName" className="form-label">Last Name</label>
                            <input type="text" className="form-control" id="settingsInputLastName" placeholder="Doe" />
                        </div>
                    </div>
                    <div className="row m-t-lg">
                        <div className="col-md-6">
                            <label for="settingsInputUserName" className="form-label">Username</label>
                            <div className="input-group">
                                <span className="input-group-text" id="settingsInputUserName-add">neptune.com/</span>
                                <input type="text" className="form-control" id="settingsInputUserName" aria-describedby="settingsInputUserName-add" placeholder="username" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label for="settingsState" className="form-label">State</label>
                            <select className="js-states form-control" id="settingsState" tabindex="-1" >
                                <optgroup label="Alaskan/Hawaiian Time Zone">
                                    <option value="AK">Alaska</option>
                                    <option value="HI">Hawaii</option>
                                </optgroup>
                            </select>
                        </div>
                    </div>
                    <div className="row m-t-lg">
                        <div className="col">
                            <label for="settingsAbout" className="form-label">About</label>
                            <textarea className="form-control" id="settingsAbout" maxlength="500" rows="4" aria-describedby="settingsAboutHelp"></textarea>
                            <div id="emailHelp" className="form-text">Brief information about you to display on profile (max: 500 characters)</div>
                        </div>
                    </div>
                    <div className="row m-t-lg">
                        <div className="col">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="SettingsNewsLetter" />
                                <label className="form-check-label" for="SettingsNewsLetter">
                                    Receive notifications about updates &amp; maintenances
                                </label>
                            </div>
                            <a href="#" className="btn btn-primary m-t-sm">Update</a>
                        </div>
                    </div> */}


        </Formik>
    )
}
