import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import BackToPopForm from '../Forms/BackToPopForm';
import { useCloseDocket, useDocket, useDocketDetailHook } from '../../hooks/docket';
import Skeleton from 'react-loading-skeleton';
import { useDebouncedCallback } from 'use-debounce';
// import DocketRequestForm from './Forms/DocketRequestForm';
// import { useCancelDocketAction, useDocketRequest, useNewDocket } from '../../hooks/lco/dockets';
// import CommentForm from './Forms/CommentForm';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '700px',
    },
    overlay: { zIndex: 1000 }
};
export default function BackToPopModal({ modal, setModal, docData, setDocData }) {

    const { docketData, docketIsLoading, filterList } = useDocketDetailHook();
    const [isLoading, setLoading] = useState(true)
    const [currentData, setCurrentData] = useState({})
    // const { handleCancelDocket, isCancellingDocket } = useCancelDocketAction('POST')
    const { handleDocketCloseAction } = useCloseDocket('PATCH');

    const handleBackToPop = async (values, { resetForm }) => {
        // console.log(values)
        const res = await handleDocketCloseAction({
            ...values,

            // vehicle1_to_pop: false,
            // vehicle2_to_pop: false,
            // vehicle3_to_pop: false,
            id: docData.code,
            action: 'update',
            remarks: 'Back to POP updated.'
        })
        // console.log(res)
        if (res.success) {
            toast.success(`#${docData.code} - Back to POP updated.`);
            // navigate(`/docket`);
            setModal(false)
        } else {
            toast.error(res.detail)
        }

    }
    const fetchData = useDebouncedCallback(() => {
        filterList(`/${docData?.code}`, true)
    }, 100)
    useEffect(() => {
        if (docketData && !docketIsLoading) {
            console.log(docketData)
            setCurrentData(docketData?.data ?? {});
            setLoading(false)
        } else {
            setLoading(true)
        }
    }, [docketData])
    return (
        <Modal
            isOpen={modal}
            onAfterOpen={() => { fetchData(); setLoading(true) }}
            // onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Back To POP Docket Modal"
        >
            <div className="container-fluid">

                <div className="d-flex justify-content-between">
                    <h5 className="modal-title mb-3 text-center text-info">Back To POP Information Required.</h5>
                    <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setModal(false); setDocData(null); }}></button>
                </div>
                <div className="modal-body" style={{ width: '100%' }}>
                    <div className="d-flex justify-content-between mb-4">
                        <div>
                            <p className="display-6 text-dark m-0">Docket ID:</p>
                        </div>
                        <div>
                            {isLoading ? <Skeleton width={150} height={35} /> : <h3 className="display-6 text-danger m-0"> {currentData.code}</h3>}

                        </div>
                    </div>


                    {/* <hr /> */}
                    {isLoading ? <Skeleton width={580} height={100} /> :<BackToPopForm
                        currentData={currentData}
                        setModal={setModal}
                        // isLoading={isCancellingDocket}
                        handleSubmit={handleBackToPop}
                        btnLabel='Update Back to POP KM'
                    />}
                </div>
            </div>
        </Modal>
    )
}
