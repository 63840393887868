import React from 'react'

import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import TextField from '../Common/Fields/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button } from '@mui/material';
// import RadioField from '../Common/Fields/RadioField';
// import Alert from '@mui/material/Alert';
// import { Stack } from '@mui/material';


export default function DocketSearchForm({ isLoading, handleSubmit, setModal }) {
    const validate = Yup.object({
        docketId: Yup.string().required('Docket ID is required.'),
    })
    return (
        <Formik
            initialValues={{
                "docketId": '',
            }}
            validationSchema={validate}
            onSubmit={handleSubmit}
        >
            {formik => (

                <Form>
                    <div className='row'>
                        <div className="col-md-12 mb-4">
                            <TextField label='Docket ID' className='form-control' name='docketId' placeholder={`Enter Docket ID`} />
                        </div>
                    </div>

                    <LoadingButton
                        loading={isLoading}
                        type="submit"
                        variant="contained"
                        disableElevation
                    >
                        Find Docket
                    </LoadingButton>
                    <Button variant="outlined" sx={{ ml: 1 }} disableElevation onClick={() => setModal(false)}>Close</Button>
                </Form>
            )}
        </Formik>
    )
}
