import React, { useEffect } from 'react'
import { formatDateTime } from '../../../../utils/shortcuts';
import ExportToExcel from '../../../../components/Export/ExportToExcel';
import { useDebouncedCallback } from 'use-debounce';
import { export_vehicles_km_report_columns } from '../../export_data';
import { Link } from 'react-router-dom';

export default function VehiclesKMReportDownload({ currentParams, data, isLoading, setShowResult }) {
    data = data?.data
    const [enableFilenameEdit, setEnableFilenameEdit] = React.useState(false);
    const [filename, setFilename] = React.useState(`Vehicles Report Export - ${formatDateTime('today', 'DD-MM-YYYY')}`);
    const [exportData, setExportData] = React.useState([]);


    const prepareExportData = useDebouncedCallback(() => {
        if (data && !isLoading) {
            const customHeadings = export_vehicles_km_report_columns(data)
            setExportData(customHeadings)
        }
    }, 100)


    useEffect(() => {
        prepareExportData()
    }, [data, isLoading])

    return (
        <div>
            <div className="row card bg-light p-4 mt-3">

                <div className="d-flex justify-content-center">
                    <h5 className="modal-title mb-3 text-center text-dark">Download Vehicle KM Report</h5>
                    {/* <h5 className='text-info modal-title'>Total Vehicles: {data?.length}</h5> */}
                </div>
                <div className="modal-body" style={{ width: '100%' }}>

                    <div>
                        {currentParams.date_to && <p>Date Range: <span className='fw-bold text-dark'>{formatDateTime(currentParams.date_from, 'DD-MM-YYYY')} to {formatDateTime(currentParams.date_to, 'DD-MM-YYYY')}</span></p>}

                        <p>Information:  <span className='text-warning fw-bold'>Total {data?.length} records found.</span></p>
                        <p>
                            File Name: <a href="#" onClick={() => setEnableFilenameEdit(!enableFilenameEdit)}>{enableFilenameEdit ? 'save' : 'edit'}</a>
                        </p>
                        <input type="text" className="form-control" value={filename} onChange={(e) => setFilename(e.target.value)} disabled={!enableFilenameEdit} />

                    </div>
                    {/* Footer */}
                    <div className=''>
                        {!data && isLoading && <div>
                            <div className='d-flex justify-content-center mt-5 gap-2'>
                                <div className="spinner-grow text-primary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                <div className="spinner-grow text-secondary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                <div className="spinner-grow text-warning" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                            <p className='text-center text-warning mt-2'>Please wait, while processing data... be patient.</p>
                        </div>}

                        {data && !isLoading && data?.length > 0 && <div>
                            <div className='d-flex justify-content-center mt-5 gap-2'>
                                <ExportToExcel exportData={exportData} isDisabled={isLoading} fileName={filename} />
                            </div>
                            <p className='text-center text-success mt-2'>Ready to download! Thanks for your patience.</p>
                        </div>}

                        <div className='d-flex justify-content-center mt-5 gap-2'>
                            <button onClick={() => setShowResult(false)} className='btn btn-dark'>Cancel</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
