import { API_HOST_URL } from "../../config";
import { endpoints } from "../../config/endpoints";
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import { fetchJson } from "../../libs/api";
import { getTokens } from "../../utils/shortcuts";
import { useState } from "react";

const USER_QUERY_KEY = 'user'


export function useGetUser(defaultParam = '', enabled = true, revalidate=false) {
    const [param, setParam] = useState(null);
    const { isLoading, data } = useQuery([USER_QUERY_KEY, { param: param, defaultParam }], async (key) => {
        const param = key.queryKey[1].param;
        const defaultParam = key.queryKey[1].defaultParam;
        try {
            const { accessToken } = getTokens();
            const param_url = param ? param : defaultParam;
            const res = await fetchJson(`${API_HOST_URL}/${endpoints.account.update_account}${param_url}`, {
                headers: { 
                    'Authorization': `Bearer ${accessToken}`,
                    'z-cache': revalidate ? Math.floor(Math.random() * 10) + 1 : null
                },
            }, true)
            return await res.json();

        } catch (err) {
            return { success: false, detail: err };
        }
    }, {
        cacheTime: 0,
        staleTime: 0,
        enabled: enabled,
    })
    return {
        userData: data,
        userIsLoading: isLoading,
        filterUser: async (value) => setParam(value),
    }
}



// Save / Update User 
export function useUpdateAction(method) {
    const { accessToken } = getTokens();
    const queryClient = useQueryClient();
    const mutation = useMutation((data) => fetchJson(`${API_HOST_URL}/${endpoints.account.update_account}${method === 'PATCH' ? '/' + data.id : ''}`, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
            'z-cache': Math.floor(Math.random() * 10) + 1
        },
        body: JSON.stringify({...data, groups: [data.groups]})
    }, true), {
        retry: 2,
    })
    return {
        handleUserAction: async (data) => {
            try {
                const res = await mutation.mutateAsync(data);
                const resData = await res.json()
                if (resData.success) {
                    await queryClient.invalidateQueries([USER_QUERY_KEY])
                }
                return resData
            } catch (err) {
                return {
                    success: false,
                    detail: err
                }
            }
        },

        useActionIsLoading: mutation.isLoading,
    }
}



