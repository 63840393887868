import { useMutation, useQuery } from "@tanstack/react-query";
// import moment from "moment/moment";
import { useState } from "react";
import { API_HOST_URL } from "../../config";
import { endpoints } from "../../config/endpoints";
import { fetchJson } from "../../libs/api";
import { getTokens } from "../../utils/shortcuts";



const CIRCUITS_QUERY_KEY = 'circuits'
const CIRCUIT_QUERY_KEY = 'circuit'



export const formatSelectOptionsList = (data) => {
    return {
        value: data?.id,
        label: data?.name ?? data?.title
    }
}

// Get LCO Circuit List
const getCircuits = async (key) => {
    const param = key.queryKey[1].param ?? null;
    const defaultParam = key.queryKey[1].defaultParam;
    try {
        const { accessToken } = getTokens();
        if (param) {
            const res = await fetchJson(`${API_HOST_URL}/${endpoints.lco.circuit_list}${param}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            }, true)
            return await res.json()
        } else if (defaultParam) {
            const res = await fetchJson(`${API_HOST_URL}/${endpoints.lco.circuit_list}${defaultParam}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            }, true)
            return await res.json()
        } else {
            const res = await fetchJson(`${API_HOST_URL}/${endpoints.lco.circuit_list}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            }, true)
            return await res.json()
        }

    } catch (err) {
        return {
            success: false,
            detail: err
        };
    }
}

export function useLCOCircuitList(defaultParam = null) {
    const [param, setParam] = useState(null);
    const { isLoading, data } = useQuery([CIRCUITS_QUERY_KEY, { param: param, defaultParam }], getCircuits, {
        cacheTime: 0,
        staleTime: 1,
    })
    return {
        circuitsData: data,
        circuitsIsLoading: isLoading,
        handleParam: async (value) => setParam(value),
    }
}



// Get LCO Circuit Detail
export function useLCOCircuitDetail(id) {
    const { accessToken } = getTokens();
    const { isLoading, isError, data, status } = useQuery(
        [CIRCUIT_QUERY_KEY, id], async () => {
            try {
                const res = await fetchJson(`${API_HOST_URL}/${endpoints.lco.circuit_list}/${id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                }, true)
                const data = await res.json()
                return data
            } catch (err) {
                return {
                    success: false
                }
            }
        }, {
        cacheTime: 0,
        staleTime: 1,
        retry: 3,
        refetchOnMount: true, // ms
        enabled: !!id,
    })
    return { circuitData: data, circuitIsLoading: isLoading, isError, status }
}


export function useFindCircuit() {
    const { accessToken } = getTokens();
    const mutation = useMutation((param) => fetchJson(`${API_HOST_URL}/${endpoints.lco.circuit_list}${param}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    }, true))

    return {
        handleFindCircuit: async (q, type) => {
            try {
                const param = `?${type}=${q}`
                if (q) {
                    const res = await mutation.mutateAsync(param);
                    return await res.json();
                } else {
                    return {
                        success: false,
                        detail: 'No query param found...'
                    }
                }
            } catch (err) {
                return {
                    success: false,
                    error: 'something wents wrong.'
                }
            }
        },
        isFindCircuitLoading: mutation.isLoading,
    }
}