import { Alert } from '@mui/material';
// import moment from 'moment';
import React, { useState } from 'react'
import Modal from 'react-modal'
// import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { usePasswordChange } from '../../hooks/user';
import PaswordChangeForm from '../Forms/PaswordChangeForm';


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '700px',
    },
    overlay: { zIndex: 1000 }
};
export default function ChangePasswordModal({ modal, setModal }) {
    // let navigate = useNavigate();
    const { handlePwdChange, PwdChangeisLoading } = usePasswordChange()
    // console.log('currentDocketData', currentDocketData)
    const [res, setResp] = useState(null)
    const handleSubmit = async (values, { resetForm }) => {
        // const { q, type } = values;
        const resp = await handlePwdChange(values);
        console.log(resp)
        setResp(resp)

        if (resp.success){
            resetForm({ values: '' });
            setModal(false);
            setResp(null)
            toast.success(resp.detail)
        }
        // if (typeof res !== 'undefined' && res.length === 0) {
        //     toast.error('No records found...')
        // } else {
        //     const { id, code } = res[0];
        //     setModal(false)
        //     toast.success('Circuit found...')
        //     navigate(`docket/add?circuit=${id}&cid=${code.toLowerCase()}`)
        // }
        // if (res.success) {
        //     toast.success(`#${currentDocketData?.code} - Status changed.`);
        //     setModal(false);
        //     setCurrentDocketData({});
        // } else {
        //     toast.error(res.detail)
        // }
        // console.log('data --> ', values);
    }
    return (
        <Modal
            isOpen={modal}
            // onAfterOpen={afterOpenModal}
            // onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <div className="container-fluid">

                <div className="d-flex justify-content-between">
                    <h5 className="modal-title mb-3 text-center text-info">Change Password</h5>
                    <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={() => setModal(false)}></button>
                </div>
                <div className="modal-body" style={{ width: '100%' }}>
                    {res && !res?.success && <Alert variant="filled" severity="error" sx={{ mb: 2 }}>
                        {res?.detail}
                    </Alert>}
                    <PaswordChangeForm
                        isLoading={PwdChangeisLoading}
                        handleSubmit={handleSubmit}
                        setModal={setModal}
                    />

                </div>
            </div>
        </Modal>
    )
}
