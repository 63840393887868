import React, { useState } from 'react'
// import VehicleSelectionForm from './Comp/VehicleSelectionForm'
import { useSearchParams } from 'react-router-dom';
import PageBreadcrumbs from '../../../components/Common/Breadcrumbs';
import { useMaterialUsedReport, useVehiclesKMReport } from '../../../hooks/reports';
import moment from 'moment';
import { serializeQueryStrings } from '../../../utils';
import MaterialsReportForm from './Comp/MaterialsReportForm';
import MaterialsUsedReportDownload from './Comp/MaterialsUsedReportDownload';
// import VehiclesKMReportDownload from './Comp/VehiclesKMReportDownload';

export default function MaterialsUsedReportPage() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [queryData, setQueryData] = useState(null);
    const [enableApi, setAPI] = useState(false);
    const { materialsUsedData, isMatUsedLoading, filterMaterialsUsed } = useMaterialUsedReport('', enableApi);
    const [showResult, setShowResult] = useState(false);

    const fetchData = (data) => {
        const params = new URLSearchParams({ ...data }).toString();
        setAPI(true)
        params && filterMaterialsUsed(`?${params}`)
        setShowResult(true)
    }

    const handleSubmit = (values) => {
        let data = { ...values };
        if (moment(values?.date_from).isValid() && moment(values?.date_to).isValid()) {
            data = {
                ...values,
                date_from: moment(values?.date_from).format('YYYY-MM-DD'),
                date_to: moment(values?.date_to).format('YYYY-MM-DD'),
            };
        }

        // setSearchParams(serializeQueryStrings(data))
        fetchData(data)
        setQueryData(data)
    }
    return (
        <div>
            <PageBreadcrumbs
                title='Materials Report'
                alt='Material Used Report'
                RightComp={() =>
                    <div className='d-flex justify-content-end'>
                        {/* <Link to='/reports/vehicle/km-report' className='btn btn-primary'>Reset</Link> */}
                    </div>
                }
            />
            <hr />
            <div className='card px-4 py-4 bg-light'>
                {!showResult && <MaterialsReportForm
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                    handleSubmit={handleSubmit}
                />}


                {showResult && <MaterialsUsedReportDownload
                    currentParams={Object.fromEntries([...searchParams])}
                    data={materialsUsedData}
                    isLoading={isMatUsedLoading}
                    setShowResult={setShowResult}
                    queryData={queryData}
                />}
            </div>
        </div>
    )
}
