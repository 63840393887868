import { TOKEN_STORE_NAME } from '../config'
import moment from 'moment';
import 'moment-timezone';


import addNotification from "react-push-notification";


export const getTokens = () => {
    const tokens = localStorage.getItem(TOKEN_STORE_NAME);
    if (tokens) {
        const { access, refresh } = JSON.parse(tokens)
        return {
            accessToken: access,
            refreshToken: refresh
        }
    } else {
        return {
            accessToken: '',
            refreshToken: ''
        }
    }

}

export const formatDateTime = (datetime, format = 'YYYY-MM-DD', day = false) => {

    if (day) {
        return moment(datetime).tz('Asia/Kolkata').startOf('day').fromNow();
    }
    if (datetime === 'today') {
        return moment().tz('Asia/Kolkata').format(format)
    } else if (datetime) {
        return moment(datetime).tz('Asia/Kolkata').format(format)
    } else {
        return '-'

    }
}

export const formatTime = (time, format = 'h:mm A') => {
    // Convert Django time string to Moment.js object
    const timeObject = moment(time, 'HH:mm:ss').tz('Asia/Kolkata');

    // Format the time using Moment.js
    const formattedTime = timeObject.format(format);

    if (formattedTime) {
        return formattedTime
    } else {
        return '-'
    }
}
export const latLongToGmap = (latitude, longitude) => {
    if (latitude && longitude) {
        return `https://google.com/maps?q=${latitude},${longitude}`
    } else {
        return false
    }
}
export const showPushNotification = (data) => {
    addNotification({
        title: data.title,
        message: data.message,
        theme: "darkblue",
        closeButton: "X",
        native: true

    });

}


export const showUser = (user, contact = true) => {
    const name = user?.name
    const mobile = user?.mobile
    let output;
    if (contact && name && mobile) {
        output = `${name} @ ${mobile}`
    } else if (contact && name && !mobile) {
        output = `${name}`
    } else if (!contact && name) {
        output = `${name}`
    } else {
        output = `Not Available`
    }
    return output
}


export const getCorporateDocketTAT = (datetime1, datetime2) => {
    const currentDatetime = moment().tz('Asia/Kolkata');

    // Convert to Moment objects and set the timezone
    const moment1 = datetime1 ? moment(datetime1).tz('Asia/Kolkata') : currentDatetime;
    const moment2 = moment(datetime2).tz('Asia/Kolkata');

    // Calculate the duration between moment1 and moment2
    const duration = moment.duration(moment1.diff(moment2));

    // Extract hours, minutes, and seconds
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    // Format the output
    const formattedOutput = `${hours}:${minutes}:${seconds}`;

    // console.log(formattedOutput); // Output: "24:0:0"
    return formattedOutput
}


export const formatDuration = (duration) => {
    // try {
    //     if (!duration) throw new Error("Invalid duration");

    //     const [days, time] = duration.split(' ');
    //     if (!days || !time) throw new Error("Invalid duration format");

    //     const [hours, minutes, seconds] = time.split(':');
    //     if (hours === undefined || minutes === undefined || seconds === undefined) throw new Error("Invalid time format");

    //     const totalHours = parseInt(days) * 24 + parseInt(hours);
    //     return `${totalHours}:${minutes}:${seconds.split('.')[0]}`;
    // } catch (error) {
    //     console.error(error.message);
    //     return "Invalid duration";
    // }

    try {
        if (!duration) return '-';

        let days = 0, time = duration;
        if (duration.includes(' ')) {
            [days, time] = duration.split(' ');
            days = parseInt(days);
        }

        const [hours, minutes, seconds] = time.split(':');
        if (hours === undefined || minutes === undefined || seconds === undefined) return '-';

        const totalHours = days * 24 + parseInt(hours);
        return `${totalHours}:${minutes}:${seconds.split('.')[0]}`;
    } catch (error) {
        console.error(error.message);
        return '-';
    }
};



export const CommentDisplay = ({ logComment }) => {
    try {
        const formattedComment = logComment.replace(/\n/g, '<br />');

        return (
            <small
                dangerouslySetInnerHTML={{ __html: formattedComment || '-' }}
            />
        );
    } catch (error) {
        // console.error(error.message);
        return '-';
    }
};