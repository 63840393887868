import React from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import TextField from '../Common/Fields/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
// import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
// import Alert from '@mui/material/Alert';
// import { Stack } from '@mui/material';


export default function PaswordChangeForm({ isLoading, handleSubmit, setModal }) {
    const validate = Yup.object({
        old_password: Yup.string()
            // .min(6, 'Password must be at least 6 characters.')
            .required('Current password is required.'),
        password: Yup.string()
            // .min(6, 'Password must be at least 6 characters.')
            .required('New password is required.'),
        password2: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Password must match.')
            .required('Confirm password is required.'),
    })
    return (
        <Formik
            initialValues={{
                old_password: '',
                password: '',
                password2: '',
            }}
            validationSchema={validate}
            onSubmit={handleSubmit}
        >
            <Form>

                <TextField label='Current Password' type='password' name='old_password' />
                <TextField label='New Password' type='password' name='password' />
                <TextField label='Confirm Password' type='password' name='password2' sx={{mb:3}}/>

                <LoadingButton
                    loading={isLoading}
                    variant="contained"
                    type="submit"
                >
                    Change Password
                </LoadingButton>
                <Button variant="outlined" sx={{ ml: 1 }} disableElevation onClick={() => setModal(false)}>Close</Button>
            </Form>
        </Formik>
    )
}
