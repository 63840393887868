import React, { useState } from 'react'
import Modal from 'react-modal'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFindCircuit } from '../../hooks/circuit';
import CircuitFinderForm from '../Forms/CircuitFinderForm';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '700px',
    },
    overlay: { zIndex: 1000 }
};
export default function UniversalDocketRequestModal({ modal, setModal, setDocketRequestFormModal, setDocketRequestFormData }) {
    let navigate = useNavigate();
    const { handleFindCircuit, isFindCircuitLoading } = useFindCircuit();
    const [results, setResults] = useState([])
    // console.log('currentDocketData', currentDocketData)

    const handleSubmit = async (values, { resetForm }) => {
        const { q, type } = values;
        let query = q.replace(/\s/g, '')
        const res = await handleFindCircuit(query, type);
        console.log(query, res)
        if (!res.success) {
            setResults([])
            toast.error('No records found...')
        } else {
            // console.log(res.data)
            setResults(res.data)
            // const { id, code } = res.data[0];
            // setModal(false)
            // toast.success('Circuit found...')
            // navigate(`docket/add?circuit=${id}&cid=${code.toLowerCase()}`)
        }
        // if (res.success) {
        //     toast.success(`#${currentDocketData?.code} - Status changed.`);
        //     setModal(false);
        //     setCurrentDocketData({});
        // } else {
        //     toast.error(res.detail)
        // }
        // console.log('data --> ', values);
    }
    return (
        <Modal
            isOpen={modal}
            // onAfterOpen={afterOpenModal}
            // onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Universal Docket Request Modal"
        >
            <div className="container-fluid">

                <div className="d-flex justify-content-between">
                    <h5 className="modal-title text-center text-info">Raise New Docket Request</h5>
                    <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={() => setModal(false)}></button>
                </div>
                <div className="modal-body" style={{ width: '100%' }}>
                    <CircuitFinderForm
                        isLoading={isFindCircuitLoading}
                        handleSubmit={handleSubmit}
                        setModal={setModal}
                        data={{ type: 'customer_id' }}
                        only={true}
                    />
                </div>
                {results.length > 0 && <div>
                    <table className='table table-primary table-striped' style={{ width: '100%' }}>
                        <thead>
                            <tr>
                                {/* <th>ID</th> */}
                                <th>CIR ID</th>
                                <th>LOC A</th>
                                <th>LOC B</th>
                                <th>Addr B</th>
                                <th>LC</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {results.length > 0 && results.map((val, i) => <tr key={i}>
                                {/* <td>{i + 1}</td> */}
                                <td><small>{val.code}</small></td>
                                <td><small>{val.location_a}</small></td>
                                <td><small>{val.location_b}</small></td>
                                <td><small>{val.address_b}</small></td>
                                <td><small>{val.lc_contact_persion || 'NA'}<br />
                                    {val.lc_mobile_no ?? ''}</small>
                                </td>
                                <td><small>{val.status}</small></td>
                                {/* <td>{!data?.active_docket?.status && <button onClick={() => { setCurrentData(data); setModal(true); }} className='btn btn-warning pb-0 btn-style-light mx-2' title='Report Docket'>
                                    <span className="material-icons-sharp">
                                        waving_hand
                                    </span>
                                </button>}</td> */}
                                <td><button onClick={() => { setDocketRequestFormData(val); setModal(true); setDocketRequestFormModal(true); }} className='btn btn-warning pb-0 btn-style-light mx-2' title='Report Docket'>
                                    <span className="material-icons-sharp">
                                        waving_hand
                                    </span>
                                </button></td>
                            </tr>)}
                        </tbody>
                    </table>
                </div>}
            </div>
        </Modal>
    )
}
