/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'

import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import TextField from '../Common/Fields/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Typography } from '@mui/material';
// import DatePicker from '../Common/Fields/DatePickerField';
import SelectField from '../Common/Fields/SelectField';
import { STATUS_FILTER_OPTIONS } from './Options/DocketForm';
import { CIRCUIT_TYPE_OPTIONS } from './Options/CircuitForm';
import { formatSelectOptionsList, useHirerList } from '../../hooks/circuit';
import Loader2 from '../Common/Loader2';
// import { useProviderList } from '../../hooks/core/provider';
import { getLocal } from '../../utils/localStorage';
import { CURRENT_PROVIDER_LOCAL_DATA } from '../../config';
import { formatUserOptionsList, useUsersList } from '../../hooks/user';
// import DateTimePicker from '../Common/Fields/DateTimePickerField';
import Datetime from 'react-datetime';
import moment from 'moment';
import { formatDateTime } from '../../utils/shortcuts';
import CheckboxField from '../Common/Fields/CheckboxField';

export default function DocketFilterForm({ isLoading, handleSubmit, setModal, resetFrom, searchParams }) {
    const { hirerData, HirerIsLoading } = useHirerList();
    // const { providersData, providerIsLoading } = useProviderList()
    // const { usersData, usersIsLoading } = useUsersList();
    const fiber_tl = useUsersList('?user_role=FTL');  // Fiber Team Leader *
    const techinicians = useUsersList('?user_role=FT');  // Technician *
    const [params, setParams] = useState({});
    const [loading, setLoading] = useState(true);
    const currentProvider = JSON.parse(getLocal(CURRENT_PROVIDER_LOCAL_DATA))

    useEffect(() => {
        const currentParams = Object.fromEntries([...searchParams]);
        // console.log('currentParams', params, params.hirer_name)
        setParams(currentParams)
        setLoading(false)
    }, [searchParams])
    // console.log('params', params)
    const validate = Yup.object({
        status: Yup.string(),
        hirer_name: Yup.string().nullable(),
        docket_id: Yup.string(),
        circuit__code: Yup.string(),
        fiber_team_leader: Yup.string().nullable(),
    }).shape({
        date_after: Yup.string().when('date_before', {
            is: (val) => val !== null,
            then: Yup.string().required('required').nullable(),
            otherwise: Yup.string().nullable()
        }),
        date_before: Yup.string().when('date_after', {
            is: (val) => val !== null,
            then: Yup.string().required('required').nullable(),
            otherwise: Yup.string().nullable()
        }),
    }, ['date_after', 'date_before']);

    if (loading) {
        return <Loader2 />
    }
    return (
        <Formik
            initialValues={{
                "date_after": params?.date_after ?? null,
                "date_before": params?.date_before ?? null,
                "status": params?.status ?? '',
                "circuit__circuit_type": params?.circuit__circuit_type ?? null,
                "hirer_name": params?.hirer_name ?? '',
                "circuit__code": params?.circuit__code ?? '',
                "code": params?.code ?? '',
                "docketed_by": params?.docketed_by ?? '',
                "fiber_team_leader": params?.fiber_team_leader ? parseInt(params?.fiber_team_leader) : '',
                "technician": params?.technician ? parseInt(params?.technician) : '',
                "circuit__location_a": params?.circuit__location_a ?? '',
                "circuit__location_b": params?.circuit__location_b ?? '',
                "customers_id": params?.customers_id ?? '',
                "provider": params?.provider ?? currentProvider?.id,
                "show_closed": params?.show_closed === 'true' ? true : false,
            }}
            validationSchema={validate}
            onSubmit={handleSubmit}
        >
            {formik => (

                <Form className="textFormAllField">
                    {/* {console.log('formdata -> ', formik)} */}

                    <div className='row'>
                        <div className="col-md-4 mb-4">
                            <TextField label='Docket ID' type="text" name='code' placeholder='Docket ID' />
                        </div>
                        <div className="col-md-4 mb-4">
                            <TextField label='Circuit ID' type="text" name='circuit__code' placeholder='Circuit ID' />
                        </div>
                        <div className="col-md-4 mb-4">
                            <TextField label='Customer ID' type="text" name='customers_id' placeholder='Customer ID' />
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-md-4 mb-4">
                            <TextField label='Location A' type="text" name='circuit__location_a' placeholder='Location A' />
                        </div>
                        <div className="col-md-4 mb-4">
                            <TextField label='Location B' type="text" name='circuit__location_b' placeholder='Location B' />
                        </div>
                        <div className="col-md-4 mb-4">
                            <TextField label='Docketed By' type="text" name='docketed_by' placeholder='Docketed By' />
                        </div>
                       
                    </div>
                    <div className='row'>
                        <div className="col-md-6 mb-4">
                            <Datetime
                                value={moment(formik.values.date_after)} // Pass the selected date as value
                                // value={formik.values.date_after} // Pass the selected date as value
                                onChange={(e) => formik.setFieldValue('date_after', formatDateTime(e, 'YYYY-MM-DD H:m'))}
                                name='date_after'
                                dateFormat="DD/MM/YYYY"
                                timeFormat="H:mm"
                                inputProps={{ // Set input properties
                                    placeholder: 'From Date', // Placeholder text
                                    
                                }}
                            />
                        </div>
                        <div className="col-md-6 mb-4">
                            {/* <DateTimePicker label='To Date' minDate='2022-04-01' name='date_before' /> */}
                            <Datetime
                                value={moment(formik.values.date_before)} // Pass the selected date as value
                                // value={formik.values.date_before} // Pass the selected date as value
                                onChange={(e) => formik.setFieldValue('date_before', formatDateTime(e, 'YYYY-MM-DD H:m'))}
                                name='date_before'
                                dateFormat="DD/MM/YYYY"
                                timeFormat="H:mm"
                                inputProps={{ // Set input properties
                                    placeholder: 'To Date', // Placeholder text
                                    
                                }}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-md-4 mb-4">
                            <Typography variant='caption'>Status</Typography>
                            <SelectField
                                name='status'
                                options={STATUS_FILTER_OPTIONS}
                                value={STATUS_FILTER_OPTIONS.filter(function (option) {
                                    return option.value === formik.values.status;
                                })}
                                onChange={(e) => formik.setFieldValue('status', e.value)}
                            />
                        </div>
                        <div className="col-md-4 mb-4">
                            <Typography variant='caption'>Circuit Type </Typography>
                            <SelectField
                                name='circuit__circuit_type'
                                options={CIRCUIT_TYPE_OPTIONS}
                                value={CIRCUIT_TYPE_OPTIONS.filter(function (option) {
                                    return option.value === formik.values.circuit__circuit_type;
                                })}
                                onChange={(e) => formik.setFieldValue('circuit__circuit_type', e.value)}
                            />
                        </div>
                        <div className="col-md-4 mb-4">
                            <Typography variant='caption'>Hirer </Typography>

                          
                            <SelectField
                                name='hirer_name'
                                options={hirerData?.data?.map(formatSelectOptionsList)}
                                value={hirerData?.data?.map(formatSelectOptionsList).filter(function (option) {
                                    return option.value.toString() === formik.values.hirer_name.toString();
                                })}
                                onChange={(e) => formik.setFieldValue('hirer_name', e.value)}
                                isLoading={HirerIsLoading}
                            />
                        </div>
                       
                        <div className="col-md-4 mb-4">
                            <Typography variant='caption'>Team Leader </Typography>
                            <SelectField
                                name='fiber_team_leader'
                                options={fiber_tl?.usersData?.data?.map(formatUserOptionsList)}
                                value={fiber_tl?.usersData?.data?.map(formatUserOptionsList).filter(function (option) {
                                    return option.value === formik.values.fiber_team_leader;
                                })}
                                onChange={(e) => formik.setFieldValue('fiber_team_leader', e.value)}
                                isLoading={fiber_tl?.usersIsLoading}
                            />
                        </div>
                        <div className="col-md-4 mb-4">
                            <Typography variant='caption'>Technician </Typography>
                            <SelectField
                                name='technician'
                                options={techinicians?.usersData?.data?.map(formatUserOptionsList)}
                                value={techinicians?.usersData?.data?.map(formatUserOptionsList).filter(function (option) {
                                    return option.value === formik.values.technician;
                                })}
                                onChange={(e) => formik.setFieldValue('technician', e.value)}
                                isLoading={fiber_tl?.usersIsLoading}
                            />
                        </div>
                        <div className="col-md-4 mb-4">
                            <Typography variant='caption'>Closed Docket </Typography>
                            <CheckboxField label='Show Closed Docket' name='show_closed' options={['Show Closed']} />
                        </div>
                    </div>


                    <LoadingButton
                        loading={isLoading}
                        type="submit"
                        variant="contained"
                        disableElevation
                    >
                        Search
                    </LoadingButton>
                    <Button variant="outlined" sx={{ ml: 1 }} disableElevation onClick={() => resetFrom(formik)}>Clear Filter</Button>
                    <Button variant="outlined" sx={{ ml: 1 }} disableElevation onClick={() => setModal(false)}>Close</Button>
                </Form>
            )}
        </Formik>
    )
}
