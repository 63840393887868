import Skeleton from "react-loading-skeleton";

export const docketSkeletonData = Array.from({ length: 20 }, (_, index) => ({
    code: <Skeleton width={100} />,
    created_at: <Skeleton width={100} />,
    get_circuit: {
        is_maintenance: <Skeleton width={100} />, // Placeholder value
        code: <Skeleton width={100} />,
        customer_id: <Skeleton width={100} />,
        customer_id_alt: <Skeleton width={100} />,
        location_a: <Skeleton width={100} />,
        address_a: <Skeleton width={150} />,
        location_b: <Skeleton width={100} />,
        address_b: <Skeleton width={150} />,
        ofc_distance: <Skeleton width={50} />,
    },
    get_fiber_tl: { name: <Skeleton width={100} /> },
    get_technician: { name: <Skeleton width={100} /> },
    get_problem: { title: <Skeleton width={150} /> },
    third_party_docket_no: <Skeleton width={100} />,
    get_hirer: { name: <Skeleton width={100} /> },
    status: <Skeleton width={80} />,
    updated_at: <Skeleton width={100} />,
    // get_closed_info: {
    //     vehicle1_to_pop: false, // Placeholder value
    //     vehicle1_pop_km: false, // Placeholder value
    //     vehicle2_to_pop: false, // Placeholder value
    //     vehicle2_pop_km: false, // Placeholder value
    //     vehicle3_to_pop: false, // Placeholder value
    //     vehicle3_pop_km: false, // Placeholder value
    // },
}));



export const MobileDocketActionSkeleton = () => (
    <div className='col-12'>
        <div className="d-flex gap-1">
            <Skeleton width={40} height={35} className="btn-style-light" />
            <Skeleton width={40} height={35} className="btn-style-light" />
            <Skeleton width={40} height={35} className="btn-style-light" />
            <Skeleton width={40} height={35} className="btn-style-light" />
            <Skeleton width={40} height={35} className="btn-style-light" />
        </div>
    </div>
);


export const DocketActionSkeleton = () => (
    <div className="d-flex gap-1">
        <Skeleton height={35} width={40} />
        <Skeleton height={35} width={40} />
        <Skeleton height={35} width={40} />
        <Skeleton height={35} width={40} />
        <Skeleton height={35} width={40} />
    </div>
);