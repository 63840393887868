// import moment from 'moment';
import React, { useState } from 'react'
import Modal from 'react-modal'
import { Link, useNavigate } from 'react-router-dom';
// import { toast } from 'react-toastify';
import { formatDateTime } from '../../utils/shortcuts';
// import { useUser } from '../../hooks/user';
// import { useDocketRequest } from '../../hooks/lco/dockets';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '700px',
        width: '100%'
    },
    overlay: { zIndex: 1000 }
};
export default function DocketUpdateRequestModal({ modal, setModal, data, setCurrentDocketReqData }) {
    // const { user } = useUser();
    // console.log('data ->', data)
    let navigate = useNavigate();


    const renderInformation = (type) => {
        if (type === 'Close') {
            return <>
                {data?.vehicle3 && <tr>
                    <th>Vehicle 3:</th>
                    <td colSpan={2}>
                        {data?.get_vehicles.vehicle3_name}
                        <br />
                        <small>
                            Start: {data?.vehicle3_start_km} - End: {data?.vehicle3_end_km} ({data?.get_vehicles?.vehicle3_total_km} KM)
                        </small>
                    </td>
                </tr>}
                <tr>
                    <th>Technician 1:</th>
                    <td>{data?.get_technician?.name ?? 'N/A'}</td>
                </tr>
                <tr>
                    <th>Technician 2:</th>
                    <td>{data?.get_technician2?.name ?? 'N/A'}</td>
                </tr>
                <tr>
                    <th>RFO:</th>
                    <td>{data?.get_rfo?.name ?? 'N/A'}</td>
                </tr>
                <tr>
                    <th>Confirmed By</th>
                    <td>{data?.confirmed_by}</td>
                </tr>
                <tr>
                    <th>Comment</th>
                    <td>{data?.comments ?? 'N/A'}</td>
                </tr>
                <tr>
                    <th>Materials:</th>
                    <td>
                        {data.materials.length !== 0 ? <table className='table'>
                            <thead className="table-dark table-sm">
                                <tr>
                                    <th><small>Material</small></th>
                                    <th><small>Value</small></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.materials &&
                                    data?.materials.map((val, i) =>
                                        <tr key={i}>
                                            <td>{val.field} {val.sub_field !== val.field ? `(${val.sub_field})` : ''}</td>
                                            <td>{val.value} <small>{val.unit && `(${val.unit})`}</small></td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table> : '- Not Updated -'}
                        <hr />
                        {data?.materials_additionals?.length !== 0 ? <table className='table'>
                            <thead className="table-dark table-sm">
                                <tr>
                                    <th style={{ width: '5%' }}><small>ID</small></th>
                                    <th style={{ width: '20%' }}><small>Fiber Type</small></th>
                                    <th style={{ width: '20%' }}><small>Comp. Name</small></th>
                                    <th style={{ width: '10%' }}><small>Drum No</small></th>
                                    <th style={{ width: '10%' }}><small>Start MT.</small></th>
                                    <th style={{ width: '10%' }}><small>End MT.</small></th>
                                    <th style={{ width: '10%' }}><small>Total MT.</small></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.materials_additionals &&
                                    data?.materials_additionals.map((val, i) =>
                                        <tr key={i}>
                                            <td>{val.id} </td>
                                            <td>{val.fiber_type} </td>
                                            <td>{val.company_name} </td>
                                            <td>{val.drum_no} </td>
                                            <td>{val.start_mt} </td>
                                            <td>{val.end_mt} </td>
                                            <td>{val.end_mt - val.start_mt} </td>

                                        </tr>
                                    )
                                }
                            </tbody>
                        </table> : '- Additional Not Updated -'}
                    </td>
                </tr>


            </>
        } else if (type === 'Hold') {
            return <>
                <tr>
                    <th>Remarks:</th>
                    <td className='text-dark'>{data?.remarks ?? 'N/A'}</td>
                </tr>
                <tr>
                    <th>Uploaded File:</th>
                    <td>
                        {data?.file ? <img src={data?.file} title={formatDateTime(data?.updated_at, 'MMMM Do YYYY, h:mm:ss a')} alt={`Image`} className="img-thumbnail mb-1" style={{ height: `26vh` }} /> : '-- No Upload'}
                    </td>
                </tr>

            </>
        } else if (type === 'Resume') {
            return <>
                <tr>
                    <th>Remarks:</th>
                    <td className='text-dark'>{data?.remarks ?? 'N/A'}</td>
                </tr>
               

            </>
        } else if (type === 'Cancel') {
            return <>
                <tr>
                    <th>Remarks:</th>
                    <td className='text-dark'>{data?.remarks ?? 'N/A'}</td>
                </tr>
                <tr>
                    <th>Uploaded File:</th>
                    <td>
                        {data?.file ? <img src={data?.file} title={formatDateTime(data?.updated_at, 'MMMM Do YYYY, h:mm:ss a')} alt={`Image`} className="img-thumbnail mb-1" style={{ height: `26vh` }} /> : '-- No Upload'}
                    </td>
                </tr>
               

            </>
        }
    }


    return (
        <Modal
            isOpen={modal}
            // onAfterOpen={afterOpenModal}
            style={customStyles}
            contentLabel={`Docket Request Modal`}
        >
            <div className="container-fluid">

                <div className="d-flex justify-content-between">
                    <h5 className="modal-title mb-3 text-center text-info">{data?.get_circuit?.code} Docket {data?.update_type} Request</h5>
                    <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setModal(false); setCurrentDocketReqData(null); }}></button>
                </div>
                <div className="modal-body" style={{ width: '100%' }}>
                    <div className="d-flex justify-content-between">
                        <div>
                            <p className="display-6 text-dark m-0">Docket ID:</p>
                        </div>
                        <div>
                            <h3 className="display-6 text-danger m-0"><Link to={`/docket/${data?.get_docket?.code}`} target='_blank'>{data?.get_docket?.code}</Link></h3>
                        </div>

                    </div>
                    <hr />
                    <table className='table m-0'>
                        <tbody>


                            <tr>
                                <th>Provider:</th>
                                <td className='text-dark'>{data?.get_docket?.provider}</td>
                            </tr>

                            <tr>
                                <th>Request Type:</th>
                                <td className='text-dark'>{data?.update_type}</td>
                            </tr>
                            <tr>
                                <th>Current Status:</th>
                                <td className='text-dark'>{data?.status}</td>
                            </tr>
                            <tr>
                                <th>Problem:</th>
                                <td className='text-dark'>{data?.get_docket?.problem}</td>
                            </tr>
                            <tr>
                                <th>Circuit:</th>
                                <td className='text-dark'>{data?.get_circuit?.code}</td>
                            </tr>
                            <tr>
                                <th>Location:</th>
                                <td colSpan={2}>
                                    <strong>A: </strong>{data?.get_circuit?.location_a || 'N/A'}
                                    <br />
                                    <strong>b: </strong>{data?.get_circuit?.location_b || 'N/A'}

                                    {/* <small>
                                        Start: {data.vehicle1_start_km} - End: {data.vehicle1_end_km} ({data?.get_vehicles?.vehicle1_total_km} KM)
                                    </small> */}

                                </td>
                            </tr>
                            <tr>
                                <th>Vehicle 1:</th>
                                <td colSpan={2}>
                                    {data.get_vehicles.vehicle1_name || 'N/A'}
                                    <br />
                                    <small>
                                        Start: {data.vehicle1_start_km} - End: {data.vehicle1_end_km} ({data?.get_vehicles?.vehicle1_total_km} KM)
                                    </small>

                                </td>
                            </tr>
                            {data.vehicle2 && <tr>
                                <th>Vehicle 2:</th>
                                <td colSpan={2}>
                                    {data.get_vehicles.vehicle2_name}
                                    <br />
                                    <small>
                                        Start: {data.vehicle2_start_km} - End: {data.vehicle2_end_km} ({data?.get_vehicles?.vehicle2_total_km} KM)
                                    </small>

                                </td>

                            </tr>}
                            {renderInformation(data.update_type)}
                            <tr>
                                <th>Requested By:</th>
                                <td>
                                    {data?.get_req_by?.account_name}
                                    <br />
                                    <small>
                                        {formatDateTime(data.created_at, 'LLLL')}
                                    </small>
                                </td>
                            </tr>
                            {data?.get_update_by && <tr>
                                <th>Action Taken By:</th>
                                <td>
                                    {data?.get_update_by?.account_name ?? 'N/A'}
                                    <br />
                                    <small>{formatDateTime(data.updated_at, 'LLLL')}</small>
                                </td>
                            </tr>}

                        </tbody>
                    </table>
                    <div className="d-flex justify-content-between align-items-center mt-3">
                        <div>
                        </div>
                        <div>
                            <button className='btn btn-dark' onClick={() => setModal(false)}> <i className="fa fa-times" aria-hidden="true"></i>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
