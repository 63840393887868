import { useMutation } from "@tanstack/react-query";
import { getTokens } from "../../utils/shortcuts";
import { fetchJson } from "../../libs/api";
import { API_HOST_URL } from "../../config";
import { endpoints } from "../../config/endpoints";


export function useSendDocketSMS() {
    const { accessToken } = getTokens();
    const mutation = useMutation(({ docket_id }) => fetchJson(`${API_HOST_URL}/${endpoints.docket.send_sms}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({ docket_id })
    }, true), {
        retry: 2,
    })

    return {
        handleSendDocketSMS: async (docket_id) => {
            try {
                const res = await mutation.mutateAsync({ docket_id });
                return await res.json();
            } catch (err) {
                return {
                    success: false,
                    error: 'something wents wrong.'
                }
            }
        },
        isSendingDocketSMS: mutation.isLoading,
    }
}

export function useSendDocketEmail() {
    const { accessToken } = getTokens();
    const mutation = useMutation(({ docket_id }) => fetchJson(`${API_HOST_URL}/${endpoints.docket.send_email}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({ docket_id })
    }, true), {
        retry: 2,
    })

    return {
        handleSendDocketEmail: async (docket_id) => {
            try {
                const res = await mutation.mutateAsync({ docket_id });
                return await res.json();
            } catch (err) {
                return {
                    success: false,
                    error: 'something wents wrong.'
                }
            }
        },
        isSendingDocketEmail: mutation.isLoading,
    }
}