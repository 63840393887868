import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { formatSelectOptionsList } from '../../../hooks/circuit';
import SelectField from '../../Common/Fields/SelectField';
import TextField from '../../Common/Fields/TextField';
import { formatUserOptionsList, useTeamList } from '../../../hooks/user';
import { useRFOList } from '../../../hooks/docket';
import { useFilterUsers } from '../../../hooks/user/roles';

export default function BasicDocket({ formik, data, disabled }) {
    const { filterUsers, isFilterIsLoading } = useFilterUsers()
    const { rfosData, rfosIsLoading } = useRFOList();
    // const { teamsData, teamsIsLoading } = useTeamList();

    const [ftDatas, setFtDatas] = useState([]) // Fiber Technician *

    const getTechnicians = async (value) => {
        // const data = await filterUsers(`?user_role=FT&parent_role=${value}`)
        const data = await filterUsers(`?all_fiber=true`)
        data.success && setFtDatas(data.data)
    }
    useEffect(() => {
        data.docket.fiber_team_leader && getTechnicians(data.docket.fiber_team_leader)
    }, [])
    return (
        <div className="card">
            <div className="card-body">
                {/* Technician */}
                <div className="row">
                    <div className='col-12'>
                        <h3 className='text-center display-6 mb-4 text-primary' style={{ 'fontSize': '22px' }}>BASIC DOCKET</h3>
                    </div>
                    <div className="col-md-4 mb-4">
                        <Typography>Technician 1 </Typography>

                        <SelectField
                            name='technician'
                            options={ftDatas.map(formatUserOptionsList)}
                            value={ftDatas.map(formatUserOptionsList).filter(function (option) {
                                return option.value === formik.values.technician;
                            })}
                            onChange={(e) => formik.setFieldValue('technician', e.value)}
                            isLoading={isFilterIsLoading && ftDatas.length === 0}
                            // isDisabled={ftDatas.length === 0}
                            isDisabled={disabled}
                        />

                    </div>
                    <div className="col-md-4 mb-4">
                        <Typography>Technician 2</Typography>

                        <SelectField
                            name='technician2'
                            options={ftDatas.map(formatUserOptionsList)}
                            value={ftDatas.map(formatUserOptionsList).filter(function (option) {
                                return option.value === formik.values.technician2;
                            })}
                            onChange={(e) => formik.setFieldValue('technician2', e.value)}
                            isLoading={isFilterIsLoading && ftDatas.length === 0}
                            // isDisabled={ftDatas.length === 0}
                            isDisabled={disabled}
                        />
                    </div>
                    <div className="col-md-4 mb-4">
                        <Typography>RFO <span className='req'>*</span></Typography>

                        <SelectField
                            name='rfo'
                            options={rfosData?.data.map(formatSelectOptionsList)}
                            value={rfosData?.data.map(formatSelectOptionsList).filter(function (option) {
                                return option.value === formik.values.rfo;
                            })}
                            onChange={(e) => formik.setFieldValue('rfo', e.value)}
                            isDisabled={disabled}
                            isLoading={rfosIsLoading}

                        />
                    </div>

                </div>
                {/* Vehicle */}
                <div className="row">
                    <div className="col-md-6">
                        <TextField label='Confirmed By *' disabled={disabled} type="text" name='confirmed_by' placeholder='Enter Confirmed By' sx={{ mb: 3 }} />
                    </div>
                    <div className="col-md-6">
                        <TextField label='Comments' disabled={disabled} type="text" name='comments' placeholder='Enter Comments' sx={{ mb: 3 }} />
                    </div>
                </div>
            </div>
        </div>
    )
}
