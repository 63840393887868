import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
// import moment from "moment/moment";
// import { useState } from "react";
import { API_HOST_URL } from "../../config";
import { endpoints } from "../../config/endpoints";
import { fetchJson } from "../../libs/api";
import { getTokens } from "../../utils/shortcuts";
import { useState } from "react";
import { DOCKETS_QUERY_KEY, DOCKETS_UNDER_REVIEW_QUERY_KEY, DOCKET_QUERY_KEY } from "../docket/query_keys";



const PROBLEMS_QUERY_KEY = 'lco_problems'
const DOCKET_REQUESTS_QUERY_KEY = 'lco_docket_requests'
const ADMIN_DOCKET_REQUESTS_QUERY_KEY = 'admin_docket_requests'


// Get LCOProblems List
export function useLCOProblemsList() {
    const { isLoading, data } = useQuery([PROBLEMS_QUERY_KEY], async () => {
        try {
            const { accessToken } = getTokens();
            const res = await fetchJson(`${API_HOST_URL}/${endpoints.lco.problems_list}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            }, true)
            return await res.json();
        } catch (err) {
            return {
                success: false,
                detail: err
            };
        }
    }, {
        // cacheTime: Infinity,
        // staleTime: 30_000, // ms
        cacheTime: 0,
        staleTime: 1,
    })
    return { problemsData: data, problemsIsLoading: isLoading }
}



export function useDocketRequestList(defaultParam = '', enabled = true) {
    const [param, setParam] = useState(null);
    const { isLoading, data } = useQuery([DOCKET_REQUESTS_QUERY_KEY, { param: param, defaultParam }], async (key) => {
        const param = key.queryKey[1].param;
        const defaultParam = key.queryKey[1].defaultParam;
        try {
            const { accessToken } = getTokens();
            const param_url = param ? param : defaultParam;
            const res = await fetchJson(`${API_HOST_URL}/${endpoints.lco.docket_request}${param_url}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            }, true)
            return await res.json()

        } catch (err) {
            return { success: false, detail: err };
        }
    }, {
        // cacheTime: Infinity,
        // staleTime: 30_000,
        cacheTime: 0,
        staleTime: 1,
        refetchOnWindowFocus:true,
        refetchOnMount:true,
        enabled: enabled,
    })
    return {
        docketRequestsData: data, 
        DocketRequestsIsLoading: isLoading,
        filterDocReqList: async (value) => setParam(value),
    }
}

// export function useDocketRequestList(enabled = true) {
//     const { isLoading, data } = useQuery([DOCKET_REQUESTS_QUERY_KEY], async () => {
//         try {
//             const { accessToken } = getTokens();
//             const res = await fetchJson(`${API_HOST_URL}/${endpoints.lco.docket_request}`, {
//                 headers: { 'Authorization': `Bearer ${accessToken}` },
//             }, true)
//             return await res.json();
//         } catch (err) {
//             return {
//                 success: false,
//                 detail: err
//             };
//         }
//     }, {
//         // cacheTime: Infinity,
//         // staleTime: 30_000, // ms
//         cacheTime: 0,
//         staleTime: 1,
//         refetchOnWindowFocus:true,
//         refetchOnMount:true,
//         enabled: enabled,
//     })
//     return { docketRequestsData: data, DocketRequestsIsLoading: isLoading }
// }

export function useDocketRequestListAdmin(defaultParam = '', enabled = true) {
    const [param, setParam] = useState(null);
    const { isLoading, data } = useQuery([ADMIN_DOCKET_REQUESTS_QUERY_KEY, { param: param, defaultParam }], async (key) => {
        const param = key.queryKey[1].param;
        const defaultParam = key.queryKey[1].defaultParam;
        try {
            const { accessToken } = getTokens();
            const param_url = param ? param : defaultParam;
            const res = await fetchJson(`${API_HOST_URL}/${endpoints.lco.docket_request_list}${param_url}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            }, true)
            return await res.json()

        } catch (err) {
            return { success: false, detail: err };
        }
    }, {
        cacheTime: Infinity,
        staleTime: 30_000,
        enabled: enabled,
    })
    return {
        docketRequestsData: data,
        docketRequestsIsLoading: isLoading,
        filterRequestsList: async (value) => setParam(value),
    }
}


// Save / Update Docket Request
export function useDocketRequest(method) {
    const { accessToken } = getTokens();
    const queryClient = useQueryClient();
    const mutation = useMutation((data) => fetchJson(`${API_HOST_URL}/${endpoints.lco.docket_request}${method === 'PATCH' ? '/' + data.id : ''}`, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(data)
    }, true), {
        retry: 2,
    })
    return {
        handleDocketRequestAction: async (data) => {
            try {
                const res = await mutation.mutateAsync(data);
                const resData = await res.json()
                if (resData.success) {
                    await queryClient.invalidateQueries([DOCKET_REQUESTS_QUERY_KEY])
                }
                return resData
               
            } catch (err) {
                return {
                    success: false,
                    detail: err
                }
            }
        },

        docketRequestIsLoading: mutation.isLoading,
    }
}


// LCO Create New Docket
export function useNewDocket(method) {
    const { accessToken } = getTokens();
    const queryClient = useQueryClient();
    const mutation = useMutation((data) => fetchJson(`${API_HOST_URL}/${endpoints.lco.create_new_docket}${method === 'PATCH' ? '/' + data.id : ''}`, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(data)
    }, true), {
        retry: 2,
    })
    return {
        handleNewDocketAction: async (data) => {
            try {
                const res = await mutation.mutateAsync(data);
                const resData = await res.json()
                if (resData.success) {
                    await queryClient.invalidateQueries([DOCKETS_UNDER_REVIEW_QUERY_KEY])
                    await queryClient.invalidateQueries([DOCKETS_QUERY_KEY])
                }
                return resData
               
            } catch (err) {
                return {
                    success: false,
                    detail: err
                }
            }
        },
        isCreatingNewDocket: mutation.isLoading,
    }
}
// Docket Accept & Assign Technician
export function useNewDocketAction(method) {
    const { accessToken } = getTokens();
    const queryClient = useQueryClient();
    const mutation = useMutation((data) => fetchJson(`${API_HOST_URL}/${endpoints.docket.docket_assign}${method === 'PATCH' ? '/' + data.id : ''}`, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(data)
    }, true), {
        retry: 2,
    })
    return {
        handleAssignDocketAction: async (data) => {
            try {
                const res = await mutation.mutateAsync(data);
                const resData = await res.json()
                if (resData.success) {
                    await queryClient.invalidateQueries([DOCKETS_UNDER_REVIEW_QUERY_KEY])
                    await queryClient.invalidateQueries([DOCKET_QUERY_KEY])
                    await queryClient.invalidateQueries([DOCKETS_QUERY_KEY])
                }
                return resData
               
            } catch (err) {
                return {
                    success: false,
                    detail: err
                }
            }
        },
        isCreatingAssignDocket: mutation.isLoading,
    }
}

// CANCEL LCO DOCKET
export function useCancelDocketAction(method) {
    const { accessToken } = getTokens();
    const queryClient = useQueryClient();
    const mutation = useMutation((data) => fetchJson(`${API_HOST_URL}/${endpoints.lco.cancel_docket}${method === 'PATCH' ? '/' + data.id : ''}`, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(data)
    }, true), {
        retry: 2,
    })
    return {
        handleCancelDocket: async (data) => {
            try {
                const res = await mutation.mutateAsync(data);
                const resData = await res.json()
                if (resData.success) {
                    await queryClient.invalidateQueries([DOCKETS_UNDER_REVIEW_QUERY_KEY])
                    await queryClient.invalidateQueries([DOCKET_QUERY_KEY])
                    await queryClient.invalidateQueries([DOCKETS_QUERY_KEY])
                }
                return resData
               
            } catch (err) {
                return {
                    success: false,
                    detail: err
                }
            }
        },
        isCancellingDocket: mutation.isLoading,
    }
}