import React from 'react'
import Modal from 'react-modal'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import DocketRequestForm from './Forms/DocketRequestForm';
import { useDocketRequest } from '../../hooks/lco/dockets';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '700px',
    },
    overlay: { zIndex: 1000 }
};
export default function DocketRequestModal({ modal, setModal, currentData, setCurrentData }) {
    let navigate = useNavigate();
    const { handleDocketRequestAction, docketRequestIsLoading } = useDocketRequest('POST')
    const handleDocketRequest = async (values, { resetForm }) => {
        const res = await handleDocketRequestAction({...values, circuit: currentData.id})
        res.success ? toast.success(res.detail) : toast.error(res.detail.replace("detail:", ""));
        setModal(false)
    }

    return (
        <Modal
            isOpen={modal}
            // onAfterOpen={afterOpenModal}
            // onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Docket Request Modal"
        >
            <div className="container-fluid">

                <div className="d-flex justify-content-between">
                    <h5 className="modal-title mb-3 text-center text-info">Create New Docket Request!</h5>
                    <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setModal(false); setCurrentData(null); }}></button>
                </div>
                <div className="modal-body" style={{ width: '100%' }}>
                    <div className="d-flex justify-content-between mb-4">
                        <div>
                            <p className="display-6 text-dark m-0">Circuit ID:</p>
                        </div>
                        <div>
                            <h3 className="display-6 text-danger m-0">{currentData?.code}</h3>
                        </div>
                    </div>

                    {/* <hr /> */}
                    <DocketRequestForm
                        setModal={setModal}
                        isLoading={docketRequestIsLoading}
                        handleSubmit={handleDocketRequest}
                        btnLabel='Raise Docket Request'
                        isCreateMode={false}
                    />
                </div>
            </div>
        </Modal>
    )
}
