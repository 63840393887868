import React from 'react'
import Modal from 'react-modal'
// import { useNavigate } from 'react-router-dom';
// import { toast } from 'react-toastify';
// import { useDocket, useDocketUpdate } from '../../hooks/docket';
// import DocketUpdateForm from '../Forms/DocketUpdateForm';
import { formatDateTime } from '../../utils/shortcuts';
import ImagePreviewModal from '../Models/ImagePreviewModal';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '700px',
    },
    overlay: { zIndex: 1000 }
};
export default function DocketUploadsModal({ modal, setModal, holdUploads, setHoldUploads }) {
    // let navigate = useNavigate();
    return (
        <Modal
            isOpen={modal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <div className="container-fluid">

                <div className="d-flex justify-content-between">
                    <h5 className="modal-title mb-3 text-info">Uploads</h5>
                    <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setModal(false); setHoldUploads(null); }}></button>
                </div>
                <div className="modal-body" style={{ width: '100%' }}>

                    <div className='row'>
                        {holdUploads && holdUploads.map((data, i) =>
                            // <div className='col-md-2 col-6' key={i}>
                            //     <img src={data.file} key={i} title={formatDateTime(data.updated_at, 'MMMM Do YYYY, h:mm:ss a')} alt={`Upload ${i+1}`} className="img-thumbnail mb-1" style={{height:`50vh` }} />
                            // </div>
                            <ImagePreviewModal
                                modal={modal}
                                setModal={setModal}
                                src={data.file}
                                text={formatDateTime(data.updated_at, 'MMMM Do YYYY, h:mm:ss a')}
                            />

                        )}
                    </div>

                </div>
            </div>
        </Modal>
    )
}
