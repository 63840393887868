import React from 'react'
import { Link, NavLink } from 'react-router-dom'

export default function FooterMenu() {
    return (
        <div>
            {/* mobile iner responsive design */}
            <div className='conainer-fluid mobileMenuDesign position-fixed py-2 d-lg-none'>
                <div className='row'>
                    <div className='col'>
                        <div className='IconsWithText text-center'>
                            <NavLink to='/' className={({ isActive }) => isActive ? 'active' : ''}>
                                <div className='iconMenu'>
                                    <i className="fas fa-home"></i>
                                </div>
                                <div className='iconMenuText'>Home</div>
                            </NavLink>
                        </div>

                    </div>
                    <div className='col'>
                        <div className='IconsWithText text-center'>
                            <NavLink to='/circuit' className={({ isActive }) => isActive ? 'active' : ''}>
                                <div className='iconMenu'>
                                    <i className="fas fa-compass"></i>
                                    <div className='iconMenuText'>Circuits</div>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                    <div className='col'>
                        <div className='IconsWithText text-center'>
                            <NavLink to='/docket' className={({ isActive }) => isActive ? 'active' : ''}>
                                <div className='iconMenu'>
                                    <span className="material-icons-sharp">push_pin</span>
                                </div>
                                <div className='iconMenuText'>Dockets</div>
                            </NavLink>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div >
    )
}
