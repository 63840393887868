import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { useField } from 'formik';
import FormHelperText from '@mui/material/FormHelperText';

export default function RadioField({ options, label, ...props }) {
    const [field, meta] = useField(props);
    return (
        <FormControl error={meta.touched && Boolean(meta.error)} variant="standard">
            <RadioGroup row>
                {options.map((value, index) => (
                    <FormControlLabel
                        key={index}
                        {...field}
                        {...props}
                        control={<Radio />}
                        label={value.label}
                        value={value.value}
                        checked={meta.value === value.value}
                    />
                ))}
            </RadioGroup>
            <FormHelperText>{meta.touched && meta.error}</FormHelperText>
        </FormControl>
    )
}