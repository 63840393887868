import { Container, Grid } from '@mui/material';
import React, { useState } from 'react'
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import CircuitInformation from '../../components/Circuit/Information';
import PageBreadcrumbs from '../../components/Common/Breadcrumbs';
import Loader from '../../components/Common/Loader';
import { useCircuitDetail } from '../../hooks/circuit';
import { useUser } from '../../hooks/user';
import Alert from '../../components/Common/Alert';
import { formatDateTime } from '../../utils/shortcuts';

export default function CircuitDetailPage() {
    const { user } = useUser();
    const { id } = useParams();
    const [historyTab, setHistoryTab] = useState(false);
    const { circuitData, circuitIsLoading } = useCircuitDetail(id);
    // console.log(circuitData)
    return (
        <>
            <Helmet>
                <title>Circuits - {`${circuitData?.data?.code} Information`}</title>
            </Helmet>
            <Container maxWidth="xxl" disableGutters>
                <PageBreadcrumbs
                    title={circuitData?.data?.code}
                    history={[{ name: 'Circuits', to: '/circuit' }]}
                    RightComp={() =>
                        <div className='d-flex gap-1'>
                            {user?.permissions?.circuits?.change && <Link to={`/circuit/update/${circuitData?.data?.id}`} className="btn btn-secondary btn-style-light btn-sm"><i className="material-icons">edit</i> Edit</Link>}



                            {user?.permissions?.dockets?.can_create_adv_docket && <>
                                {circuitData?.data?.has_recent_docket?.status ? <Link className="btn btn-danger pb-0 btn-style-light btn-sm" style={{ marginLeft: 5 }} to={`/docket/add?circuit=${circuitData?.data?.id}`} title={`Docket Reopened until ${formatDateTime(circuitData?.data?.has_recent_docket.opened_until, 'MMMM Do YYYY, h:mm a')}`}>
                                    <span className="material-icons-sharp">restart_alt</span></Link> : circuitData?.data?.status === 'Active' | circuitData?.data?.status === 'Under Termination' ? <Link to={`/docket/add?circuit=${circuitData?.data?.id}`} className='btn btn-danger pb-0 btn-style-light btn-sm' title='New Docket'>
                                        <i className="material-icons-sharp">
                                            push_pin
                                        </i> Docket
                                    </Link> : ''}
                            </>}

                            <button className="btn btn-dark btn-style-light btn-sm" onClick={() => setHistoryTab(!historyTab)}>
                                <i className="material-icons">manage_history</i>{historyTab ? ' Hide History' : ' Show History'}</button>
                        </div>
                    }
                />

                <Grid container>
                    <Grid>
                        {circuitData?.data?.status === 'Under Termination' && <Alert
                            title='Warning: Under Termination!'
                            text="You can't update any field until you change the status of current circuit."
                            type='warning'
                        />}
                        {circuitData?.data?.status === 'Under Maintenance' && circuitData?.data?.get_maintenance_date && <Alert
                            title='Warning: Under Maintenance!'
                            text={`This Circuit is in Under Maintenance from ${formatDateTime(circuitData?.data?.get_maintenance_date?.start_from, 'MMM Do YYYY, h:mm:ss a')} to ${formatDateTime(circuitData?.data?.get_maintenance_date?.end_to, 'MMM Do YYYY, h:mm:ss a')}.`}
                            type='warning'
                        />}
                    </Grid>
                    <Grid item xs={12} sx={{ p: 1 }}>

                        {circuitIsLoading && <Loader />}

                        {user?.permissions?.circuits?.view && circuitData?.success && <CircuitInformation data={circuitData?.data} historyTab={historyTab} setHistoryTab={setHistoryTab} />}

                        {!user?.permissions?.circuits?.view && circuitData?.success && <Alert
                            title='Permission Error!'
                            text='You do not have permission to perform this action.'
                            type='danger'
                        />}
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}
