import React from 'react'
import DocketCloseForm from '../Forms/DocketCloseForm'
import { useCloseDocket } from '../../hooks/docket'
import { toast } from 'react-toastify';
import DocketCloseFormVertical from '../Forms/DocketCloseForm/DocketCloseFormVertical';
// import { useNavigate } from 'react-router-dom';

export default function CloseDocketAction({ data, setInfoModal }) {
    // let navigate = useNavigate();
    const { handleDocketCloseAction, docketCloseActionIsLoading } = useCloseDocket('PATCH');
    const handleSubmit = async (values, { resetForm }) => {
        // console.log('Close Docket Form Submit: ', values)
        const { technician, technician2, technician3, materials } = values;
        // console.log('materials: ', materials)
        if (materials.filter(function (e) { !e.value && toast.error(`${e.field} material value is required.`); return e.value === ''; }).length > 0) {
            // IT WILL EXECUTE ONLY ONCE ON VALIDATIOn ERROR OF MATERIALAS +
            return;
        }

        const res = await handleDocketCloseAction({
            ...values,
            ch_hh: values.ch_hh | 0,
            ch_mm: values.ch_mm | 0,
            ch_ss: values.ch_ss | 0,
            oh_hh: values.oh_hh | 0,
            oh_mm: values.oh_mm | 0,
            oh_ss: values.oh_ss | 0,
            docket: {
                technician,
                technician2,
                technician3,
                status: 'Resolved',
            },
            id: data.docket.code,
            action: 'resolved',
            remarks: 'Docked Closed / Resolved.'
        })
        // console.log('res -> ', res)

        if (res.success) {
            toast.success(`#${data.docket.code} - ${res.detail}`);
            // navigate(`/docket`);
            setInfoModal(true)
        } else {
            toast.error(res.detail)
        }

    }

    const renderFormType = () => {
        if (window.screen.width < 767) {
            return <DocketCloseFormVertical
                data={data}
                isLoading={docketCloseActionIsLoading}
                handleSubmit={handleSubmit}
                submitBtnLabel='Close Docket'
            />
        } else {
            return <DocketCloseForm
                data={data}
                isLoading={docketCloseActionIsLoading}
                handleSubmit={handleSubmit}
                submitBtnLabel='Close Docket'
            />
        }
    }

    return renderFormType()
    return (
        <>
            {/* <DocketCloseFormVertical
                data={data}
                isLoading={docketCloseActionIsLoading}
                handleSubmit={handleSubmit}
                submitBtnLabel='Close Docket'
            /> */}

        </>
    )
}
