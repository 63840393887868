import React, { useState } from 'react'
import { formatDateTime } from '../../utils/shortcuts'
import { Link } from 'react-router-dom';
import { MobileActionButtonsSkeleton } from '../../pages/circuit/Components/SkeletonLoader';

export default function MobileCircuits({ data, user, setDocketRequestData, setDocketRequestModal, setCurrentData, setCreateModal, setCorporateDocketModal, circuitsIsLoading }) {
    const [showTab, setTab] = useState(0)
    return (
        <div>
            <div className='row mt-3'>
                <div className='col-12'>
                    {data.map((row, i) => <div className="shadow-sm p-3 mb-2 bg-white rounde" key={i}>
                        <div className='row' onClick={() => setTab(i)}>
                            <div className='col-6'>
                                <h6 className='mb-0'>
                                    <button type="button" className="btn btn-secondary btn-sm text-center me-2 downBtnTable"><i className={`fas fa-chevron-${showTab === i ? 'down' : 'right'}`}></i></button>
                                    {row?.code}
                                </h6>
                            </div>
                            <div className='col-6  text-end'>
                                <h6 className='mb-0'>
                                    {row?.status}
                                </h6>
                            </div>
                            <div className='col-12 mt-3'>
                                <div className='row m-0'>
                                    <div className='col-3'>
                                        <p className='mb-1'>Loc. A:</p>
                                    </div>
                                    <div className='col-9 text-end'>
                                        <p className='mb-1'>{row?.location_a}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className='row m-0'>
                                    <div className='col-3'>
                                        <p className='mb-1'>Loc. B:</p>
                                    </div>
                                    <div className='col-9 text-end'>
                                        <p className='mb-1'>{row?.location_b}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {showTab === i && <div className='row detailsinerTableMobile'>
                            <div className='col-12 mb-2'>
                                <div className='row'>
                                    <div className='col-3'>
                                        <h6>ID:</h6>
                                    </div>
                                    <div className='col-9'>
                                        <h6>{row?.code}</h6>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 mb-2'>
                                <div className='row'>
                                    <div className='col-3'>
                                        <h6>Type:</h6>
                                    </div>
                                    <div className='col-9'>
                                        <h6>{row?.circuit_type} {row?.link_type ? `(${row?.link_type})` : ''}</h6>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 mb-2'>
                                <div className='row'>
                                    <div className='col-3'>
                                        <h6>Cust. ID:</h6>
                                    </div>
                                    <div className='col-9'>
                                        <h6>{row?.customer_id}</h6>
                                    </div>
                                </div>
                            </div>

                            <div className='col-12 mb-2'>
                                <div className='row'>
                                    <div className='col-3'>
                                        <h6>Created:</h6>
                                    </div>
                                    <div className='col-9'>
                                        <h6>{formatDateTime(row.created_at, 'DD-MM-YY h:mm a')}</h6>
                                    </div>
                                </div>
                            </div>


                            <div className='col-12 mb-2'>
                                <div className='row'>
                                    <div className='col-3'>
                                        <h6>Updated:</h6>
                                    </div>
                                    <div className='col-9'>
                                        <h6>{formatDateTime(row.updated_at, 'DD-MM-YY h:mm a')}</h6>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 mb-2'>
                                <div className='row'>
                                    {circuitsIsLoading ? <MobileActionButtonsSkeleton /> : <div className='col-12'>

                                        <div className="d-flex gap-1">
                                            <Link to={`/circuit/${row.id}`} className='btn btn-primary pb-0 btn-style-light' title='View'>
                                                <span className="material-icons-sharp">
                                                    visibility
                                                </span>
                                            </Link>
                                            {user?.permissions?.circuits?.change && <Link to={`/circuit/update/${row.id}`} className='btn btn-dark pb-0 btn-style-light' title='Update Circuit'>
                                                <span className="material-icons-sharp">
                                                    mode_edit
                                                </span>
                                            </Link>}

                                            {user?.permissions?.dockets?.can_create_adv_docket && user?.user_role === 'EXP' && <>
                                                {row?.has_recent_docket?.status ? <Link className="btn btn-danger pb-0 btn-style-light" style={{ marginLeft: 5 }} to={`/docket/add?circuit=${row.id}`} title={`Docket Reopened until ${formatDateTime(row.has_recent_docket.opened_until, 'MMMM Do YYYY, h:mm a')}`}>
                                                    <span className="material-icons-sharp">restart_alt</span></Link> : row.status === 'Active' | row.status === 'Under Termination' ? <Link to={`/docket/add?circuit=${row.id}`} className='btn btn-danger pb-0 btn-style-light' title='New Docket'>
                                                        <span className="material-icons-sharp">
                                                            flag
                                                        </span>
                                                    </Link> : ''}
                                            </>}
                                            {/* {console.log(user?.permissions?.dockets)} */}
                                            {user?.permissions?.dockets?.can_create_docket && row.status === 'Active' && !row?.active_docket?.status && row?.circuit_type !== 'Leased Line' && row?.circuit_type !== 'WAN' && <button onClick={() => { setCurrentData(row); setCreateModal(true); }} className='btn btn-danger pb-0 btn-style-light mx-2' title='Create New Docket'>
                                                <span className="material-icons-sharp">
                                                    push_pin
                                                </span>
                                            </button>}
                                            {user?.permissions?.dockets?.can_request_docket && row.status === 'Active' && !row?.active_docket?.status && <button onClick={() => { setDocketRequestData(row); setDocketRequestModal(true); }} className='btn btn-warning pb-0 btn-style-light' title='Docket Request'>
                                                <span className="material-icons-sharp">
                                                    waving_hand
                                                </span>
                                            </button>}
                                            {/* Corporate Docket */}
                                            {user?.permissions?.dockets?.can_create_corp_docket && row.status === 'Active' && !row?.active_docket?.status && row?.circuit_type === 'Corporate' && <button onClick={() => { setCurrentData(row); setCorporateDocketModal(true); }} className='btn btn-danger pb-0 btn-style-light mx-2' title='New Corporate Docket'>
                                                <span className="material-icons-sharp">
                                                    back_hand
                                                </span>
                                            </button>}
                                        </div>

                                    </div>}
                                </div>
                            </div>
                        </div>}
                    </div>
                    )}

                </div>

            </div>
        </div>
    )
}
