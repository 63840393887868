import React from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import LoadingButton from '@mui/lab/LoadingButton';
import { Link } from 'react-router-dom';
import TextField from '../../Common/Fields/TextField';
import { IS_ACTIVE_FILTER_OPTIONS_BOOL, USER_ROLE_OPTIONS } from '../Options/UserForm';
import { formatUserOptionsList, useUsersList } from '../../../hooks/user';
import { useUserGroupsList } from '../../../hooks/user/groups';
import { Typography } from '@mui/material';
import SelectField from '../../Common/Fields/SelectField';
import { formatSelectOptionsList } from '../../../hooks/circuit';
// import Alert from '@mui/material/Alert';
// import { Stack } from '@mui/material';


export default function UserAddForm({ isLoading, handleSubmit }) {
    const { usersData, usersIsLoading } = useUsersList(); // '?is_parent=false'
    const { groupsData, isGroupsLoading } = useUserGroupsList('');

    const phoneSchema = Yup.string().phone('IN', true);
    const validate = Yup.object({
        full_name: Yup.string()
            .min(6, 'Enter your leagal name.')
            .required('Enter your full name.'),
        email: Yup.string().email().required('Email is required.'),
        password: Yup.string()
            .min(6, 'Password must be at least 6 characters.')
            .required('Password is required.'),
        password2: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Password must match.')
            .required('Confirm password is required.'),

        mobile: Yup.string().test('test-phone', 'Mobile not valid.', (value) => {
            if (value) return phoneSchema.isValidSync(value);
            return true;
        }),
    })
    return (
        <Formik
            initialValues={{
                full_name: '',
                email: '',
                mobile: '',
                user_role: '',
                gender: null,
                is_active: true,
                is_staff: false,
                is_superuser: false,
                parent_role: '',
                groups: '',
                password: '',
                password2: '',
            }}
            validationSchema={validate}
            onSubmit={handleSubmit}
        >
            {formik => (
                <Form>
                    <div className='row'>
                        <div className="col-md-12 mb-2">

                            <TextField label='Account Name *' type='text' name='full_name' placeholder='Full name' />
                        </div>
                        <div className="col-md-6 mb-2">
                            <TextField label='Email *' type='email' name='email' placeholder='E-mail address' />
                        </div>
                        <div className="col-md-6 mb-2">
                            <TextField label='Mobile' type='text' name='mobile' placeholder='10-digit mobile number.' />
                        </div>
                        <div className="col-md-6 mb-2">
                            <TextField label='Password *' type='password' name='password' placeholder='Password' autoComplete='off' />
                        </div>
                        <div className="col-md-6 mb-2">
                            <TextField label='Confirm Password *' type='password' name='password2' placeholder='Confirm Password' autoComplete='off' />
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-md-4 mb-2">
                            <Typography>Role <span className='req'>*</span></Typography>
                            <SelectField
                                name='user_role'
                                options={USER_ROLE_OPTIONS}
                                value={USER_ROLE_OPTIONS.filter(function (option) {
                                    return option.value === formik.values.user_role;
                                })}
                                onChange={(e) => formik.setFieldValue('user_role', e.value)}
                            />

                        </div>
                        <div className="col-md-4 mb-2">
                            <Typography>Parent of</Typography>
                            <SelectField
                                name='parent_role'
                                options={usersData?.data?.map(formatUserOptionsList)}
                                isLoading={usersIsLoading}
                                value={usersData?.data?.map(formatUserOptionsList).filter(function (option) {
                                    return option.value.toString() === formik.values.parent_role.toString();
                                })}
                                onChange={(e) => formik.setFieldValue('parent_role', e.value)}
                            />
                        </div>
                        <div className="col-md-4 mb-2">
                            <Typography>Group</Typography>
                            <SelectField
                                name='groups'
                                options={groupsData?.data?.map(formatSelectOptionsList)}
                                value={groupsData?.data?.map(formatSelectOptionsList).filter(function (option) {
                                    return option.value.toString() === formik.values.groups.toString();
                                })}
                                onChange={(e) => formik.setFieldValue('groups', e.value)}
                                isLoading={isGroupsLoading}
                            />
                        </div>
                    </div>
                    <div className='row mb-4'>
                        <div className="col-md-4 mb-2">
                            <Typography>Is Active</Typography>
                            <SelectField
                                name='is_active'
                                options={IS_ACTIVE_FILTER_OPTIONS_BOOL}
                                value={IS_ACTIVE_FILTER_OPTIONS_BOOL.filter(function (option) {
                                    return option.value === formik.values.is_active;
                                })}
                                onChange={(e) => formik.setFieldValue('is_active', e.value)}
                            />
                        </div>
                        <div className="col-md-4 mb-2">
                            <Typography>Admin Panel Access</Typography>
                            <SelectField
                                name='is_staff'
                                options={IS_ACTIVE_FILTER_OPTIONS_BOOL}
                                value={IS_ACTIVE_FILTER_OPTIONS_BOOL.filter(function (option) {
                                    return option.value === formik.values.is_staff;
                                })}
                                onChange={(e) => formik.setFieldValue('is_staff', e.value)}
                            />
                        </div>
                        <div className="col-md-4 mb-2">
                            <Typography>Full Access</Typography>
                            <SelectField
                                name='is_superuser'
                                options={IS_ACTIVE_FILTER_OPTIONS_BOOL}
                                value={IS_ACTIVE_FILTER_OPTIONS_BOOL.filter(function (option) {
                                    return option.value === formik.values.is_superuser;
                                })}
                                onChange={(e) => formik.setFieldValue('is_superuser', e.value)}
                            />
                        </div>
                    </div>


                    <LoadingButton
                        loading={isLoading}
                        variant="contained"
                        disableElevation
                        type="submit"
                        size="large"
                        color="success"
                    >
                        Add
                    </LoadingButton>
                    {/* <Link to="/" className="auth-forgot-password float-end">das</Link> */}
                </Form>)}
        </Formik>
    )
}
