import React, { Fragment, useContext, useEffect, useState } from 'react'
import { AxisOptions, Chart } from "react-charts";
import ResizableBox from '../../Common/ResizableBox';
import { useDocketStaticsChart } from '../../../hooks/docket/statics';
import Loader2 from '../../Common/Loader2';
import Loader from '../../Common/Loader';
import SelectField from '../../Common/Fields/SelectField';
import { DAY_OPTIONS, MONTH_OPTIONS, YEAR_OPTIONS } from './Options';
import providerContext from '../../../context/provider/providerContext';


export default function ChartComp() {
    const currentDate = new Date();
    const [selectedYear, setSelectedYear] = useState(currentDate.getFullYear());
    const [selectedMonth, setSelectedMonth] = useState(currentDate.getMonth() + 1);
    const [selectedDay, setSelectedDay] = useState('all');

    const { currentProvider } = useContext(providerContext);
    const [enableApi, setAPI] = useState(false)
    const { docketsChartData, isChartDataLoading, handleStaticsParam } = useDocketStaticsChart('', enableApi);
    // const datas = [
    //     {
    //         label: 'Pending',
    //         data: [
    //             {
    //                 primary: 'Total',
    //                 secondary: 500,
    //                 likes: 130,
    //             },
    //             {
    //                 primary: 'Vodafone',
    //                 secondary: 1000,
    //                 likes: 130,
    //             },
    //             {
    //                 primary: 'Airtel',
    //                 secondary: 6600,
    //                 likes: 150,
    //             },
    //             {
    //                 primary: 'MCBSPL',
    //                 secondary: 150,
    //                 likes: 150,
    //             },
    //         ],

    //     },
    //     {
    //         label: 'Hold',
    //         data: [
    //             {
    //                 primary: 'Ordinal Group 0',
    //                 secondary: 1000,
    //                 likes: 130,
    //             },
    //             {
    //                 primary: 'Ordinal Group 1',
    //                 secondary: 6600,
    //                 likes: 150,
    //             },
    //             {
    //                 primary: 'Ordinal Group 3',
    //                 secondary: 150,
    //                 likes: 150,
    //             },
    //         ],

    //     },
    // ]

    const [yearOptions, setYearOptions] = useState([]);
    const [monthOptions, setMonthOptions] = useState([]);
    const [dayOptions, setDayOptions] = useState([]);

    // Function to generate an array of numbers from start to end
    const range = (start, end) => Array.from({ length: end - start + 1 }, (_, i) => start + i);


    const generateDays = () => {
        // Generate day options based on the current month and year
        const daysInMonth = new Date(selectedYear, selectedMonth, 0).getDate();
        const days = range(1, daysInMonth).map(day => ({
            value: day,
            label: day.toString()
        }));
        setDayOptions(days);
        // console.log('generated ss', days)
    }
    useEffect(() => {
        // Get the current date
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        // const currentMonth = currentDate.getMonth() + 1; // Months are 0-indexed, so add 1
        // const currentDay = currentDate.getDate();

        // Generate year options from 2023 to the current year
        const years = range(2023, currentYear).map(year => ({
            value: year,
            label: year.toString()
        }));

        // Generate month options from 1 to 12
        const months = range(1, 12).map(month => ({
            value: month,
            label: month.toString()
        }));
        setYearOptions(years);
        setMonthOptions(months);
        // setDayOptions(days);
        // generateDays()
        // CALL API
        // setAPI(true)
        // handleStaticsParam(`?provider=${currentProvider?.id}&year=${currentYear}&month=${currentMonth}&day=${selectedDay}`)
    }, []); 

    // const data = docketsChartData?.data
    
    // console.log(isChartDataLoading, 'data -> ', docketsChartData)
    // Function to handle change of year, month, or day
    // const handleChange = (e) => {
    //     // Call primaryAxis and secondaryAxes
    //     // console.log(primaryAxis);
    //     // console.log(secondaryAxes);
    //     console.log('log -> ', e.target.name)
    //     console.log('log -> ', e.target.value)

    //     // handleStaticsParam(`?provider=${currentProvider?.id}&year=`)
    //     const { name, value } = e.target;
    //     // Update selected year, month, or day based on the user's selection
    //     var params;
    //     switch (name) {
    //         case 'year':
    //             setSelectedYear(value);
    //             generateDays()
    //             // params = `?provider=${currentProvider?.id}&year=${value}&month=${currentMonth}&day=${selectedDay}`
    //             break;
    //         case 'month':
    //             setSelectedMonth(value);
    //             generateDays()
    //             break;
    //         case 'day':
    //             setSelectedDay(value);
    //             break;
    //         default:
    //             break;
    //     }
    //     // const params = `?provider=${currentProvider?.id}&year=${selectedYear}&month=${selectedMonth}&day=${selectedDay}`
    //     console.log(params)
    // };

    useEffect(() => {
        generateDays();
        const params = `?provider=${currentProvider?.id}&year=${selectedYear}&month=${selectedMonth}&day=${selectedDay}`
        // console.log('CALLED', params)
        setAPI(true)
        handleStaticsParam(params)
    }, [selectedYear, selectedMonth, selectedDay, currentProvider])


    const primaryAxis = React.useMemo(() => ({ getValue: (data) => data.primary, }), [])
    const secondaryAxes = React.useMemo(() => [{ getValue: (data) => data.secondary, },], [])
    return (
        <div className='test'>

            <ResizableBox
                width={800}
            >
                <div className='row'>
                    <div className='col-4'>
                        <select className="form-select" value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)} name='year'>
                            <option disabled>Year</option>
                            {yearOptions.map((data, i) =>
                                <option value={data.value} key={i} >{data.label}</option>
                            )}
                        </select>
                    </div>
                    <div className='col-4'>
                        <select className="form-select" value={selectedMonth} onChange={(e) => { setSelectedMonth(e.target.value); } } name='month'>
                            <option disabled>Month</option>
                            {monthOptions.map((data, i) =>
                                <option value={data.value} key={i} >{data.label}</option>
                            )}
                        </select>
                    </div>
                    <div className='col-4'>
                        <select className="form-select" value={selectedDay} onChange={(e) => setSelectedDay(e.target.value)} name='day'>
                            <option disabled>Day</option>
                            
                            <option value='all' key={'all'}>All</option>
                            {dayOptions.map((data, i) =>
                                <Fragment key={i}>
                                    <option value={data.value} key={i}>{data.label}</option>
                                </Fragment>
                            )}
                        </select>
                    </div>
                </div>
                {isChartDataLoading && <Loader />}
                {docketsChartData && !isChartDataLoading && <Chart
                    options={{
                        data: docketsChartData,
                        primaryAxis,
                        secondaryAxes,
                    }}
                />}
            </ResizableBox>
        </div>
    )
}
