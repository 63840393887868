import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
// import BackToPopForm from '../Forms/BackToPopForm';
import { useCloseDocket, useDocket, useDocketDetailHook } from '../../hooks/docket';
import Skeleton from 'react-loading-skeleton';
import { useDebouncedCallback } from 'use-debounce';
import { useDocketHoldVehicles, useDocketHoldVehiclesDetail } from '../../hooks/docket/latest';
import { capitalize } from '../../utils';
import BackToPopForm from '../Forms/BackToPopForm';
import BackToPopVehicleHoldForm from '../Forms/BackToPopVehicleHoldForm';
// import { useCancelDocketAction, useDocketRequest, useNewDocket } from '../../hooks/lco/dockets';
// import CommentForm from './Forms/CommentForm';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '700px',
    },
    overlay: { zIndex: 1000 }
};
export default function BackToPopVehicleHoldModal({ modal, setModal, docket, currentData }) {
    
    const [apiEnable, setAPI] = useState(false)
    const { holdVehicleData, isLoadingHoldVehicleData, filterHoldVehicleList } = useDocketHoldVehiclesDetail('', apiEnable);
    const { handleDocketBackToPOP, isUpdatingBackToPOP } = useDocketHoldVehicles('PATCH');
    const [isLoading, setLoading] = useState(true)
    const [fetchedData, setFetchedData] = useState({})
    // const { handleCancelDocket, isCancellingDocket } = useCancelDocketAction('POST')
    // const { handleDocketCloseAction } = useCloseDocket('PATCH');

    const handleBackToPop = async (values, { resetForm }) => {
        const res = await handleDocketBackToPOP({
            ...values,

            id: currentData.id,
            action: 'update',
            remarks: 'Back to POP updated.'
        })
        // // console.log(res)
        if (res.success) {
            toast.success(`#${fetchedData?.get_docket_code} - Back to POP updated.`);
            // navigate(`/docket`);
            setModal(false)
        } else {
            toast.error(res.detail)
        }

    }
    const fetchData = useDebouncedCallback(() => {
        setAPI(true)
        filterHoldVehicleList(`/${currentData?.id}`, true)
    }, 100)
    useEffect(() => {
        if (holdVehicleData && !isLoadingHoldVehicleData) {
            console.log(holdVehicleData)
            setFetchedData(holdVehicleData?.data ?? {});
            setLoading(false)
        } else {
            setLoading(true)
        }
    }, [holdVehicleData])
    return (
        <Modal
            isOpen={modal}
            onAfterOpen={() => { fetchData(); setLoading(true) }}
            // onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Back To POP Docket Modal"
        >
            <div className="container-fluid">

                <div className="d-flex justify-content-between">
                    <h5 className="modal-title mb-3 text-center text-info">Update Back To POP ({capitalize(currentData?.type) ?? '-'}).</h5>
                    <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setModal(false);}}></button>
                </div>
                <div className="modal-body" style={{ width: '100%' }}>
                    <div className="d-flex justify-content-between mb-4">
                        <div>
                            <p className="display-6 text-dark m-0">Docket ID:</p>
                        </div>
                        <div>
                            {isLoading ? <Skeleton width={150} height={35} /> : <h3 className="display-6 text-danger m-0"> {fetchedData?.get_docket_code}</h3>}
                        </div>
                    </div>


                    <hr />
                    {isLoading ? <Skeleton width={580} height={100} /> : <BackToPopVehicleHoldForm
                        fetchedData={fetchedData}
                        setModal={setModal}
                        isLoading={isUpdatingBackToPOP}
                        handleSubmit={handleBackToPop}
                        btnLabel='Update Back to POP KM'
                    />}
                </div>
            </div>
        </Modal>
    )
}
