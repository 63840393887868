import React from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import TextField from '../Common/Fields/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
// import { Link } from 'react-router-dom';
import { Alert, Button, Typography } from '@mui/material';
import SelectField from '../Common/Fields/SelectField';
import { DOCKET_LM_STATUS } from './Options/DocketForm';
import { Link } from 'react-router-dom';
import CheckboxField from '../Common/Fields/CheckboxField';
import { formatVehicleOptionsList, useVehiclesList } from '../../hooks/core/vehicle';
import { useUser } from '../../hooks/user';
// import Alert from '@mui/material/Alert';
// import { Stack } from '@mui/material';


export default function DocketHoldRequestForm({ data, isLoading, handleSubmit, setModal }) {
    const { vehiclesData, vehiclesIsLoading } = useVehiclesList();
    const { user } = useUser();
    const validate = Yup.object({
        status: Yup.string().required('Status is required.'),
        remarks: Yup.string().required('Remarks is required.'),

        vehicle1: Yup.string().nullable().when("status", {
            is: (val) => val === 'Hold',
            then: Yup.string().required('Vehicle 1 is required.').nullable()
        }),
        vehicle1_start_km: Yup.number().required('Start KM is required.'),
        vehicle1_end_km: Yup.number().required('End KM is required.'),
        vehicle2_start_km: Yup.number().required('Start KM is required.'),
        vehicle2_end_km: Yup.number().required('End KM is required.'),
        file: Yup
            .mixed()
            .test("fileType", "Unsupported File Format", (value) => {
                if (value && value.length > 0) {
                    for (let i = 0; i < value.length; i++) {
                        if (value[i].type !== "image/png" && value[i].type !== "image/jpg" && value[i].type !== "image/jpeg" && value[i].type !== "video/mp4") {
                            return false;
                        }
                    }
                }
                return true;
            })
            .test("fileSize", "File Size is too large", (value) => {
                if (value && value?.length > 0) {
                    for (let i = 0; i < value.length; i++) {
                        // console.log(`${value[i].name}`, `${value[i].size}`)
                        //  Byte Size
                        if (value[i].size > 20154227) {
                            // 2154227 - 2 MB
                            // 5154227 - 5 MB
                            // 15154227 - 15 MB
                            // 20154227 - 20 MB
                            // 25154227 - 25 MB
                            return false;
                        }
                    }
                }
                return true;
            }),
    })

    const STATUS_OPTIONS = [
        // { 'label': 'Only Update', value: 'Update' },
        ...(user?.permissions?.dockets?.can_share_update ? [{ label: 'Only Update', value: 'Update' }] : []),
        { 'label': 'Hold', value: 'Hold' },
        ...(user?.permissions?.dockets?.can_cancel_docket ? [{ 'label': 'Cancelled', value: 'Cancelled' }] : []),
        // { 'label': 'Cancelled', value: 'Cancelled' },
    ]
    const STATUS_OPTIONS_ALT = [
        { 'label': 'Resume', value: 'Resume' },
        // { 'label': 'Only Update', value: 'Update' },
        ...(user?.permissions?.dockets?.can_share_update ? [{ label: 'Only Update', value: 'Update' }] : []),
    ]


    return (
        <Formik
            initialValues={{
                "status": 'Hold',
                "remarks": '',

                // Vehicle States
                "vehicle1": null,
                "vehicle1_start_km": 0,
                "vehicle1_end_km": 0,
                "vehicle2": null,
                "vehicle2_start_km": 0,
                "vehicle2_end_km": 0,
                // Send Mail
                "send_mail": false,
                // Files
                "file": null,
                // Activity
                'is_activity': false
            }}
            validationSchema={validate}
            onSubmit={handleSubmit}
        >
            {formik => (

                <Form>
                    {/* {console.log(formik)} */}
                    <div className='row' id='modalFOrm'>
                        <div className="col-md-12 mb-4">

                            {formik.values.status === 'Update' && formik?.touched?.status && formik.values.is_activity ? <>
                                {/* LM UPDATE FORM VIEW */}
                                <SelectField
                                    className='mt-3'
                                    name='remarks'
                                    options={DOCKET_LM_STATUS}
                                    value={DOCKET_LM_STATUS.filter(function (option) {
                                        return option.value === formik.values.remarks;
                                    })}
                                    onChange={(e) => {
                                        formik.setFieldValue('remarks', e.value)
                                    }}
                                />
                            </> : <>
                                {/* DEFAULT FORM VIEW */}

                                <TextField label='Remarks *' size="medium" type="text" name='remarks' placeholder='Remarks' sx={{ mt: 2 }} />

                            </>}


                            {formik.values.status === 'Hold' && <>
                                {/* HOLD FORM VIEW */}
                                <div className='row mt-2'>
                                    <div className="col-md-5 mb-2">
                                        <Typography variant='caption'>Vehicle 1 <span className='req'>*</span></Typography>

                                        <SelectField
                                            name='vehicle1'
                                            options={vehiclesData?.data.map(formatVehicleOptionsList)}
                                            value={vehiclesData?.data.map(formatVehicleOptionsList).filter(function (option) {
                                                return option.value === formik.values.vehicle1;
                                            })}
                                            onChange={(e) => formik.setFieldValue('vehicle1', e.value)}
                                            isLoading={vehiclesIsLoading}
                                        />
                                    </div>
                                    <div className="col-md-3 mb-2 mt-2">
                                        <TextField label='Start KM *' type="number" name='vehicle1_start_km' placeholder='Start KM' sx={{ mt: 2 }} />
                                    </div>
                                    <div className="col-md-3 mb-2 mt-2">
                                        <TextField label='End KM *' type="number" name='vehicle1_end_km' placeholder='End KM' sx={{ mt: 2 }} />
                                    </div>
                                    <div className="col-md-1 mb-2 mt-2">
                                        {formik.values.vehicle1 && <span className="badge text-white bg-primary mt-4">{formik.values.vehicle1_end_km - formik.values.vehicle1_start_km} KM</span>}
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-md-5 mb-2">
                                        <Typography variant='caption'>Vehicle 2</Typography>

                                        <SelectField
                                            name='vehicle2'
                                            options={vehiclesData?.data.map(formatVehicleOptionsList)}
                                            value={vehiclesData?.data.map(formatVehicleOptionsList).filter(function (option) {
                                                return option.value === formik.values.vehicle2;
                                            })}
                                            onChange={(e) => formik.setFieldValue('vehicle2', e.value)}
                                            isLoading={vehiclesIsLoading}
                                        />
                                    </div>
                                    <div className="col-md-3 mb-2 mt-2">
                                        <TextField label='Start KM *' type="number" name='vehicle2_start_km' placeholder='Start KM' sx={{ mt: 2 }} />
                                    </div>
                                    <div className="col-md-3 mb-2 mt-2">
                                        <TextField label='End KM *' type="number" name='vehicle2_end_km' placeholder='End KM' sx={{ mt: 2 }} />
                                    </div>
                                    <div className="col-md-1 mb-2 mt-2">
                                        {formik.values.vehicle2 && <span className="badge text-white bg-primary mt-4">{formik.values.vehicle2_end_km - formik.values.vehicle2_start_km} KM</span>}
                                    </div>
                                </div>
                            </>}

                            {formik.values.status === 'Hold' | formik.values.status === 'Cancelled' ? <>
                                {/* HOLD OR CANCELLED FORM VIEW */}
                                <div className='row mt-2'>
                                    <div className="col-md-5 mb-2">
                                        <Typography variant='caption'>Reference Photo / Video </Typography>
                                        <input
                                            className='form-control'
                                            name="files"
                                            type="file"
                                            accept=".jpg, .jpeg, .png, .mp4, .mpeg"
                                            // multiple
                                            onChange={(event) => {
                                                formik.setFieldValue("file", Array.from(event.target.files));
                                            }}
                                        />
                                    </div>
                                </div>
                            </> : ''}
                            {formik.values.status === 'Update' && <CheckboxField label='LM Update' name='is_activity' options={['LM Update']} />}
                            {formik.values.status === 'Update' && <CheckboxField label='Send Email' name='send_mail' options={['Send Email']} />}

                        </div>
                        {formik.submitCount >= 1 && formik.errors && Object.entries(formik.errors).map(([key, value]) =>
                            <Alert severity="error" sx={{ mt: 1, mb: 1 }} key={key}>{value}</Alert>
                        )}
                    </div>

                    <div className='d-flex justify-content-between align-items-center'>
                        <div>
                            <LoadingButton
                                loading={isLoading}
                                variant="contained"
                                type="submit"
                                disableElevation
                            >
                                Request for Hold
                            </LoadingButton>
                            <Button variant="outlined" disableElevation onClick={() => setModal(false)}>Close</Button>
                        </div>

                        {/* {user?.permissions?.dockets?.can_close_docket && <Link to={`/docket/close/${data.code}`} className='btn btn-warning'>Close Docket</Link>} */}
                    </div>
                </Form>
            )}
        </Formik>
    )
}
