import { useState } from "react";
import providerContext from "./providerContext";
import { CURRENT_PROVIDER_LOCAL_DATA } from "../../config";
import { getLocal } from "../../utils/localStorage";


const ProviderState = (props) => {
    const [currentProvider, setCurrentProvider] = useState(JSON.parse(getLocal(CURRENT_PROVIDER_LOCAL_DATA)))
    // const [notifications, setNotifications] = useState(JSON.parse(getLocal(NOTIFICATION_TRACK_DATA)))
    const actions = {
        'setCurrentProvider': (data) => setCurrentProvider(data),
        // 'setNotifications': (data) => setNotifications(data),
    }
    return (
        <providerContext.Provider value={{ currentProvider, actions }}>
            {props.children}
        </providerContext.Provider>
    )
}
export default ProviderState;