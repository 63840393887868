import { Container, Grid } from '@mui/material';
import React from 'react'
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import PageBreadcrumbs from '../../../components/Common/Breadcrumbs';
import { useUsersList } from '../../../hooks/user';
import UserInformation from '../../../components/Admin/Users/UserInformation';
import Loader from '../../../components/Common/Loader';
import Alert from '../../../components/Common/Alert';

export default function UserDetailPage() {
    const { id } = useParams();
    const { usersData, usersIsLoading } = useUsersList(`/${id}`);
    // console.log(usersData)
    return (
        <>
            <Helmet>
                <title>User - {`${usersData?.data?.account_name} Information`}</title>
            </Helmet>
            <Container maxWidth="xxl" disableGutters>
                <PageBreadcrumbs
                    title={usersData?.data?.account_name ?? 'User'}
                    history={[{ name: 'Users', to: '/admin/users' }]}
                    RightComp={() =>
                        <>
                            {/* <Link to={`/circuit/update/${circuitData?.data?.id}`} className="btn btn-secondary btn-style-light"><i className="material-icons">edit</i>Update</Link> */}
                        </>
                    }
                />

                <Grid container>
                    <Grid item xs={12} sx={{ p: 1 }}>
                        {usersData && !usersIsLoading && !usersData.success && <Alert
                            title='Error!'
                            text={usersData.detail}
                            type='danger'
                        />}
                        {usersIsLoading ? <Loader /> : <UserInformation data={usersData?.data} />}
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}
