import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { useLCOCircuitDetail } from '../../hooks/lco/circuits';
import { Helmet } from 'react-helmet';
import Loader from '../Common/Loader';
import LCOCircuitInformation from './CircuitInformation';
import PageBreadcrumbs from '../Common/Breadcrumbs';
import DocketRequestModal from './DocketRequestModal';

export default function LCOCircuitDetail() {
    const { code } = useParams();
    const { circuitData, circuitIsLoading } = useLCOCircuitDetail(code);
    // console.log(circuitData)
    const [modal, setModal] = useState(false)
    const [currentData, setCurrentData] = useState(null)
    return (
        <>
            <Helmet>
                <title>Circuits - {`${circuitData?.data?.code} Information`}</title>
            </Helmet>
            <PageBreadcrumbs
                    title={circuitData?.data?.code}
                    history={[{ name: 'Dashboard', to: '/' }]}
                    RightComp={() =>
                        <div className='d-flex gap-1'>
                            {!circuitData?.data?.active_docket.status && <button onClick={() => {setCurrentData(circuitData?.data); setModal(true);}} className='btn btn-danger pb-0 btn-style-light mx-2' title='New Docket'>
                                <span className="material-icons-sharp">
                                    push_pin
                                </span>
                            </button>}
                        </div>
                    }
                />
            <div className='container-fluid'>
                <div item xs={12} sx={{ p: 1 }}>

                    {circuitIsLoading && <Loader />}
                    {circuitData && !circuitIsLoading && <LCOCircuitInformation data={circuitData?.data}  />}
                </div>
            </div>
            <DocketRequestModal
                modal={modal}
                setModal={setModal}
                currentData={currentData} 
                setCurrentData={setCurrentData}
            />
        </>

    )
}
