
import React from 'react'
import Modal from 'react-modal'
import DatePicker from '../Common/Fields/DatePickerField';
import { Button } from '@mui/material';
import { formatDateTime } from '../../utils/shortcuts';
import TextField from '../Common/Fields/TextField';
import DateTimePicker from '../Common/Fields/DateTimePickerField';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '700px',
    },
    overlay: { zIndex: 1000 }
};
export default function UnderMaintenanceModal({ formik, modal, setModal }) {

    return (
        <Modal
            isOpen={modal}
            // onAfterOpen={afterOpenModal}
            // onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Modal"
        >
            <div className="container-fluid">

                <div className="d-flex justify-content-between">
                    <h5 className="modal-title mb-3 text-center text-info">Schedule Circuit Maintenance</h5>
                    <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={() => setModal(false)}></button>
                </div>
                <div className="modal-body" style={{ width: '100%' }}>
                    <div className='row'>
                        <div className="col-md-12 mb-4">
                            <label>Start From:</label>
                            {formik.initialValues.start_from ? <p className='fw-bold'>{formatDateTime(formik.initialValues.start_from, 'MMMM Do YYYY, h:mm:ss a')}</p> :
                                <DateTimePicker label='Start from:' minDate={formatDateTime('today')} name='start_from' />}
                        </div>
                        <div className="col-md-12 mb-4">
                            <label>End To:</label>
                            {formik.initialValues.end_to ? <p className='fw-bold'> {formatDateTime(formik.initialValues.end_to, 'MMMM Do YYYY, h:mm:ss a')}</p>:
                            <DateTimePicker label='End to:' minDate={formatDateTime('today')} name='end_to' />}
                        </div>
                    </div>
                    <Button variant="contained" disableElevation onClick={() => setModal(false)}> {formik.initialValues.start_from ? 'Close' : 'Save'}</Button>
                </div>
            </div>
        </Modal>
    )
}
