import React from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Button, Typography } from '@mui/material';
import { useLCOProblemsList } from '../../hooks/lco/dockets';
import { formatSelectOptionsList } from '../../hooks/circuit';
import SelectField from '../Common/Fields/SelectField';
import TextField from '../Common/Fields/TextField';
import { useProblemsList } from '../../hooks/docket';


export default function MaintDocketRequestForm({ isLoading, handleSubmit, setModal }) {
    const { problemsData, problemsIsLoading } = useProblemsList();
    const validate = Yup.object({
        // problem: Yup.string().required('Select problem.'),
        problem: Yup.string().nullable().when("status", {
            is: (val) => val === 'Hold',
            then: Yup.string().required('Select problem.').nullable()
        }),
        comment: Yup.string().required('Comment is required.'),
        location_b: Yup.string().required('Location B is required.'),
        location_a: Yup.string().required('Location A is required.'),
    })
    return (
        <Formik
            initialValues={{
                location_a: '',
                location_b: '',
                comment: '',
                maint_problem: null,
                is_maint_docket: true,
            }}
            validationSchema={validate}
            onSubmit={handleSubmit}
        >
            {formik => (

                <Form>
                    <div className='row'>
                        <div className="col-md-12 mb-4">
                            <Typography variant='caption'>Select Problem <span className='req'>*</span></Typography>
                            <SelectField
                                name='maint_problem'
                                options={problemsData?.data?.map(formatSelectOptionsList)}
                                value={problemsData?.data?.map(formatSelectOptionsList).filter(function (option) {
                                    return option.value === formik.values.maint_problem;
                                })}
                                onChange={(e) => formik.setFieldValue('maint_problem', e.value)}
                                // isDisabled={disabled}
                                isLoading={problemsIsLoading}
                            />

                        </div>

                    </div>
                    <div className='row'>
                        <div className="col-md-6">

                            <TextField label='Location A *' type="text" name='location_a' placeholder='Location A' sx={{ mt: 2 }} />
                        </div>
                        <div className="col-md-6">

                            <TextField label='Location B *' type="text" name='location_b' placeholder='Location B' sx={{ mt: 2 }} />
                        </div>
                        <div className='col-md-12'>
                            <TextField label='Comment *' type="text" name='comment' placeholder='Comment' sx={{ mt: 2 }} />
                        </div>

                    </div>

                    <LoadingButton
                        loading={isLoading}
                        type="submit"
                        variant="contained"
                        disableElevation
                    >
                        Raise Docket Request
                    </LoadingButton>
                    <Button variant="outlined" sx={{ ml: 1 }} disableElevation onClick={() => setModal(false)}>Close</Button>
                </Form>
            )}
        </Formik>
    )
}
