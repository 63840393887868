import Skeleton from "react-loading-skeleton";

export const skeletonRows = Array.from({ length: 20 }, (_, index) => ({
    code: <Skeleton width={100} />,
    customer_id: <Skeleton width={100} />,
    customer_id_alt: <Skeleton width={100} />,
    circuit_type: <Skeleton width={100} />,
    get_provider: { title: <Skeleton width={100} /> },
    location_a: <Skeleton width={100} />,
    address_a: <Skeleton width={150} />,
    pin_a: <Skeleton width={50} />,
    mobile_a: <Skeleton width={100} />,
    location_b: <Skeleton width={100} />,
    address_b: <Skeleton width={150} />,
    pin_b: <Skeleton width={50} />,
    mobile_b: <Skeleton width={100} />,
    area_a: <Skeleton width={100} />,
    address: <Skeleton width={150} />,
    third_party_circuit_id: <Skeleton width={100} />,
    get_ti: { account_name: <Skeleton width={100} /> },
    get_hi: { account_name: <Skeleton width={100} /> },
    get_fiber_tl: { account_name: <Skeleton width={100} /> },
    type_of_connectivity: <Skeleton width={100} />,
    office_name: <Skeleton width={100} />,
    customer_type: <Skeleton width={100} />,
    get_hirer: { name: <Skeleton width={100} /> },
    ofc_distance: <Skeleton width={50} />,
    total_dockets: <Skeleton width={50} />,
    status: <Skeleton width={80} />,
    created_at: <Skeleton width={100} />,
    updated_at: <Skeleton width={100} />
}));



export const MobileActionButtonsSkeleton = () => (
    <div className='col-12'>
        <div className="d-flex gap-1">
            <Skeleton width={40} height={35} className="btn-style-light" />
            <Skeleton width={40} height={35} className="btn-style-light" />
            <Skeleton width={40} height={35} className="btn-style-light" />
        </div>
    </div>
);


export const ActionSkeleton = () => (
    <div className="d-flex gap-1">
        <Skeleton height={35} width={40} />
        <Skeleton height={35} width={40} />
        <Skeleton height={35} width={40} />
        <Skeleton height={35} width={40} />
        <Skeleton height={35} width={40} />
    </div>
);