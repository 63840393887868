export const DOCKETS_QUERY_KEY = 'dockets'
export const DOCKETS_UNDER_REVIEW_QUERY_KEY = 'docket_under_review'
export const DOCKET_QUERY_KEY = 'docket'
export const DOCKET_STATIC_COUNTS = 'docket_counts'
export const RFO_QUERY_KEY  = 'docket_rfos'
export const PROBLEM_QUERY_KEY  = 'problems'
export const CLOSED_DOCKETS_QUERY_KEY = 'closed_dockets'
export const DOCKET_UPDATES_QUERY_KEY = 'docket_updates'
export const DOCKET_UPDATE_REQUEST_QUERY_KEY = 'docket_update_requests'
export const DOCKET_HOLD_VEHICLE_QUERY_KEY = 'docket_hold_vehicle_update'
// CORPORATE DOCKETS
export const CORPORATE_DOCKETS_QUERY_KEY = 'corporate_dockets'
export const CORPORATE_DOCKET_TICKETS_QUERY_KEY = 'corporate_docket_tickets'
export const CORP_DOCKET_NOTIFICATION_QUERY_KEY = 'corporate_docket_tickets_notification'