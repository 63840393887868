import { TextField } from '@mui/material'
import React from 'react'
import { formatMaterialOptionsList, useMaterialsList } from '../../../hooks/core/material';
import SelectField from '../../Common/Fields/SelectField';
import { FIBER_MATERIAL_ID } from '../../../config';

export default function AdditionalMaterial({ formik }) {

    const { materialsData, materialsIsLoading } = useMaterialsList(`?parent=${FIBER_MATERIAL_ID}`);
    // console.log('materialsData -> ', materialsData)
    // Additional Section
    const handleAdditionalRow = () => {
        const additionals = [...formik.values.materials_additionals]
        additionals.push({
            id: null,
            fiber_type: '',
            fiber_type_id: null,
            company_name: '',
            drum_no: '',
            start_mt: 0,
            end_mt: 0
        })
        formik.setFieldValue('materials_additionals', additionals)
        // console.log(additionals)
    }

    const handleMaterialType = (id, select) => {
        // console.log(select)
        const additionals = [...formik.values.materials_additionals]
        additionals[id].fiber_type = select.name
        additionals[id].fiber_type_id = select.value
        formik.setFieldValue('materials_additionals', additionals)
    }
    const handleChangeCompanyName = (id, value) => {
        const additionals = [...formik.values.materials_additionals]
        additionals[id].id = id + 1
        additionals[id].company_name = value
        formik.setFieldValue('materials_additionals', additionals)
    }
    const handleChangeDrumNo = (id, value) => {
        const additionals = [...formik.values.materials_additionals]
        additionals[id].drum_no = value
        formik.setFieldValue('materials_additionals', additionals)
    }
    const handleChangeStartMtr = (id, value) => {
        const additionals = [...formik.values.materials_additionals]
        additionals[id].start_mt = value
        formik.setFieldValue('materials_additionals', additionals)
    }
    const handleChangeEndMtr = (id, value) => {
        const additionals = [...formik.values.materials_additionals]
        additionals[id].end_mt = value
        formik.setFieldValue('materials_additionals', additionals)
    }
    const handleRemoveRow = (id) => {
        const additionals = [...formik.values.materials_additionals]
        if (additionals.length > 0) {
            additionals.splice(id, 1)
            formik.setFieldValue('materials_additionals', additionals)
        }
    }
    return (
        <>
            <div className='row border-top mt-2'>
                <div className='col-12'>
                    <h3 className='text-center display-6 mb-4 text-dark mt-3' style={{ 'fontSize': '22px' }}>Additionals <span style={{ fontSize: '12px' }}>(Optional)</span></h3>

                    {formik?.values?.materials_additionals?.length > 0 && <div>
                        {!materialsData?.success && <p className='text-danger'>Found invalid FIBER_MATERIAL_ID in the environment file.</p>}
                        <div className='table-responsive'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th style={{width: '5%'}}>ID #</th>
                                        <th style={{width: '15%'}}>Fiber Type</th>
                                        <th style={{width: '15%'}}>Company Name</th>
                                        <th style={{width: '10%'}}>Drum No</th>
                                        <th style={{width: '10%'}}>Start MT.</th>
                                        <th style={{width: '10%'}}>End MT.</th>
                                        <th style={{width: '10%'}}>Total MT.</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {formik?.values?.materials_additionals?.map((val, i) =>
                                        <tr key={i}>
                                            <td className='fw-bold'>{val.id}</td>
                                            <td>
                                                <SelectField
                                                    options={materialsData?.data?.map(formatMaterialOptionsList)}
                                                    value={materialsData?.data?.map(formatMaterialOptionsList).filter(function (option) {
                                                        return option.value === val.fiber_type_id;
                                                    })}
                                                    onChange={(e) => handleMaterialType(i, e)}
                                                    isLoading={materialsIsLoading}
                                                    className='mx-2'
                                                />
                                            </td>
                                            <td>
                                                <TextField
                                                    type='text'
                                                    // label={`Comapny Name`}
                                                    value={val.company_name}
                                                    onChange={(e) => handleChangeCompanyName(i, e.target.value)}
                                                    variant='standard'
                                                    error={val.value === ''}
                                                    helperText={`Comapny Name is required`}
                                                    size="small"
                                                    
                                                />
                                            </td>
                                            <td>
                                                <TextField
                                                    type='text'
                                                    // label={`Comapny Name`}
                                                    value={val.drum_no}
                                                    onChange={(e) => handleChangeDrumNo(i, e.target.value)}
                                                    variant='standard'
                                                    error={val.value === ''}
                                                    helperText={`Drum No is required`}
                                                    size="small"
                                                />
                                            </td>
                                            <td>
                                                <TextField
                                                    type='number'
                                                    value={val.start_mt}
                                                    onChange={(e) => handleChangeStartMtr(i, e.target.value)}
                                                    variant='standard'
                                                    error={val.value === ''}
                                                    helperText={`Start MT. is required`}
                                                    size="small"
                                                />
                                            </td>
                                            <td>
                                                <TextField
                                                    type='number'
                                                    value={val.end_mt}
                                                    onChange={(e) => handleChangeEndMtr(i, e.target.value)}
                                                    variant='standard'
                                                    error={val.value === ''}
                                                    helperText={`End MT. is required`}
                                                    size="small"
                                                />
                                            </td>
                                            <td className='fw-bold'>
                                                {val.end_mt - val.start_mt} MT &nbsp;
                                                <button className='btn btn-sm btn-danger' title='delete row' style={{ padding: 0 }} onClick={() => handleRemoveRow(i)}><span className="material-icons m-0">clear</span></button>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>}
                    {/* <div className='col-3'>
                        <button type='button' className='btn btn-dark mt-2 btn-style-light' onClick={() => handleAdditionalRow()}>
                            <i className="material-icons-sharp">
                                add
                            </i>
                            add additional</button>
                    </div> */}
                    <div className='d-flex justify-content-center d-flex align-items-center mb-2'>
                        <button type='button' className='btn btn-dark mt-2 btn-style-light btn-sm' onClick={() => handleAdditionalRow()}>
                            <i className="material-icons-sharp">
                                add
                            </i>
                            add additional</button>
                    </div>
                </div>
            </div>
        </>
    )
}
