
import React from 'react'
import Modal from 'react-modal'
import DatePicker from '../Common/Fields/DatePickerField';
import { Button } from '@mui/material';
import { formatDateTime } from '../../utils/shortcuts';
import TextField from '../Common/Fields/TextField';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '700px',
    },
    overlay: { zIndex: 1000 }
};
export default function ScheduleExpiryModal({  formik, modal, setModal}) {
    
    return (
        <Modal
            isOpen={modal}
            // onAfterOpen={afterOpenModal}
            // onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Modal"
        >
            <div className="container-fluid">

                <div className="d-flex justify-content-between">
                    <h5 className="modal-title mb-3 text-center text-info">Schedule Expiry Circuit</h5>
                    <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={() => setModal(false)}></button>
                </div>
                <div className="modal-body" style={{ width: '100%' }}>
                <div className='row'>
                        <div className="col-md-12 mb-4">
                            <DatePicker label='Expiry Date' minDate={formatDateTime('today')} name='expiry_date' />                            
                            <TextField label='Remarks *' type="text" name='remarks' placeholder='Remarks' sx={{ mt: 2 }} />                            
                        </div>                        
                    </div>
                    <Button variant="contained" disableElevation onClick={() => setModal(false)} disabled={!formik.values.remarks}> {formik.values.remarks ? 'Ok' : 'Enter remarks'}</Button>                    
                </div>
            </div>
        </Modal>
    )
}
