import React from 'react'
import Modal from 'react-modal'
import { setLocal } from '../../utils/localStorage';
import { CORP_DOCKET_LIST_COLUMNS } from '../../config';
import { Formik, Form } from 'formik';
import CorporateDocketColumns from '../Forms/VisiblityFilterForms/CorporateDocketColumns';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '700px',
    },
    overlay: { zIndex: 1000 }
};



function DocketColumnForm({ isLoading, handleSubmit, setModal, visibleCols }) {
    return (
        <Formik
            initialValues={visibleCols}
            onSubmit={handleSubmit}
        >
            {formik => (

                <Form>
                    <CorporateDocketColumns
                        formik={formik}
                        isLoading={isLoading}
                        setModal={setModal}
                        visibleCols={visibleCols}
                    />
                </Form>
            )}
        </Formik>
    )
}
export default function CorporateDocketFilterColumnsModal({ modal, setModal, visibleCols, setVisibleCols }) {

    const handleSubmit = async (values) => {
        setVisibleCols(values)
        setLocal(CORP_DOCKET_LIST_COLUMNS, JSON.stringify(values))
        setModal(false)
    }
    return (
        <Modal
            isOpen={modal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <div className="container-fluid">

                <div className="d-flex justify-content-between">
                    <h5 className="modal-title mb-3 text-center text-info">Column Visibility</h5>
                    <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={() => setModal(false)}></button>
                </div>
                <div className="modal-body" style={{ width: '100%' }}>
                    <DocketColumnForm
                        isLoading={false}
                        handleSubmit={handleSubmit}
                        setModal={setModal}
                        visibleCols={visibleCols} 
                        setVisibleCols={setVisibleCols}
                    />
                </div>
            </div>
        </Modal>
    )
}
