import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react'
import { getCurrentDate } from '../../../utils';
import DatePicker from '../../Common/Fields/DatePickerField';
import SelectField from '../../Common/Fields/SelectField';
import TextField from '../../Common/Fields/TextField';
import { CONNECTIVITY_TYPE_OPTIONS, CUSTOMER_TYPE_OPTIONS } from '../Options/CircuitForm';

export default function OtherDetail({ formik, disabled }) {
    return (
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className='col-12'>
                        <h3 className='text-center display-6 mb-4 text-primary' style={{ 'fontSize': '22px' }}>OTHER DETAILS</h3>
                    </div>

                    
                    <div className="col-md-6 mb-4">
                        <Typography>Customer Type </Typography>
                        <SelectField
                            name='customer_type'
                            options={CUSTOMER_TYPE_OPTIONS}
                            isSearchable={false}
                            value={CUSTOMER_TYPE_OPTIONS.filter(function (option) {
                                return option.value === formik.values.customer_type;
                            })}
                            onChange={(e) => formik.setFieldValue('customer_type', e.value)}
                            isDisabled={disabled}
                        />
                    </div>
                    <div className="col-md-6 mb-4">
                        <Typography>Type of Connectivity </Typography>
                        <SelectField
                            name='type_of_connectivity'
                            options={CONNECTIVITY_TYPE_OPTIONS}
                            isSearchable={false}
                            value={CONNECTIVITY_TYPE_OPTIONS.filter(function (option) {
                                return option.value === formik.values.type_of_connectivity;
                            })}
                            onChange={(e) => formik.setFieldValue('type_of_connectivity', e.value)}
                            isDisabled={disabled}
                        />
                    </div>
                    <div className="col-md-3">
                        <TextField label='Bandwidth[MB-GIG]' type="text" disabled={disabled} name='bandwidth_mb_gig' placeholder='Bandwidth[MB-GIG]' sx={{ mb: 3 }} />
                    </div>
                    <div className="col-md-3">
                        <TextField label='Mail ID' type="text" name='email' disabled={disabled} placeholder='Email' sx={{ mb: 3 }} />
                    </div>
                    <div className="col-md-3">
                        <TextField label='IP Details' type="text" disabled={disabled} name='ip_details' placeholder='IP' sx={{ mb: 3 }} />
                    </div>
                    <div className="col-md-3">
                        <TextField label='OSM ID' type="text" disabled={disabled} name='osm_id' placeholder='OSM ID' sx={{ mb: 3 }} />
                    </div>
                    <div className="col-md-3">
                        <TextField label='Indus ID' type="text" disabled={disabled} name='indus_id' placeholder='Indus ID' sx={{ mb: 3 }} />
                    </div>
                    <div className="col-md-3">
                        {/* <TextField label='Link Delivery Date *' type="text" name='link_delivery_date' placeholder='YYYY-MM-DD' sx={{ mb: 3 }} /> */}
                        <DatePicker label='Link Delivery Date *' disabled={disabled} minDate={dayjs(getCurrentDate('-'))} name='link_delivery_date' />
                    </div>
                    <div className="col-md-6">
                        <TextField label='Office Name' type="text" disabled={disabled} name='office_name' placeholder='Office Name' sx={{ mb: 3 }} />
                    </div>

                </div>
            </div>
        </div>

    )
}
