import { Typography } from '@mui/material';
import React from 'react'
import { formatSelectOptionsList } from '../../../hooks/circuit';
import SelectField from '../../Common/Fields/SelectField';
// import TextAreaField from '../../Common/Fields/TextAreaField';
import TextField from '../../Common/Fields/TextField';
// import Loader2 from '../../Common/Loader2';

export default function LocationDetail({ formik, disabled }) {
    // const { districtData, districtIsLoading } = useDistrictList();
    return (
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className='col-12'>
                        <h3 className='text-center display-6 mb-4 text-primary' style={{ 'fontSize': '22px' }}>LOCATION DETAILS</h3>
                    </div>
                    <div className="col-md-6">
                        <p className='text-center text-dark fw-bold'>Location A <br /><small className='fw-bold'>[BTS | POP | HUB]</small></p>
                        <div className='row'>
                            <div className="col-9">
                                <TextField label='Location *' disabled={disabled} type="text" name='location_a' placeholder='BTS | POP | HUB' sx={{ mb: 3 }} />
                            </div>
                            <div className="col-3">
                                <TextField label='Pin *' type="text" disabled={disabled} name='pin_a' placeholder='Pin A' sx={{ mb: 3 }} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-6">
                                <TextField label='Mobile' type="text" disabled={disabled} name='mobile_a' placeholder='Mobile' sx={{ mb: 3 }} />
                            </div>
                            <div className="col-6">
                                <TextField label='Port Details *' type="text" disabled={disabled} name='port_details_a' placeholder='Port Details' sx={{ mb: 3 }} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-12">
                                <TextField label='Address *' type="text" name='address_a' disabled={disabled} placeholder='Address' sx={{ mb: 3 }} />
                            </div>
                            <div className="col-4">
                                <TextField label='City' type="text" name='city_a' disabled={disabled} placeholder='City' sx={{ mb: 3 }} />
                            </div>
                            <div className="col-4">
                                <TextField label='Post' type="text" name='post_a' disabled={disabled} placeholder='Post' sx={{ mb: 3 }} />
                            </div>
                            <div className="col-4">
                                <TextField label='District' type="text" name='district_a' disabled={disabled} placeholder='District' sx={{ mb: 3 }} />
                            </div>
                            <div className="col-4">
                                <TextField label='Area' type="text" name='area_a' disabled={disabled} placeholder='Area' sx={{ mb: 3 }} />
                            </div>
                            <div className="col-4">
                                <TextField label='Latitude' type="text" name='latitude_a' disabled={disabled} placeholder='Latitude' sx={{ mb: 3 }} />
                            </div>
                            <div className="col-4">
                                <TextField label='Longitude' type="text" name='longitude_a' disabled={disabled} placeholder='Longitude' sx={{ mb: 3 }} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <p className='text-center text-dark fw-bold'>Location B <br /><small className='fw-bold'>[Customer End | Office]</small></p>
                        <div className='row'>
                            <div className="col-9">
                                <TextField label='Location *' type="text" disabled={disabled} name='location_b' placeholder='Customer End | Office' sx={{ mb: 3 }} />
                            </div>
                            <div className="col-3">
                                <TextField label='Pin *' type="text" disabled={disabled} name='pin_b' placeholder='Pin A' sx={{ mb: 3 }} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-6">
                                <TextField label='Mobile' type="text" disabled={disabled} name='mobile_b' placeholder='Mobile' sx={{ mb: 3 }} />
                            </div>
                            <div className="col-6">
                                <TextField label='Port Details *' type="text" disabled={disabled} name='port_details_b' placeholder='Port Details' sx={{ mb: 3 }} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-12">
                                <TextField label='Address *' type="text" name='address_b' disabled={disabled} placeholder='Address' sx={{ mb: 3 }} />
                            </div>
                            <div className="col-4">
                                <TextField label='City' type="text" name='city_b' disabled={disabled} placeholder='City' sx={{ mb: 3 }} />
                            </div>
                            <div className="col-4">
                                <TextField label='Post' type="text" name='post_b' disabled={disabled} placeholder='Post' sx={{ mb: 3 }} />
                            </div>
                            <div className="col-4">
                                <TextField label='District' type="text" name='district_b' disabled={disabled} placeholder='District' sx={{ mb: 3 }} />
                            </div>
                            <div className="col-4">
                                <TextField label='Area' type="text" name='area_b' disabled={disabled} placeholder='Area' sx={{ mb: 3 }} />
                            </div>
                            <div className="col-4">
                                <TextField label='Latitude' type="text" name='latitude_b' disabled={disabled} placeholder='Latitude' sx={{ mb: 3 }} />
                            </div>
                            <div className="col-4">
                                <TextField label='Longitude' type="text" name='longitude_b' disabled={disabled} placeholder='Longitude' sx={{ mb: 3 }} />
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}
