import React from 'react'
import Modal from 'react-modal'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFindCircuit } from '../../hooks/circuit';
import CircuitFinderForm from '../Forms/CircuitFinderForm';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '700px',
    },
    overlay: { zIndex: 1000 }
};
export default function CircuitFinderModal({ modal, setModal }) {
    let navigate = useNavigate();
    const { handleFindCircuit, isFindCircuitLoading } = useFindCircuit();
    // console.log('currentDocketData', currentDocketData)

    const handleSubmit = async (values, { resetForm }) => {
        const { q, type } = values;
        const res = await handleFindCircuit(q, type);
        if (!res.success) {
            toast.error('No records found...')
        } else {
            const { id, code } = res.data[0];
            setModal(false)
            toast.success('Circuit found...')
            navigate(`docket/add?circuit=${id}&cid=${code.toLowerCase()}`)
        }
        // if (res.success) {
        //     toast.success(`#${currentDocketData?.code} - Status changed.`);
        //     setModal(false);
        //     setCurrentDocketData({});
        // } else {
        //     toast.error(res.detail)
        // }
        // console.log('data --> ', values);
    }
    return (
        <Modal
            isOpen={modal}
            // onAfterOpen={afterOpenModal}
            // onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <div className="container-fluid">

                <div className="d-flex justify-content-between">
                    <h5 className="modal-title mb-3 text-center text-info">Create new Docket</h5>
                    <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={() => setModal(false)}></button>
                </div>
                <div className="modal-body" style={{ width: '100%' }}>
                    <CircuitFinderForm
                        isLoading={isFindCircuitLoading}
                        handleSubmit={handleSubmit}
                        setModal={setModal}
                    />
                </div>
            </div>
        </Modal>
    )
}
