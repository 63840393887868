import React, { useState } from 'react'
import { formatDateTime } from '../../utils/shortcuts'
import { truncate } from '../../utils'
import { Link } from 'react-router-dom'
import { MobileDocketActionSkeleton } from '../../pages/docket/Components/DocketSkeletonLoader'
import HandleHoldVehicleBackToPop from '../../pages/docket/Components/HandleHoldVehicleBackToPop'

export default function MobileDockets({ data, user, setDocData, setCurrentDocketData, handleDocketInfoModal, setDocketChangeModal, setfiberTLChangeModal, setBacktoPopModal, docketsIsLoading }) {
    const [showTab, setTab] = useState(0)
    return (
        <div className='row mt-3'>
            <div className='col-12'>
                {data.map((row, i) => <div className="shadow-sm p-3 mb-2 bg-white rounde" key={i}>
                    <div className='row' onClick={() => setTab(i)}>
                        <div className='col-6'>
                            <h6 className='mb-0'>
                                <button type="button" className="btn btn-secondary btn-sm text-center me-2 downBtnTable"><i className={`fas fa-chevron-${showTab === i ? 'down' : 'right'}`}></i></button>
                                {row.code}
                            </h6>
                        </div>
                        <div className='col-6  text-end'>
                            <h6 className='mb-0'>
                                {row.status}
                            </h6>
                        </div>
                        <div className='col-12 mt-3'>
                            <div className='row m-0'>
                                {row?.get_closed_info?.vehicle1_to_pop && !row?.get_closed_info?.vehicle1_pop_km | row?.get_closed_info?.vehicle2_to_pop && !row?.get_closed_info?.vehicle2_pop_km | row?.get_closed_info?.vehicle3_to_pop && !row?.get_closed_info?.vehicle3_pop_km ? <div className='col-12 text-center' >
                                    <span className="badge text-danger bg-light" role='button' onClick={() => {
                                    setBacktoPopModal(true);
                                    setDocData(row);
                                }}>update required</span>
                                </div> : ''}
                                <HandleHoldVehicleBackToPop docket={row} />

                                <div className='col-3'>
                                    <p className='mb-1'>Loc. A:</p>
                                </div>
                                <div className='col-9 text-end'>
                                    <p className='mb-1'>{row?.get_circuit?.location_a}</p>
                                </div>

                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='row m-0'>
                                <div className='col-3'>
                                    <p className='mb-1'>Loc. B:</p>
                                </div>
                                <div className='col-9 text-end'>
                                    <p className='mb-1'>{row?.get_circuit?.location_b}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {showTab === i && <div className='row detailsinerTableMobile'>
                        <div className='col-12 mb-2'>
                            <div className='row'>
                                <div className='col-3'>
                                    <h6>ID:</h6>
                                </div>
                                <div className='col-9'>
                                    <h6>{row.code}</h6>
                                </div>
                            </div>
                        </div>
                        {/* <div className='col-12 mb-2'>
                            <div className='row'>
                                <div className='col-3'>
                                    <h6>Loc. A:</h6>
                                </div>
                                <div className='col-9'>
                                    <h6>{row?.get_circuit?.location_a}</h6>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 mb-2'>
                            <div className='row'>
                                <div className='col-3'>
                                    <h6>Loc. B:</h6>
                                </div>
                                <div className='col-9'>
                                    <h6>{row?.get_circuit?.location_b}</h6>
                                </div>
                            </div>
                        </div> */}
                        <div className='col-12 mb-2'>
                            <div className='row'>
                                <div className='col-3'>
                                    <h6>Problem:</h6>
                                </div>
                                <div className='col-9'>
                                    {/* <h6>{row?.get_problem?.title ? truncate(row?.get_problem?.title, 50) : '-'}</h6> */}
                                    <h6>{row?.get_problem?.title ?? '-'}</h6>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 mb-2'>
                            <div className='row'>
                                <div className='col-3'>
                                    <h6>Created:</h6>
                                </div>
                                <div className='col-9'>
                                    <h6>{formatDateTime(row.created_at, 'DD-MM-YY h:mm a')}</h6>
                                </div>
                            </div>
                        </div>


                        <div className='col-12 mb-2'>
                            <div className='row'>
                                <div className='col-3'>
                                    <h6>Updated:</h6>
                                </div>
                                <div className='col-9'>
                                    <h6>{formatDateTime(row.updated_at, 'DD-MM-YY h:mm a')}</h6>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 mb-2'>
                            <div className='row'>
                                <div className='col-12'>
                                    {docketsIsLoading ? <MobileDocketActionSkeleton /> : <div className="d-flex gap-1">

                                        <Link to={`/docket/${row.code}?docket=${row.code}&circuit=${row.get_circuit.code}`} className='btn btn-primary pb-0 btn-style-light' title='View'>
                                            <span className="material-icons-sharp">
                                                visibility
                                            </span>
                                        </Link>

                                        {row.status !== 'Cancelled' && <>
                                            <button className="btn btn-dark btn-style-light pb-0" onClick={() => handleDocketInfoModal(row)} title='Quick Info' ><span className="material-icons-sharp">info</span></button>

                                            {row.status !== 'Closed' ? <>
                                                {user?.permissions?.dockets?.change && <>

                                                    {user?.user_role === 'EXP' && <Link to={row.is_maintenance ? `/docket/maintenance/update/${row.code}` : `/docket/update/${row.code}`} className='btn btn-dark btn-style-light pb-0' title='Edit Docket'>
                                                        <span className="material-icons-sharp">
                                                            mode_edit
                                                        </span>
                                                    </Link>}
                                                    {user?.permissions?.dockets?.can_change_tl | user?.permissions?.dockets?.can_change_tech ? <button className="btn btn-info btn-style-light pb-0" onClick={() => { setDocData(row); setfiberTLChangeModal(true); }} title='Change Fiber Team Leader ' ><span className="material-icons-sharp">manage_accounts</span></button> : ''}
                                                </>}

                                                {user?.permissions?.dockets?.change && <button className="btn btn-warning pb-0 btn-style-light" onClick={() => { setDocketChangeModal(true); setCurrentDocketData(row) }} data-bs-toggle="tooltip" data-bs-placement="left" title="Change Status">
                                                    <span className="material-icons-sharp">
                                                        manage_history
                                                    </span>
                                                </button>}
                                            </> : ''}
                                            {!user?.permissions?.dockets?.change && <>
                                                {row.status === 'Resolved' && row.has_recent_docket.status ? <Link className="btn btn-danger btn-style-light pb-0" style={{ marginLeft: 5 }} to={`/docket/add?circuit=${row.circuit}`} title={`Docket Reopened until ${formatDateTime(row.has_recent_docket.opened_until, 'MMMM Do YYYY, h:mm a')}`}>
                                                    <span className="material-icons-sharp">restart_alt</span></Link> : ''}
                                            </>}
                                        </>}

                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>)}

                {/* <div className="shadow-sm p-3 mb-2 bg-white rounde">
                    <div className='row'>
                        <div className='col-6'>
                            <h6 className='mb-0'>
                                <button type="button" className="btn btn-secondary btn-sm text-center me-2 downBtnTable"><i className="fas fa-chevron-down"></i></button>
                                MBMMB2687
                            </h6>
                        </div>
                        <div className='col-6  text-end'>
                            <h6 className='mb-0'>
                                Pending
                            </h6>
                        </div>
                    </div>
                </div>
                <div className="shadow-sm p-3 mb-2 bg-white rounde">
                    <div className='row'>
                        <div className='col-6'>
                            <h6 className='mb-0'>
                                <button type="button" className="btn btn-secondary btn-sm text-center me-2 downBtnTable"><i className="fas fa-chevron-down"></i></button>
                                MBMMB2687
                            </h6>
                        </div>
                        <div className='col-6  text-end'>
                            <h6 className='mb-0'>
                                Pending
                            </h6>
                        </div>
                    </div>
                </div>
                <div className="shadow-sm p-3 mb-2 bg-white rounde">
                    <div className='row'>
                        <div className='col-6'>
                            <h6 className='mb-0'>
                                <button type="button" className="btn btn-secondary btn-sm text-center me-2 downBtnTable"><i className="fas fa-chevron-down"></i></button>
                                MBMMB2687
                            </h6>
                        </div>
                        <div className='col-6  text-end'>
                            <h6 className='mb-0'>
                                Pending
                            </h6>
                        </div>
                    </div>
                </div>
                <div className="shadow-sm p-3 mb-2 bg-white rounde">
                    <div className='row'>
                        <div className='col-6'>
                            <h6 className='mb-0'>
                                <button type="button" className="btn btn-secondary btn-sm text-center me-2 downBtnTable"><i className="fas fa-chevron-down"></i></button>
                                MBMMB2687
                            </h6>
                        </div>
                        <div className='col-6  text-end'>
                            <h6 className='mb-0'>
                                Pending
                            </h6>
                        </div>
                    </div>
                </div> */}

            </div>

        </div>
    )
}
