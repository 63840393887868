import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
// import { useNavigate } from 'react-router-dom';
// import { toast } from 'react-toastify';
// import { useDocket, useDocketUpdate } from '../../hooks/docket';
import { useSimpleDocketUpdate } from '../../hooks/docket';
import { toast } from 'react-toastify';
import CorpDocketTicketForm from '../Forms/CorpDocketForm/CorpDocketTicketForm';
import { useCorpDocketTicket, useCorpDocketTicketList } from '../../hooks/docket/corporate';
import { useUser } from '../../hooks/user';
import Loader from '../Common/Loader';
import { formatDateTime } from '../../utils/shortcuts';
import ImagePreviewModal from './ImagePreviewModal';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '700px',
    },
    overlay: { zIndex: 1000 }
};


export default function CorporateDocketTicketModal({ modal, setModal, docData }) {
    // let navigate = useNavigate();
    // console.log(data)
    const { user } = useUser();
    const [showAddForm, setShowAddForm] = useState(false);
    const [showImageModal, setImageModal] = useState(false);
    const [currentData, setCurrentData] = useState(null);
    const { corpDocketTicketData, isLoadingCorpDocketTicketData, filterList } = useCorpDocketTicketList();
    const { handleCorpDocketTicket, isCreatingDocket } = useCorpDocketTicket(); // Using for File Uploading (Axios)


    // useEffect(() => {
    //     filterList(`/${docData?.code}`, user?.permissions?.dockets?.can_view_corp_docket_ticket)
    // }, [])

    const handleSubmit = async (values, { resetForm }) => {
        const { file } = values
        const formData = new FormData();
        file && formData.append("file", file[0]);

        for (let key in values) {
            if (key === 'file') { continue; }
            if (values[key]) {
                formData.append(key, values[key]);
            }
        }

        const res = await handleCorpDocketTicket(formData, docData?.code)
        // console.log('doc -> ',res)
        if (res.success) {
            toast.success(`${docData?.code} - ${res.detail}`);
            setModal(false)
            setShowAddForm(false)
        } else {
            toast.error(res.detail)
        }
    }

    return (
        <Modal
            isOpen={modal}
            onAfterOpen={() => filterList(`/${docData?.code}`, user?.permissions?.dockets?.can_view_corp_docket_ticket)}
            // onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Docket Ticket Modal"
        >
            <div className="container-fluid">

                <div className="d-flex justify-content-between">
                    <h5 className="modal-title mb-3 text-info">Corporate Docket Ticket ({`${docData?.code}`})</h5>
                    <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setModal(false); }}></button>
                </div>
                <div className="modal-body" style={{ width: '100%' }}>
                    {docData.status !== 'Closed' && <div className="d-flex justify-content-between align-items-center mb-1">
                        <div>
                            {user?.permissions?.dockets?.can_add_corp_docket_ticket && <button className={`btn btn-${showAddForm ? 'dark' : 'primary'}`} onClick={() => setShowAddForm(!showAddForm)}> <i className="material-icons-sharp">{showAddForm ? 'close_fullscreen' : 'add'}</i>  {showAddForm ? 'Hide' : 'New Correspondence'}</button>}
                        </div>
                        <div></div>
                    </div>}
                    {user?.permissions?.dockets?.can_add_corp_docket_ticket && showAddForm && <CorpDocketTicketForm
                        isLoading={isCreatingDocket}
                        handleSubmit={handleSubmit}
                        data={docData}
                        setModal={setModal}
                    />}
                    <hr />

                    {user?.permissions?.dockets?.can_view_corp_docket_ticket && <div className=''>
                        {/* <small>{isLoadingCorpDocketTicketData && <Loader label='Fetching correspondence.' />}</small> */}

                        <table className="table">
                            <thead className="table-dark table-sm">
                                <tr>
                                    <th>
                                        <small>Message</small>
                                    </th>
                                    <th title="Attachment">
                                        <small>Atta.</small>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {corpDocketTicketData && !isLoadingCorpDocketTicketData && corpDocketTicketData.success && corpDocketTicketData.data.length > 0 ?
                                    (corpDocketTicketData.data.map(
                                        (val, i) => (
                                            <tr key={i}>
                                                <td title={`${val?.get_created_by?.name} - ${formatDateTime(val.updated_at, 'D-M-YY h:ma')}`}>
                                                    {val.comment} <small> - {val?.get_created_by?.name}</small>
                                                </td>
                                                <td>
                                                    {val.file ? <span className="material-icons-outlined" role='button' onClick={() => { setCurrentData(val); setImageModal(true); }}> image </span> : ''}
                                                </td>
                                            </tr>
                                        ),
                                    )) : !isLoadingCorpDocketTicketData && <tr>
                                        <td colSpan="2" className='text-center'>No data available</td>
                                    </tr>}


                                {isLoadingCorpDocketTicketData && <tr>
                                    <td colSpan={2} className='text-center'>Loading ...</td>
                                </tr>}
                            </tbody>
                        </table>
                    </div>}
                </div>
            </div>
            <ImagePreviewModal
                modal={showImageModal}
                setModal={setImageModal}
                src={currentData?.file}
                text={currentData?.comment}
            />
        </Modal>
    )
}
