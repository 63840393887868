/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import Header from './Header';
import AppMenu from './AppMenu';
import CircuitFinderModal from '../Models/CircuitFinderModal';
import ChangePasswordModal from '../Models/ChangePasswordModal';
import DocketCloseModal from '../Models/DocketCloseModal';
import SendCustomSMSModal from '../Models/SendCustomSMSModal';
import MyAccountModal from '../Models/MyAccountModal';
import Footer from './Footer';
import UniversalDocketRequestModal from '../Models/UniversalDocketRequestModal';
import DocketRequestModal from '../LCO/DocketRequestModal';
import { useUser } from '../../hooks/user';
import { useDocketUnderReviewList } from '../../hooks/docket';
import coreContext from '../../context/core/coreContext';


export default function Layout() {
    const { user } = useUser();
    const {  data: { review: {docketsUnderReviewData, docketsUnderReviewIsLoading, handleParam} } } = useContext(coreContext);
    // const { docketsUnderReviewData, docketsUnderReviewIsLoading, handleParam } = useDocketUnderReviewList();
    const [docketCreateModal, setDocketCreateModal] = useState(false);
    const [docketRequestModal, setDocketRequestModal] = useState(false);
    const [docketRequestFormModal, setDocketRequestFormModal] = useState(false);
    const [docketRequestFormData, setDocketRequestFormData] = useState(null);
    const [docketCloseModal, setDocketCloseModal] = useState(false);
    const [pwdChangeModal, setPwdChangeModal] = useState(false);
    const [sendCustomSMSModal, setSendCustomSMSModal] = useState(false);
    const [myAccountModal, setMyAccountModal] = useState(false);
    // console.log(docketsUnderReviewData?.data?.length)
    return (
        <>
            <div className="app horizontal-menu align-content-stretch d-flex flex-wrap">

                <div className="app-container">
                    <div className="search container">
                        <form>
                            <input className="form-control" type="text" placeholder="Type here..." aria-label="Search" />
                        </form>
                        <a href="#" className="toggle-search"><i className="material-icons">close</i></a>
                    </div>
                    <Header
                        setDocketCreateModal={setDocketCreateModal}
                        setDocketRequestModal={setDocketRequestModal}
                    />

                    <AppMenu
                        setDocketCreateModal={setDocketCreateModal}
                        setMyAccountModal={setMyAccountModal}
                        setPwdChangeModal={setPwdChangeModal}
                        setDocketCloseModal={setDocketCloseModal}
                        setSendCustomSMSModal={setSendCustomSMSModal} />


                    <div className="app-content">
                        <div className="content-wrapper">
                            <div className="container-fluid">
                                {user?.permissions?.dockets?.can_accept_docket && docketsUnderReviewData?.data?.length > 0 && <div className="d-flex justify-content-center bg-danger text-light p-2 gap-2">
                                    <div className='d-flex align-items-center'>
                                        You have {docketsUnderReviewData?.data?.length} under review dockets.
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <Link to='/docket/under-review' className='btn btn-sm btn-outline-dark text-light'> View now</Link>
                                    </div>
                                </div>}
                                <Outlet />

                                {/* Docket Create Modal */}
                                {docketCreateModal && <CircuitFinderModal
                                    modal={docketCreateModal}
                                    setModal={setDocketCreateModal}
                                />}
                                {/* Universal Docket Request Modal */}
                                {docketRequestModal && <UniversalDocketRequestModal
                                    modal={docketRequestModal}
                                    setModal={setDocketRequestModal}
                                    setDocketRequestFormData={setDocketRequestFormData}
                                    setDocketRequestFormModal={setDocketRequestFormModal}
                                />}
                                {/* Docket Request Modal */}
                                {docketRequestFormModal && <DocketRequestModal
                                    modal={docketRequestFormModal}
                                    setModal={setDocketRequestFormModal}
                                    currentData={docketRequestFormData}
                                    setCurrentData={setDocketRequestFormData}
                                />}
                                {/* Docket Close Modal */}
                                {docketCloseModal && <DocketCloseModal
                                    modal={docketCloseModal}
                                    setModal={setDocketCloseModal}
                                />}
                                {/* My Account Modal */}
                                {myAccountModal && <MyAccountModal
                                    modal={myAccountModal}
                                    setModal={setMyAccountModal}
                                />}
                                {/* Change Password Modal */}
                                {pwdChangeModal && <ChangePasswordModal
                                    modal={pwdChangeModal}
                                    setModal={setPwdChangeModal}
                                />}

                                {/* Send Custom SMS Modal */}
                                {sendCustomSMSModal && <SendCustomSMSModal
                                    modal={sendCustomSMSModal}
                                    setModal={setSendCustomSMSModal}
                                />}
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <Footer />


        </>
    )
}
