import React from 'react'
import Modal from 'react-modal'
import { toast } from 'react-toastify';
import { useCustomSMS } from '../../hooks/core/sms';
import SMSForm from '../Forms/SMSForm';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '700px',
    },
    overlay: { zIndex: 1000 }
};

export default function SendCustomSMSModal({ modal, setModal }) {
    const { handleCustomSms, isSendingSms } = useCustomSMS()
    const handleSubmit = async (values, { resetForm }) => {
        // console.log(values)
        const resp = await handleCustomSms(values);

        if (resp.success) {
            resetForm({ values: '' });
            setModal(false);
            toast.success('SMS has been sent.')
        } else {
            toast.error(resp.detail)
        }
    }

    return (
        <Modal
            isOpen={modal}
            // onAfterOpen={afterOpenModal}
            // onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <div className="container-fluid">

                <div className="d-flex justify-content-between">
                    <h5 className="modal-title mb-3 text-center text-info">Send SMS</h5>
                    <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={() => setModal(false)}></button>
                </div>
                <div className="modal-body" style={{ width: '100%' }}>
                    <SMSForm
                        isLoading={isSendingSms}
                        handleSubmit={handleSubmit}
                        setModal={setModal}
                    />
                </div>
            </div>
        </Modal>
    )
}
