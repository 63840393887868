import React from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Button, Typography } from '@mui/material';
import { useLCOProblemsList } from '../../../hooks/lco/dockets';
import SelectField from '../../Common/Fields/SelectField';
import { formatSelectOptionsList } from '../../../hooks/circuit';
import TextField from '../../Common/Fields/TextField';
import { APPROVAL_REQUIRED_PROBLEM_IDS } from '../../../config';


export default function DocketRequestForm({ isLoading, handleSubmit, setModal, btnLabel = 'Raise Docket', isCreateMode = false }) {
    const { problemsData, problemsIsLoading } = useLCOProblemsList();
    const validate = Yup.object({
        // problem: Yup.string().required('Select problem.'),
        problem: Yup.string().nullable().when("status", {
            is: (val) => val === 'Hold',
            then: Yup.string().required('Select problem.').nullable()
        }),
        comment: Yup.string().required('Comment is required.'),
    })
    return (
        <Formik
            initialValues={{
                problem: '',
                comment: '',
            }}
            validationSchema={validate}
            onSubmit={handleSubmit}
        >
            {formik => (

                <Form>
                    <div className='row'>
                        <div className="col-md-12 mb-4">
                            <Typography variant='caption'>Select Problem <span className='req'>*</span></Typography>
                            <SelectField
                                name='problem'
                                options={problemsData?.data?.map(formatSelectOptionsList)}
                                value={problemsData?.data?.map(formatSelectOptionsList).filter(function (option) {
                                    return option.value === formik.values.problem;
                                })}
                                onChange={(e) => formik.setFieldValue('problem', e.value)}
                                // isDisabled={disabled}
                                isLoading={problemsIsLoading}
                            />
                           
                               {/* <TextField label='Comment *' type="text" name='comment' disabled={formik.values.problem === PERSONAL_DOCKET_PROBLEM_ID && isCreateMode} placeholder='Comment' sx={{ mt: 2 }} /> */}
                               <TextField label='Comment *' type="text" name='comment' disabled={APPROVAL_REQUIRED_PROBLEM_IDS.includes(formik.values.problem) && isCreateMode} placeholder='Comment' sx={{ mt: 2 }} />
                            
                            {/* {formik.values.problem === PERSONAL_DOCKET_PROBLEM_ID && isCreateMode && <span className='text-danger mt-2'>To create a personal docket, please submit a docket request.</span>} */}
                            {APPROVAL_REQUIRED_PROBLEM_IDS.includes(formik.values.problem) && isCreateMode && <span className='text-danger mt-2'>To create a personal docket, please submit a docket request.</span>}
                             
                        </div>
                        {/* {console.log(formik.values.problem)} */}
                        {/* {formik.submitCount >= 1 && formik.errors && Object.entries(formik.errors).map(([key, value]) =>
                            <Alert severity="error" sx={{ mt: 1, mb: 1 }} key={key}>{value}</Alert>
                        )} */}
                    </div>
                    <LoadingButton
                        loading={isLoading}
                        type="submit"
                        variant="contained"
                        disableElevation
                        // disabled={formik.values.problem === PERSONAL_DOCKET_PROBLEM_ID && isCreateMode}
                        disabled={APPROVAL_REQUIRED_PROBLEM_IDS.includes(formik.values.problem) && isCreateMode}
                    >
                        {btnLabel}
                    </LoadingButton>
                    <Button variant="outlined" sx={{ ml: 1 }} disableElevation onClick={() => setModal(false)}>Close</Button>
                </Form>
            )}
        </Formik>
    )
}
