import { Container } from '@mui/material';
import React from 'react'
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import PageBreadcrumbs from '../../../components/Common/Breadcrumbs';
import UserForm from '../../../components/Forms/UserForm/UserAddForm';
import { useUserAction } from '../../../hooks/user';

export default function UserAddPage() {
    let navigate = useNavigate();
    const { handleUserAction, userActionIsLoading } = useUserAction('POST');
    const handleSubmit = async (values, { resetForm }) => {
        // console.log(values)
        const res = await handleUserAction(values);
        if (res.success) {
            toast.success(res.detail);
            resetForm({ values: '' });
            navigate(`/admin/users/`);
        } else {
            toast.error(res.detail)
        }
        // console.log('data --> ', res);
    }
    return (
        <>
            <Helmet>
                <title>Add New User</title>
            </Helmet>
            <Container maxWidth="xxl" disableGutters>
                <PageBreadcrumbs
                    title="Add User"
                    history={[{ name: 'Users', to: '/admin/users' }]}
                />
            </Container>
            <div className='container'>

                <div className="row ">
                    <div className="col mt-2">
                        <div className="">
                            <h1 className='display-6'>USER CREATION</h1>
                        </div>
                        <UserForm
                            isLoading={userActionIsLoading}
                            handleSubmit={handleSubmit}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
