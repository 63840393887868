import { useMutation } from "@tanstack/react-query";
import { getTokens } from "../../utils/shortcuts";
import { fetchJson } from "../../libs/api";
import { API_HOST_URL } from "../../config";
import { endpoints } from "../../config/endpoints";

export function useCustomSMS() {
    const { accessToken } = getTokens();
    const mutation = useMutation((data) => fetchJson(`${API_HOST_URL}/${endpoints.core.send_custom_sms}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(data)
    }, true), {
        retry: 2,
    })
    return {
        handleCustomSms: async (data) => {
            try {
                const apiRes = await mutation.mutateAsync(data);
                const resData = await apiRes.json()
                return resData                
            } catch (err) {
                return {
                    success: false,
                    detail: 'Something wents wrong.'
                }
            }
        },

        isSendingSms: mutation.isLoading,
    }
}