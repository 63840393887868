import React from 'react'
import { Link } from 'react-router-dom'
// import { PROJECT_TITLE } from '../../config'
import LoginForm from '../../components/Forms/UserForm/LoginForm'
import { useLogin } from '../../hooks/user';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
// import { useClient } from '../../hooks/core/utils';
import { formatDateTime } from '../../utils/shortcuts';


export default function LoginPage() {
    let navigate = useNavigate();
    // const { getClient } = useClient();
    const { handleLogin, loginLoading } = useLogin();
    //  console.log('first', getClient?.ip)
    const handleLoginSubmit = async (values, { resetForm }) => {
        const { username, password, ip } = values;
        const device_info = { "last_ip": ip ?? '0.0.0.0', "date": formatDateTime('today', 'YYYY-MM-DD H:m:s') }
        const res = await handleLogin(username, password, device_info);
        if (res.success) {
            toast.success('Login successfull')
            navigate(`/`);
        } else {
            toast.error(res.detail);
        }
    }
    return (
        <>
            <Helmet>
                <title>Login </title>
            </Helmet>


            <div className="login-7">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="form-section">
                                <div className="logo">
                                    <Link to="/">
                                        <img src="/assets/images/logo.png" alt="logo" className='img-fluid' />
                                    </Link>
                                </div>
                                <h3>Sign Into Your Account</h3>
                                <div className="login-inner-form">
                                    <LoginForm
                                        isLoading={loginLoading}
                                        handleSubmit={handleLoginSubmit}
                                    />
                                </div>
                                <div className='mt-5 text-center'>
                                    <p>
                                        <Link to="/lco/login" className="auth-forgot-password fw-bold">LCO Login</Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
