import React from 'react'
import Modal from 'react-modal'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDocket, useDocketHoldRequest, useDocketUpdate, useDocketUpdateRequest } from '../../hooks/docket';
import DocketUpdateForm from '../Forms/DocketUpdateForm';
import DocketHoldRequestForm from '../Forms/DocketHoldRequestForm';
import DocketResumeRequestForm from '../Forms/DocketResumeRequestForm';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '700px',
    },
    overlay: { zIndex: 1000 }
};
export default function DocketResumeRequestModal({ modal, setModal, currentDocketData, setCurrentDocketData }) {
    let navigate = useNavigate();
    // const { handleDocketAction, docketActionIsLoading } = useDocket('PATCH');
    // const { handleDocketUpdate, isDocketUpdating } = useDocketUpdate(); // Using for File Uploading (Axios)
    // const { handleDocketHoldReq, isRequestUpdating } = useDocketHoldRequest(); // Using for File Uploading (Axios)
    const { handleDocketRequestAction, docketRequestIsLoading } = useDocketUpdateRequest('POST');
    // console.log('currentDocketData', currentDocketData)

    const handleSubmit = async (values, { resetForm }) => {
        // console.log(values)

        const res = await handleDocketRequestAction({
            ...values,
            docket: currentDocketData.id,
            update_type: 'Resume'
        })
        // console.log('res -> ', res)

        if (res.success) {
            toast.success(res.detail);
            setModal(false)
            setCurrentDocketData({});
            // navigate(`/docket/${closedDocketsData.data.docket.code}`);
            // setInfoModal(true)
        } else {
            toast.error(res.detail)
        }
        // const { file, vehicle1, vehicle2 } = values

        // const formData = new FormData();
        // file && formData.append("file", file[0]);
        // for (let key in values) {
        //     if (key === 'file') { continue; }
        //     if (key === 'vehicle1') { continue; }
        //     if (key === 'vehicle2') { continue; }
        //     formData.append(key, `${values[key]}`);
        // }
        // vehicle1 && formData.append("vehicle1", vehicle1);
        // vehicle2 && formData.append("vehicle2", vehicle2);
        // formData.append("status", 'Pending');
        // formData.append("docket", currentDocketData.id);
        // formData.append("update_type", 'Hold');
        // // const res = await handleDocketUpdate(formData, currentDocketData.code)
        // const res = await handleDocketHoldReq(formData, currentDocketData.code)
        // // console.log('save res', res)
        // if (res.success) {
        //     toast.success(`${currentDocketData?.code} - Hold request sent.`);
        //     setModal(false)
        //     setCurrentDocketData({});
        // } else {
        //     toast.error(res.detail)
        // }
        // console.log('data --> ', res);
    }
    return (
        <Modal
            isOpen={modal}
            // onAfterOpen={afterOpenModal}
            // onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <div className="container-fluid">

                <div className="d-flex justify-content-between">
                    <h5 className="modal-title mb-3 text-info">Create resume request for [{currentDocketData?.code}] {currentDocketData.alt_status === 'Waiting for Confirmation' && <code>Waiting for Confirmation</code>} </h5>
                    <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setModal(false); setCurrentDocketData({}); }}></button>
                </div>
                <div className="modal-body" style={{ width: '100%' }}>

                    <DocketResumeRequestForm
                        isLoading={docketRequestIsLoading}
                        handleSubmit={handleSubmit}
                        data={currentDocketData}
                        setModal={setModal}
                    />
                </div>
            </div>
        </Modal>
    )
}
