
import React from 'react'
import { formatDateTime } from '../../utils/shortcuts'
import { Link } from 'react-router-dom'

export default function DocketProgress({ data }) {
    // console.log('data -> ', data)
    // console.log('data.has_recent_docket -> ', data.has_recent_docket)
    // console.log('progress_log -> ', data.progress_log)
    return (
        <div className='row'>
            <div className="col-12 mb-3">
                <div className='showProgress'>
                    <a className='btn btn-dark btn-style-light nav-notifications-toggle' id="notificationsDropDown" href="#" data-bs-toggle="dropdown" aria-expanded="true"> <i className="fas fa-eye"></i> Show Process Bar</a>
                    <div className='dropdown-menu dropdown-menu-end notifications-dropdown' aria-labelledby="notificationsDropDown">
                        <div className="d-flex justify-content-center flex-column align-self-center linkInerListOrder">
                            <ul>
                                {data.progress_log.map((val, i) => <li className={`${val.status && ' addColor'}`} key={i}>
                                    <div>
                                        <p className={`${val.status && ' text-success'}`}>{val.label}</p>

                                        {data?.has_recent_docket?.status && i === 6 && <>
                                            <Link className='btn btn-sm btn-outline-dark text-danger' to={`/docket/add?circuit=${data.circuit}`}>Reopen Docket</Link>
                                            
                                            <code>Until, {formatDateTime(data?.has_recent_docket?.opened_until, 'MMMM Do YYYY, h:mm a')}</code>
                                        </>}
                                    </div>

                                </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
                
            </div>

        </div>
    )
}
