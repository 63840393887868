import React from 'react'
import { useField } from 'formik';
import { TextareaAutosize } from '@mui/material';
// import { FormLabel, Textarea } from '@mui/joy';

export default function TextAreaField({ label, required, ...props }) {
    const [field] = useField(props);
    return (

        <>
            {/* <FormLabel>{label}</FormLabel> */}
            <label className='mx-1'>{label} {required && <span className='req'>*</span>}</label>
            {/* <Textarea
                label={label}
                variant='outlined'
                {...field}
                {...props}
                className="mb-3"
                minRows={1}
                style={{ height: 40, borderRadius: 5 }}
            /> */}

            <TextareaAutosize
                label={label}
                variant='standard'
                {...field}
                {...props}
                className="form-control mb-3"
            />
        </>

    )
}

