import React from 'react'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TextField from '@mui/material/TextField';
import { useField } from 'formik';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import MomentAdapter from "@material-ui/pickers/adapter/moment";
// import moment from "moment";
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';


// <LocalizationProvider dateAdapter={AdapterDayjs}>
//     <DemoContainer components={['DateTimePicker']}>
//         <DateTimePicker label="Basic date time picker" />
//     </DemoContainer>
// </LocalizationProvider

import Datetime from 'react-datetime';


export default function DateTimePicker({ label, ...props }) {
    const [field, meta, helpers] = useField(props);
    // const timeZoneFromServer = "Asia/Kolkata";
    // console.log('field: ', field)
    // console.log('meta: ',meta)
    // console.log('helpers: ',helpers)
    return <Datetime  
        onChange={(e) => {helpers.setValue(e)}}
        inputProps ={{
            placeholder: label,
            dateFormat: 'DD-MM-YYYY H:m'
        }}
        // value={moment('2023-09-13T04:30:00Z').format('YYYY-MM-DD H:m:s')}
        
        utc={false}
    />
}
//     return (
//         <LocalizationProvider dateAdapter={AdapterDayjs}>
//             {/* <DemoContainer components={['DateTimePicker']}> */}
//                 <DateTimePicker
//                     // onChange={(option) => helpers.setValue(moment(option).format('YYYY-MM-DD'))}
//                     onChange={(option) => helpers.setValue(option)}
//                     label={label}
//                     inputFormat="DD-MM-YYYY"
//                     // views={["year", "month", "date"]}      
//                     renderInput={(params) => <TextField variant='outlined' size="small" fullWidth {...field} {...params} autoComplete='off' error={meta.touched && Boolean(meta.error)}
//                         helperText={meta.touched && meta.error} />}
//                     {...meta}
//                     {...props}
//                 />
//             {/* </DemoContainer> */}
//         </LocalizationProvider>
//     )
// }