import { LoadingButton } from '@mui/lab'
import { Form, Formik } from 'formik'
import React from 'react'
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import * as Yup from 'yup'
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { InputField } from '../../../../components/Common/Fields/BSField';



export default function MaterialsReportForm({ searchParams, setSearchParams, handleSubmit }) {
    let navigate = useNavigate();
    const currentParams = Object.fromEntries([...searchParams]);



    const validationSchema = Yup.object().shape({
        docket_id: Yup.string().nullable(),
        date_from: Yup.date().nullable(),
        date_to: Yup.date().nullable()
    }).test('docket-or-dates', 'Either Docket ID or both dates are required', function (value) {
        const { docket_id, date_from, date_to } = value;
        if (!docket_id && (!date_from || !date_to)) {
            return this.createError({
                path: 'docket_id',
                message: 'Either Docket ID or both from-to dates are required'
            });
        }
        return true;
    });


    return (
        <Formik
            initialValues={{
                docket_id: currentParams?.docket_id ?? '',
                date_from: null, // Initial start date
                date_to:  null // Initial end date,
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
        >
            {formik => (
                <Form>
                    <div className='row'>
                        <div className='col-md-12 mb-4'>
                            <InputField label='Docket ID' type="text" name='docket_id' placeholder='Docket ID' />

                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-md-6 mb-4">
                            <label htmlFor="date_from">From Date</label>
                            <Datetime
                                value={formik.values.date_from}
                                onChange={date => formik.setFieldValue('date_from', date)}
                                dateFormat="YYYY-MM-DD"
                                timeFormat={false}
                                inputProps={{ placeholder: 'Select start date' }}
                            />
                            {formik.errors.date_from && <div className="text-danger">{formik.errors.date_from}</div>}
                        </div>
                        <div className="col-md-6 mb-4">
                            <label htmlFor="date_to">To Date</label>
                            <Datetime
                                value={formik.values.date_to}
                                onChange={date => formik.setFieldValue('date_to', date)}
                                dateFormat="YYYY-MM-DD"
                                timeFormat={false}
                                inputProps={{ placeholder: 'Select end date' }}
                            />
                            {formik.errors.date_to && <div className="text-danger">{formik.errors.date_to}</div>}
                        </div>
                    </div>



                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <LoadingButton
                                loading={false}
                                type="submit"
                                variant="contained"
                                disableElevation
                            >
                                Get Report
                            </LoadingButton>

                            <Button variant="outlined" sx={{ ml: 1 }} disableElevation
                                onClick={() => {
                                    formik.resetForm({
                                        values: {
                                            docket_id: '',
                                            date_from: null,
                                            date_to: null
                                        }
                                    });
                                    navigate(`/reports/material/used-report`);
                                }}
                            >Reset</Button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}