import React from 'react'
import Modal from 'react-modal'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import DocketRequestForm from './Forms/DocketRequestForm';
import { useDocketRequest, useNewDocket } from '../../hooks/lco/dockets';
import { useCorporateDocket } from '../../hooks/docket/corporate';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '700px',
    },
    overlay: { zIndex: 1000 }
};
export default function CorporateDocketCreateModal({ modal, setModal, currentData, setCurrentData }) {
    let navigate = useNavigate();
    const { handleNewCorporateDocket, isCreatingCorporateDocket } = useCorporateDocket('POST')

    const handleCreateNewDocket = async (values, { resetForm }) => {
        // console.log(values)
        const res = await handleNewCorporateDocket({
            ...values, 
            circuit: currentData.id,
            provider: currentData.provider,
            action: 'add',
        })
        // console.log(res)
        res.success ? toast.success(res.detail) : toast.error(res.detail.replace("detail:", ""));
        setModal(false)
        // navigate(`/docket/${res?.data?.code}`)
    }

    return (
        <Modal
            isOpen={modal}
            // onAfterOpen={afterOpenModal}
            // onRequestClose={closeModal}
            style={customStyles}
            contentLabel="New Corporate Docket"
        >
            <div className="container-fluid">

                <div className="d-flex justify-content-between">
                    <h5 className="modal-title mb-3 text-center text-info">New Corporate Docket!</h5>
                    <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setModal(false); setCurrentData(null); }}></button>
                </div>
                <div className="modal-body" style={{ width: '100%' }}>
                    <div className="d-flex justify-content-between mb-4">
                        <div>
                            <p className="display-6 text-dark m-0">Circuit ID:</p>
                        </div>
                        <div>
                            <h3 className="display-6 text-danger m-0">{currentData?.code}</h3>
                        </div>
                    </div>
                    {/* <hr /> */}
                    <DocketRequestForm
                        setModal={setModal}
                        isLoading={isCreatingCorporateDocket}
                        handleSubmit={handleCreateNewDocket}
                        btnLabel='Raise New Docket'
                        isCreateMode={true}
                    />
                </div>
            </div>
        </Modal>
    )
}
