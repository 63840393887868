import { Container } from '@mui/material';
import React, { useContext } from 'react'
import { Helmet } from 'react-helmet';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import PageBreadcrumbs from '../../../components/Common/Breadcrumbs';
import CorpManualDocketForm from '../../../components/Forms/CorpDocketForm/CorpManualDocketForm';
import { useCorpateDocketList, useCorporateDocket } from '../../../hooks/docket/corporate';
import providerContext from '../../../context/provider/providerContext';
import moment from 'moment';
import { formatDateTime } from '../../../utils/shortcuts';
import { useUser } from '../../../hooks/user';
import CorpDocketCloseForm from '../../../components/Forms/CorpDocketForm/CorpDocketCloseForm';
import { Grid } from "@mui/material";
import Alert from '../../../components/Common/Alert';
import Loader from '../../../components/Common/Loader';
export default function CloseCorporateDocketPage() {
    const { currentProvider } = useContext(providerContext);
    let navigate = useNavigate();
    const { user } = useUser();
    const { id } = useParams();
    const { corporateDockets, isCorporateDocketsLoading, filterDocketsList } = useCorpateDocketList(`/${id}`);
    // console.log(corporateDockets)
    const { handleNewCorporateDocket, isCreatingCorporateDocket } = useCorporateDocket('PATCH')
    const handleSubmit = async (values, { resetForm }) => {

        const res = await handleNewCorporateDocket({
            ...values,
            id: id,
            action: 'closed',
            comment: 'Docket Closed!',
            status: 'Closed',
        })

        if (res.success) {
            toast.success(res.detail);
            resetForm({ values: '' });
            navigate(`/docket/corporate`);
        } else {
            toast.error(res.detail)
        }
    }

    const renderDocketClose = (data) => {
        if (data?.status === "Closed") {
            return (
                <>
                    <Alert
                        title="Docket already Closed!"
                        text={`Docket ${data.code} has been closed on ${formatDateTime(data?.closed_at, "DD-MM-YYYY")}`}
                        type="primary"
                    />
                    <Link className="btn btn-info" to={`/docket/corporate/${data.code}`}>
                        View Docket
                    </Link>
                </>
            );
        } 
        return <CorpDocketCloseForm
            data={data}
            isLoading={isCreatingCorporateDocket}
            handleSubmit={handleSubmit}
            btnLabel='Close Corporate Docket'
        />
    }
    return (
        <>
            <Helmet>
                <title>Corporate - Close Docket</title>
            </Helmet>
            <Container maxWidth="xxl" disableGutters>
                <PageBreadcrumbs
                    title="Close Corporate Docket"
                    alt='Corporate Docket Close'
                    history={[{ name: 'Corporate Dockets', to: '/docket/corporate' }]}
                />
            </Container>
            <div className='container'>

                <div className="row ">
                    <div className="col mt-2">
                        <Grid item xs={12} sx={{ p: 1 }}>
                            {isCorporateDocketsLoading && <Loader />}
                            {!corporateDockets?.success && !isCorporateDocketsLoading && (
                                <Alert
                                    title="Corporate Docket Not Found!"
                                    text={`Entered a Invalid Docket Id.`}
                                    type="danger"
                                />
                            )}
                        </Grid>

                        <div className="row customInfoDesign w-100">
                            {/* {user?.permissions?.dockets?.view && (
                                <Grid item xs={4} sx={{ p: 1 }}>
                                    {closedDocketsData?.data &&
                                        closedDocketsData?.success &&
                                        !closedDocketsIsLoading && (
                                            <CloseDocketInfo data={closedDocketsData?.data} />
                                        )}
                                </Grid>
                            )} */}
                            <Grid item xs={12} sx={{ p: 2 }}>
                                {user?.permissions?.dockets?.can_close_corp_docket ? (
                                    <>
                                        {corporateDockets?.data &&
                                            corporateDockets?.success &&
                                            !isCorporateDocketsLoading &&
                                            renderDocketClose(corporateDockets?.data)}
                                    </>
                                ) : (
                                    <Alert
                                        title="Permission Error!"
                                        text="You do not have permission to perform this action."
                                        type="danger"
                                    />
                                )}
                            </Grid>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
