import React, { useContext, useEffect } from 'react'
import Modal from 'react-modal'
import { useUser } from '../../hooks/user';
import ExportToExcel from '../Export/ExportToExcel';
import { useDocketExport } from '../../hooks/docket/latest';
import { formatDateTime } from '../../utils/shortcuts';
import { useDebouncedCallback } from 'use-debounce';
import providerContext from '../../context/provider/providerContext';
import Skeleton from 'react-loading-skeleton';
import { export_docket_columns } from '../../utils/docket_export';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '700px',
    },
    overlay: { zIndex: 1000 }
};
export default function DocketExportModal({ data, modal, setModal, searchParams }) {
    const { user } = useUser();
    const [exportData, setExportData] = React.useState([]);
    const { currentProvider } = useContext(providerContext);
    const [filename, setFilename] = React.useState(`${currentProvider?.title || 'All'} Docket Export - ${formatDateTime('today', 'DD-MM-YYYY')}`);
    const [enableFilenameEdit, setEnableFilenameEdit] = React.useState(false);
    const [enableApi, setAPI] = React.useState(false)


    // let navigate = useNavigate();
    const { docketsData, docketsIsLoading, handleParam } = useDocketExport('', enableApi); // API Data
    const currentParams = Object.fromEntries([...searchParams]);

    const fetchExportData = useDebouncedCallback(() => {
        let params = ''
        const provider = parseInt(currentProvider?.id);

        if (currentParams.date_after) {
            params = new URLSearchParams({
                ...currentParams,
                date_range: `${formatDateTime(currentParams?.date_after, 'YYYY-MM-DD H:m')},${formatDateTime(currentParams?.date_before, 'YYYY-MM-DD H:m')}`,
                provider
            }).toString();
        } else {
            params = new URLSearchParams({ ...currentParams, provider }).toString();
        }
        setAPI(true)
        params && handleParam(`?${params}`)
        // console.log(docketsData)
    }, 300)


    useEffect(() => {
        if (docketsData && !docketsIsLoading) {
            const customHeadings = export_docket_columns(docketsData)
            setExportData(customHeadings)
        }
    }, [docketsData, docketsIsLoading])

    // useEffect(() => {
    //     fetchExportData()

    // }, [])


    return (
        <Modal
            isOpen={modal}
            onAfterOpen={() => { fetchExportData(); }}
            style={customStyles}
            contentLabel="Docket Export Modal"
        >
            <div className="container-fluid">

                <div className="d-flex justify-content-between">
                    <h5 className="modal-title mb-3 text-center text-info">Docket List Export!</h5>
                    <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={() => setModal(false)}></button>
                </div>
                <div className="modal-body" style={{ width: '100%' }}>
                    <div>
                        {/* Counter */}
                        <div className='mb-3'>
                            <div className='row m-0'>
                                <div className="shadow-sm mx-1 bg-white rounded col p-2">
                                    <div className='mobileDocket'>
                                        <h5>Total</h5>
                                        <h3 className='text-dark'>
                                            {data ? data.total : <Skeleton width={50} />}
                                        </h3>
                                    </div>
                                </div>
                                <div className="shadow-sm mx-1 bg-white rounded col p-2">
                                    <div className='mobileDocket'>
                                        <h5>Pending</h5>
                                        <h3 className='text-info'>
                                            {data ? data.pending : <Skeleton width={50} />}
                                        </h3>
                                    </div>
                                </div>
                                <div className="shadow-sm mx-1 bg-white rounded col p-2" >
                                    <div className='mobileDocket'>
                                        <h5>HOLD</h5>
                                        <h3 className='text-warning'>
                                            {data ? data.hold : <Skeleton width={50} />}
                                        </h3>
                                    </div>
                                </div>
                                <div className="shadow-sm mx-1 bg-white rounded col p-2" >
                                    <div className='mobileDocket'>
                                        <h5>Resolved</h5>
                                        <h3 className='text-success'>
                                            {data ? data.resolved : <Skeleton width={50} />}
                                        </h3>
                                    </div>
                                </div>
                                <div className="shadow-sm mx-1 bg-white rounded col p-2" >
                                    <div className='mobileDocket'>
                                        <h5>CANCELLED</h5>
                                        <h3 className='text-danger'>
                                            {data ? data.cancelled : <Skeleton width={50} />}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div>
                        {/* File Info */}
                        <p>Provider: <span className='fw-bold text-dark'>{currentProvider?.title ?? 'Failed to fetch...'}</span></p>
                        {currentParams.date_after && <p>Date Range: <span className='fw-bold text-dark'>{formatDateTime(currentParams.date_after, 'DD-MM-YYYY H:m')} to {formatDateTime(currentParams.date_before, 'DD-MM-YYYY H:m')}</span></p>}
                        <p>Information:  <span className='text-warning fw-bold'>{currentParams.show_closed === 'true' ? 'Exporting all the dockets.' : 'Exporting dockets, excluding closed dockets.'}</span></p>
                        <p>
                            File Name: <a href="#" onClick={() => setEnableFilenameEdit(!enableFilenameEdit)}>{enableFilenameEdit ? 'save' : 'edit'}</a>
                        </p>
                        <input type="text" className="form-control" value={filename} onChange={(e) => setFilename(e.target.value)} disabled={!enableFilenameEdit} />

                    </div>
                    {/* Footer */}
                    <div className=''>
                        {!docketsData && docketsIsLoading && <div>
                            <div className='d-flex justify-content-center mt-5 gap-2'>
                                <div className="spinner-grow text-primary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                <div className="spinner-grow text-secondary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                <div className="spinner-grow text-warning" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                            <p className='text-center text-warning mt-2'>Please wait, while processing data... be patient.</p>
                        </div>}

                        {docketsData && !docketsIsLoading && <div>
                            <div className='d-flex justify-content-center mt-5 gap-2'>
                                {user?.user_role === 'EXP' | user?.user_role === 'EX' ? <ExportToExcel exportData={exportData} isDisabled={docketsIsLoading} fileName={filename} /> : <span>You do not have permission to download.</span>}
                            </div>
                            <p className='text-center text-success mt-2'>Ready to download! Thanks for your patience.</p>
                        </div>}

                    </div>
                </div>
            </div>
        </Modal>
    )
}
