import { FormHelperText } from '@mui/material';
import { useField } from 'formik';
import React from 'react';
import Select, { components, ControlProps } from 'react-select';

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        background: '#fff',
        borderColor: '#9e9e9e',
        minHeight: '40px',
        height: '40px',
        boxShadow: state.isFocused ? null : null,
        cursor: state.isDisabled ? 'not-allowed' : 'default',
    }),
    // placeholder: (defaultStyles) => {
    //     return {
    //         ...defaultStyles,
    //         color: 'green',
    //     }
    // },

    valueContainer: (provided, state) => ({
        ...provided,
        height: '40px',
        padding: '0 6px'
    }),

    input: (provided, state) => ({
        ...provided,
        margin: '0px',
    }),
    indicatorSeparator: state => ({
        display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '40px',
    }),
    menuPortal: base => ({ ...base, zIndex: 999999 }),
    singleValue: (provided) => ({
        ...provided,
        color: '#000'
    })
    // option: (styles, { isDisabled }) => {
    //     // const color = chroma(data.color);
    //     return {
    //       ...styles,
    //       backgroundColor: isDisabled ? 'red' : 'blue',
    //       color: 'green',
    //     };
    //   },
    // option: (provided, state) => ({
    //     ...provided,
    //     backgroundColor: state.isSelected ? '#192E49' : 'inherit',
    //     '&:hover': { backgroundColor: state.isSelected ? '#192E49' : 'rgb(222, 235, 255)' }
    //   }),
};
// const controlStyles = {
//     border: '1px solid black',
//     padding: '5px',
//     background: colourOptions[2].color,
//     color: 'white',
//   };

// const ControlComponent = (props) => (
//     <div style={customStyles}>
//         <p>Custom Control</p>
//         <components.Control {...props} />
//     </div>
// );
export default function SelectField({ ...props }) {
    const [meta] = useField(props);
    // console.log(field)
    return (
        <>
            <Select
                menuPortalTarget={document.body}
                // styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                styles={customStyles}
                menuPosition={'fixed'}
                isSearchable={true}
                // components={{ Control: ControlComponent }}
                // defaultValue={meta.value ? { value: meta.value } : 'Select'}
                // value={props.options.filter(function (option) {
                //     return option.value === meta.initialValue;
                // })}
                // theme={theme => ({
                //     ...theme,
                //     colors: {
                //         ...theme.colors,
                //         neutral50: 'green',  // Placeholder color
                //     },
                // })}
                {...props}

            />
            {(meta.touched && meta.error) && <FormHelperText style={{ 'fontSize': '12px', 'color': 'rgb(244, 67, 54)' }}>{meta.touched && meta.error}</FormHelperText>}
        </>
    )
}