import React, { useState } from 'react'
import { formatDateTime } from '../../utils/shortcuts'
import { truncate } from '../../utils'
import { Link } from 'react-router-dom'
// :FIXIT
export default function MobileDocketReviews({ data, user, setDocData, setDocInfoModal, setfiberTLChangeModal }) {
    const [showTab, setTab] = useState(0)
    // return <>
    //     <div className="shadow-sm p-3 mb-2 bg-white rounde mt-2">
    //         <div className="row">
    //             <div className="col-6">
    //                 <h6 className="mb-0"><button type="button" className="btn btn-secondary btn-sm text-center me-2 downBtnTable"><i className="fas fa-chevron-down"></i></button>MBDMB8518</h6>
    //             </div>
    //             <div className="col-6  text-end">
    //                 <h6 className="mb-0">Pending</h6>
    //             </div>
    //             <div className="col-12 mt-3">
    //                 <div className="row m-0">
    //                     <div className="col-3">
    //                         <p className="mb-1">Loc. A:</p>
    //                     </div>
    //                     <div className="col-9 text-end">
    //                         <p className="mb-1">DURGACHAK POP</p>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div className="col-12">
    //                 <div className="row m-0">
    //                     <div className="col-3">
    //                         <p className="mb-1">Loc. B:</p>
    //                     </div>
    //                     <div className="col-9 text-end">
    //                         <p className="mb-1">M D CABLE</p>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //         <div className="row detailsinerTableMobile">
    //             <div className="col-12 mb-2">
    //                 <div className="row">
    //                     <div className="col-5">
    //                         <h6>DOCKET ID:</h6>
    //                     </div>
    //                     <div className="col-7">
    //                         <h6>MBDMB8518</h6>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div className="col-12 mb-2">
    //                 <div className="row">
    //                     <div className="col-5">
    //                         <h6>CUSTOMER CODE:</h6>
    //                     </div>
    //                     <div className="col-7">
    //                         <h6>MBDMB8518</h6>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div className="col-12 mb-2">
    //                 <div className="row">
    //                     <div className="col-5">
    //                         <h6>LOCATION A:</h6>
    //                     </div>
    //                     <div className="col-7">
    //                         <h6>Nabana Hub</h6>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div className="col-12 mb-2">
    //                 <div className="row">
    //                     <div className="col-5">
    //                         <h6>LOCATION B:</h6>
    //                     </div>
    //                     <div className="col-7">
    //                         <h6>Maa Swarasati</h6>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div className="col-12 mb-2">
    //                 <div className="row">
    //                     <div className="col-5">
    //                         <h6>PROBLEM:</h6>
    //                     </div>
    //                     <div className="col-7">
    //                         <h6>Signal Off</h6>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div className="col-12 mb-2">
    //                 <div className="row">
    //                     <div className="col-5">
    //                         <h6>CREATED TIME:</h6>
    //                     </div>
    //                     <div className="col-7">
    //                         <h6>4-4-24 1:20pm</h6>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div className="col-12 mb-2">
    //                 <div className="row">
    //                     <div className="col-5">
    //                         <h6>ACTION AREA:</h6>
    //                     </div>
    //                     <div className="col-7">
    //                         <h6></h6>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // </>
    return (
        <div className='row mt-3'>
            <div className='col-12'>
                {data.map((row, i) => <div className="shadow-sm p-3 mb-2 bg-white rounde" key={i}>
                    <div className='row' onClick={() => setTab(i)}>
                        <div className='col-6'>
                            <h6 className='mb-0'>
                                <button type="button" className="btn btn-secondary btn-sm text-center me-2 downBtnTable"><i className={`fas fa-chevron-${showTab === i ? 'down' : 'right'}`}></i></button>
                                {row.code}
                            </h6>
                        </div>
                        <div className='col-6  text-end'>
                            <h6 className='mb-0'>
                                {row.status}
                            </h6>
                        </div>
                        <div className='col-12 mt-3'>
                            <div className='row m-0'>


                                <div className='col-3'>
                                    <p className='mb-1'>Loc. A:</p>
                                </div>
                                <div className='col-9 text-end'>
                                    <p className='mb-1'>{row?.get_circuit?.location_a}</p>
                                </div>

                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='row m-0'>
                                <div className='col-3'>
                                    <p className='mb-1'>Loc. B:</p>
                                </div>
                                <div className='col-9 text-end'>
                                    <p className='mb-1'>{row?.get_circuit?.location_b}</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='row m-0'>
                                <div className='col-3'>
                                    <p className='mb-1'>Cust. ID:</p>
                                </div>
                                <div className='col-9 text-end'>
                                    <p className='mb-1'>{row?.get_circuit?.customer_id}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {showTab === i && <div className='row detailsinerTableMobile'>
                        <div className='col-12 mb-2'>
                            <div className='row'>
                                <div className='col-3'>
                                    <h6>ID:</h6>
                                </div>
                                <div className='col-9'>
                                    <h6>{row.code}</h6>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 mb-2'>
                            <div className='row'>
                                <div className='col-3'>
                                    <h6>Problem:</h6>
                                </div>
                                <div className='col-9'>
                                    <h6>{row?.get_problem?.title}</h6>
                                </div>
                            </div>
                        </div>


                        <div className='col-12 mb-2'>
                            <div className='row'>
                                <div className='col-3'>
                                    <h6>Created at:</h6>
                                </div>
                                <div className='col-9'>
                                    <h6>{formatDateTime(row.created_at, 'DD-MM-YY h:mm a')}</h6>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 mb-2'>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className="d-flex gap-1">




                                        <button className="btn btn-dark btn-style-light pb-0" onClick={() => { setDocData(row); setDocInfoModal(true) }} title='Quick Info' ><span className="material-icons-sharp">info</span></button>

                                        {user?.permissions?.dockets?.change && <>


                                            {user?.permissions?.dockets?.can_change_tl | user?.permissions?.dockets?.can_change_tech ? <button className="btn btn-info btn-style-light pb-0" onClick={() => { setDocData(row); setfiberTLChangeModal(true); }} title='Change Fiber Team Leader ' ><span className="material-icons-sharp">manage_accounts</span></button> : ''}
                                        </>}



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>)}

                {/* <div className="shadow-sm p-3 mb-2 bg-white rounde">
                    <div className='row'>
                        <div className='col-6'>
                            <h6 className='mb-0'>
                                <button type="button" className="btn btn-secondary btn-sm text-center me-2 downBtnTable"><i className="fas fa-chevron-down"></i></button>
                                MBMMB2687
                            </h6>
                        </div>
                        <div className='col-6  text-end'>
                            <h6 className='mb-0'>
                                Pending
                            </h6>
                        </div>
                    </div>
                </div>
                <div className="shadow-sm p-3 mb-2 bg-white rounde">
                    <div className='row'>
                        <div className='col-6'>
                            <h6 className='mb-0'>
                                <button type="button" className="btn btn-secondary btn-sm text-center me-2 downBtnTable"><i className="fas fa-chevron-down"></i></button>
                                MBMMB2687
                            </h6>
                        </div>
                        <div className='col-6  text-end'>
                            <h6 className='mb-0'>
                                Pending
                            </h6>
                        </div>
                    </div>
                </div>
                <div className="shadow-sm p-3 mb-2 bg-white rounde">
                    <div className='row'>
                        <div className='col-6'>
                            <h6 className='mb-0'>
                                <button type="button" className="btn btn-secondary btn-sm text-center me-2 downBtnTable"><i className="fas fa-chevron-down"></i></button>
                                MBMMB2687
                            </h6>
                        </div>
                        <div className='col-6  text-end'>
                            <h6 className='mb-0'>
                                Pending
                            </h6>
                        </div>
                    </div>
                </div>
                <div className="shadow-sm p-3 mb-2 bg-white rounde">
                    <div className='row'>
                        <div className='col-6'>
                            <h6 className='mb-0'>
                                <button type="button" className="btn btn-secondary btn-sm text-center me-2 downBtnTable"><i className="fas fa-chevron-down"></i></button>
                                MBMMB2687
                            </h6>
                        </div>
                        <div className='col-6  text-end'>
                            <h6 className='mb-0'>
                                Pending
                            </h6>
                        </div>
                    </div>
                </div> */}

            </div>

        </div>
    )
}
