
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { toast } from 'react-toastify';
import Alert from '../../../components/Common/Alert';
import { useUser } from '../../../hooks/user';
import { useClosedDocketList, useDocketUpdateRequest } from '../../../hooks/docket';
import PageBreadcrumbs from '../../../components/Common/Breadcrumbs';
import DocketInfoModal from '../../../components/Models/DocketInfoModal';
import CloseDocketInfo from '../../../components/Docket/CloseDocketInfo';
import { formatDateTime } from '../../../utils/shortcuts';
import Loader from '../../../components/Common/Loader';
import DocketRequestCloseForm from '../../../components/Forms/DocketRequestCloseForm';

export default function DocketCloseRequestPage() {
    const { user } = useUser();
    const { id } = useParams();
    let navigate = useNavigate();
    const [apiEnable, setApiEnable] = useState(false)
    const docketFindForm = useRef(null)
    const { handleDocketRequestAction, docketRequestIsLoading } = useDocketUpdateRequest('POST');
    const { closedDocketsData, closedDocketsIsLoading, handleParam } = useClosedDocketList(null, apiEnable);
    const [modal, setInfoModal] = useState(false)
    const handleSendCloseRequest = async (values, { resetForm }) => {
        // console.log('reqq -> ', values)
        const { materials } = values;
        if (materials.filter(function (e) { !e.value && toast.error(`${e.field} material value is required.`); return e.value === ''; }).length > 0) {
            // IT WILL EXECUTE ONLY ONCE ON VALIDATIOn ERROR OF MATERIALAS +
            return;
        }

        const res = await handleDocketRequestAction({
            ...values,
            docket: closedDocketsData.data.docket.id,
            update_type: 'Close'
        })
        // console.log('res -> ', res)

        if (res.success) {
            toast.success(res.detail);
            navigate(`/docket/${closedDocketsData.data.docket.code}`);
            // setInfoModal(true)
        } else {
            toast.error(res.detail)
        }

    }

    useEffect(() => {
        setApiEnable(true)
        handleParam(`/${id}`)
    }, [id])

    const handleFindDocket = async () => {
        setApiEnable(true)
        const form = docketFindForm.current
        handleParam(`/${form['docketId'].value}`)
        navigate(`/docket/close/${form['docketId'].value}`)
    }


    const renderDocketClose = (data) => {
        if (!user?.permissions?.dockets?.can_req_close) {
            return <Alert
                title='Permission Error!'
                text={`You do not have the permission to close the docket.`}
                type='warning'
            />
        }
        if (data?.docket?.status === 'Resolved') {
            return <>
                <Alert
                    title='Docket already Closed!'
                    text={`Docket ${data.docket.code} has been closed on ${formatDateTime(data?.created_at, 'DD-MM-YYYY')}`}
                    type='primary'
                />
                <Link className='btn btn-info' to={`/docket/${data.docket.code}`}>View Docket</Link>
            </>
        } else if (data?.docket?.status === 'Hold') {
            return <>
                <Alert
                    title='Docket is on Hold!'
                    text={`Please Resume the Docket ${data?.docket?.code} to Close.`}
                    type='warning'
                />
                <Link className='btn btn-info' to={`/docket/${data.docket.code}`}>View Docket</Link>
                {/* <button className='btn btn-danger mx-2' onClick={() => handleForceCloseDocket(data?.docket?.code)} disabled={docketActionIsLoading}>Force Close the Docket</button> */}
            </>
        } else {
            return <DocketRequestCloseForm
                data={data}
                isLoading={docketRequestIsLoading}
                handleSubmit={handleSendCloseRequest}
                submitBtnLabel='Send Close Docket'
            />
        }
    }
    return (
        <>
            <PageBreadcrumbs
                title='Docket Close Request'
                history={[{ name: 'Docket', to: '/docket' }, { name: id, to: `/docket/${id}` },]}
                RightComp={() =>
                    <>
                        <form ref={docketFindForm} onSubmit={handleFindDocket}>
                            <input
                                type="text"
                                className="form-control form-control-solid m-b-lg"
                                defaultValue={id}
                                name='docketId'
                                placeholder='Enter Docket ID'
                                required
                            />
                        </form>
                    </>
                }
            />

            <Grid container>
                <Grid item xs={12} sx={{ p: 1 }}>
                    {closedDocketsIsLoading && <Loader />}
                    {!closedDocketsData?.success && !closedDocketsIsLoading && <Alert
                        title='Docket Not Found!'
                        text={`Entered a Invalid Docket Id.`}
                        type='danger'
                    />}
                </Grid>
                <div className='row customInfoDesign w-100'>
                    {user?.permissions?.dockets?.view && <Grid item xs={4} sx={{ p: 1 }}>
                        {closedDocketsData?.data && closedDocketsData?.success && !closedDocketsIsLoading && <CloseDocketInfo data={closedDocketsData?.data} />}
                    </Grid>}
                    <Grid item xs={8} sx={{ p: 2 }}>
                        {user?.permissions?.dockets?.change ? <>
                            {closedDocketsData?.data && closedDocketsData?.success && !closedDocketsIsLoading && renderDocketClose(closedDocketsData?.data)}
                        </> :
                            <Alert
                                title='Permission Error!'
                                text='You do not have permission to perform this action.'
                                type='danger'
                            />
                        }
                    </Grid>
                </div>
            </Grid>

            <div className='container'>
                <div className="row ">
                    <div className="col mt-2">
                        {closedDocketsData?.data && !closedDocketsIsLoading && <DocketInfoModal
                            modal={modal}
                            setModal={setInfoModal}
                            docData={closedDocketsData?.data?.docket}
                        />}
                    </div>
                </div>
            </div>

        </>
    )
}
