

export const CIRCUIT_TYPE_OPTIONS = [
    { value: 'Broadband', label: 'Broadband' },
    { value: 'Corporate', label: 'Corporate' },
    { value: 'Trunk Route', label: 'Trunk Route' },
    { value: 'WAN', label: 'WAN' },
    { value: 'CATV', label: 'CATV' },
    { value: 'Local Channel', label: 'Local Channel' },
    { value: 'Backbone', label: 'Backbone' },
    { value: 'Mixing Link', label: 'Mixing Link' },
    { value: 'Leased Line', label: 'Leased Line' },
    { value: 'Maintenance', label: 'Maintenance' },
    { value: 'Underground', label: 'Underground' },
]

export const LINK_TYPE_OPTIONS = [
    { 'label': 'Primary', value: 'Primary' },
    { 'label': 'Secondary', value: 'Secondary' }
]


// TODO: DELETE THIS LINE NOT USING ON / CircuitForm/CurcuitDetail.js
export const STATUS_OPTIONS = [
    { 'label': 'Active', value: 'Active' },
    { 'label': 'Deactive', value: 'Deactive' },
    { 'label': 'Terminated', value: 'Terminated' },
]
export const STATUS_FILTER_OPTIONS = [
    { 'label': 'Active', value: 'Active' },
    { 'label': 'Deactive', value: 'Deactive' },
    { 'label': 'Terminated', value: 'Terminated' },
    { 'label': 'Under Termination', value: 'Under Termination' },
    { 'label': 'Under Maintenance', value: 'Under Maintenance' },
]

export const CUSTOMER_TYPE_OPTIONS = [
    { 'label': 'LL', value: 'LL' },
    { 'label': 'CATV', value: 'CATV' },
    { 'label': 'MCCTVL', value: 'MCCTVL' },
    { 'label': 'MDC', value: 'MDC' },
    { 'label': 'MLC', value: 'MLC' },
    { 'label': 'MTR', value: 'MTR' },
    { 'label': 'MDUCKR', value: 'MDUCKR' },
    { 'label': 'WAN', value: 'WAN' },
    { 'label': 'MBAC', value: 'MBAC' },
    { 'label': 'MML', value: 'MML' },
    { 'label': 'BB', value: 'BB' },
    { 'label': 'CC', value: 'CC' },
    { 'label': 'MFTTH', value: 'MFTTH' },
    { 'label': 'MUG', value: 'MUG' },
    { 'label': 'MPPPOE', value: 'MPPPOE' },
]

export const CONNECTIVITY_TYPE_OPTIONS = [
    { 'label': 'P2P', value: 'P2P' },
    { 'label': 'ILL', value: 'ILL' },
    { 'label': 'NLD', value: 'NLD' },
    { 'label': 'MPLSL', value: 'MPLSL' },
    { 'label': 'NPLC', value: 'NPLC' },
    { 'label': 'DIA', value: 'DIA' },
    { 'label': 'ELL', value: 'ELL' },
    { 'label': 'RTU', value: 'RTU' },
    { 'label': 'DP', value: 'DP' },
]

export const OFC_COLOR_OPTIONS = [
    { 'label': 'Red', value: 'Red' },
    { 'label': 'Blue', value: 'Blue' },
    { 'label': 'Green', value: 'Green' },
    { 'label': 'Yellow', value: 'Yellow' },
    { 'label': 'Orange', value: 'Orange' },
    { 'label': 'Brown', value: 'Brown' },
    { 'label': 'Black', value: 'Black' },
    { 'label': 'White', value: 'White' },
    { 'label': 'Grey', value: 'Grey' },
    { 'label': 'Pink', value: 'Pink' },
]
