import React from 'react'
import Modal from 'react-modal'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import DocketRequestForm from './Forms/DocketRequestForm';
import { useCancelDocketAction, useDocketRequest, useNewDocket } from '../../hooks/lco/dockets';
import CommentForm from './Forms/CommentForm';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '700px',
    },
    overlay: { zIndex: 1000 }
};
export default function DocketCancelModal({ modal, setModal, currentData, setCurrentData }) {
    // console.log(currentData)
    let navigate = useNavigate();
    const { handleCancelDocket, isCancellingDocket } = useCancelDocketAction('POST')

    const handleLcoCancelDocket = async (values, { resetForm }) => {
        // console.log(values)
        const res = await handleCancelDocket({...values, docket: currentData.code})
        console.log(res)
        res.success ? toast.success(res.detail) : toast.error(res.detail.replace("detail:", ""));
        setModal(false)
        navigate(`/docket/${res?.data?.code}`)
    }

    return (
        <Modal
            isOpen={modal}
            // onAfterOpen={afterOpenModal}
            // onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Cancel Docket Modal"
        >
            <div className="container-fluid">

                <div className="d-flex justify-content-between">
                    <h5 className="modal-title mb-3 text-center text-info">Cancel Docket!</h5>
                    <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setModal(false); setCurrentData(null); }}></button>
                </div>
                <div className="modal-body" style={{ width: '100%' }}>
                    <div className="d-flex justify-content-between mb-4">
                        <div>
                            <p className="display-6 text-dark m-0">Docket ID:</p>
                        </div>
                        <div>
                            <h3 className="display-6 text-danger m-0">{currentData?.code}</h3>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <div>
                            <p className="text-dark m-0">Problem:</p>
                        </div>
                        <div>
                            <p className="text-dark m-0">{currentData?.get_problem?.title}</p>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                        <div>
                            <p className="text-dark m-0">Reason of docket:</p>
                        </div>
                        <div>
                            <p className="text-dark m-0">{currentData?.comment}</p>
                        </div>
                    </div>
                    {/* <hr /> */}
                    <CommentForm
                        setModal={setModal}
                        isLoading={isCancellingDocket}
                        handleSubmit={handleLcoCancelDocket}
                        btnLabel='Cancel Docket'
                    />
                </div>
            </div>
        </Modal>
    )
}
