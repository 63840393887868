import React from 'react'
import Modal from 'react-modal'
import { toast } from 'react-toastify';
import { formatDateTime } from '../../utils/shortcuts';
import { secondsToHMS } from '../../utils';
import { useUser } from '../../hooks/user';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '700px',
        width: '100%'
    },
    overlay: { zIndex: 1000 }
};
export default function DocketReviewPermissionModal({ modal, setModal, docData }) {
    const { user } = useUser();

    function CopyToClipboard(containerid = 'table') {
        var range = document.createRange();
        range.selectNode(document.getElementById(containerid));
        window.getSelection().removeAllRanges(range);
        window.getSelection().addRange(range);
        document.execCommand("copy");
        toast.info('Copied to clipboard');
        // alert("Text Copied to clipboard");
    }
    const renderDocketInfo = (data) => {
            // console.log(user)
            // Pending State
            return <table className='table m-0' id='table'>
                <tbody>
                    {/* <tr>
                        <td className='text-dark text-center' colSpan={2}>
                            <p className='text-center'>
                                *Dear Sir*
                            </p>
                        </td>
                    </tr> */}
                    <tr>
                        <td className='text-dark' colSpan={2}>
                            <p>*Dear Sir,*</p>
                            <p>*Approval Required For CID & Customer Code:* {data?.get_circuit?.code} & {data?.get_circuit?.customer_id}</p>
                            <p>*Location A[POP]:* {data?.get_circuit?.location_a}</p>	
                            <p>*Location B[LC]:* {data?.get_circuit?.location_b}</p>
                            <p>*Problem:* {data?.get_problem?.title}</p>
                            <p>*Remarks:* {data?.comment}</p>
                            <p>*Requested By:*	{data?.get_user?.account_name} @ {data?.get_user?.mobile ?? 'NOT AVAILABLE'}</p>
                            <p>*Request Date & Time:* {formatDateTime(docData.created_at, 'LLLL')}</p>
                            <p>*Forwarded By:* {user?.account_name}</p>
                        </td>
                    </tr>
                   
                </tbody>
            </table>
        
    }

    return (
        <Modal
            isOpen={modal}
            // onAfterOpen={afterOpenModal}
            // onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <div className="container-fluid">

                <div className="d-flex justify-content-between">
                    <h5 className="modal-title mb-3 text-center text-info">Docket Approval Format</h5>
                    <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setModal(false); }}></button>

                </div>
                <div className="modal-body" style={{ width: '100%' }}>
                    <div className="d-flex justify-content-between align-items-center mt-3">
                        <div><button className='btn btn-dark' onClick={() => setModal(false)}> <i className="fa fa-times" aria-hidden="true"></i>Close</button></div>
                        <div>
                            <button className='btn btn-dark' onClick={() => { CopyToClipboard(); setModal(false); }}> <i className="fa fa-copy" aria-hidden="true"></i>Copy clipboard</button>
                        </div>
                    </div>
                    <hr />
                    {renderDocketInfo(docData)}
                    {/* <div className="d-flex justify-content-between align-items-center mt-3">
                        <div></div>
                        <div>
                            <button className='btn btn-dark' onClick={() => setModal(false)}> <i className="fa fa-times" aria-hidden="true"></i>Close</button>
                        </div>
                    </div> */}
                </div>
            </div>
        </Modal>
    )
}
