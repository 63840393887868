import { useQuery } from "@tanstack/react-query";
import { getTokens } from "../../utils/shortcuts";
import { fetchJson } from "../../libs/api";
import { endpoints } from "../../config/endpoints";
import { API_HOST_URL } from "../../config";

const VEHICLES_QUERY_KEY = 'vehicles'


// Get Vehicles List
export function useVehiclesList() {
    const { isLoading, data } = useQuery([VEHICLES_QUERY_KEY], async () => {
        try {
            const { accessToken } = getTokens();
            const res = await fetchJson(`${API_HOST_URL}/${endpoints.core.vehicle_list}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            }, true)
            return await res.json();
        } catch (err) {
            return {
                success: false,
                detail: err
            };
        }
    }, {
        // cacheTime: Infinity,
        // staleTime: 30_000, // ms
        cacheTime: 0,
        staleTime: 1,
    })
    return { vehiclesData: data, vehiclesIsLoading: isLoading }
}

export const formatVehicleOptionsList = (data) => {
    return {
        value: data.id,
        label: data.vehicle_name
    }
}