import React from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import TextField from '../../Common/Fields/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { Link } from 'react-router-dom';
import "yup-phone";
// import Alert from '@mui/material/Alert';
// import { Stack } from '@mui/material';


export default function SendOTPForm({ isLoading, handleSubmit, btnLabel }) {
    const validate = Yup.object({
        mobile: Yup.string()
            .phone("IN")
            .required('Enter your valid mobile number.'),
    })
    return (
        <Formik
            initialValues={{
                mobile: '',
            }}
            validationSchema={validate}
            onSubmit={handleSubmit}
        >
            <Form>
                <TextField label='Mobile' className='form-control m-b-md' name='mobile' placeholder='Mobile Number' sx={{ mb: 3 }} />
               

                <LoadingButton
                    loading={isLoading}
                    variant="contained"
                    type="submit"
                    disableElevation
                >
                    {btnLabel}
                </LoadingButton>
                <Link to="/account/login" className="auth-forgot-password float-end"> Back</Link>
            </Form>
        </Formik>
    )
}
