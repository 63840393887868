import React, { useState } from 'react'
import { isEmptyArr, secondsToHMS } from '../../utils'
import { formatDateTime } from '../../utils/shortcuts'
import DocketUploadsModal from './DocketUploadsModal';

export default function DocketHoldTable({ data, showTotalCopm = false }) {
    const [modal, setModal] = useState(false);
    const [holdUploads, setHoldUploads] = useState(null);
    const renderTotalComp = (data) => {
        // var durationTotal = data.docket_hold_data.reduce((accum, item) => item.duration && accum + parseFloat(item.duration), 0)
        // console.log('data', data)
        const closed_docket = data?.get_closed_info
        return <>

            {closed_docket &&
                <tr>
                    <td colSpan={2}>Cust. Access Issue:</td>
                    <td colSpan={2} className='text-danger'>{`${String(closed_docket.ch_hh).padStart(2, 0) ?? '00'}:${String(closed_docket.ch_mm).padStart(2, 0) ?? '00'}:${String(closed_docket.ch_ss).padStart(2, 0) ?? '00'}`}</td>
                </tr>
            }
            {closed_docket && <tr>
                <td colSpan={2}>Others Issue:</td>
                <td colSpan={2} className='text-danger'>
                    {`${String(closed_docket.oh_hh).padStart(2, 0) ?? '00'}:${String(closed_docket.oh_mm).padStart(2, 0) ?? '00'}:${String(closed_docket.oh_ss).padStart(2, 0) ?? '00'}`}
                    <br />
                    <small>{data.get_closed_info.others_hold_reason}</small>
                </td>
            </tr>}
            <tr>
                <td colSpan={2}>Total Tat:</td>
                <td colSpan={2} className='text-dark'>{secondsToHMS(data?.get_total_tat)}</td>
            </tr>
            <tr>
                <td colSpan={2}>Hold Tat:</td>
                <td colSpan={2}>
                    {/* {secondsToHMS(durationTotal - HMSToSeconds(formatNumberToHMS(data.get_closed_info.customer_hold)) - HMSToSeconds(formatNumberToHMS(data.get_closed_info.others_hold)))} */}
                    {/* {secondsToHMS((durationTotal - HMSToSeconds(`${closed_docket.ch_hh | 0}:${closed_docket.ch_mm | 0}:${closed_docket.ch_ss | 0}`) - HMSToSeconds(`${closed_docket.oh_hh | 0}:${closed_docket.oh_mm | 0}:${closed_docket.oh_ss | 0}`)).toFixed(2))} */}
                    {secondsToHMS(data?.get_hold_tat)}
                    {/* {secondsToHMS((durationTotal - HMSToSeconds(`${closed_docket.ch_hh | 0}:${closed_docket.ch_mm | 0}:${closed_docket.ch_ss | 0}`) - HMSToSeconds(`${closed_docket.oh_hh | 0}:${closed_docket.oh_mm | 0}:${closed_docket.oh_ss | 0}`)).toFixed(2))} */}

                </td>
            </tr>
            <tr>
                <td colSpan={2}>Wait Tat:</td>
                <td colSpan={2}>
                    {secondsToHMS(data?.get_wait_tat)}
                </td>
            </tr>
           
            <tr>
                <td colSpan={2}>Actual Tat:</td>
                {/* {console.log(data)} */}
                <td colSpan={2} className='text-dark'>{secondsToHMS(data?.get_actual_tat)}</td>
            </tr>

        </>
    }
    return (
        <>
            <table className="table">
                <thead className="table-dark">
                    <tr>
                        <th>Hold</th>
                        <th>Start</th>
                        <th>End</th>
                        <th>Duration</th>
                    </tr>
                </thead>
                <tbody>
                    {isEmptyArr(data.docket_hold_data) && <tr>
                        <td colSpan={4} className='text-center'>No Hold</td>
                    </tr>}
                    {data.docket_hold_data.filter(val => val.not_hold === false).map((val, i) =>
                        <tr key={i}>
                            <td onClick={() => {
                                !isEmptyArr(val.docket_hold_files) && setHoldUploads(val.docket_hold_files)
                                setModal(true)
                            }}>
                                <span className={`${!isEmptyArr(val.docket_hold_files) && 'text-primary fw-bold pointer'}`}>
                                    Hold {i + 1}
                                </span>
                            </td>
                            <td title={`Hold by ${val?.get_hold_by?.name ?? '-'} `}>
                                {formatDateTime(val.hold_start, 'llll')}
                                <br />
                                <small><strong>{val?.get_hold_by?.name ?? '-'}</strong>: {val.hold_start_reason}</small>
                            </td>
                            <td title={`Resume by ${val?.get_resume_by?.name ?? '-'}`}>
                                {formatDateTime(val.hold_end, 'llll')}
                                <br />
                                <small><strong>{val?.get_resume_by?.name ?? '-'}</strong>: {val.hold_end_reason}</small>
                            </td>
                            <td>{val.hold_end ? secondsToHMS(val.duration) : '-'}</td>
                        </tr>
                    )}
                    {showTotalCopm && data.docket_hold_data && data.get_closed_info && renderTotalComp(data)}

                </tbody>
            </table>
            <DocketUploadsModal
                modal={modal}
                setModal={setModal}
                holdUploads={holdUploads}
                setHoldUploads={setHoldUploads}
            />
        </>
    )
}
