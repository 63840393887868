import React from 'react'
import { formatDateTime } from '../../../utils/shortcuts'
import { Card, CardContent, Grid } from '@mui/material'
// import UserForm from '../../Forms/UserForm/UserAddForm'
import { useUser, useUserAction, useUsersList } from '../../../hooks/user';
import { toast } from 'react-toastify';
import UserUpdateForm from '../../Forms/UserForm/UserUpdateForm';
import { Link } from 'react-router-dom';

export default function UserInformation({ data }) {
    const { user } = useUser();
    const childUsers = useUsersList(`?parent_role=${data?.id}`);
    const { handleUserAction, userActionIsLoading } = useUserAction('PATCH');
    const handleSubmit = async (values, { resetForm }) => {
        // console.log(values)
        const res = await handleUserAction({ ...values, id: data.id });
        if (res.success) {
            toast.success(res.detail);
            // resetForm({ values: '' });
            // navigate(`/admin/users/`);
        } else {
            toast.error(res.detail)
        }
        // console.log('data --> ', res);
    }
    // console.log('usersData', usersData)
    return (
        <>

            <div className="d-flex justify-content-between">
                <div>
                    <h3 className="display-6">Role: {data?.user_role === '-' ? 'Not Defined' : data?.user_role} <small style={{ fontSize: 14 }}>{data?.get_parent && `(Under ${data?.get_parent?.name})`}</small></h3>
                    <p className="lead"><em>Last updated at {formatDateTime(data?.updated_at, 'Do MMMM, YYYY')}...</em></p>
                </div>
                <div>
                    <h3 className="display-6">User ID: {data?.id}</h3>
                    <p className="lead">Status: {data?.is_active ? 'Active' : 'Deactive'}</p>
                </div>
            </div>

            <Grid container>
                {/* USER DETAILS */}
                {user?.permissions?.users?.view && <Grid item xs={4} sx={{ p: 0.5 }}>
                    <Card>
                        <CardContent>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <td colSpan={2}><em>USER DETAILS</em> &nbsp;<span className="badge text-white bg-dark">{data.user_role === '-' ? 'Not Defined' : data.user_role}</span></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* <tr>
                                        <th scope="col">Name:</th>
                                        <td className='text-dark'>
                                            <Link to={`/circuit/${data.get_circuit.id}`}>{data.get_circuit.code}</Link>
                                        </td>
                                    </tr> */}
                                    <tr>
                                        <th scope="col">Name:</th>
                                        <td>{data.full_name ?? '-'}</td>
                                    </tr>
                                    <tr>
                                        <th scope="col">Username:</th>
                                        <td>{data.username ?? '-'}</td>
                                    </tr>
                                    <tr>
                                        <th scope="col">Email:</th>
                                        <td>{data.email ?? '-'}</td>
                                    </tr>
                                    <tr>
                                        <th scope="col">Role:</th>
                                        <td>{data.user_role ?? '-'}</td>
                                    </tr>
                                    <tr>
                                        <th scope="col">Parent Role:</th>
                                        <td>

                                            <Link to={`/admin/users/${data.get_parent.id}`} title='Visit profile'>{data.get_parent.name ?? '-'} {data.get_parent && `(${data.get_parent.role})`}</Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="col">Group:</th>
                                        <td>{data.get_groups ?? '-'}</td>
                                    </tr>


                                    <tr>
                                        <th scope="col">Created at:
                                            <br />
                                            <em>
                                                <small>
                                                    last updated at:
                                                </small>
                                            </em>
                                            <br />
                                            <em>
                                                <small>
                                                    last login at:
                                                </small>
                                            </em>
                                        </th>
                                        <td>
                                            {formatDateTime(data.created_at, 'MMMM Do YYYY, h:mm:ss a')}
                                            <br />
                                            <em>
                                                <small>
                                                    {formatDateTime(data.updated_at, 'MMMM Do YYYY, h:mm:ss a')}
                                                </small>
                                            </em>
                                            <br />
                                            <em>
                                                <small>
                                                    {formatDateTime(data.last_login, 'MMMM Do YYYY, h:mm:ss a')}
                                                </small>
                                            </em>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </CardContent>
                    </Card>
                </Grid>}
                {user?.permissions?.users?.view && <Grid item xs={3} sx={{ p: 0.5 }}>
                    <Card>
                        <CardContent>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <td colSpan={2}><em>Users Handling </em></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {childUsers.usersData && !childUsers.usersIsLoading &&
                                        childUsers.usersData.data.map((val, i) => <tr key={i}>
                                            {/* <th scope="col">Name:</th> */}
                                            <td>
                                                <Link to={`/admin/users/${val.id}`} title='Visit profile'>{i + 1}. {val.account_name ?? '-'} ({val.user_role ?? '-'})</Link>
                                            </td>
                                        </tr>)}
                                </tbody>
                            </table>
                        </CardContent>
                    </Card>
                </Grid>}
                {user?.permissions?.users?.change && <Grid item xs={5} sx={{ p: 0.5 }}>
                    <Card>
                        <CardContent>

                            <div className="row ">
                                <div className="col mt-2">
                                    <div className="">
                                        <h1 className='display-6'>USER UPDATE</h1>
                                    </div>

                                    <UserUpdateForm
                                        data={data}
                                        isLoading={userActionIsLoading}
                                        handleSubmit={handleSubmit}
                                    />
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>}







            </Grid>
        </>
    )
}
