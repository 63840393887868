import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import TextField from '../Common/Fields/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Typography } from '@mui/material';
import DatePicker from '../Common/Fields/DatePickerField';
import SelectField from '../Common/Fields/SelectField';
import { STATUS_FILTER_OPTIONS } from './Options/CircuitForm';
import { CIRCUIT_TYPE_OPTIONS } from './Options/CircuitForm';
import { formatSelectOptionsList, useHirerList } from '../../hooks/circuit';
import Loader2 from '../Common/Loader2';
import "yup-phone";
import { CURRENT_PROVIDER_LOCAL_DATA } from '../../config';
import { getLocal } from '../../utils/localStorage';
import { formatUserOptionsList, useUsersList } from '../../hooks/user';
// import { useProviderList } from '../../hooks/core/provider';


export default function CircuitFilterForm({ isLoading, handleSubmit, setModal, resetFrom, searchParams }) {
    const { hirerData, HirerIsLoading } = useHirerList();
    // const { providersData, providerIsLoading } = useProviderList()
    const ti_users = useUsersList('?user_role=TI');
    const hi_users = useUsersList('?user_role=HI');
    const ftl_users = useUsersList('?user_role=FTL');
    const currentProvider = JSON.parse(getLocal(CURRENT_PROVIDER_LOCAL_DATA))
    const [params, setParams] = useState({});
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        const currentParams = Object.fromEntries([...searchParams]);
        setParams(currentParams)
        setLoading(false)
    }, [searchParams])

    const validate = Yup.object({
        status: Yup.string(),
        hirer_name: Yup.string().nullable(),
        code: Yup.string(),
        lc_mobile_no: Yup.string(),
        third_party_circuit_id: Yup.string(),
        q: Yup.string(),

    }).shape({
        date_after: Yup.string().when('date_before', {
            is: (val) => val !== null,
            then: Yup.string().required('required').nullable(),
            otherwise: Yup.string().nullable()
        }),
        date_before: Yup.string().when('date_after', {
            is: (val) => val !== null,
            then: Yup.string().required('required').nullable(),
            otherwise: Yup.string().nullable()
        }),
    }, ['date_after', 'date_before']);

    if (loading) {
        return <Loader2 />
    }
    return (
        <Formik
            initialValues={{
                "date_after": params?.date_after ?? null,
                "date_before": params?.date_before ?? null,
                "status": params?.status ?? '',
                "circuit_type": params?.circuit_type ?? null,
                "hirer_name": params?.hirer_name ?? '',
                "code": params?.code ?? '',
                "lc_mobile_no": params?.lc_mobile_no ?? '',
                "third_party_circuit_id": params?.third_party_circuit_id ?? '',
                "provider": params?.provider ?? currentProvider?.id,
                "q": params?.q ?? '',
                "customers_id": params?.customers_id ?? '',
                "location_b": params?.location_b ?? '',
                "territory_incharge": params?.territory_incharge ? parseInt(params?.territory_incharge) : '',
                "hub_incharge": params?.hub_incharge ? parseInt(params?.hub_incharge) : '',
                "fiber_vendor_team_leader": params?.fiber_vendor_team_leader ? parseInt(params?.fiber_vendor_team_leader) : '',
            }}
            validationSchema={validate}
            onSubmit={handleSubmit}
        >
            {formik => (

                <Form>
                    {/* {console.log('formdata -> ', formik)} */}
                    <div className='row'>
                        <div className="col-md-6 mb-4">
                            <TextField label='Search' type="text" name='q' placeholder='Search' />
                        </div>
                        <div className="col-md-6 mb-4">
                            <TextField label='Customer ID' type="text" name='customers_id' placeholder='Customer ID' />
                        </div>

                    </div>
                    <div className='row'>
                        <div className="col-md-4 mb-4">
                            <TextField label='Circuit ID' type="text" name='code' placeholder='Circuit ID' />
                        </div>
                        <div className="col-md-4 mb-4">
                            <TextField label='LC Mobile No' type="text" name='lc_mobile_no' placeholder='LC Mobile No' />
                        </div>
                        <div className="col-md-4 mb-4">
                            <TextField label='3rd Party Circuit ID' type="text" name='third_party_circuit_id' placeholder='3rd Party Circuit ID' />
                        </div>
                    </div>
                    <div className='row'>

                        <div className="col-md-6 mb-4">
                            <TextField label='Location B' type="text" name='location_b' placeholder='Location B' />
                        </div>

                    </div>
                    <div className='row'>
                        <div className="col-md-4 mb-4">
                            <Typography variant='caption'>Territory Incharge </Typography>
                            <SelectField
                                name='territory_incharge'
                                options={ti_users?.usersData?.data?.map(formatUserOptionsList)}
                                value={ti_users?.usersData?.data?.map(formatUserOptionsList).filter(function (option) {
                                    return option.value === formik.values.territory_incharge;
                                })}
                                onChange={(e) => formik.setFieldValue('territory_incharge', e.value)}
                                isLoading={ti_users?.usersIsLoading}
                            />
                        </div>
                        <div className="col-md-4 mb-4">
                            <Typography variant='caption'>Hub Incharge </Typography>
                            <SelectField
                                name='hub_incharge'
                                options={hi_users?.usersData?.data?.map(formatUserOptionsList)}
                                value={hi_users?.usersData?.data?.map(formatUserOptionsList).filter(function (option) {
                                    return option.value === formik.values.hub_incharge;
                                })}
                                onChange={(e) => formik.setFieldValue('hub_incharge', e.value)}
                                isLoading={hi_users?.usersIsLoading}
                            />
                        </div>
                        <div className="col-md-4 mb-4">
                            <Typography variant='caption'>Fiber TL</Typography>
                            <SelectField
                                name='fiber_vendor_team_leader'
                                options={ftl_users?.usersData?.data?.map(formatUserOptionsList)}
                                value={ftl_users?.usersData?.data?.map(formatUserOptionsList).filter(function (option) {
                                    return option.value === formik.values.fiber_vendor_team_leader;
                                })}
                                onChange={(e) => formik.setFieldValue('fiber_vendor_team_leader', e.value)}
                                isLoading={ftl_users?.usersIsLoading}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-md-6 mb-4">
                            <DatePicker label='From Date' minDate='2023-04-01' name='date_after' />
                        </div>
                        <div className="col-md-6 mb-4">
                            <DatePicker label='To Date' minDate='2023-04-01' name='date_before' />
                        </div>
                    </div>

                    <div className='row'>
                        <div className="col-md-4 mb-4">
                            <Typography variant='caption'>Status</Typography>
                            <SelectField
                                name='status'
                                options={STATUS_FILTER_OPTIONS}
                                value={STATUS_FILTER_OPTIONS.filter(function (option) {
                                    return option.value === formik.values.status;
                                })}
                                onChange={(e) => formik.setFieldValue('status', e.value)}
                            />
                        </div>
                        <div className="col-md-4 mb-4">
                            <Typography variant='caption'>Circuit Type </Typography>
                            <SelectField
                                name='circuit_type'
                                options={CIRCUIT_TYPE_OPTIONS}
                                value={CIRCUIT_TYPE_OPTIONS.filter(function (option) {
                                    return option.value === formik.values.circuit_type;
                                })}
                                onChange={(e) => formik.setFieldValue('circuit_type', e.value)}
                            />
                        </div>
                        <div className="col-md-4 mb-4">
                            <Typography variant='caption'>Hirer </Typography>

                            {/* {hirerData && !HirerIsLoading ? <SelectField
                                name='hirer_name'
                                options={hirerData.map(formatSelectOptionsList)}
                                isLoading={HirerIsLoading}
                                value={hirerData.map(formatSelectOptionsList).filter(function (option) {
                                    return option.value.toString() === formik.values.hirer_name.toString();
                                })}
                                onChange={(e) => formik.setFieldValue('hirer_name', e.value)}

                            /> : <Loader2 color="inherit" />} */}

                            <SelectField
                                name='hirer_name'
                                options={hirerData?.data?.map(formatSelectOptionsList)}
                                value={hirerData?.data?.map(formatSelectOptionsList).filter(function (option) {
                                    return option.value.toString() === formik.values.hirer_name.toString();
                                })}
                                onChange={(e) => formik.setFieldValue('hirer_name', e.value)}
                                isLoading={HirerIsLoading}
                            />
                        </div>
                        {/* <div className="col-md-4 mb-4">
                            <Typography variant='caption'>Circuits For </Typography>

                            <SelectField
                                name='provider'
                                options={providersData?.data?.map(formatSelectOptionsList)}
                                value={providersData?.data?.map(formatSelectOptionsList).filter(function (option) {
                                    return option.value.toString() === formik.values.provider.toString();
                                })}
                                onChange={(e) => formik.setFieldValue('provider', e.value)}
                                isLoading={providerIsLoading}
                            />
                        </div> */}
                    </div>


                    <LoadingButton
                        loading={isLoading}
                        type="submit"
                        variant="contained"
                        disableElevation
                    >
                        Search
                    </LoadingButton>
                    <Button variant="outlined" sx={{ ml: 1 }} disableElevation onClick={() => resetFrom(formik)}>Clear Filter</Button>
                    <Button variant="outlined" sx={{ ml: 1 }} disableElevation onClick={() => setModal(false)}>Close</Button>
                </Form>
            )}
        </Formik>
    )
}
