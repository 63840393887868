import { Typography } from '@mui/material';
import React from 'react'
import { formatSelectOptionsList } from '../../../hooks/circuit';
import SelectField from '../../Common/Fields/SelectField';
import { CIRCUIT_TYPE_OPTIONS, LINK_TYPE_OPTIONS } from '../Options/CircuitForm';
import { useProviderList } from '../../../hooks/core/provider';
import TextField from '../../Common/Fields/TextField';

export default function CircuitDetail({ formik, disabled }) {
    const { providersData, providerIsLoading } = useProviderList()
    // console.log(formik)
    return (
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className='col-12'>
                        <h3 className='text-center display-6 mb-4 text-primary' style={{ 'fontSize': '22px' }}>CIRCUIT DETAILS</h3>

                    </div>
                    <div className="col-md-3 mb-4">
                        <Typography>Docket For <span className='req'>*</span></Typography>

                        <SelectField
                            name='provider'
                            options={providersData?.data.map(formatSelectOptionsList)}
                            value={providersData?.data.map(formatSelectOptionsList).filter(function (option) {
                                return option.value === formik.values.provider;
                            })}
                            onChange={(e) => formik.setFieldValue('provider', e.value)}
                            isLoading={providerIsLoading}
                            isDisabled={disabled}
                        />
                    </div>

                    <div className="col-md-3 mb-4">
                        <Typography>Circuit Type <span className='req'>*</span></Typography>
                        <SelectField
                            name='circuit_type'
                            options={CIRCUIT_TYPE_OPTIONS}
                            value={CIRCUIT_TYPE_OPTIONS.filter(function (option) {
                                return option.value === formik.values.circuit_type;
                            })}
                            onChange={(e) => formik.setFieldValue('circuit_type', e.value)}
                            isDisabled={disabled}
                        />

                    </div>
                    <div className="col-md-3 mb-4">
                        <Typography>Link Type <span className='req'>*</span></Typography>
                        <SelectField
                            name='link_type'
                            options={LINK_TYPE_OPTIONS}
                            isSearchable={false}
                            value={LINK_TYPE_OPTIONS.filter(function (option) {
                                return option.value === formik.values.link_type;
                            })}
                            onChange={(e) => formik.setFieldValue('link_type', e.value)}
                            isDisabled={disabled}
                        />
                    </div>
                    <div className="col-md-3 mb-4">
                    <Typography>Order ID </Typography>
                    <TextField label='' type="text" disabled={disabled} name='order_id' placeholder='Order ID (Optional) ' sx={{ mb: 3 }} variant="standard" /></div>
                </div>
            </div>
        </div>
    )
}
