import React from 'react';

export default function List() {
  return (
    <div className="container mt-4">
      <div className="card">
        <div className="card-header">
          User List
        </div>
        <div className="card-body">
          <p className="card-text">This component displays a list of users styled with Bootstrap 5.</p>
          <button className="btn btn-primary" disabled>Currently Disabled</button>
        </div>
      </div>
    </div>
  );
}