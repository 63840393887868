import { formatDateTime } from "./shortcuts"

export const export_circuit_columns = (circuitsData) => {
    // console.log('circuit -> ', circuitsData)
    return circuitsData?.data?.map(item => ({
        // Circuit Details
        "Circuit ID": item.code,
        "Circuit Type": item.circuit_type,
        "Link Type": item.link_type,
        "Customer ID 1": item.customer_id,
        "Customer ID 2": item.customer_id_alt,
        "Hirer Name": item?.get_hirer?.name,
        "3rd Party Circuit ID": item.third_party_circuit_id,

        // Location A
        "Location A": item.location_a,
        "Mobile A": item.mobile_a,
        "City A": item.city_a,
        "Post A": item.post_a,
        "Address A": item.address_a,
        "Pin A": item.pin_a,
        "Area A": item.area_a,
        "District A": item.district_a,
        "Latitude A": item.latitude_a,
        "Longitude A": item.longitude_a,
        "Port Details A": item.port_details_a,

        // Location B
        "Location B": item.location_b,
        "Mobile B": item.mobile_b,
        "City B": item.city_b,
        "Post B": item.post_b,
        "Address B": item.address_b,
        "Pin B": item.pin_b,
        "Area B": item.area_b,
        "District B": item.district_b,
        "Latitude B": item.latitude_b,
        "Longitude B": item.longitude_b,
        "Port Details B": item.port_details_b,

        // Contact Details
        "LC Contact Person": item.lc_contact_persion,
        "LC Mobile": item.lc_mobile_no,
        "Territory Incharge": item?.get_ti?.name,
        "Hub Incharge": item?.get_hi?.name,
        "Technician": item.get_technician?.name,
        "AC Manager": item.get_ac_manager?.name,
        "Service Assurance": item.service_assurance,
        "Fiber Vendor TL": item.get_fiber_tl?.name,
        "Fiber TL": item.get_fe_rf_team?.name,
        "Feasibility Done by": item.get_feasibility_done_by?.name,

        // Other Details
        "Bandwidth mb_gig": item.bandwidth_mb_gig,
        "Customer Type": item.customer_type,
        "Type of Connectivity": item.type_of_connectivity,
        "Email": item.email,
        "IP": item.ip_details,
        "OSM ID": item.osm_id,
        "Indus ID": item.indus_id,
        "Link Delivery Date": item.link_delivery_date,
        "Office Name": item.office_name,

        // Fiber Details
        "OFC Cable Drum No": item.ofc_cable_drum_no,
        "OFC Color": item.ofc_color,
        "OFC Distance": item.ofc_distance,
        "Pole Joint No": item.pole_joint_no,
        "Address": item.address,
        "Latitude": item.latitude,
        "Longitude": item.longitude,
        
        // "Total Dockets": item.total_dockets,
        "Status": item.status,
        "Created at": formatDateTime(item.created_at, 'DD-MM-YYYY HH:mm:ss'),
        "Last updated at": formatDateTime(item.updated_at, 'DD-MM-YYYY HH:mm:ss'),
    }))
}