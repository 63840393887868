import moment from 'moment';
import React from 'react'
import Modal from 'react-modal'
import { useNavigate } from 'react-router-dom';
import { useSendDocketEmail, useSendDocketSMS } from '../../hooks/docket/send';
import { toast } from 'react-toastify';
import { handleWhatsappShare } from '../../utils/whatsappShare';
import { useUser } from '../../hooks/user';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '700px',
    },
    overlay: { zIndex: 1000 }
};
export default function DocketCreationModal({ modal, setModal, cirData, docData }) {
    const { user } = useUser();
    let navigate = useNavigate();
    const { handleSendDocketSMS, isSendingDocketSMS } = useSendDocketSMS()
    const { handleSendDocketEmail, isSendingDocketEmail } = useSendDocketEmail()

    // Handle Send Docket SMS
    const handleDocketSMS = async () => {
        const res = await handleSendDocketSMS(docData.code)
        res.success ? toast.success(res.detail) : toast.error(res.detail);
    }
    const handleDocketEmail = async () => {
        const res = await handleSendDocketEmail(docData.code)
        res.success ? toast.success(res.detail) : toast.error(res.detail);
    }
    // console.log(docData)
    return (
        <Modal
            isOpen={modal}
            // onAfterOpen={afterOpenModal}
            // onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <div className="container-fluid">

                <div className="d-flex justify-content-between">
                    <h5 className="modal-title mb-3 text-center text-info">Docket has been created!</h5>
                    <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setModal(false); navigate(`/docket`); }}></button>
                </div>
                <div className="modal-body" style={{ width: '100%' }}>
                    <div className="d-flex justify-content-between">
                        <div>
                            <p className="display-6 text-dark m-0">Docket ID:</p>
                        </div>
                        <div>
                            <h3 className="display-6 text-danger m-0">{docData.code}</h3>
                        </div>
                    </div>
                    <hr />
                    {user?.user_role === 'EXP' && <div className='mt-3'>
                        <button className='btn btn-primary' onClick={handleDocketSMS} disabled={isSendingDocketSMS}>Send SMS</button>
                        <button className='btn btn-primary mx-2' onClick={handleDocketEmail} disabled={isSendingDocketEmail}>Send Email</button>
                        <button className='btn btn-primary mx-2' onClick={() => handleWhatsappShare(cirData, docData)}>Share on WhatsApp</button>
                    </div>}
                    <hr />
                    <table className='table m-0'>
                        <tbody>
                            <tr>
                                <td className='fw-bold text-dark text-center' colSpan={2}>
                                    <p className='text-center'>New Docket Raised @ {docData?.get_hirer?.name}</p>
                                </td>
                            </tr>
                            <tr>
                                <th>Problem:</th>
                                <td className='text-dark'>{docData?.get_problem?.title ?? 'NA'}</td>
                            </tr>
                            <tr>
                                <th>3rd Party Docket No:</th>
                                <td>{docData.third_party_docket_no || 'NA'}</td>
                            </tr>
                            <tr>
                                <th>Circuit ID:</th>
                                <td className='text-dark'><strong>{cirData.code}</strong></td>
                            </tr>
                            <tr>
                                <th>Customer ID:</th>
                                <td className='text-dark'><strong>{cirData.customer_id}</strong></td>
                            </tr>
                            <tr>
                                <th>LC Details:</th>
                                <td>{cirData.lc_contact_persion ?? '-'} @ {cirData.lc_mobile_no ?? 'NOT AVAILABLE'}</td>
                            </tr>

                            <tr>
                                <th>Location B:</th>
                                <td>{cirData.location_b}</td>
                            </tr>
                            <tr>
                                <th>Location A:</th>
                                <td>{cirData.location_a}</td>
                            </tr>
                            <tr>
                                <th>Docketed By:</th>
                                <td>{docData.docketed_by} @ {docData.docketed_by_contact ?? 'NOT AVAILABLE'}</td>
                            </tr>
                            {docData.get_fiber_tl && <tr>
                                <th>Firber TL:</th>
                                <td>{docData.get_fiber_tl.name} @ {docData.get_fiber_tl.mobile ?? 'NOT AVAILABLE'}</td>
                            </tr>}
                            {docData.get_technician && <tr>
                                <th>Technician:</th>
                                <td>{docData.get_technician.name} @ {docData.get_technician.mobile ?? 'NOT AVAILABLE'}</td>
                            </tr>}
                            <tr>
                                <th>Docketed Time:</th>
                                <td>{moment(docData.created_at).format('LLLL')}</td>
                            </tr>
                            <tr>
                                <th>Comments:</th>
                                <td>{docData?.comment || 'NA'}
                                </td>
                            </tr>
                        </tbody>
                    </table>


                </div>
            </div>
        </Modal>
    )
}
