import React from 'react'
import { Formik, Form, Field, FieldArray } from 'formik';
import * as Yup from 'yup'
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Button, Typography } from '@mui/material';
import SelectField from '../../Common/Fields/SelectField';
import TextField from '../../Common/Fields/TextField';
import { useUser } from '../../../hooks/user';
import { useProblemsList } from '../../../hooks/docket';
import { formatSelectOptionsList } from '../../../hooks/circuit';
import { PRIORITY_OPTIONS } from '../Options/CorporateDocket';
import TextAreaField from '../../Common/Fields/TextAreaField';
import DatePicker from '../../Common/Fields/DatePickerField';
import TimePicker from '../../Common/Fields/TimePicker';
import { useNavigate } from 'react-router-dom';
import Datetime from 'react-datetime';



export default function CorpManualDocketForm({ data, isLoading, handleSubmit, setModal, disabled, btnLabel = 'Submit' }) {
    const { user } = useUser();
    let navigate = useNavigate();
    const { problemsData, problemsIsLoading } = useProblemsList();
    // const teams = useUsersListLite('?all_teams=true');  
    // const field_teams = useUsersListLite('?all_field_teams=true');  // Technician *

    const validate = Yup.object({
        case_title: Yup.string().required('Case title is required.'),
        customer_name: Yup.string().required('Case title is required.'),
        priority: Yup.string().required('Priority is required.'),
        problem: Yup.number().required('Problem is required.').nullable(),

    })
    return (
        <Formik
            initialValues={{
                case_title: data.case_title ?? '',
                customer_name: data.customer_name ?? '',
                case_reason: data.case_reason ?? '',
                priority: data.priority ?? 'Medium',
                case_origin: data.case_origin ?? '',
                partner: data.partner ?? '',
                contact_person: data.contact_person ?? '',
                zone: data.zone ?? '',
                next_followup_date: data.next_followup_date ?? null,
                next_followup_time: data.next_followup_time ?? null,
                add_item: data.add_item ?? '',
                problem: data?.problem ?? null,
                is_manual_docket: true,
                comment: 'New corporate docket!',
                // send_mail: false,
            }}
            validationSchema={validate}
            onSubmit={handleSubmit}
        >
            {formik => (

                <Form>
                    {/* {console.log(formik)} */}
                    <div className='row'>
                        <div className='col-12'>
                            <h3 className='text-center display-6 mb-4 text-primary mt-3' style={{ 'fontSize': '22px' }}>COMPLAINT DETAILS</h3>
                        </div>
                        <div className="col-md-6 col-12 mt-2 mb-2">
                            <TextField label='Case Title *' type="text" name='case_title' placeholder='Case Title' sx={{ mb: 3 }} />
                        </div>
                        <div className="col-md-6 col-12 mt-2 mb-2">
                            <TextField label='Customer name *' type="text" name='customer_name' placeholder='Customer name' sx={{ mb: 3 }} />
                        </div>

                        <div className="col-md-4 col-12 mt-2 mb-2">
                            <TextField label='Case reason' type="text" name='case_reason' placeholder='Case reason' sx={{ mb: 3 }} />
                        </div>
                        <div className="col-md-4 col-12 mt-2 mb-2">
                            <TextField label='Case Origin' type="text" name='case_origin' placeholder='Case Origin' sx={{ mb: 3 }} />
                        </div>
                        <div className="col-md-4 col-12 mt-2 mb-2">
                            <TextField label='Partner' type="text" name='partner' placeholder='Partner' sx={{ mb: 3 }} />
                        </div>
                        <div className="col-md-6 col-12 mt-2 mb-2">
                            <TextField label='Contact Person' type="text" name='contact_person' placeholder='Contact Person' sx={{ mb: 3 }} />
                        </div>
                        <div className="col-md-6 col-12 mt-2 mb-2">
                            <TextField label='Zone' type="text" name='zone' placeholder='Zone' sx={{ mb: 3 }} />
                        </div>
                        <div className="col-md-6 col-6 mt-2 mb-2">
                            {/* <DatePicker label='Next Follow Up Date' type="text" name='next_followup_date' placeholder='Next Follow Up Date' sx={{ mb: 3 }} /> */}
                            <Datetime
                                value={formik.values.next_followup_date} // Pass the selected date as value
                                onChange={(e) => formik.setFieldValue('next_followup_date', e)}
                                name='next_followup_date'
                                inputProps={{ // Set input properties
                                    placeholder: 'Next Follow Up Date', // Placeholder text
                                    dateFormat: 'DD-MM-YYYY', // Date format
                                }}
                                timeFormat={false} 
                            />
                        </div>
                        <div className="col-md-6 col-6 mt-2 mb-2">
                            {/* <TimePicker label='Next Follow Up time' onChange={(e) => formik.setFieldValue('next_followup_time', e)} type="text" name='next_followup_time' placeholder='Next Follow Up time' sx={{ mb: 3 }} /> */}
                            <Datetime
                                value={formik.values.next_followup_time} // Pass the selected date as value
                                onChange={(e) => formik.setFieldValue('next_followup_time', e)}
                                name='next_followup_time'
                                inputProps={{ // Set input properties
                                    placeholder: 'Next Follow Up time', // Placeholder text
                                    
                                }}
                                timeFormat="h:mm A"
                                dateFormat={false}
                            />

                        </div>
                        <div className="col-md-12 col-12">
                            <TextAreaField label='Add Item' type="text" name='add_item' placeholder='Add Item' sx={{ mb: 3 }} />
                        </div>
                        <div className="col-md-6 col-12">
                            <Typography>Problem <span className='req'>*</span></Typography>
                            <SelectField
                                name='problem'
                                options={problemsData?.data?.map(formatSelectOptionsList)}
                                value={problemsData?.data?.map(formatSelectOptionsList).filter(function (option) {
                                    return option.value === formik.values.problem;
                                })}
                                onChange={(e) => formik.setFieldValue('problem', e.value)}
                                isDisabled={disabled}
                                isLoading={problemsIsLoading}
                            />
                        </div>
                        <div className="col-md-6 col-12">
                            <Typography variant='caption'>Priority </Typography>
                            <SelectField
                                name='priority'
                                options={PRIORITY_OPTIONS}
                                value={PRIORITY_OPTIONS.filter(function (option) {
                                    return option.value === formik.values.priority;
                                })}
                                onChange={(e) => formik.setFieldValue('priority', e.value)}
                            />
                        </div>


                        {formik.submitCount >= 1 && formik.errors && Object.entries(formik.errors).map(([key, value]) =>
                            <Alert severity="error" sx={{ mt: 1, mb: 1 }} key={key}>{value}</Alert>
                        )}
                    </div>

                    <div className='mt-5 text-center'>
                        <LoadingButton
                            loading={isLoading}
                            variant="contained"
                            type="submit"
                            disableElevation
                        >
                            {btnLabel}
                        </LoadingButton>
                        <Button variant="outlined" sx={{ ml: 1 }} disableElevation onClick={() => navigate(`/docket/corporate`)}>Close</Button>
                    </div>


                </Form>
            )}
        </Formik>
    )
}
