import React, { useEffect, useState } from 'react';
import DataTableBase from '../../../components/Common/DataTableBase';
import { formatDateTime } from '../../../utils/shortcuts';
import { Link, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useUser } from '../../../hooks/user';
import PageBreadcrumbs from '../../../components/Common/Breadcrumbs';
import Alert from '../../../components/Common/Alert';
import { toast } from 'react-toastify';
import { useDocketNotifications, useDocketUpdatesList } from '../../../hooks/docket';
import { useDebouncedCallback } from 'use-debounce';

export default function DocketRequestList() {
    const { user } = useUser();
    const [enableApi, setAPI] = useState(false)
    const [searchParams] = useSearchParams();

    // Handling Docket Updates
    const { docketUpdatesData, docketUpdatesIsLoading, filterUpdatesList } = useDocketUpdatesList('', enableApi)
    const { handleMarkAllAsRead, markingIsLoading } = useDocketNotifications()

    const columns = [
        {
            name: '# ID',
            selector: row => row.id,
            sortable: true,
            width: '50px !important',
        },
        {
            name: 'Docket',
            sortable: true,
            width: "150px",
            cell: (row) => (<>
                <Link to={`/docket/${row?.get_docket?.code}`}>{row?.get_docket?.code}</Link>
            </>)
        },
        {
            name: 'Update',
            selector: row => row.reason ? row.reason : row.action,
        },
        {
            name: 'By',
            width: "200px",
            sortable: true,
            selector: row => row.user,
        },
        {
            name: 'Provider',
            width: "200px",
            sortable: true,
            selector: row => row?.get_docket?.provider,
        },
        {
            name: 'Hirer',
            width: "200px",
            sortable: true,
            selector: row => row?.get_docket?.hirer,
        },
        {
            name: 'Last Updated',
            width: "150px",
            selector: row => formatDateTime(row.updated_at, 'D-M-YY h:ma'),
        },
    ];
    const conditionalRowStyles = [
        // {
        //     when: row => row.status === 'Accept',
        //     style: {
        //         backgroundColor: '#70d9106b',
        //         color: '#000000de',
        //         '&:hover': {
        //             cursor: 'pointer',
        //         },
        //     },
        // },


    ];

    const handleMarkAllAsReadBtn = async () => {
        const res = await handleMarkAllAsRead()
        toast.info(res?.detail);
    }

    

    // Fetch Data
    const fetchData = useDebouncedCallback(() => {
        const currentParams = Object.fromEntries([...searchParams]);
        let params = ''
        const limit = parseInt(currentParams?.limit ?? '10');
        const status = 'Pending';        
        const type = 'all';        
        params = new URLSearchParams({ ...currentParams, limit, status, type }).toString();
        setAPI(true)
        params && filterUpdatesList(`?${params}`)
    }, 300)

    useEffect(() => {
        fetchData()

    }, [searchParams])
    return (
        <>
            <Helmet>
                <title>Docket Notifications - List</title>
            </Helmet>
            <PageBreadcrumbs
                title='All Docket Notifications'
                history={[{ name: 'Docket', to: '/docket' }]}
                RightComp={() =>
                    <div className='d-flex gap-1'>
                        {user?.user_role === 'EXP' && <button className="btn btn-dark btn-style-light" onClick={() => handleMarkAllAsReadBtn()} disabled={markingIsLoading}><i className="material-icons">visibility</i> Mark all as Seen</button>}
                    </div>
                }
            />
            <div className='col-md-12'>
                {user?.permissions?.dockets?.change ? <DataTableBase
                    columns={columns}
                    data={docketUpdatesData?.data}
                    direction="auto"
                    fixedHeaderScrollHeight="300px"
                    pagination
                    responsive
                    subHeaderAlign="right"
                    subHeaderWrap
                    progressPending={docketUpdatesIsLoading}
                    defaultPageSize={10}
                    conditionalRowStyles={conditionalRowStyles}
                /> :
                    <Alert
                        title='Error!'
                        text='Permission Denied!'
                        type='danger'
                    />
                }
            </div>

            {/* <DocketRequestsFilterModal
                modal={requestsFilterModal}
                setModal={setRequestsFilterModal}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
            /> */}

            {/* {currentDocketReqData && <DocketRequestModal
                modal={docketReqModal}
                setModal={setDocketReqModal}
                data={currentDocketReqData}
                setCurrentDocketReqData={setCurrentDocketReqData}

            />} */}
        </>
    )
}
