import React from 'react'

import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import TextField from '../Common/Fields/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button } from '@mui/material';
import RadioField from '../Common/Fields/RadioField';
import { Link } from 'react-router-dom';
// import Alert from '@mui/material/Alert';
// import { Stack } from '@mui/material';


export default function CircuitFinderForm({ isLoading, handleSubmit, setModal, data, only }) {
    const validate = Yup.object({
        type: Yup.string().required('Type is required.'),
        q: Yup.string().required('Field is required.'),

    })
    return (
        <Formik
            initialValues={{
                "q": '',
                "type": data?.type ? data?.type : 'customer_id',
            }}
            validationSchema={validate}
            onSubmit={handleSubmit}
        >
            {formik => (

                <Form>
                    <div className='row'>
                        {/* <div className='col-12'>
                            <h3 className='text-center display-6 mb-4 text-primary' style={{ 'fontSize': '22px' }}>CIRCUIT DETAILS</h3>

                        </div> */}
                        <div className="col-md-12">
                            {!only && <RadioField
                                label='Type'
                                options={[
                                    { label: 'Customer ID', value: 'customer_id' },
                                    { label: 'Circuit ID', value: 'code' },
                                    { label: 'Mobile No', value: 'lc_mobile_no' },
                                ]}
                                name='type'
                            />}
                            <TextField label={`${formik.values.type.replace("code", "Circuit ID").replace("customer_id", "Customer ID").replace("lc_mobile_no", "Mobile No")} *`} className='form-control mt-3' name='q' placeholder={`Enter ${formik.values.type.replace("code", "Circuit ID").replace("customer_id", "Customer ID").replace("lc_mobile_no", "Mobile No")}`} />
                        </div>
                    </div>

                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <LoadingButton
                                loading={isLoading}
                                type="submit"
                                variant="contained"
                                disableElevation
                            >
                                Docket It
                            </LoadingButton>
                            <Button variant="outlined" disableElevation onClick={() => setModal(false)}>Close</Button>
                        </div>
                        {/* <div>
                            <Link to={`/docket/maintenance/add`} onClick={() => setModal(false)} className='btn btn-dark'>New Maintenance Docket</Link>
                        </div> */}
                    </div>

                </Form>
            )}
        </Formik>
    )
}
