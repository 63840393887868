import React from 'react'
import Modal from 'react-modal'
import { useNavigate } from 'react-router-dom';
import DocketSearchForm from '../Forms/DocketSearchForm';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '700px',
    },
    overlay: { zIndex: 1000 }
};
export default function DocketCloseModal({ modal, setModal }) {
    let navigate = useNavigate();

    const handleSubmit = async (values, { resetForm }) => {
        const { docketId } = values;      
        let query = docketId.replace(/\s/g, '').toUpperCase();  
        navigate(`/docket/close/${query}`)
        setModal(false)
    }
    return (
        <Modal
            isOpen={modal}
            // onAfterOpen={afterOpenModal}
            // onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <div className="container-fluid">

                <div className="d-flex justify-content-between">
                    <h5 className="modal-title mb-3 text-center text-info">Docket Close</h5>
                    <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={() => setModal(false)}></button>
                </div>
                <div className="modal-body" style={{ width: '100%' }}>
                     <DocketSearchForm
                        isLoading={false}
                        handleSubmit={handleSubmit}
                        setModal={setModal}
                    /> 
                </div>
            </div>
        </Modal>
    )
}
