import React from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import TextField from '../Common/Fields/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
// import { Link } from 'react-router-dom';
import { Alert, Button, Typography } from '@mui/material';
import SelectField from '../Common/Fields/SelectField';
import { DOCKET_LM_STATUS } from './Options/DocketForm';
import { Link } from 'react-router-dom';
import CheckboxField from '../Common/Fields/CheckboxField';
import { formatVehicleOptionsList, useVehiclesList } from '../../hooks/core/vehicle';
import { useUser } from '../../hooks/user';
// import Alert from '@mui/material/Alert';
// import { Stack } from '@mui/material';


export default function DocketResumeRequestForm({ data, isLoading, handleSubmit, setModal }) {
    const { user } = useUser();
    const validate = Yup.object({
        // status: Yup.string().required('Status is required.'),
        remarks: Yup.string().required('Remarks is required.'),
    })



    return (
        <Formik
            initialValues={{
                // "status": 'Resume',
                "remarks": '',


                // Send Mail
                // "send_mail": false,
                // Files
                // "file": null,
                // Activity
                // 'is_activity': false
            }}
            validationSchema={validate}
            onSubmit={handleSubmit}
        >
            {formik => (

                <Form>
                    {/* {console.log(formik)} */}
                    <div className='row' id='modalFOrm'>
                        <div className="col-md-12 mb-4">
                            <TextField label='Remarks *' size="medium" type="text" name='remarks' placeholder='Remarks' sx={{ mt: 2 }} />
                        </div>
                        {formik.submitCount >= 1 && formik.errors && Object.entries(formik.errors).map(([key, value]) =>
                            <Alert severity="error" sx={{ mt: 1, mb: 1 }} key={key}>{value}</Alert>
                        )}
                    </div>

                    <div className='d-flex justify-content-between align-items-center'>
                        <div>
                            <LoadingButton
                                loading={isLoading}
                                variant="contained"
                                type="submit"
                                disableElevation
                            >
                                Request for Resume
                            </LoadingButton>
                            <Button variant="outlined" disableElevation onClick={() => setModal(false)}>Close</Button>
                        </div>

                    </div>
                </Form>
            )}
        </Formik>
    )
}
