import React from 'react'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useField } from 'formik';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';


export default function CheckboxField({ options, label, checked, ...props }) {
    const [field, meta] = useField(props);
    // console.log('props -> ',props);
    // console.log('meta -> ',meta);
    return (
        <FormControl error={meta.touched && Boolean(meta.error)} variant="standard">
            <FormGroup row>
                {options.map((value, index) => (
                    <FormControlLabel
                        key={index}
                        control={<Checkbox checked={!!meta.value} />}
                        label={label}
                        {...field}
                        {...props}
                        value={props.default === 'boolean' ? field.value : value}
                    />
                ))}
            </FormGroup>
            <FormHelperText>{meta.touched && meta.error}</FormHelperText>
        </FormControl>
    )
}