import { Container } from '@mui/material';
import React, { useContext } from 'react'
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import PageBreadcrumbs from '../../../components/Common/Breadcrumbs';
import CorpManualDocketForm from '../../../components/Forms/CorpDocketForm/CorpManualDocketForm';
import { useCorporateDocket } from '../../../hooks/docket/corporate';
import providerContext from '../../../context/provider/providerContext';
import moment from 'moment';
import { formatDateTime } from '../../../utils/shortcuts';

export default function CorporateDocketAddPage() {
    const { currentProvider } = useContext(providerContext);
    let navigate = useNavigate();
    // const { handleCircuitAction, circuitActionIsLoading } = useCircuit('POST');
    // const handleSubmit = async (values, { resetForm }) => {
    //     // console.log(values)
    //     const res = await handleCircuitAction(values);
    //     if (res.success) {
    //         toast.success(res.detail);
    //         resetForm({ values: '' });
    //         navigate(`/circuit`);
    //     } else {
    //         toast.error(res.detail)
    //     }
    //     // console.log('data --> ', res);
    // }
    const { handleNewCorporateDocket, isCreatingCorporateDocket } = useCorporateDocket('POST')
    const handleSubmit = async (values, { resetForm }) => {

        // console.log(values.next_followup_time)
        // console.log(moment(values?.next_followup_time).format('hh:mm:ss') )
        // console.log(values.next_followup_date)
        // console.log(formatDateTime(values?.next_followup_date, 'YYYY-MM-DD'))
        // console.log(moment(values?.next_followup_date).format('YYYY-MM-DD'))
        // console.log(moment(values?.next_followup_date).format('YYYY-MM-DD'))
        // console.log(formatDateTime(values?.next_followup_date, 'YYYY-MM-DD'))
        // console.log(formatDateTime(values?.next_followup_time, 'hh:mm:ss'))
        // console.log(moment(values?.next_followup_time).format('hh:mm:ss') )

        const res = await handleNewCorporateDocket({
            ...values, 
            provider: currentProvider.id,
            next_followup_date: values.next_followup_date ? moment(values?.next_followup_date).format('YYYY-MM-DD') : null,
            next_followup_time: values.next_followup_time ? moment(values?.next_followup_time).format('hh:mm:ss') : null,
            // next_followup_time: moment(values?.date_of_join).format('YYYY-MM-DD') ?? null,
            action: 'add',
        })
        // // console.log(res)

        if (res.success) {
            toast.success(res.detail);
            resetForm({ values: '' });
            navigate(`/docket/corporate`);
        } else {
            toast.error(res.detail)
        }
}
    return (
        <>
            <Helmet>
                <title>Corporate - Add Docket</title>
            </Helmet>
            <Container maxWidth="xxl" disableGutters>
                <PageBreadcrumbs
                    title="Create Corporate Docket"
                    alt='Corporate Docket Creation'
                    history={[{ name: 'Corporate Dockets', to: '/docket/corporate' }]}
                />
            </Container>
            <div className='container'>

                <div className="row ">
                    <div className="col mt-2">
                        {/* <div className="">
                            <h1 className='display-6'>CIRCUIT CREATION</h1>
                        </div> */}
                        <CorpManualDocketForm
                            data={{}}
                            isLoading={false}
                            handleSubmit={handleSubmit}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
