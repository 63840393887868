import { Container } from '@mui/material';
import React from 'react'
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import PageBreadcrumbs from '../../components/Common/Breadcrumbs';
import CircuitForm from '../../components/Forms/CircuitForm'
import { useCircuit } from '../../hooks/circuit';

export default function CircuitAddPage() {
    let navigate = useNavigate();
    const { handleCircuitAction, circuitActionIsLoading } = useCircuit('POST');
    const handleSubmit = async (values, { resetForm }) => {
        // console.log(values)
        const res = await handleCircuitAction(values);
        if (res.success) {
            toast.success(res.detail);
            resetForm({ values: '' });
            navigate(`/circuit`);
        } else {
            toast.error(res.detail)
        }
        // console.log('data --> ', res);
    }
    return (
        <>
            <Helmet>
                <title>Circuits - Add Circuit</title>
            </Helmet>
            <Container maxWidth="xxl" disableGutters>
                <PageBreadcrumbs
                    title="Add Circuit"
                    alt='Circuit Creation'
                    history={[{ name: 'Circuits', to: '/circuit' }]}
                />
            </Container>
            <div className='container'>

                <div className="row ">
                    <div className="col mt-2">
                        {/* <div className="">
                            <h1 className='display-6'>CIRCUIT CREATION</h1>
                        </div> */}
                        <CircuitForm
                            data={{}}
                            isLoading={circuitActionIsLoading}
                            handleSubmit={handleSubmit}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
