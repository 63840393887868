import React, { useState } from 'react'
import { useUser } from '../../hooks/user';
import { Helmet } from 'react-helmet';
import { Container } from '@mui/material';
import PageBreadcrumbs from '../../components/Common/Breadcrumbs';
import AccountUpdateForm from '../../components/Forms/UserForm/AccountUpdateForm';
import { useGetUser, useUpdateAction } from '../../hooks/user/normal';
import { toast } from 'react-toastify';
import { formatDateTime } from '../../utils/shortcuts';
import moment from 'moment';

export default function AccountUpdatePage() {
    const [showForm, setForm] = useState(false)
    const { user, isLoading } = useUser();
    const { userData, userIsLoading } = useGetUser(`/${user?.id}`, !!user, true);
    const { handleUserAction, useActionIsLoading } = useUpdateAction('PATCH');
    // console.log(user)
    // console.log(userData)

    const handleSubmit = async (values, { resetForm }) => {
        const {date_of_join} = values
        // console.log(values?.date_of_join)
        // console.log(formatDateTime(date_of_join, 'YYYY-MM-DD'))
        let date = moment(date_of_join).format('YYYY-MM-DD');
        // console.log(date)
        const res = await handleUserAction({
            ...values,
            date_of_birth: moment(values?.date_of_birth).format('YYYY-MM-DD') ?? null,
            date_of_join: moment(values?.date_of_join).format('YYYY-MM-DD') ?? null,
            id: user.id
        });
        if (res.success) {
            toast.success(res.detail);
            // resetForm({ values: '' });
            // navigate(`/admin/users/`);
        } else {
            toast.error(res.detail)
        }
        // console.log('data --> ', res);
    }
    return (
        <>
            <Helmet>
                <title>My account </title>
            </Helmet>
            <Container maxWidth="xxl" disableGutters>
                <PageBreadcrumbs
                    title={user?.account_name}
                    alt={'Account update'}
                    history={[{ name: 'My account', to: '/account/me' }]}
                    RightComp={() =>
                        <>
                            <button className="btn btn-secondary btn-style-light" onClick={() => setForm(!showForm)}>
                                <i className="material-icons">{showForm ? 'close' : 'edit'}</i> {showForm ? 'Close' : 'Update'}</button>
                        </>
                    }

                />
            </Container>
            <div className='container'>
                <div className='row'>
                    <div className={`${showForm ? 'col-md-5' : 'col-md-7'}`}>
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className='col-12'>
                                        <h3 className='text-center display-6 mb-4 text-primary' style={{ 'fontSize': '22px' }}>Account Details</h3>
                                    </div>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <td colSpan={2}><em>USER DETAILS:</em> &nbsp;</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="col">Name:</th>
                                                <td>{userData?.data?.full_name ?? '-'}</td>
                                            </tr>
                                            <tr>
                                                <th scope="col">Gender:</th>
                                                <td>{userData?.data?.gender}</td>
                                            </tr>

                                            <tr>
                                                <th scope="col">Username:</th>
                                                <td>{userData?.data?.username ?? '-'}</td>
                                            </tr>
                                            <tr>
                                                <th scope="col">Email:</th>
                                                <td>{userData?.data?.email ?? '-'}</td>
                                            </tr>
                                            <tr>
                                                <th scope="col">Mobile:</th>
                                                <td>{userData?.data?.mobile === '' ? 'Not Updated' : userData?.data?.mobile}</td>
                                            </tr>
                                            <tr>
                                                <th scope="col">Role:</th>
                                                <td>{userData?.data?.user_role ?? '-'}</td>
                                            </tr>
                                            {userData?.data?.date_of_birth && <tr>
                                                <th scope="col">DOB:</th>
                                                <td>{formatDateTime(userData?.data?.date_of_birth, 'DD-MM-YYYY')}</td>
                                            </tr>}
                                            <tr>
                                                <th scope="col">DOJ:</th>
                                                <td>{formatDateTime(userData?.data?.date_of_join, 'DD-MM-YYYY')}</td>
                                            </tr>
                                            {userData?.data?.get_parent && <tr>
                                                <th scope="col">Head:</th>
                                                <td>{userData?.data?.get_parent.name}</td>
                                            </tr>}
                                            <tr>
                                                <th scope="col">Group:</th>
                                                <td>{userData?.data?.get_groups ?? '-'}</td>
                                            </tr>
                                            <tr>
                                                <th scope="col">Created at:
                                                    <br />
                                                    <em>
                                                        <small>
                                                            last updated at:
                                                        </small>
                                                    </em>
                                                    <br />
                                                    <em>
                                                        <small>
                                                            last login at:
                                                        </small>
                                                    </em>
                                                </th>
                                                <td>
                                                    {formatDateTime(userData?.data?.created_at, 'MMMM Do YYYY, h:mm:ss a')} {userData?.data?.get_created_by && `(${userData?.data?.get_created_by?.name})`}
                                                    <br />
                                                    <em>
                                                        <small>
                                                            {formatDateTime(userData?.data?.updated_at, 'MMMM Do YYYY, h:mm:ss a')} {userData?.data?.get_updated_by && `(${userData?.data?.get_updated_by?.name})`}
                                                        </small>
                                                    </em>
                                                    <br />
                                                    <em>
                                                        <small>
                                                            {formatDateTime(userData?.data?.last_login, 'MMMM Do YYYY, h:mm:ss a')}
                                                        </small>
                                                    </em>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    {showForm && <div className='col-md-7'>
                        {userData && !userIsLoading && <AccountUpdateForm
                            data={userData?.data}
                            isLoading={useActionIsLoading}
                            handleSubmit={handleSubmit}
                        />}
                    </div>}
                </div>
            </div>
        </>
    )
}
