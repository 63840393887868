import React from 'react'
import Modal from 'react-modal'
import { toast } from 'react-toastify';
import CorpDocketTeamForm from '../Forms/CorpDocketForm/CorpDocketTeamForm';
import { useCorporateDocket } from '../../hooks/docket/corporate';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '700px',
    },
    overlay: { zIndex: 1000 }
};
export default function CorpDocketChangeModal({ modal, setModal, docData, setDocData}) {
    // let navigate = useNavigate();
    const { handleNewCorporateDocket, isCreatingCorporateDocket } = useCorporateDocket('PATCH')
    const handleSubmit = async (values, { resetForm }) => {

        const res = await handleNewCorporateDocket({
            ...values, 
            action: 'update',
            id: docData.code
        })
        // console.log(res)
        if(res.success){
            toast.success(res.detail)
            setDocData(res?.data)
            setModal(false)
        } else {
            toast.error(res.detail.replace("detail:", ""))
        }
    }
    return (
        <Modal
            isOpen={modal}
            // onAfterOpen={afterOpenModal}
            // onRequestClose={closeModal}
            style={customStyles}
            contentLabel="TL Change Modal"
        >
            <div className="container-fluid">

                <div className="d-flex justify-content-between">
                    <h5 className="modal-title mb-3 text-info">Change Corporate Docket ({`${docData?.code}`})</h5>
                    <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setModal(false); }}></button>
                </div>
                <div className="modal-body" style={{ width: '100%' }}>

                    <CorpDocketTeamForm
                        isLoading={isCreatingCorporateDocket}
                        handleSubmit={handleSubmit}
                        data={docData}
                        setModal={setModal}
                    /> 
                </div>
            </div>
        </Modal>
    )
}
