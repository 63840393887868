import React from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import TextField from '../../Common/Fields/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { Link } from 'react-router-dom';
import { useClient } from '../../../hooks/core/utils';
// import Alert from '@mui/material/Alert';
// import { Stack } from '@mui/material';


export default function LoginForm({ isLoading, handleSubmit }) {
    const { getClient } = useClient();
    const validate = Yup.object({
        username: Yup.string()
            // .min(4, 'Username must be at least 4 characters.')
            .required('Enter your username.'),
        password: Yup.string()
            // .min(6, 'Password must be at least 6 characters.')
            .required('Enter your password.'),
    })
    return (
        <Formik
            initialValues={{
                username: '',
                password: '',
                ip: getClient?.ip ?? '0.0.0.0'
            }}
            validationSchema={validate}
            onSubmit={handleSubmit}
        >
            <Form>
                <TextField label='Username' className='form-control m-b-md' name='username' placeholder='Email / Username' sx={{ mb: 3 }} />
                <TextField label='Password' type="password" name='password' placeholder='Your Password' sx={{ mb: 3 }} />

                <div className='mt-2'>
                    <LoadingButton
                        loading={isLoading}
                        variant="contained"
                        type="submit"
                        disableElevation
                    >
                        Login
                    </LoadingButton>
                    <Link to="/account/forgot" className="auth-forgot-password float-end">Forgot password?</Link>
                </div>
            </Form>
        </Formik>
    )
}
