import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Button, Typography } from '@mui/material';
import SelectField from '../../Common/Fields/SelectField';
import TextField from '../../Common/Fields/TextField';
import { formatUserOptionsList, useUser, useUsersList, useUsersListLite } from '../../../hooks/user';




export default function CorpDocketTeamForm({ data, isLoading, handleSubmit, setModal }) {
    const { user } = useUser();

    const teams = useUsersListLite('?all_teams=true');  
    const field_teams = useUsersListLite('?all_field_teams=true');  // Technician *

    const validate = Yup.object({
        team: Yup.number().required('Team is required.').nullable(),
        // alt_team: Yup.number().required('Alt. Team is required.').nullable(),
        // field_team: Yup.number().required('Field Team is required.').nullable(),
       
    })
    return (
        <Formik
            initialValues={{
                team: data.team ?? null,
                alt_team: data?.alt_team ?? null,
                field_team: data?.field_team ?? null,
                comment: 'Team Modified!',
                // send_mail: false,
            }}
            validationSchema={validate}
            onSubmit={handleSubmit}
        >
            {formik => (

                <Form>
                    {/* {console.log(formik)} */}
                    <div className='row' id='modalFOrm'>
                        <div className="col-md-6 col-12 mb-2">
                            <Typography>Team <span className='req'>*</span></Typography>
                            <SelectField
                                name='team'
                                options={teams?.usersData?.data?.map(formatUserOptionsList)}
                                value={teams?.usersData?.data?.map(formatUserOptionsList).filter(function (option) {
                                    return option.value === formik.values.team;
                                })}
                                onChange={(e) => formik.setFieldValue('team', e.value)}
                                isLoading={teams?.usersIsLoading}
                                isDisabled={!user?.permissions?.account?.is_admin}
                                // isClearable
                                
                            />
                        </div>
                        <div className="col-md-6 col-12 mb-2">
                            <Typography>Alt. Team {formik.values.alt_team && <small className='text-primary' role='button' onClick={() => formik.setFieldValue('alt_team', null)}>Clear</small>}</Typography>
                            <SelectField
                                name='alt_team'
                                options={teams?.usersData?.data?.map(formatUserOptionsList)}
                                value={teams?.usersData?.data?.map(formatUserOptionsList).filter(function (option) {
                                    return option.value === formik.values.alt_team;
                                })}
                                onChange={(e) => formik.setFieldValue('alt_team', e.value)}
                                isLoading={teams?.usersIsLoading}
                                isDisabled={!user?.permissions?.dockets?.can_change_corp_docket}
                                
                            />
                        </div>
                        <div className="col-md-12 col-12 mb-2">
                            <Typography>Field Team {formik.values.field_team && <small className='text-primary' role='button' onClick={() => formik.setFieldValue('field_team', null)}>Clear</small>}</Typography>
                            <SelectField
                                name='field_team'
                                options={field_teams?.usersData?.data?.map(formatUserOptionsList)}
                                value={field_teams?.usersData?.data?.map(formatUserOptionsList).filter(function (option) {
                                    return option.value === formik.values.field_team;
                                })}
                                onChange={(e) => formik.setFieldValue('field_team', e.value)}
                                isLoading={field_teams?.usersIsLoading}
                                isDisabled={!user?.permissions?.dockets?.can_change_corp_docket}
                                
                            />
                        </div>
                        <div className="col-md-12 col-12 mb-2">
                            <TextField label='Remarks' type="text" name='comment' placeholder='Remarks' sx={{ mb: 3 }} />
                        </div>
                        {formik.submitCount >= 1 && formik.errors && Object.entries(formik.errors).map(([key, value]) =>
                            <Alert severity="error" sx={{ mt: 1, mb: 1 }} key={key}>{value}</Alert>
                        )}
                    </div>

                    <LoadingButton
                        loading={isLoading}
                        variant="contained"
                        type="submit"
                        disableElevation
                    >
                        Update
                    </LoadingButton>
                    <Button variant="outlined" sx={{ ml: 1 }} disableElevation onClick={() => setModal(false)}>Close</Button>


                </Form>
            )}
        </Formik>
    )
}
