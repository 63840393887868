import { useMutation, useQuery } from "@tanstack/react-query";
import { getTokens } from "../../utils/shortcuts";
import { fetchJson } from "../../libs/api";
import { endpoints } from "../../config/endpoints";
import { API_HOST_URL } from "../../config";
import { useState } from "react";

const MATERIALS_QUERY_KEY = 'materials'


// Get Materials List
// export function useMaterialsList() {
//     const { isLoading, data } = useQuery([MATERIALS_QUERY_KEY], async () => {
//         try {
//             const { accessToken } = getTokens();
//             const res = await fetchJson(`${API_HOST_URL}/${endpoints.core.material_list}`, {
//                 headers: { 'Authorization': `Bearer ${accessToken}` },
//             }, true)
//             return await res.json();
//         } catch (err) {
//             return {
//                 success: false,
//                 detail: err
//             };
//         }
//     }, {
//         // cacheTime: Infinity,
//         // staleTime: 30_000, // ms
//         cacheTime: 0,
//         staleTime: 1,
//     })
//     return { materialsData: data, materialsIsLoading: isLoading }
// }


export function useMaterialsList(defaultParam = '', enabled = true) {
    const [param, setParam] = useState(null);
    const { isLoading, data } = useQuery([MATERIALS_QUERY_KEY, { param: param, defaultParam }], async (key) => {
        const param = key.queryKey[1].param;
        const defaultParam = key.queryKey[1].defaultParam;
        try {
            const { accessToken } = getTokens();
            const param_url = param ? param : defaultParam;
            const res = await fetchJson(`${API_HOST_URL}/${endpoints.core.material_list}${param_url}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            }, true)
            return await res.json()

        } catch (err) {
            return { success: false, detail: err };
        }
    }, {
        cacheTime: Infinity,
        staleTime: 30_000,
        enabled: enabled,
    })
    return {
        materialsData: data,
        materialsIsLoading: isLoading,
        filterMaterialList: async (value) => setParam(value),
    }
}


export function useFilterMaterials() {
    const { accessToken } = getTokens();
    const mutation = useMutation((param) => fetchJson(`${API_HOST_URL}/${endpoints.core.material_list}${param}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    }, true))

    return {
        filterMaterials: async (param) => {
            try {
                const res = await mutation.mutateAsync(param);
                return await res.json();
            } catch (err) {
                return {
                    success: false,
                    error: 'something wents wrong.'
                }
            }
        },
        isFilterIsLoading: mutation.isLoading,
    }
}

export const formatMaterialOptionsList = (data) => {
    return {
        value: data.id,
        label: data.name,
        name: data.name,
        unit: data.unit,
    }
}