import { LoadingButton } from '@mui/lab'
import { Form, Formik } from 'formik'
import React from 'react'
import { formatVehicleOptionsList, useVehiclesList } from '../../../../hooks/core/vehicle';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { serializeQueryStrings } from '../../../../utils';
import moment from 'moment';
import * as Yup from 'yup'
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';


const animatedComponents = makeAnimated();

export default function VehicleSelectionForm({ searchParams, setSearchParams, handleSubmit }) {
    let navigate = useNavigate();
    const { vehiclesData, vehiclesIsLoading } = useVehiclesList();
    const currentParams = Object.fromEntries([...searchParams]);

    

    const validationSchema = Yup.object().shape({
        vehicle_ids: Yup.array().min(1, 'At least one vehicle must be selected').required('Vehicle selection is required').nullable(),
        date_from: Yup.date().required('Start date is required'),
        date_to: Yup.date().required('End date is required')
    });


    return (
        <Formik
            initialValues={{
                vehicle_ids: currentParams?.vehicle_ids ?? [], // Initial selected vehicle IDs
                date_from: currentParams?.date_from ?? null, // Initial start date
                date_to: currentParams?.date_to ?? null // Initial end date,
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
        >
            {formik => (
                <Form>
                    <div className='row'>
                        <div className="col-md-12 mb-4">
                            <div className="d-flex justify-content-end gap-2 align-items-center mb-0">
                                <p
                                    type="button"
                                    className="btn btn-primary btn-sm"
                                    onClick={() => formik.setFieldValue('vehicle_ids', vehiclesData?.data?.map(vehicle => vehicle.id))}
                                >
                                    Select All
                                </p>
                                {formik?.values?.vehicle_ids?.length > 0 && <p
                                    type="button"
                                    className="btn btn-danger btn-sm"
                                    onClick={() => formik.setFieldValue('vehicle_ids', [])}
                                >
                                    Remove All
                                </p>}
                            </div>
                            <Select
                                name='vehicle_ids'
                                options={vehiclesData?.data?.map(formatVehicleOptionsList)}
                                value={vehiclesData?.data?.map(formatVehicleOptionsList).filter(option => formik.values.vehicle_ids.includes(option.value))}
                                onChange={(selectedOptions) => formik.setFieldValue('vehicle_ids', selectedOptions.map(option => option.value))}
                                isLoading={vehiclesIsLoading}
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                isMulti // Allows multiple selections
                            />
                            {formik.errors.vehicle_ids && <div className="text-danger">{formik.errors.vehicle_ids}</div>}
                        </div>
                    </div>

                    <div className='row'>
                        <div className="col-md-6 mb-4">
                            <label htmlFor="date_from">From Date</label>
                            <Datetime
                                value={formik.values.date_from}
                                onChange={date => formik.setFieldValue('date_from', date)}
                                dateFormat="YYYY-MM-DD"
                                timeFormat={false}
                                inputProps={{ placeholder: 'Select start date' }}
                            />
                            {formik.errors.date_from && <div className="text-danger">{formik.errors.date_from}</div>}
                        </div>
                        <div className="col-md-6 mb-4">
                            <label htmlFor="date_to">To Date</label>
                            <Datetime
                                value={formik.values.date_to}
                                onChange={date => formik.setFieldValue('date_to', date)}
                                dateFormat="YYYY-MM-DD"
                                timeFormat={false}
                                inputProps={{ placeholder: 'Select end date' }}
                            />
                            {formik.errors.date_to && <div className="text-danger">{formik.errors.date_to}</div>}
                        </div>
                    </div>



                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <LoadingButton
                                loading={false}
                                type="submit"
                                variant="contained"
                                disableElevation
                            >
                                Get Report
                            </LoadingButton>

                            <Button variant="outlined" sx={{ ml: 1 }} disableElevation
                                onClick={() => {
                                    formik.resetForm({
                                        values: {
                                            vehicle_ids: [],
                                            date_from: null,
                                            date_to: null
                                        }
                                    });
                                    navigate(`/reports/vehicle/km-report`);
                                }}
                            >Reset</Button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}