// import moment from 'moment';
import React, { useEffect } from 'react'
import Modal from 'react-modal'
import { Link, useNavigate } from 'react-router-dom';
import { useSendDocketEmail, useSendDocketSMS } from '../../hooks/docket/send';
import { toast } from 'react-toastify';
import { handleWhatsappShare } from '../../utils/whatsappShare';
import { formatDateTime } from '../../utils/shortcuts';
import { secondsToHMS } from '../../utils';
import { useUser } from '../../hooks/user';
import { useState } from 'react';
import EmailPreviewModal from './EmailPreviewModal';
import { useNewDocketAction } from '../../hooks/lco/dockets';
import DocketReviewPermissionModal from './DocketReviewPermissionModal';
import { useSimpleDocketUpdate } from '../../hooks/docket';
import { InputField } from '../Common/Fields/BSField';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '700px',
        width: '100%'
    },
    overlay: { zIndex: 1000 }
};
export default function DocketReviewModal({ modal, setModal, docData, redirect }) {
    const { user } = useUser();
    // console.log('user ->', user)
    let navigate = useNavigate();
    const { handleAssignDocketAction, isCreatingAssignDocket } = useNewDocketAction('POST')
    const { handleDocketUpdate, isDocketUpdating } = useSimpleDocketUpdate(); // Using for File Uploading (Axios)
    // const { handleSendDocketEmail, isSendingDocketEmail } = useSendDocketEmail()
    const [showReasonField, setShowReasonField] = useState(false)
    const [reasonField, setReasonField] = useState('')
    // console.log('docData', docData)



    const handleAssignDocket = async (data) => {
        // console.log('first', data)
        const res = await handleAssignDocketAction({ docket_id: docData.code })
        res.success ? toast.success(res.detail) : toast.error(res.detail);
        setModal(false)
        // console.log(res)
    }
    const handleCancelDocket = async (data) => {
        // console.log('first', data)
        // const res = await handleAssignDocketAction({ docket_id: docData.code })
        const formData = new FormData();
        formData.append("status", 'Cancelled');
        formData.append("remarks", reasonField);
        const res = await handleDocketUpdate(formData, docData.code)
        res.success ? toast.warn(res.detail) : toast.error(res.detail);
        setModal(false)
        // console.log(res)
    }
    const handleDocketEmail = async () => {
        // const res = await handleSendDocketEmail(docData.code)
        // res.success ? toast.success(res.detail) : toast.error(res.detail);
    }

    useEffect(() => {
        setShowReasonField(false)
        setReasonField('')
    }, [modal])
    return (
        <Modal
            isOpen={modal}
            // onAfterOpen={afterOpenModal}
            // onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <div className="container-fluid">

                <div className="d-flex justify-content-between">
                    <h5 className="modal-title mb-3 text-center text-info">Docket Information</h5>
                    <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setModal(false); redirect && navigate('/docket'); }}></button>

                </div>

                <div className="modal-body" style={{ width: '100%' }}>
                    <div className="d-flex justify-content-between">
                        <div>
                            <p className="display-6 text-dark m-0">Docket ID:</p>
                        </div>
                        <div>
                            <h3 className="display-6 text-danger m-0">{docData.code}</h3>
                        </div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between align-items-center mt-3">
                        <div>
                            {/* {user?.user_role !== 'EXP' && <div className='d-flex gap-2'>
                                <Link to={docData.is_maintenance ? `/docket/maintenance/update/${docData.code}` : `/docket/update/${docData.code}`} className='btn btn-dark btn-style-light pb-0' title='Edit Docket'>
                                    <span className="material-icons-sharp">
                                        mode_edit
                                    </span>
                                </Link>
                            </div>} */}
                            {user?.permissions?.dockets?.can_accept_docket && !showReasonField && <div className='d-flex gap-2'>

                                <button className='btn btn-primary btn-style-light pb-0' title='Accept Docket' disabled={isCreatingAssignDocket} onClick={() => handleAssignDocket(docData)}>
                                    <span className="material-icons-sharp">
                                        done
                                    </span>
                                </button>
                                {user?.user_role === 'EXP' && <>
                                    <button className='btn btn-danger btn-style-light pb-0' title='Cancel Docket' disabled={isDocketUpdating} onClick={() => setShowReasonField(true)}>
                                        <span className="material-icons-sharp">
                                            close
                                        </span>
                                    </button>
                                    <Link to={docData.is_maintenance ? `/docket/maintenance/update/${docData.code}?status=Pending` : `/docket/update/${docData.code}?status=Pending`} className='btn btn-dark btn-style-light pb-0' title='Edit Docket'>
                                        <span className="material-icons-sharp">
                                            mode_edit
                                        </span>
                                    </Link>


                                    {/* <button className='btn btn-info' onClick={() => setEmailPreviewModal(true)}> <i className="fa fa-eye" aria-hidden="true"></i></button>
                                    <DocketReviewPermissionModal
                                        modal={emailPreviewModal}
                                        setModal={setEmailPreviewModal}
                                        docData={docData}
                                    /> */}
                                </>}
                                {/* <button className='btn btn-danger btn-style-light pb-0' title='Ignore Docket'>
                                    <span className="material-icons-sharp">
                                        close
                                    </span>
                                </button> */}
                            </div>}

                        </div>
                        <div>
                        </div>
                    </div>
                    {showReasonField && <div>
                        <input type="text" className={`form-control shadow-none`} placeholder='Enter the reason' value={reasonField} onChange={(e) => setReasonField(e.target.value)} />
                        <div className='d-flex gap-2'>
                            <button className='btn btn-warning btn-style-light pb-0 mt-2' title='Cancel Docket' disabled={isDocketUpdating || !reasonField} onClick={() => handleCancelDocket(docData)}>
                                <span className="material-icons-sharp">
                                    done
                                </span>
                            </button>
                            <button className='btn btn-danger btn-style-light pb-0 mt-2' title='Cancel' disabled={isDocketUpdating} onClick={() => setShowReasonField(false)}>
                                <span className="material-icons-sharp">
                                    close
                                </span>
                            </button>
                        </div>
                    </div>}
                    <hr />
                    <table className='table m-0'>
                        <tbody>
                            <tr>
                                <td className='fw-bold text-dark text-center' colSpan={2}>
                                    <p className='text-center'>New Docket Waiting @ {docData?.get_hirer?.name}</p>
                                </td>
                            </tr>
                            {/* <tr>
                                <th>Status:</th>
                                <td className='fw-bold text-dark'>{docData.status} {docData.alt_status && docData.status === 'Pending' ? ` - ${docData.alt_status}` : ''}</td>
                            </tr> */}
                            <tr>
                                <th>Problem:</th>
                                <td className='text-dark'>{docData?.get_problem?.title ?? 'NA'}</td>
                            </tr>
                            {!docData.get_circuit.is_maintenance && <>
                                <tr>
                                    <th>Circuit ID:</th>
                                    <td className='text-dark'><strong>{docData.get_circuit.is_maintenance ? 'N/A' : <Link to={`/circuit/${docData.get_circuit.id}?ref=docketinfo&id=${docData.code}`} target="_blank">{docData.get_circuit.code}</Link>}</strong></td>
                                </tr>
                                <tr>
                                    <th>Customer ID:</th>
                                    <td>{docData.get_circuit.customer_id}</td>
                                </tr>
                            </>}
                            <tr>
                                <th>LC Details:</th>
                                <td>{docData.get_circuit.lc_contact_persion || '-'} @ {docData.get_circuit.lc_mobile_no || 'NOT AVAILABLE'}</td>
                            </tr>
                            <tr>
                                <th>OFC Distance:</th>
                                <td>{docData.get_circuit.ofc_distance || '-'} </td>
                            </tr>
                            <tr>
                                <th>Location A:</th>
                                <td>{docData.get_circuit.location_a}</td>
                            </tr>
                            <tr>
                                <th>Location B:</th>
                                <td>{docData.get_circuit.location_b}</td>
                            </tr>

                            <tr>
                                <th>Docketed By:</th>
                                <td>{docData.docketed_by} @ {docData.docketed_by_contact ?? 'NOT AVAILABLE'}</td>
                            </tr>
                            <tr>
                                <th>3rd Party Docket No:</th>
                                <td>{docData.third_party_docket_no || 'N/A'}</td>
                            </tr>
                            <tr>
                                <th>Docket Opening Time:</th>
                                {/* <td>{moment(docData.created_at).format('LLLL')}</td> */}
                                <td>{formatDateTime(docData.created_at, 'LLLL')}</td>
                            </tr>
                            {docData.get_fiber_tl && <tr>
                                <th>Fiber TL:</th>
                                <td>{docData.get_fiber_tl.name} @ {docData.get_fiber_tl.mobile ?? 'NOT AVAILABLE'}</td>
                            </tr>}
                            {docData.get_technician && <tr>
                                <th>Technician:</th>
                                <td>{docData.get_technician.name} @ {docData.get_technician.mobile ?? 'NOT AVAILABLE'}</td>
                            </tr>}


                            {/* <tr>
                                <th>Cust. Access Issue:</th>
                                <td>{docData?.get_closed_info?.ch_hh || '0'}:{docData?.get_closed_info?.ch_mm || '0'}:{docData?.get_closed_info?.ch_ss || '0'}</td>
                            </tr> */}
                            {/* <tr>
                                <th>Other`s Issue:</th>
                                <td>{docData?.get_closed_info?.oh_hh || '0'}:{docData?.get_closed_info?.oh_mm || '0'}:{docData?.get_closed_info?.oh_ss || '0'}</td>
                            </tr>
                            <tr>
                                <th>Others Hold Reason:</th>
                                <td>{docData?.get_closed_info?.others_hold_reason || 'N/A'}</td>
                            </tr> */}
                            {/* <tr>
                                <th>RFO:</th>
                                <td>{docData?.get_closed_info?.get_rfo?.name ?? 'N/A'}</td>
                            </tr> */}

                            {/* <tr>
                                <th>Total KM:</th>
                                <td>{docData?.get_total_km} KM</td>
                            </tr> */}
                            {/* <tr>
                                <th>Confirmed By:</th>
                                <td>{docData?.get_closed_info?.confirmed_by ?? 'N/A'}</td>
                            </tr> */}
                            <tr>
                                <th>Comments:</th>
                                <td>{docData?.comment || 'NA'} <br />
                                    {/* <code>Close:</code> {docData?.get_closed_info?.comments || 'NA'} */}
                                </td>
                            </tr>


                            {/* </> : ''} */}
                        </tbody>
                    </table>


                    <div className="d-flex justify-content-between align-items-center mt-3">
                        <div>

                        </div>
                        <div>
                            <button className='btn btn-dark' onClick={() => { setModal(false); redirect && navigate('/docket'); }}> <i className="fa fa-times" aria-hidden="true"></i>Close</button>
                        </div>
                    </div>

                </div>
            </div>
        </Modal>
    )
}
