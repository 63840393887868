import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
// import moment from "moment/moment";
// import { useState } from "react";
import { API_HOST_URL } from "../../config";
import { endpoints } from "../../config/endpoints";
import { fetchJson } from "../../libs/api";
import { getTokens } from "../../utils/shortcuts";
import { useState } from "react";
import { CORPORATE_DOCKETS_QUERY_KEY, CORPORATE_DOCKET_TICKETS_QUERY_KEY, CORP_DOCKET_NOTIFICATION_QUERY_KEY } from "../docket/query_keys";
import axios from "axios";


export function useCorpateDocketList(defaultParam = '', enabled = true) {
    const [param, setParam] = useState(null);
    const { isLoading, data } = useQuery([CORPORATE_DOCKETS_QUERY_KEY, { param: param, defaultParam }], async (key) => {
        const param = key.queryKey[1].param;
        const defaultParam = key.queryKey[1].defaultParam;
        try {
            const { accessToken } = getTokens();
            const param_url = param ? param : defaultParam;
            const res = await fetchJson(`${API_HOST_URL}/${endpoints.corporate.docket_list}${param_url}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            }, true)
            return await res.json()

        } catch (err) {
            return { success: false, detail: err };
        }
    }, {
        cacheTime: Infinity,
        staleTime: 30_000,
        enabled: enabled,
    })
    return {
        corporateDockets: data,
        isCorporateDocketsLoading: isLoading,
        filterDocketsList: async (value) => setParam(value),
    }
}

export function useCorporateDocket(method) {
    const { accessToken } = getTokens();
    const queryClient = useQueryClient();
    const mutation = useMutation((data) => fetchJson(`${API_HOST_URL}/${endpoints.corporate.docket_list}${method === 'PATCH' ? '/' + data.id : ''}`, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(data)
    }, true), {
        retry: 2,
    })
    return {
        handleNewCorporateDocket: async (data) => {
            try {
                const res = await mutation.mutateAsync(data);
                const resData = await res.json()
                if (resData.success) {
                    await queryClient.invalidateQueries([CORPORATE_DOCKETS_QUERY_KEY])
                    await queryClient.invalidateQueries(['circuits'])
                }
                return resData

            } catch (err) {
                return {
                    success: false,
                    detail: err
                }
            }
        },
        isCreatingCorporateDocket: mutation.isLoading,
    }
}



export function useCorpDocketTicketList(defaultParam = '', enabled = true) {
    const [param, setParam] = useState(null);
    const { isLoading, data } = useQuery([CORPORATE_DOCKET_TICKETS_QUERY_KEY, { param: param, defaultParam }], async (key) => {
        const param = key.queryKey[1].param;
        const defaultParam = key.queryKey[1].defaultParam;
        try {
            const { accessToken } = getTokens();
            const param_url = param ? param : defaultParam;
            const res = await fetchJson(`${API_HOST_URL}/${endpoints.corporate.ticket_list}${param_url}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            }, true)
            return await res.json()

        } catch (err) {
            return { success: false, detail: err };
        }
    }, {
        // cacheTime: Infinity,
        // staleTime: 30_000,
        cacheTime: 0,
        staleTime: 1,
        refetchOnWindowFocus: true,
        refetchOnMount: true,
        enabled: enabled,
    })
    return {
        corpDocketTicketData: data,
        isLoadingCorpDocketTicketData: isLoading,
        filterList: async (value) => setParam(value),
    }
}

// Update Docket Ticket Axios [File Upload]
export function useCorpDocketTicket() {
    const { accessToken } = getTokens();
    const queryClient = useQueryClient();
    const mutation = useMutation(
        ({ data, id }) =>
            axios.post(`${API_HOST_URL}/${endpoints.corporate.ticket_list}/${id}`, data, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                onUploadProgress: (e) => {
                    // let percent = Math.round((100 * e.loaded) / e.total)
                    // console.log('Process: ', percent, e)
                },
            }),
        {
            retry: 2,
        },
    );
    return {
        handleCorpDocketTicket: async (data, id) => {
            try {
                const res = await mutation.mutateAsync({ data, id });
                if (res.status === 201) {
                    await queryClient.invalidateQueries([CORPORATE_DOCKETS_QUERY_KEY]);
                    await queryClient.invalidateQueries([CORPORATE_DOCKET_TICKETS_QUERY_KEY]);
                    await queryClient.invalidateQueries([CORP_DOCKET_NOTIFICATION_QUERY_KEY]);
                }
                return res.data;
            } catch (error) {
                // console.log("Err -> ", error);
                // error.response.status === 400
                return {
                    success: false,
                    detail: error?.response?.data?.detail ?? error?.message,
                }
            }
        },
        isCreatingDocket: mutation.isLoading,
    };
}



export function useCorporateDocketNotificationList(defaultParam = "", enabled = true) {
    const [param, setParam] = useState(null);
    var queryClient = useQueryClient();
    const { isLoading, data } = useQuery(
        [CORP_DOCKET_NOTIFICATION_QUERY_KEY, { param: param, defaultParam }],
        async (key) => {
            const param = key.queryKey[1].param;
            const defaultParam = key.queryKey[1].defaultParam;
            try {
                const { accessToken } = getTokens();
                const param_url = param ? param : defaultParam;
                const res = await fetchJson(
                    `${API_HOST_URL}/${endpoints.corporate.ticket_notifications_list}${param_url}`,
                    {
                        headers: { Authorization: `Bearer ${accessToken}` },
                    },
                    true,
                );
                return await res.json();
            } catch (err) {
                return { success: false, detail: err };
            }
        },
        {
            cacheTime: Infinity,
            staleTime: 30_000,
            enabled: enabled,
        },
    );
    return {
        corpDocketNotifications: data,
        corpDocketNotificationsIsLoading: isLoading,
        filterUpdatesList: async (value) => setParam(value),
        retriveCorpNotificationData: async (value) => {
            // const queryClient = useQueryClient();
            try {
                const { accessToken } = getTokens();
                const res = await fetchJson(
                    `${API_HOST_URL}/${endpoints.corporate.ticket_notifications_list}/${value}`,
                    {
                        headers: { Authorization: `Bearer ${accessToken}` },
                    },
                    true,
                );
                const resData = await res.json();
                if (resData.success) {
                    await queryClient.invalidateQueries([CORP_DOCKET_NOTIFICATION_QUERY_KEY]);
                    await queryClient.invalidateQueries([CORPORATE_DOCKET_TICKETS_QUERY_KEY]);
                }
                return resData;
            } catch (err) {
                return { success: false, detail: err };
            }
        },
    };
}


export function useCorpDocketNotifications() {
    const { accessToken } = getTokens();
    const queryClient = useQueryClient();
    const mutation = useMutation(
      (data) =>
        fetchJson(
          `${API_HOST_URL}/${endpoints.corporate.mark_all_as_read}`,
          {
            method: "post",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(data),
          },
          true,
        ),
      {
        retry: 0,
      },
    );
    return {
      handleMarkAllAsRead: async (data) => {
        try {
          const res = await mutation.mutateAsync(data);
          const resData = await res.json();
          if (resData.success) {
            await queryClient.invalidateQueries([CORP_DOCKET_NOTIFICATION_QUERY_KEY]);
            await queryClient.invalidateQueries([CORPORATE_DOCKET_TICKETS_QUERY_KEY]);
            // await queryClient.invalidateQueries([DOCKET_UPDATE_REQUEST_QUERY_KEY])
          }
          return resData;
        } catch (err) {
          return {
            success: false,
            detail: err,
          };
        }
      },
  
      markingIsLoading: mutation.isLoading,
    };
  }