// import moment from 'moment';

import Modal from 'react-modal'
import { useNavigate } from 'react-router-dom';
import { serializeQueryStrings } from '../../utils';
import DocketRequestsFilterForm from '../LCO/Forms/DocketRequestsFilterForm';
import { DOCKET_REQUESTS_STATUS_FILTER_OPTIONS, DOCKET_UPDATE_REQUESTS_FILTER_OPTIONS } from '../Forms/Options/DocketForm';
import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Typography } from '@mui/material';
import SelectField from '../Common/Fields/SelectField';
import Loader2 from '../Common/Loader2';
import { LIMIT_OPTIONS } from '../Forms/Options/Common';
// import { toast } from 'react-toastify';
// import { useFindCircuit } from '../../hooks/circuit';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '700px',
    },
    overlay: { zIndex: 1000 }
};
export default function DocketUpdateRequestFilterModal({ modal, setModal, searchParams, setSearchParams }) {
    let navigate = useNavigate();
    const [params, setParams] = useState({});
    const [loading, setLoading] = useState(true);

    const handleSubmit = async (values) => {
        setSearchParams(serializeQueryStrings(values))
        setModal(false)
    }
    const resetFrom = (formik) => {
        formik.resetForm()
        navigate(`/docket/update/requests`)
        setModal(false)
    }
    useEffect(() => {
        const currentParams = Object.fromEntries([...searchParams]);
        setParams(currentParams)
        setLoading(false)
    }, [searchParams])

    const validate = Yup.object({
        status: Yup.string(),

    })

    if (loading) {
        return <Loader2 />
    }
    return (
        <Modal
            isOpen={modal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <div className="container-fluid">

                <div className="d-flex justify-content-between">
                    <h5 className="modal-title mb-3 text-center text-info">Filter Docket Update Requests</h5>
                    <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={() => setModal(false)}></button>
                </div>
                <div className="modal-body" style={{ width: '100%' }}>
                    {/* <DocketRequestsFilterForm
                        isLoading={false}
                        handleSubmit={handleSubmit}
                        setModal={setModal}
                        resetFrom={resetFrom}
                        searchParams={searchParams}
                    /> */}
                    <Formik
                        initialValues={{

                            "status": params?.status ?? '',
                            "limit": params?.limit ?? '20',
                            "update_type": params?.update_type ?? '',
                        }}
                        validationSchema={validate}
                        onSubmit={handleSubmit}
                    >
                        {formik => (

                            <Form>


                                <div className='row'>
                                    <div className="col-md-4 mb-4">
                                        <Typography variant='caption'>Type</Typography>
                                        <SelectField
                                            name='update_type'
                                            options={DOCKET_UPDATE_REQUESTS_FILTER_OPTIONS}
                                            value={DOCKET_UPDATE_REQUESTS_FILTER_OPTIONS.filter(function (option) {
                                                return option.value === formik.values.update_type;
                                            })}
                                            onChange={(e) => formik.setFieldValue('update_type', e.value)}
                                        />
                                    </div>
                                    <div className="col-md-4 mb-4">
                                        <Typography variant='caption'>Status</Typography>
                                        <SelectField
                                            name='status'
                                            options={DOCKET_REQUESTS_STATUS_FILTER_OPTIONS}
                                            value={DOCKET_REQUESTS_STATUS_FILTER_OPTIONS.filter(function (option) {
                                                return option.value === formik.values.status;
                                            })}
                                            onChange={(e) => formik.setFieldValue('status', e.value)}
                                        />
                                    </div>
                                    <div className="col-md-4 mb-4">
                                        <Typography variant='caption'>Limit records</Typography>
                                        <SelectField
                                            name='limit'
                                            options={LIMIT_OPTIONS}
                                            value={LIMIT_OPTIONS.filter(function (option) {
                                                return option.value === formik.values.limit;
                                            })}
                                            onChange={(e) => formik.setFieldValue('limit', e.value)}
                                        />
                                    </div>
                                </div>


                                <LoadingButton
                                    loading={loading}
                                    type="submit"
                                    variant="contained"
                                    disableElevation
                                >
                                    Search
                                </LoadingButton>
                                <Button variant="outlined" sx={{ ml: 1 }} disableElevation onClick={() => resetFrom(formik)}>Reset</Button>
                                <Button variant="outlined" sx={{ ml: 1 }} disableElevation onClick={() => setModal(false)}>Close</Button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </Modal>
    )
}
