import { Container } from '@mui/material'
import React, { useState } from 'react'
import PageBreadcrumbs from '../../../components/Common/Breadcrumbs'
import { Helmet } from 'react-helmet'
import DocketMaintenanceAltForm from '../../../components/Forms/DocketMaintenanceForm/AltLayout'
import { useMaintenanceDocket } from '../../../hooks/docket'
import { toast } from 'react-toastify'
import MaintanceDocketInfoModal from '../../../components/Models/MaintanceDocketInfoModal'
import { useSearchParams } from 'react-router-dom'

export default function MaintenanceDocketAddPage() {
    const [searchParams, setSearchParams] = useSearchParams();
    const currentParams = Object.fromEntries([...searchParams]);
    // console.log('currentParams->', currentParams)
    const { handleDocketAction, docketActionIsLoading } = useMaintenanceDocket('POST');
    const [modal, setModal] = useState(false)
    const [docData, setDocData] = useState(null);

    const handleSubmit = async (values, { resetForm }) => {
        // console.log('Values :', values)

        const {
            provider,
            circuit_type,
            link_type,
            order_id,

            location_a,
            pin_a,
            mobile_a,
            port_details_a,
            address_a,
            city_a,
            post_a,
            area_a,
            district_a,
            latitude_a,
            longitude_a,

            location_b,
            pin_b,
            mobile_b,
            port_details_b,
            address_b,
            city_b,
            post_b,
            area_b,
            district_b,
            latitude_b,
            longitude_b,
            // area,
            // district,

            // lc_contact_persion,
            // lc_mobile_no,
            // customer_type,
            // type_of_connectivity,

            // ofc_color,
            // ofc_distance,
            // latitude,
            // longitude,

            fiber_team_leader,
            technician,
            third_party_docket_no,
            problem,
            hirer_name,
            comment,
            docketed_by,
            docketed_by_contact,
            // status,
        } = values;

        const data = {
            circuit: {
                provider: provider,
                hirer_name: hirer_name,
                is_maintenance: true,

                circuit_type: circuit_type,
                link_type: link_type,

                location_a: location_a,
                pin_a: pin_a,
                mobile_a: mobile_a,
                port_details_a: port_details_a,
                address_a: address_a,
                city_a: city_a,
                post_a: post_a,
                area_a: area_a,
                district_a: district_a,
                latitude_a: latitude_a,
                longitude_a: longitude_a,

                location_b: location_b,
                pin_b: pin_b,
                mobile_b: mobile_b,
                port_details_b: port_details_b,
                address_b: address_b,
                city_b: city_b,
                post_b: post_b,
                area_b: area_b,
                district_b: district_b,
                latitude_b: latitude_b,
                longitude_b: longitude_b,
                // area: area,
                // district: district,

                // lc_contact_persion: lc_contact_persion,
                // lc_mobile_no: lc_mobile_no,

                // customer_type: customer_type,
                // type_of_connectivity: type_of_connectivity,

                // ofc_color: ofc_color,
                // ofc_distance: ofc_distance,
                // latitude: latitude,
                // longitude: longitude,

            },
            provider: provider,
            hirer_name: hirer_name,
            is_maintenance: true,
            order_id: order_id,

            fiber_team_leader: fiber_team_leader,
            technician: technician,
            third_party_docket_no: third_party_docket_no,
            problem: problem,
            comment: comment,
            docketed_by: docketed_by,
            docketed_by_contact: docketed_by_contact,

            // status: status
        }

        const res = await handleDocketAction(data);
        if (res.success) {
            setDocData(res?.data)
            setModal(true)
            toast.success(res.detail);
            resetForm({ values: '' });
        } else {
            toast.error(res.detail)
        }

    }
    return (
        <>
            <Helmet>
                <title>New Maintenance Docket</title>
            </Helmet>
            <Container maxWidth="xxl" disableGutters>
                <PageBreadcrumbs
                    title="New Maintenance Docket"
                    history={[{ name: 'Docket', to: '/docket' }]}
                />
                <div className='container'>
                    <div className="row">
                        <div className='mt-2'>
                            <DocketMaintenanceAltForm
                                data={{
                                    provider: parseInt(currentParams?.provider) ?? '',
                                    get_circuit: {
                                        circuit_type: currentParams?.circuit_type ?? '',
                                        location_a: currentParams?.location_a ?? '',
                                        location_b: currentParams?.location_b ?? '',
                                    },
                                    problem: parseInt(currentParams?.problem) ?? '',
                                    comment: currentParams?.comment ?? '', 
                                    docketed_by: currentParams?.docketed_by ?? '', 
                                    docketed_by_contact: currentParams?.docketed_by_contact ? currentParams?.docketed_by_contact.replace(' 91', '') : '', 
                                }}
                                btnLabel='Create Docket'
                                isLoading={docketActionIsLoading}
                                handleSubmit={handleSubmit}
                            />

                            {docData && <MaintanceDocketInfoModal
                                modal={modal}
                                setModal={setModal}
                                docData={docData}
                                headerTitle='Maintenance Docket has been created!'
                            />}
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}
