import { Button, ButtonGroup, Divider, Stack } from '@mui/material'
import React from 'react'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

export default function FormSteper({ steps, currentStep, setCurrentStep }) {
    return (
        <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            alignItems="center"
            size='large'
            justifyContent="center"
            justify="center"
            className="overFlowBtnInerMobile"
            sx={{ mt: 3, mb: 3 }}
        >
            <ButtonGroup variant="text" size="large" aria-label="text button group" disableElevation fullWidth>
                {steps.map((step, i) =>
                    <Button
                        key={i}
                        color="primary"
                        variant={`${step.id === currentStep ? 'contained' : 'outlined'}`}
                        endIcon={<NavigateNextIcon />}
                        onClick={() => setCurrentStep(step.id)}
                    >{step.label}</Button>
                )}
            </ButtonGroup>
        </Stack>
    )
}
