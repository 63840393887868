import React, { useState } from 'react'
import { isEmptyArr } from '../../utils'
import DocketUploadsModal from './DocketUploadsModal';
import { formatDateTime } from '../../utils/shortcuts';

export default function DocketVehicleHoldTable({ data, showTotalCopm = false }) {
    // console.log('StackedInline', data)
    const [modal, setModal] = useState(false);
    const [holdUploads, setHoldUploads] = useState(null);
    const renderTotalComp = (data) => {
        // var holdKmTotal = data.docket_hold_data.reduce((accum, item) => accum + parseInt(item?.get_vehicles?.vehicle1_total_km) + parseInt(item?.get_vehicles?.vehicle2_total_km), 0)

        var holdKmTotal = data.docket_hold_data.reduce((accum, item) => {
            const vehicle1Km = parseInt(item?.get_vehicles?.vehicle1_total_km);
            const vehicle2Km = parseInt(item?.get_vehicles?.vehicle2_total_km);

            const validVehicle1Km = isNaN(vehicle1Km) ? 0 : vehicle1Km;
            const validVehicle2Km = isNaN(vehicle2Km) ? 0 : vehicle2Km;

            return accum + validVehicle1Km + validVehicle2Km;
        }, 0);
        const closed_docket = data?.get_closed_info
        // console.log(closed_docket);
        return <>
            <tr>
                <td colSpan={4} className='text-center fw-bold'>Used Vehicles</td>
            </tr>
            <tr>
                <td>Vehicle 1</td>
                <td colSpan={2} className='text-center'>
                    {closed_docket.get_vehicles.vehicle1_name}
                    <br />
                    <small>
                        Start: {closed_docket.vehicle1_start_km} - End: {closed_docket.vehicle1_end_km} - Pop: {closed_docket.vehicle1_pop_km} ({closed_docket?.get_vehicles?.vehicle1_total_km} KM)
                    </small>
                </td>
                <td>{closed_docket?.get_vehicles?.vehicle1_total_km} KM</td>
            </tr>
            {closed_docket.vehicle2 && <tr>
                <td>Vehicle 2</td>
                <td colSpan={2} className='text-center'>
                    {closed_docket.get_vehicles.vehicle2_name}
                    <br />
                    <small>
                        Start: {closed_docket.vehicle2_start_km} - End: {closed_docket.vehicle2_end_km} - Pop: {closed_docket.vehicle2_pop_km} ({closed_docket?.get_vehicles?.vehicle2_total_km} KM)
                    </small>
                </td>
                <td>{closed_docket?.get_vehicles?.vehicle2_total_km} KM</td>
            </tr>}
            {closed_docket.vehicle3 && <tr>
                <td>Vehicle 3</td>
                <td colSpan={2} className='text-center'>
                    {closed_docket.get_vehicles.vehicle3_name}
                    <br />
                    <small>
                        Start: {closed_docket.vehicle3_start_km} - End: {closed_docket.vehicle3_end_km} - Pop: {closed_docket.vehicle3_pop_km} ({closed_docket?.get_vehicles?.vehicle3_total_km} KM)
                    </small>
                </td>
                <td>{closed_docket?.get_vehicles?.vehicle3_total_km} KM</td>
            </tr>}

            {closed_docket &&
                <tr>
                    <td colSpan={3}>Total Vehicles Utilized:</td>
                    <td className='text-danger'>{holdKmTotal} KM</td>
                </tr>
            }

            <tr>
                <td colSpan={3}>Vehicles Total Usage:</td>
                <td>
                    {holdKmTotal + (closed_docket?.get_vehicles?.vehicle1_total_km + closed_docket?.get_vehicles?.vehicle2_total_km + closed_docket?.get_vehicles?.vehicle3_total_km)} KM
                </td>
            </tr>
        </>
    }
    return (
        <table className="table">
            <thead className="table-dark">
                <tr>
                    <th>Type</th>
                    <th>Vehicle 1</th>
                    <th>Vehicle 2</th>
                    <th>Total KM</th>
                </tr>
            </thead>
            <tbody>
                {isEmptyArr(data.docket_hold_data) && <tr>
                    <td colSpan={4} className='text-center'>No Hold</td>
                </tr>}
                {data.docket_hold_data.map((val, i) =>
                    <tr key={i}>
                        {/* <td>Hold {i + 1}</td> */}
                        <td onClick={() => {
                            !isEmptyArr(val.docket_hold_files) && setHoldUploads(val.docket_hold_files)
                            setModal(true)
                        }}>
                            <span className={`${!isEmptyArr(val.docket_hold_files) && 'text-primary fw-bold pointer'}`}>
                                {val.not_hold ? 'Transfer' : `Hold ${i + 1}`}
                            </span>
                        </td>
                        <td title={`Added by ${val?.get_hold_by?.name ?? '-'}  - ${formatDateTime(val.updated_at, ' d/m/y h:mm A')}`}>
                            {val?.get_vehicles?.vehicle1_name || '-'}
                            <br />
                            <small>
                                Start: {val?.get_vehicles?.vehicle1_start_km} - End: {val?.get_vehicles?.vehicle1_end_km} - Pop: {val?.get_vehicles?.vehicle1_pop_km} ({val?.get_vehicles?.vehicle1_total_km} KM)
                            </small>
                        </td>
                        <td title={`Added by ${val?.get_hold_by?.name ?? '-'} - ${formatDateTime(val.updated_at, ' d/m/y h:mm A')}`}>
                            {val?.get_vehicles?.vehicle2_name || '-'}
                            <br />
                            {val?.get_vehicles?.vehicle2 &&
                                <small>
                                    Start: {val?.get_vehicles?.vehicle2_start_km} - End: {val?.get_vehicles?.vehicle2_end_km} - Pop: {val?.get_vehicles?.vehicle2_pop_km} ({val?.get_vehicles?.vehicle2_total_km} KM)
                                </small>
                            }
                        </td>

                        {/* <td>{isNaN(((val?.get_vehicles?.vehicle1_end_km - val?.get_vehicles?.vehicle1_start_km) + (val?.get_vehicles?.vehicle2_end_km - val?.get_vehicles?.vehicle2_start_km))) ? 0 : ((val?.get_vehicles?.vehicle1_end_km - val?.get_vehicles?.vehicle1_start_km) + (val?.get_vehicles?.vehicle2_end_km - val?.get_vehicles?.vehicle2_start_km))} KM</td> */}
                        <td>
                            {(() => {
                                const vehicle1Km = parseInt(val?.get_vehicles?.vehicle1_total_km) || 0;
                                const vehicle2Km = parseInt(val?.get_vehicles?.vehicle2_total_km) || 0;
                                const totalKm = vehicle1Km + vehicle2Km;
                                return isNaN(totalKm) ? '0 KM' : `${totalKm} KM`;
                            })()}
                        </td>

                    </tr>
                )}
                {showTotalCopm && data.docket_hold_data && data.get_closed_info && renderTotalComp(data)}


                <DocketUploadsModal
                    modal={modal}
                    setModal={setModal}
                    holdUploads={holdUploads}
                    setHoldUploads={setHoldUploads}
                />

            </tbody>
        </table>
    )
}
