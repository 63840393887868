export const endpoints = {
    account: {
        login: 'accounts/v1/user/login', // email / username / mobile :: password 
        login_with_otp: 'accounts/v1/user/login/otp',
        logout: 'accounts/v1/user/logout',
        user: 'accounts/v1/user',
        change_password: 'accounts/v1/user/password/change',
        update_account: 'accounts/v1/me',
        // Forgot Password
        send_otp: 'accounts/v1/send/otp',
        reset_password: 'accounts/v1/password/reset',
    },
    circuit: {
        circuit_find: 'circuit/v1/find',
        circuit_list: 'circuit/v1/list/circuit',
        circuit_list_lite: 'circuit/v1/list/lite',
        hirer_list: 'circuit/v1/list/hirer',
        district_list: 'circuit/v1/list/district',
        static: 'circuit/v1/statics',
    },
    docket: {
        list: 'docket/v1/list/docket',
        list_lite: 'docket/v1/list/lite',
        detail: 'docket/v1/list/advanced', // Docket Detail
        export: 'docket/v1/list/advanced', // Export Docket
        update_hold_vehicle: 'docket/v1/update/hold-vehicle', // Update Hold Vehicle Back to POP
        docket_under_review_list: 'docket/v1/under-review-list',
        docket_assign: 'docket/v1/assgin-docket', // Accept Docket
        maintenance_list: 'docket/v1/list/maintenance/docket',
        closed_list: 'docket/v1/list/docket/closed',
        rfo_list: 'docket/v1/list/rfo',
        problems: 'docket/v1/list/problems',
        send_sms: 'docket/v1/sms',
        send_email: 'docket/v1/email',
        updates: 'history/v1/docket/updates',
        static: 'docket/v1/statics',
        statics_chart: 'docket/v1/statics/chart',
        simple_change: 'docket/v1/change', // for TL Change
        update_request: 'docket/v1/update-requests', // # DOCKET HOLD/CLOSE REQUESTS
        mark_all_as_read: 'history/v1/docket/updates/mark-read', // # Docket notification mark as read
    },
    corporate: {
        docket_list: 'corporate/v1/docket',
        ticket_list: 'corporate/v1/docket/tickets', // tickets/{code}
        ticket_notifications_list: 'corporate/v1/docket/notifications/tickets', // notifications
        mark_all_as_read: 'corporate/v1/docket/tickets/notifications/markallread', // notifications mark all read
    },
    users: {
        list: 'accounts_admin/v1/users',
        lite: 'accounts_admin/v1/users/lite',
        team_list: 'accounts/v1/list/team',
        groups: 'accounts_admin/v1/users/groups',
    },
    core: {
        provider_list: 'core/v1/list/provider',
        vehicle_list: 'core/v1/list/vehicle',
        material_list: 'core/v1/list/material',
        location_list: 'core/v1/list/location',
        send_custom_sms: 'core/v1/send/sms',
    },
    lco: {
        circuit_list: 'lco/v1/list/circuit',
        problems_list: 'lco/v1/list/problems',
        docket_request: 'lco/v1/docket/request',
        create_new_docket: 'docket/v1/create-new',
        docket_request_list: 'lco/v1/docket/requests/list',
        cancel_docket: 'lco/v1/cancel-docket',
    },
    report: {
        vehicles_km: 'reports/v1/vehicles/km',
        materials_used: 'reports/v1/materials/used',
    },
}

