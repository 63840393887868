import React from 'react'
import MuiTextField from '@mui/material/TextField';
import { useField } from 'formik';

import { styled } from '@mui/material'

const CustomTextField = styled(MuiTextField)({
    '& .MuiInputBase-root.Mui-disabled': {
        backgroundColor: '#f0f0f0',
    },
});

export default function TextField({ label, ...props }) {
    const [field, meta] = useField(props);
    return (
        <MuiTextField
            label={label}
            variant='outlined'
            size="small"
            margin="dense"
            fullWidth
            {...field}
            {...props}
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
        />
    )
}
