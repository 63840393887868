import { Card, CardContent, Grid, Paper } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import { formatDateTime } from '../../utils/shortcuts'


export default function LCOCircuitInformation({ data }) {
    console.log('ifno -> ', data)

    return (
        <>
            <div className="row justify-content-between">
                <div className='col-md-6'>
                    <h3 className="display-6 text-dark">{data.get_provider.title} Circuit</h3>
                    <p className="lead"><em>Last updated at {moment(data?.updated_at).fromNow()}...</em></p>
                </div>
                <div className='col-md-6 text-md-end'>
                    <h3 className="display-6 text-dark">ID: {data?.code}</h3>
                    <p className="lead">Status: <span className="badge badge-primary">{data?.status}</span></p>
                </div>
            </div>
            <Grid container>
                {/* CIRCUIT DETAILS */}
                <Grid item lg={4} xs={12} sx={{ p: 0.5 }}>
                    <Card>
                        <CardContent>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <td colSpan={2}><em>CIRCUIT DETAILS</em> </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="col">Circuit Type:</th>
                                        <td>{data.circuit_type}</td>
                                    </tr>
                                    <tr>
                                        <th scope="col">Link Type:</th>
                                        <td>{data.link_type}</td>
                                    </tr>
                                    {data.customer_id && <tr>
                                        <th scope="col">Customer ID:</th>
                                        <td>{data.customer_id}</td>
                                    </tr>}
                                    <tr>
                                        <th scope="col">3rd Party Circuit ID:</th>
                                        <td>{data.third_party_circuit_id || '-'}</td>
                                    </tr>
                                    <tr>
                                        <th scope="col">Status:</th>
                                        <td>
                                            {data.status}
                                            <br />
                                            {typeof data.termination_log !== 'undefined' && data.termination_log !== 0 &&
                                                data.termination_log.map((log, i) => {
                                                    return <small key={i} title={`Expiry: ${log.expiry_date}, Scheduled at: ${log.date}, By: ${log.name}`}>
                                                        Under Termination {i + 1} - {log.remarks}<br />
                                                    </small>

                                                })
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="col">Created at:
                                            <br />
                                            <em>
                                                <small>
                                                    Updated at:
                                                </small>
                                            </em>
                                        </th>
                                        <td>
                                            {moment(data.created_at).format('MMMM Do YYYY, h:mm:ss a')}
                                            <br />
                                            <em>
                                                <small>
                                                    {moment(data.updated).format('MMMM Do YYYY, h:mm:ss a')}
                                                </small>
                                            </em>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </CardContent>
                    </Card>
                </Grid>

                {/* LOCATION DETAILS */}
                <Grid item lg={8} xs={12} sx={{ p: 0.5 }}>
                    <Card>
                        <CardContent className='table-responsive'>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <td colSpan={2} className='text-center'><em>LOCATION A DETAILS</em></td>
                                        <td colSpan={2} className='text-center'><em>LOCATION B DETAILS</em></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="col">Location A:
                                            <br />
                                            <em>
                                                <small>
                                                    BTS | POP | HUB
                                                </small>
                                            </em>
                                        </th>
                                        <td>{data.location_a}</td>
                                        <th scope="col">Location B:
                                            <br />
                                            <em>
                                                <small>
                                                    Customer End | Office
                                                </small>
                                            </em>
                                        </th>
                                        <td>{data.location_b}</td>
                                    </tr>
                                    <tr>
                                        <th scope="col">PIN:</th>
                                        <td>{data.pin_a}</td>
                                        <th scope="col">PIN:</th>
                                        <td>{data.pin_b}</td>
                                    </tr>
                                    {/* <tr>
                                        <th scope="col">Mobile A:</th>
                                        <td>{data.mobile_a || '-'}</td>
                                        <th scope="col">Mobile B:</th>
                                        <td>{data.mobile_b || '-'}</td>
                                    </tr> */}
                                    <tr>
                                        <th scope="col">Address A:</th>
                                        <td>{data.address_a}</td>
                                        <th scope="col">Address B:</th>
                                        <td>{data.address_b}</td>
                                    </tr>
                                    <tr>
                                        <th scope="col">Port Details A:</th>
                                        <td>{data.port_details_a}</td>
                                        <th scope="col">Port Details B:</th>
                                        <td>{data.port_details_b}</td>
                                    </tr>
                                    <tr>
                                        <th scope="col" colSpan={2}>Area:</th>
                                        <td colSpan={2}>{data.area}</td>
                                    </tr>
                                    {/* <tr>
                                        <th scope="col" colSpan={2}>District:</th>
                                        <td colSpan={2}>{data.get_district.name}</td>
                                    </tr> */}
                                </tbody>
                            </table>
                        </CardContent>
                    </Card>
                </Grid>

                {/* CONTACT DETAILS */}
                {/* <Grid item lg={4} xs={12} sx={{ p: 0.5 }}>
                    <Card>
                        <CardContent>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <td colSpan={2}><em>CONTACT DETAILS</em></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="col">
                                            LC.Contact Person:
                                            <br />
                                            LC Mobile No:
                                        </th>
                                        <td>
                                            {data.lc_contact_persion ?? '-'}
                                            <br />
                                            {data.lc_mobile_no ?? '-'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="col">
                                            Technician Name:
                                            <br />
                                            Mobile:
                                        </th>
                                        <td>
                                            {data?.get_technician?.name ?? '-'}
                                            <br />
                                            {data?.get_technician?.mobile ?? '-'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="col">
                                            Territory Incharge:
                                            <br />
                                            Mobile:
                                        </th>
                                        <td>
                                            {data.get_ti.name}
                                            <br />
                                            {data.get_ti.mobile || '-'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="col">
                                            Hub Incharge:
                                            <br />
                                            Mobile:
                                        </th>
                                        <td>
                                            {data.get_hi.name}
                                            <br />
                                            {data.get_hi.mobile || '-'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="col">
                                            AC Manager:
                                            <br />
                                            Mobile:
                                        </th>
                                        <td>
                                            {data.get_ac_manager.name}
                                            <br />
                                            {data.get_ac_manager.mobile || '-'}
                                        </td>
                                    </tr>


                                    <tr>
                                        <th scope="col">Service Assurance:</th>
                                        <td>{data.service_assurance || '-'}</td>
                                    </tr>
                                    <tr>
                                        <th scope="col">
                                            Fiber[Vendor]Team Leader:
                                            <br />
                                            Mobile:
                                        </th>
                                        <td>
                                            {data.get_fiber_tl.name}
                                            <br />
                                            {data.get_fiber_tl.mobile || '-'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="col">
                                            Fiber TL:
                                            <br />
                                            Mobile:
                                        </th>
                                        <td>
                                            {data.get_fe_rf_team.name}
                                            <br />
                                            {data.get_fe_rf_team.mobile || '-'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="col">
                                            Feasibility Done By:
                                            <br />
                                            Mobile:
                                        </th>
                                        <td>
                                            {data.get_feasibility_done_by.name}
                                            <br />
                                            {data.get_feasibility_done_by.mobile || '-'}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </CardContent>
                    </Card>
                </Grid> */}

                {/* Previous Dockets */}
                {data.dockets.length !== 0 &&
                    <Grid item lg={12} xs={12} sx={{ p: 0.5 }}>
                        <Card>
                            <Paper style={{ maxHeight: 797, overflow: 'auto' }}>
                                <CardContent>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th colSpan={4}><em>Previous Dockets </em></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.dockets.map((docket, i) =>
                                                <tr key={i}>
                                                    <td>
                                                        {docket.code}
                                                        &nbsp;
                                                        {data.has_recent_docket.status && <span className="badge badge-info badge-style-light">Cooling down, <small>{formatDateTime(data.has_recent_docket.opened_until, 'MMMM Do YYYY, h:mm a')}</small></span>}
                                                    </td>
                                                    <td><em>{docket?.get_problem?.title} </em></td>
                                                    <td><em>{docket.status} </em></td>
                                                    <td>{formatDateTime(docket.created_at, 'MMMM Do YYYY, h:mm a')}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </CardContent>
                            </Paper>
                        </Card>
                    </Grid>
                }
            </Grid>
        </>
    )
}
