import React, { useState } from 'react'
import BackToPopVehicleHoldModal from '../../../components/Models/BackToPopVehicleHoldModal';

export default function HandleHoldVehicleBackToPop({ docket }) {
    const [backToPopHoldVehicleModal, setBacktoPopHoldVehicleModal] = useState(false);
    const [currentData, setCurrentData] = useState({});
    return (
        <div>
            <div className='d-flex gap-1'>
                {docket.pending_back_to_pop && docket.pending_back_to_pop.length > 0 && docket.pending_back_to_pop.map((item) => (
                    item.type === 'forward' ? (
                        <span key={item.id} className="badge text-danger bg-light" role='button' onClick={() => {setBacktoPopHoldVehicleModal(true); setCurrentData(item)}}>forw.</span>
                    ) : item.type === 'hold' ? (
                        <span key={item.id} className="badge text-primary bg-light" role='button' onClick={() => {setBacktoPopHoldVehicleModal(true); setCurrentData(item)}}>hold.</span>
                    ) : null
                ))}
            </div>


            {backToPopHoldVehicleModal && <BackToPopVehicleHoldModal
                modal={backToPopHoldVehicleModal}
                setModal={setBacktoPopHoldVehicleModal}
                docket={docket}
                currentData={currentData}
            />}
        </div>
    )
}
