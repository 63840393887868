import React, { useState } from 'react'
import { useLCOCircuitList } from '../../hooks/lco/circuits'
import Loader2 from '../Common/Loader2'
import { formatDateTime } from '../../utils/shortcuts'
import { Link } from 'react-router-dom'
import DocketRequestModal from './DocketRequestModal'
import DocketCreateModal from './DocketCreateModal'
import { useUser } from '../../hooks/user'
export default function LCOCircuitsList() {
    return <></>
}
// export function LCOCircuitsList() {
//     const { user } = useUser();
//     const { circuitsData, circuitsIsLoading, handleParam } = useLCOCircuitList()
//     // console.log(circuitsData)
//     const [modal, setModal] = useState(false)
//     const [docketCreateModal, setCreateModal] = useState(false)
//     const [currentData, setCurrentData] = useState(null)
//     return (
//         <>
//             <table className='table table-striped'>
//                 <thead>
//                     <tr>
//                         <td>Circuit ID</td>
//                         <td>Customer ID</td>
//                         <td>Location A</td>
//                         <td>Location B</td>
//                         <td>Status</td>
//                         <td>Total Dockets</td>
//                         <td>Action</td>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {circuitsIsLoading && <tr>
//                         <td colSpan={100}> <Loader2 color="inherit" /></td>
//                     </tr>}
//                     {circuitsData && !circuitsIsLoading && circuitsData?.data?.map((data, i) => <tr key={i}>
//                         <td>
//                             {data?.is_under_maintenance && <>
//                                 <span className="badge badge-danger badge-style-light">Under Maintenance</span>
//                                 <br />
//                             </>}
//                             <Link to={`/lco/circuit/${data.code}`} >{data.code} </Link>
//                         </td>
//                         <td>{data.customer_id ?? '-'} </td>
//                         <td>{data.location_a}</td>
//                         <td>{data.location_b}</td>
//                         <td>{data.status}</td>
//                         <td>
//                             <span className="badge badge-info badge-style-light">{data?.dockets?.length} Total</span>  {data?.active_docket?.status && <span className="badge badge-danger badge-style-light" title={data?.active_docket?.code}>1 active</span>}
//                         </td>
//                         {/* <td>{formatDateTime(data.updated_at, 'MMMM Do YYYY, h:mm a')}</td> */}
//                         <td>
//                             <Link to={`/lco/circuit/${data.code}`} className='btn btn-primary pb-0 btn-style-light mx-2' title='View'>
//                                 <span className="material-icons-sharp">
//                                     visibility
//                                 </span>
//                             </Link>

//                             {!data?.active_docket?.status && <>

//                                 <button onClick={() => { setCurrentData(data); setModal(true); }} className='btn btn-warning pb-0 btn-style-light mx-2' title='Report Docket'>
//                                     <span className="material-icons-sharp">
//                                         waving_hand
//                                     </span>
//                                 </button>
//                                 {user?.permissions?.dockets?.can_create_docket && <button onClick={() => { setCurrentData(data); setCreateModal(true); }} className='btn btn-danger pb-0 btn-style-light mx-2' title='Create New Docket'>
//                                     <span className="material-icons-sharp">
//                                         push_pin
//                                     </span>
//                                 </button>}
//                             </>}
//                         </td>
//                     </tr>)}
//                 </tbody>
//             </table>
//             <DocketRequestModal
//                 modal={modal}
//                 setModal={setModal}
//                 currentData={currentData}
//                 setCurrentData={setCurrentData}
//             />
//             <DocketCreateModal
//                 modal={docketCreateModal}
//                 setModal={setCreateModal}
//                 currentData={currentData}
//                 setCurrentData={setCurrentData}
//             />
//         </>
//     )
// }
