import React from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Button, Typography } from '@mui/material';
import TextField from '../Common/Fields/TextField';


export default function BackToPopForm({ currentData, isLoading, handleSubmit, setModal, btnLabel = 'Raise Docket' }) {
    // const { problemsData, problemsIsLoading } = useLCOProblemsList();
    const validate = Yup.object({

        vehicle1_pop_km: Yup.string().required('KM is required.'),
    })
    return (
        <Formik
            initialValues={{
                vehicle1_pop_km: 0,
                vehicle2_pop_km: 0,
                vehicle3_pop_km: 0,
            }}
            validationSchema={validate}
            onSubmit={handleSubmit}
        >
            {formik => (

                <Form>
                    <div className='row'>

                        {currentData?.get_closed_info?.get_vehicles?.vehicle1_name && currentData?.get_closed_info?.vehicle1_to_pop && <div>
                            <div className="d-flex justify-content-between mb-2">
                                <div>
                                    <p className="text-dark m-0"><strong>Vehicle 1:</strong> {currentData?.get_closed_info?.get_vehicles?.vehicle1_name} (<small>Start:{currentData?.get_closed_info?.vehicle1_start_km}</small> <small>End:{currentData?.get_closed_info?.vehicle1_end_km}</small>)</p>
                                </div>
                                <div>
                                    <p className="text-dark m-0">{currentData?.get_closed_info?.get_vehicles?.vehicle1_total_km} KM</p>
                                </div>
                            </div>
                            <TextField label='End to POP KM *' type="number" name='vehicle1_pop_km' placeholder='End to POP KM' sx={{ mt: 2 }} />
                        </div>}
                        {currentData?.get_closed_info?.get_vehicles?.vehicle2_name && currentData?.get_closed_info?.vehicle2_to_pop && <div>
                            <div className="d-flex justify-content-between mb-2 mt-2">
                                <div>
                                    <p className="text-dark m-0"><strong>Vehicle 2:</strong> {currentData?.get_closed_info?.get_vehicles?.vehicle2_name} (<small>Start:{currentData?.get_closed_info?.vehicle2_start_km}</small> <small>End:{currentData?.get_closed_info?.vehicle2_end_km}</small>)</p>
                                </div>
                                <div>
                                    <p className="text-dark m-0">{currentData?.get_closed_info?.get_vehicles?.vehicle2_total_km} KM</p>
                                </div>
                            </div>
                            <TextField label='End to POP KM *' type="number" name='vehicle2_pop_km' placeholder='End to POP KM' sx={{ mt: 2 }} />
                        </div>}
                        {currentData?.get_closed_info?.get_vehicles?.vehicle3_name && currentData?.get_closed_info?.vehicle3_to_pop && <div>

                            <div className="d-flex justify-content-between mb-2 mt-2">
                                <div>
                                    <p className="text-dark m-0"><strong>Vehicle 3:</strong> {currentData?.get_closed_info?.get_vehicles?.vehicle3_name} (<small>Start:{currentData?.get_closed_info?.vehicle3_start_km}</small> <small>End:{currentData?.get_closed_info?.vehicle3_end_km}</small>)</p>
                                </div>
                                <div>
                                    <p className="text-dark m-0">{currentData?.get_closed_info?.get_vehicles?.vehicle3_total_km} KM</p>
                                </div>
                            </div>
                            <TextField label='End to POP KM *' type="number" name='vehicle3_pop_km' placeholder='End to POP KM' sx={{ mt: 2 }} />
                        </div>}
                        {/* <div className="col-md-12 mb-4">

                            <TextField label='End KM *' type="number" name='vehicle2_pop_km' placeholder='End KM' sx={{ mt: 2 }} />
                            <TextField label='End KM *' type="number" name='vehicle3_pop_km' placeholder='End KM' sx={{ mt: 2 }} />
                        </div> */}
                        {/* {console.log(formik.values.problem)} */}
                        {/* {formik.submitCount >= 1 && formik.errors && Object.entries(formik.errors).map(([key, value]) =>
                            <Alert severity="error" sx={{ mt: 1, mb: 1 }} key={key}>{value}</Alert>
                        )} */}
                    </div>
                    <LoadingButton
                        loading={isLoading}
                        type="submit"
                        variant="contained"
                        disableElevation
                        disabled={formik.values.vehicle1_pop_km <= 0}
                    >
                        {btnLabel}
                    </LoadingButton>
                    <Button variant="outlined" sx={{ ml: 1 }} disableElevation onClick={() => setModal(false)}>Close</Button>
                </Form>
            )}
        </Formik>
    )
}
