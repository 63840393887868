import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Typography } from '@mui/material';
import "yup-phone";
import { DOCKET_REQUESTS_STATUS_FILTER_OPTIONS } from '../../Forms/Options/DocketForm';
import SelectField from '../../Common/Fields/SelectField';
import Loader2 from '../../Common/Loader2';
import { LIMIT_OPTIONS } from '../../Forms/Options/Common';
// import { useProviderList } from '../../hooks/core/provider';


export default function DocketRequestsFilterForm({ isLoading, handleSubmit, setModal, resetFrom, searchParams }) {
    const [params, setParams] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const currentParams = Object.fromEntries([...searchParams]);
        setParams(currentParams)
        setLoading(false)
    }, [searchParams])

    const validate = Yup.object({
        status: Yup.string()

    })

    if (loading) {
        return <Loader2 />
    }
    return (
        <Formik
            initialValues={{
                // "date_after": params?.date_after ?? null,
                // "date_before": params?.date_before ?? null,
                "status": params?.status ?? 'Pending',
                "is_maint_docket": params?.is_maint_docket ?? '',
                "limit": params?.limit ?? '20',
                // "code": params?.code ?? '',
                // "q": params?.q ?? '',
            }}
            // validationSchema={validate}
            onSubmit={handleSubmit}
        >
            {formik => (

                <Form>
                    {/* {console.log('formdata -> ', formik)} */}
                    {/* <div className='row'>
                        <div className="col-md-6 mb-4">
                            <TextField label='Search' type="text" name='q' placeholder='Search' />
                        </div>
                        <div className="col-md-6 mb-4">
                            <TextField label='Circuit ID' type="text" name='code' placeholder='Circuit ID' />
                        </div>
                    </div> */}
                    {/* <div className='row'>
                        <div className="col-md-6 mb-4">
                            <DatePicker label='From Date' minDate='2022-04-01' name='date_after' />
                        </div>
                        <div className="col-md-6 mb-4">
                            <DatePicker label='To Date' minDate='2022-04-01' name='date_before' />
                        </div>
                    </div> */}

                    <div className='row'>
                        <div className="col-md-6 mb-4">
                            <Typography variant='caption'>Status</Typography>
                            <SelectField
                                name='status'
                                options={DOCKET_REQUESTS_STATUS_FILTER_OPTIONS}
                                value={DOCKET_REQUESTS_STATUS_FILTER_OPTIONS.filter(function (option) {
                                    return option.value === formik.values.status;
                                })}
                                onChange={(e) => formik.setFieldValue('status', e.value)}
                            />
                        </div>
                   
                        <div className="col-md-6 mb-4">
                            <Typography variant='caption'>Limit records</Typography>
                            <SelectField
                                name='limit'
                                options={LIMIT_OPTIONS}
                                value={LIMIT_OPTIONS.filter(function (option) {
                                    return option.value === formik.values.limit;
                                })}
                                onChange={(e) => formik.setFieldValue('limit', e.value)}
                            />
                        </div>
                    </div>


                    <LoadingButton
                        loading={isLoading}
                        type="submit"
                        variant="contained"
                        disableElevation
                    >
                        Search
                    </LoadingButton>
                    <Button variant="outlined" sx={{ ml: 1 }} disableElevation onClick={() => resetFrom(formik)}>Reset</Button>
                    <Button variant="outlined" sx={{ ml: 1 }} disableElevation onClick={() => setModal(false)}>Close</Button>
                </Form>
            )}
        </Formik>
    )
}
