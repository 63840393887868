import React from 'react'
import { Formik, Form, Field, FieldArray } from 'formik';
import * as Yup from 'yup'
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Button, Typography } from '@mui/material';
import SelectField from '../../Common/Fields/SelectField';
import TextField from '../../Common/Fields/TextField';
import { formatUserOptionsList, useUser, useUsersListLite } from '../../../hooks/user';
import { useProblemsList, useRFOList } from '../../../hooks/docket';
import { formatSelectOptionsList } from '../../../hooks/circuit';
import { PRIORITY_OPTIONS, YESNO_OPTIONS } from '../Options/CorporateDocket';
import TextAreaField from '../../Common/Fields/TextAreaField';
import DatePicker from '../../Common/Fields/DatePickerField';
import TimePicker from '../../Common/Fields/TimePicker';
import { useNavigate } from 'react-router-dom';



export default function CorpDocketCloseForm({ data, isLoading, handleSubmit, setModal, disabled, btnLabel = 'Submit' }) {
    const { user } = useUser();
    let navigate = useNavigate();
    const { rfosData, rfosIsLoading } = useRFOList();
    const teams = useUsersListLite('?all_teams=true');
    // const teams = useUsersListLite('?all_teams=true');  
    // const field_teams = useUsersListLite('?all_field_teams=true');  // Technician *

    const validate = Yup.object({
        attend_by_team: Yup.string().required('Attend By Team is required.'),
        confirmed_by: Yup.string().required('Confirmed by is required.'),
        physical_visit_required: Yup.string().required('Physical Visit required is required.'),
        rfo: Yup.number().required('RFO is required.').nullable(),
        work_done_by: Yup.number().required('Work done by').nullable(),

    })
    return (
        <Formik
            initialValues={{
                attend_by_team: data.attend_by_team ?? '',
                attend_by_team_remarks: data.attend_by_team_remarks ?? '',
                confirmed_by: data.confirmed_by ?? '',
                confirmed_by_remarks: data.confirmed_by_remarks ?? '',
                rfo: data?.rfo ?? null,
                work_done_by: data?.work_done_by ?? null,
                work_done_by_remarks: data.work_done_by_remarks ?? '',
                physical_visit_required: data.physical_visit_required ?? 'No',
                person_name: data.person_name ?? '',
                equipment_field: data.equipment_field ?? '',
                km_field: data.km_field ?? '',
                close_comment: '',
                // send_mail: false,
            }}
            validationSchema={validate}
            onSubmit={handleSubmit}
        >
            {formik => (

                <Form>
                    {/* {console.log(formik)} */}
                    <div className='row'>
                        <div className='col-12'>
                            <h3 className='text-center display-6 mb-4 text-primary mt-3' style={{ 'fontSize': '22px' }}>CLOSE DETAILS</h3>
                        </div>
                        <div className="col-md-6 col-12 mt-2 mb-2">
                            <TextField label='Attend By Team *' type="text" name='attend_by_team' placeholder='Attend By Team' sx={{ mb: 3 }} />
                        </div>
                        <div className="col-md-6 col-12 mt-2 mb-2">
                            <TextField label='Remarks' type="text" name='attend_by_team_remarks' placeholder='Remarks' sx={{ mb: 3 }} />
                        </div>

                        <div className="col-md-6 col-12 mt-2 mb-2">
                            <TextField label='Confirmed by *' type="text" name='confirmed_by' placeholder='Confirmed by' sx={{ mb: 3 }} />
                        </div>
                        <div className="col-md-6 col-12 mt-2 mb-2">
                            <TextField label='Remarks' type="text" name='confirmed_by_remarks' placeholder='Remarks' sx={{ mb: 3 }} />
                        </div>
                        <div className="col-md-6 col-12 mt-2">
                            <Typography variant='caption'>RFO <span className='req'>*</span></Typography>
                            <SelectField
                                name='rfo'
                                options={rfosData?.data.map(formatSelectOptionsList)}
                                value={rfosData?.data.map(formatSelectOptionsList).filter(function (option) {
                                    return option.value === formik.values.rfo;
                                })}
                                onChange={(e) => formik.setFieldValue('rfo', e.value)}
                                isDisabled={disabled}
                                isLoading={rfosIsLoading}

                            />
                        </div>
                        <div className="col-md-6 col-12 mt-2">
                            <Typography variant='caption'>Work done by <span className='req'>*</span></Typography>
                            <SelectField
                                name='work_done_by'
                                options={teams?.usersData?.data?.map(formatUserOptionsList)}
                                value={teams?.usersData?.data?.map(formatUserOptionsList).filter(function (option) {
                                    return option.value === formik.values.work_done_by;
                                })}
                                onChange={(e) => formik.setFieldValue('work_done_by', e.value)}
                                isLoading={teams?.usersIsLoading}
                                isDisabled={!user?.permissions?.dockets?.can_change_corp_docket}
                            // isClearable

                            />
                        </div>

                        <div className="col-md-6 col-12 mt-2 mb-2">
                            <TextField label='Work Done By Remarks' type="text" name='work_done_by_remarks' placeholder='Work Done By Remarks' sx={{ mb: 3 }} />
                        </div>
                        <div className="col-md-6 col-12 mt-2 mb-2">
                            <TextField label='Comment' type="text" name='close_comment' placeholder='Comment' sx={{ mb: 3 }} />
                        </div>


                        <div className='row'>
                            <div className="col-md-6 col-12">
                                <Typography variant='caption'>Physical Visit required <span className='req'>*</span></Typography>
                                <SelectField
                                    name='physical_visit_required'
                                    options={YESNO_OPTIONS}
                                    value={YESNO_OPTIONS.filter(function (option) {
                                        return option.value === formik.values.physical_visit_required;
                                    })}
                                    onChange={(e) => formik.setFieldValue('physical_visit_required', e.value)}
                                />
                            </div>
                            {formik.values.physical_visit_required === 'Yes' && <div className="col-md-6 col-12 mt-3 mb-2">
                                <TextField label='Person Name *' type="text" name='person_name' placeholder='Contact Person Name' sx={{ mb: 3 }} />
                            </div>}
                        </div>
                        <div className="col-md-6 col-12 mt-2 mb-2">
                            <TextField label='Equipment field' type="text" name='equipment_field' placeholder='Equipment field' sx={{ mb: 3 }} />
                        </div>
                        <div className="col-md-6 col-12 mt-2 mb-2">
                            <TextField label='Km Field' type="text" name='km_field' placeholder='Km Field' sx={{ mb: 3 }} />
                        </div>


                        {formik.submitCount >= 1 && formik.errors && Object.entries(formik.errors).map(([key, value]) =>
                            <Alert severity="error" sx={{ mt: 1, mb: 1 }} key={key}>{value}</Alert>
                        )}
                    </div>

                    <div className='mt-5 text-center'>
                        <LoadingButton
                            loading={isLoading}
                            variant="contained"
                            type="submit"
                            disableElevation
                        >
                            {btnLabel}
                        </LoadingButton>
                        <Button variant="outlined" sx={{ ml: 1 }} disableElevation onClick={() => navigate(`/docket/corporate`)}>Close</Button>
                    </div>


                </Form>
            )}
        </Formik>
    )
}
