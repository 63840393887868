import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { API_HOST_URL } from "../../config";
import { endpoints } from "../../config/endpoints";
import { fetchJson } from "../../libs/api";
import { getTokens } from "../../utils/shortcuts";
import {
    CLOSED_DOCKETS_QUERY_KEY,
    DOCKETS_QUERY_KEY,
    DOCKET_QUERY_KEY,
    DOCKET_STATIC_COUNTS,
    DOCKETS_UNDER_REVIEW_QUERY_KEY,
    DOCKET_UPDATES_QUERY_KEY,
    DOCKET_UPDATE_REQUEST_QUERY_KEY,
    PROBLEM_QUERY_KEY,
    RFO_QUERY_KEY,
} from "./query_keys";
import axios from "axios";

// Get Dockets List (OLD)
const getDockets = async (key) => {
    const param = key.queryKey[1].param;
    const defaultParam = key.queryKey[1].defaultParam;
    try {
        const { accessToken } = getTokens();
        if (param) {
            const res = await fetchJson(
                `${API_HOST_URL}/${endpoints.docket.list}${param}`,
                {
                    headers: { Authorization: `Bearer ${accessToken}` },
                },
                true,
            );
            return await res.json();
        }
        const res = await fetchJson(
            `${API_HOST_URL}/${endpoints.docket.list}${defaultParam ? defaultParam : "?limit=50"}`,
            {
                headers: { Authorization: `Bearer ${accessToken}` },
            },
            true,
        );
        return await res.json();
    } catch (err) {
        return {
            success: false,
            detail: err,
        };
    }
};

export function useDocketList(defaultParam = null, enabled = true) {
    const [param, setParam] = useState(null);
    const { isLoading, data } = useQuery(
        [DOCKETS_QUERY_KEY, { param: param, defaultParam }],
        getDockets,
        {
            cacheTime: Infinity,
            staleTime: 30_000,
            enabled: enabled,
        },
    );
    return {
        docketsData: data,
        docketsIsLoading: isLoading,
        handleParam: async (value) => setParam(value),
    };
}

// Get Dockets List (Lite)
const getDocketsLite = async (key) => {
    const param = key.queryKey[1].param ?? null;
    const defaultParam = key.queryKey[1].defaultParam;
    try {
        const { accessToken } = getTokens();
        if (param) {
            const res = await fetchJson(`${API_HOST_URL}/${endpoints.docket.list_lite}${param}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            }, true)
            return await res.json()
        } else if (defaultParam) {
            const res = await fetchJson(`${API_HOST_URL}/${endpoints.docket.list_lite}${defaultParam}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            }, true)
            return await res.json()
        } else {
            const res = await fetchJson(`${API_HOST_URL}/${endpoints.docket.list_lite}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            }, true)
            return await res.json()
        }

    } catch (err) {
        return {
            success: false,
            detail: err
        };
    }
}

export function useDocketListLite(defaultParam = null, enabled = true) {
    const [param, setParam] = useState(null);
    const { isLoading, data } = useQuery([DOCKETS_QUERY_KEY, { param: param, defaultParam }], getDocketsLite, {
        // cacheTime: 0,
        // staleTime: 0,
        cacheTime: Infinity,
        staleTime: 30_000, // ms
        enabled: enabled,
        // refetchOnMount: true, // ms
    })
    return {
        docketsData: data,
        docketsIsLoading: isLoading,
        handleParam: async (value) => setParam(value),
    }
}

// Dockets Under Review
export function useDocketUnderReviewList(defaultParam = "", enabled = true) {
    const [param, setParam] = useState(null);
    const { isLoading, data } = useQuery(
        [DOCKETS_UNDER_REVIEW_QUERY_KEY, { param: param, defaultParam }],
        async (key) => {
            const param = key.queryKey[1].param;
            const defaultParam = key.queryKey[1].defaultParam;
            try {
                const { accessToken } = getTokens();
                const param_url = param ? param : defaultParam;
                const res = await fetchJson(
                    `${API_HOST_URL}/${endpoints.docket.docket_under_review_list}${param_url}`,
                    {
                        headers: { Authorization: `Bearer ${accessToken}` },
                    },
                    true,
                );
                return await res.json();
            } catch (err) {
                return { success: false, detail: err };
            }
        },
        {
            // cacheTime: Infinity,
            // staleTime: 30_000,
            refetchOnWindowFocus: true,
            cacheTime: 0,
            staleTime: 1,
            enabled: enabled,
        },
    );
    return {
        docketsUnderReviewData: data,
        docketsUnderReviewIsLoading: isLoading,
        handleParam: async (value) => setParam(value),
    };
}

// Get Docket Detail
export function useDocketDetail(id) {
    const { accessToken } = getTokens();
    const { isLoading, isError, data, status } = useQuery(
        [DOCKET_QUERY_KEY],
        async () => {
            try {
                const res = await fetchJson(
                    `${API_HOST_URL}/${endpoints.docket.list}/${id}`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${accessToken}`,
                        },
                    },
                    true,
                );
                const data = await res.json();
                return data;
            } catch (err) {
                return {
                    success: false,
                };
            }
        },
        {
            cacheTime: 0,
            staleTime: 1,
            // cacheTime: Infinity,
            // staleTime: 30_000, // ms
            retry: 3,
            refetchOnMount: true, // ms
        },
    );
    return { docketData: data, docketIsLoading: isLoading, isError, status };
}



export function useDocketDetailHook(defaultParam = '', enabled = true) {
    const [param, setParam] = useState(null);
    const { isLoading, data } = useQuery([DOCKET_QUERY_KEY, { param: param, defaultParam }], async (key) => {
        const param = key.queryKey[1].param;
        const defaultParam = key.queryKey[1].defaultParam;
        try {
            const { accessToken } = getTokens();
            const param_url = param ? param : defaultParam;
            const res = await fetchJson(`${API_HOST_URL}/${endpoints.docket.detail}${param_url}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            }, true)
            return await res.json()

        } catch (err) {
            return { success: false, detail: err };
        }
    }, {
        // cacheTime: Infinity,
        // staleTime: 30_000,
        cacheTime: 10,
        staleTime: 10,
        refetchOnWindowFocus: true,
        refetchOnMount: true,
        enabled: enabled,
    })
    return {
        docketData: data,
        docketIsLoading: isLoading,
        filterList: async (value) => setParam(value),
    }
}

// Save / Update Docket
export function useDocket(method) {
    const { accessToken } = getTokens();
    const queryClient = useQueryClient();
    const mutation = useMutation(
        (data) =>
            fetchJson(
                `${API_HOST_URL}/${endpoints.docket.list}${method === "PATCH" ? "/" + data.id : ""}`,
                {
                    method: method,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify(data),
                },
                true,
            ),
        {
            retry: 0,
        },
    );
    return {
        handleDocketAction: async (data) => {
            try {
                const res = await mutation.mutateAsync(data);
                const resData = await res.json();
                if (resData.success) {
                    if (method === "PATCH") {
                        await queryClient.invalidateQueries([DOCKET_QUERY_KEY])
                        await queryClient.invalidateQueries([DOCKETS_UNDER_REVIEW_QUERY_KEY]);
                    } else {
                        await queryClient.invalidateQueries([DOCKETS_QUERY_KEY])
                    }

                    //   await queryClient.invalidateQueries([CLOSED_DOCKETS_QUERY_KEY]);
                    //   await queryClient.invalidateQueries([DOCKET_STATIC_COUNTS]);
                    await queryClient.invalidateQueries([DOCKETS_UNDER_REVIEW_QUERY_KEY]);
                }
                return resData;
            } catch (err) {
                return {
                    success: false,
                    detail: err,
                };
            }
        },

        docketActionIsLoading: mutation.isLoading,
    };
}

// Docket Update Axios [File Upload]
export function useDocketUpdate() {
    // Used on Status change file upload
    const { accessToken } = getTokens();
    const queryClient = useQueryClient();
    const mutation = useMutation(
        ({ data, id }) =>
            axios.patch(`${API_HOST_URL}/${endpoints.docket.list}/${id}`, data, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                onUploadProgress: (e) => {
                    // let percent = Math.round((100 * e.loaded) / e.total)
                    // console.log('Process: ', percent, e)
                },
            }),
        {
            retry: 2,
        },
    );
    return {
        handleDocketUpdate: async (data, id) => {
            try {
                const res = await mutation.mutateAsync({ data, id });
                if (res.status === 200) {
                    await queryClient.invalidateQueries([DOCKETS_QUERY_KEY]);
                    await queryClient.invalidateQueries([DOCKET_QUERY_KEY]);
                    await queryClient.invalidateQueries([DOCKET_STATIC_COUNTS]);
                }
                return res.data;
            } catch (err) {
                return {
                    success: false,
                    detail: err,
                };
            }
        },
        isDocketUpdating: mutation.isLoading,
    };
}

// Docket Update Axios  Simple (Team Leader Change)
export function useSimpleDocketUpdate() {
    // Used on Docket TL Change Modal
    const { accessToken } = getTokens();
    const queryClient = useQueryClient();
    const mutation = useMutation(
        ({ data, id }) =>
            axios.patch(
                `${API_HOST_URL}/${endpoints.docket.simple_change}/${id}`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                    onUploadProgress: (e) => {
                        // let percent = Math.round((100 * e.loaded) / e.total)
                        // console.log('Process: ', percent, e)
                    },
                },
            ),
        {
            retry: 2,
        },
    );
    return {
        handleDocketUpdate: async (data, id) => {
            try {
                const res = await mutation.mutateAsync({ data, id });
                if (res.status === 200) {
                    await queryClient.invalidateQueries([DOCKETS_QUERY_KEY]);
                    await queryClient.invalidateQueries([DOCKET_QUERY_KEY]);
                    await queryClient.invalidateQueries([DOCKET_STATIC_COUNTS]);
                    await queryClient.invalidateQueries([DOCKETS_UNDER_REVIEW_QUERY_KEY]);
                }
                return res.data;
            } catch (err) {
                return {
                    success: false,
                    detail: err,
                };
            }
        },
        isDocketUpdating: mutation.isLoading,
    };
}

// Get ClosedDockets List
const getClosedDockets = async (key) => {
    const param = key.queryKey[1].param;
    const defaultParam = key.queryKey[1].defaultParam;
    try {
        const { accessToken } = getTokens();
        if (param) {
            const res = await fetchJson(
                `${API_HOST_URL}/${endpoints.docket.closed_list}${param}`,
                {
                    headers: { Authorization: `Bearer ${accessToken}` },
                },
                true,
            );
            return await res.json();
        }
        const res = await fetchJson(
            `${API_HOST_URL}/${endpoints.docket.closed_list}${defaultParam ? defaultParam : ""}`,
            {
                headers: { Authorization: `Bearer ${accessToken}` },
            },
            true,
        );
        return await res.json();
    } catch (err) {
        return {
            success: false,
            detail: err,
        };
    }
};
export function useClosedDocketList(defaultParam = null, enabled = true) {
    const [param, setParam] = useState(null);
    const { isLoading, data } = useQuery(
        [CLOSED_DOCKETS_QUERY_KEY, { param: param, defaultParam }],
        getClosedDockets,
        {
            // cacheTime: Infinity,
            // staleTime: 30_000, // ms
            cacheTime: 0,
            staleTime: 1,
            refetchOnWindowFocus: true,
            enabled: enabled,
        },
    );
    return {
        closedDocketsData: data,
        closedDocketsIsLoading: isLoading,
        handleParam: async (value) => setParam(value),
    };
}

// Save / Update Close Docket
export function useCloseDocket(method) {
    const { accessToken } = getTokens();
    const queryClient = useQueryClient();
    const mutation = useMutation(
        (data) =>
            fetchJson(
                `${API_HOST_URL}/${endpoints.docket.closed_list}${method === "PATCH" ? "/" + data.id : ""}`,
                {
                    method: method,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify(data),
                },
                true,
            ),
        {
            retry: 2,
        },
    );
    return {
        handleDocketCloseAction: async (data) => {
            try {
                const res = await mutation.mutateAsync(data);
                const resData = await res.json();
                if (resData.success) {
                    await queryClient.invalidateQueries([DOCKETS_QUERY_KEY]);
                    await queryClient.invalidateQueries([DOCKET_QUERY_KEY]);
                    await queryClient.invalidateQueries([DOCKET_STATIC_COUNTS]);
                    await queryClient.invalidateQueries([CLOSED_DOCKETS_QUERY_KEY]);
                }
                return resData;
            } catch (err) {
                return {
                    success: false,
                    detail: err,
                };
            }
        },
        docketCloseActionIsLoading: mutation.isLoading,
    };
}

// Get RFO List
export function useRFOList() {
    const { isLoading, data } = useQuery(
        [RFO_QUERY_KEY],
        async () => {
            try {
                const { accessToken } = getTokens();
                const res = await fetchJson(
                    `${API_HOST_URL}/${endpoints.docket.rfo_list}`,
                    {
                        headers: { Authorization: `Bearer ${accessToken}` },
                    },
                    true,
                );
                return await res.json();
            } catch (err) {
                return {
                    success: false,
                    detail: err,
                };
            }
        },
        {
            cacheTime: Infinity,
            staleTime: 30_000, // ms
            // cacheTime: 0,
            // staleTime: 1,
        },
    );
    return { rfosData: data, rfosIsLoading: isLoading };
}
// Get Problems List
export function useProblemsList() {
    const { isLoading, data } = useQuery(
        [PROBLEM_QUERY_KEY],
        async () => {
            try {
                const { accessToken } = getTokens();
                const res = await fetchJson(
                    `${API_HOST_URL}/${endpoints.docket.problems}`,
                    {
                        headers: { Authorization: `Bearer ${accessToken}` },
                    },
                    true,
                );
                return await res.json();
            } catch (err) {
                return {
                    success: false,
                    detail: err,
                };
            }
        },
        {
            cacheTime: Infinity,
            staleTime: 30_000, // ms
            // cacheTime: 0,
            // staleTime: 1,
        },
    );
    return { problemsData: data, problemsIsLoading: isLoading };
}

// Save / Update Maintenance Docket
export function useMaintenanceDocket(method) {
    const { accessToken } = getTokens();
    const queryClient = useQueryClient();
    const mutation = useMutation(
        (data) =>
            fetchJson(
                `${API_HOST_URL}/${endpoints.docket.maintenance_list}${method === "PATCH" ? "/" + data.id : ""}`,
                {
                    method: method,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify({ ...data, groups: [data.groups] }),
                },
                true,
            ),
        {
            retry: 2,
        },
    );
    return {
        handleDocketAction: async (data) => {
            try {
                const res = await mutation.mutateAsync(data);
                const resData = await res.json();
                if (resData.success) {
                    await queryClient.invalidateQueries([DOCKETS_QUERY_KEY]);
                }
                return resData;
            } catch (err) {
                return {
                    success: false,
                    detail: err,
                };
            }
        },

        docketActionIsLoading: mutation.isLoading,
    };
}

export function useDocketUpdatesList(defaultParam = "", enabled = true) {
    const [param, setParam] = useState(null);
    var queryClient = useQueryClient();
    const { isLoading, data } = useQuery(
        [DOCKET_UPDATES_QUERY_KEY, { param: param, defaultParam }],
        async (key) => {
            const param = key.queryKey[1].param;
            const defaultParam = key.queryKey[1].defaultParam;
            try {
                const { accessToken } = getTokens();
                const param_url = param ? param : defaultParam;
                const res = await fetchJson(
                    `${API_HOST_URL}/${endpoints.docket.updates}${param_url}`,
                    {
                        headers: { Authorization: `Bearer ${accessToken}` },
                    },
                    true,
                );
                return await res.json();
            } catch (err) {
                return { success: false, detail: err };
            }
        },
        {
            cacheTime: Infinity,
            staleTime: 30_000,
            enabled: enabled,
        },
    );
    return {
        docketUpdatesData: data,
        docketUpdatesIsLoading: isLoading,
        filterUpdatesList: async (value) => setParam(value),
        retriveUpdateData: async (value) => {
            // const queryClient = useQueryClient();
            try {
                const { accessToken } = getTokens();
                const res = await fetchJson(
                    `${API_HOST_URL}/${endpoints.docket.updates}/${value}`,
                    {
                        headers: { Authorization: `Bearer ${accessToken}` },
                    },
                    true,
                );
                const resData = await res.json();
                if (resData.success) {
                    await queryClient.invalidateQueries([DOCKET_UPDATES_QUERY_KEY]);
                }
                return resData;
            } catch (err) {
                return { success: false, detail: err };
            }
        },
    };
}

export function useDocketUpdateRequestList(defaultParam = "", enabled = true) {
    const [param, setParam] = useState(null);
    const { isLoading, data } = useQuery(
        [DOCKET_UPDATE_REQUEST_QUERY_KEY, { param: param, defaultParam }],
        async (key) => {
            const param = key.queryKey[1].param;
            const defaultParam = key.queryKey[1].defaultParam;
            try {
                const { accessToken } = getTokens();
                const param_url = param ? param : defaultParam;
                const res = await fetchJson(
                    `${API_HOST_URL}/${endpoints.docket.update_request}${param_url}`,
                    {
                        headers: { Authorization: `Bearer ${accessToken}` },
                    },
                    true,
                );
                return await res.json();
            } catch (err) {
                return { success: false, detail: err };
            }
        },
        {
            cacheTime: Infinity,
            staleTime: 30_000,
            enabled: enabled,
        },
    );
    return {
        updateRequestsData: data,
        updateRequestsIsLoading: isLoading,
        filterUpdateRequest: async (value) => setParam(value),
    };
}

export function useDocketUpdateRequest(method) {
    const { accessToken } = getTokens();
    const queryClient = useQueryClient();
    const mutation = useMutation(
        (data) =>
            fetchJson(
                `${API_HOST_URL}/${endpoints.docket.update_request}${method === "PATCH" ? "/" + data.id : ""}`,
                {
                    method: method,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify(data),
                },
                true,
            ),
        {
            retry: 0,
        },
    );
    return {
        handleDocketRequestAction: async (data) => {
            try {
                const res = await mutation.mutateAsync(data);
                const resData = await res.json();
                if (resData.success) {
                    await queryClient.invalidateQueries([CLOSED_DOCKETS_QUERY_KEY]);
                    await queryClient.invalidateQueries([
                        DOCKET_UPDATE_REQUEST_QUERY_KEY,
                    ]);
                }
                return resData;
            } catch (err) {
                return {
                    success: false,
                    detail: err,
                };
            }
        },

        docketRequestIsLoading: mutation.isLoading,
    };
}

// Mark all docket notifications as read
export function useDocketNotifications() {
    const { accessToken } = getTokens();
    const queryClient = useQueryClient();
    const mutation = useMutation(
        (data) =>
            fetchJson(
                `${API_HOST_URL}/${endpoints.docket.mark_all_as_read}`,
                {
                    method: "post",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify(data),
                },
                true,
            ),
        {
            retry: 0,
        },
    );
    return {
        handleMarkAllAsRead: async (data) => {
            try {
                const res = await mutation.mutateAsync(data);
                const resData = await res.json();
                if (resData.success) {
                    await queryClient.invalidateQueries([DOCKET_UPDATES_QUERY_KEY]);
                    // await queryClient.invalidateQueries([DOCKET_UPDATE_REQUEST_QUERY_KEY])
                }
                return resData;
            } catch (err) {
                return {
                    success: false,
                    detail: err,
                };
            }
        },

        markingIsLoading: mutation.isLoading,
    };
}

// Docket Hold Request Axios [File Upload]
export function useDocketHoldRequest() {
    // Used on Status change file upload
    const { accessToken } = getTokens();
    const queryClient = useQueryClient();
    const mutation = useMutation(
        ({ data, id }) =>
            axios.post(`${API_HOST_URL}/${endpoints.docket.update_request}`, data, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                onUploadProgress: (e) => {
                    // let percent = Math.round((100 * e.loaded) / e.total)
                    // console.log('Process: ', percent, e)
                },
            }),
        {
            retry: 2,
        },
    );
    return {
        handleDocketHoldReq: async (data, id) => {
            try {
                const res = await mutation.mutateAsync({ data, id });
                if (res.status === 201) {
                    await queryClient.invalidateQueries([
                        DOCKET_UPDATE_REQUEST_QUERY_KEY,
                    ]);
                }
                return res.data;
            } catch (err) {
                console.log("Err -> ", err);
                return {
                    success: false,
                    detail: "Already received request for this docket.",
                };
            }
        },
        isRequestUpdating: mutation.isLoading,
    };
}
