import React, { useContext } from 'react'
import Modal from 'react-modal'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
// import DocketRequestForm from './Forms/DocketRequestForm';
import { useDocketRequest } from '../../hooks/lco/dockets';
import MaintDocketRequestForm from '../Forms/MaintDocketRequestForm';
import coreContext from '../../context/core/coreContext';
import providerContext from '../../context/provider/providerContext';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '700px',
    },
    overlay: { zIndex: 1000 }
};
export default function MaintDocketRequestModal({ modal, setModal, currentData, setCurrentData }) {
    // let navigate = useNavigate();
    const { handleDocketRequestAction, docketRequestIsLoading } = useDocketRequest('POST')
    const { currentProvider } = useContext(providerContext);
    const handleDocketRequest = async (values, { resetForm }) => {
        const res = await handleDocketRequestAction({ ...values, provider: currentProvider?.id })
        // console.log(res)
        if (res.success) {
            toast.success(res.detail)
            resetForm({ values: '' });
            setModal(false)
        } else {
            toast.error(res.detail.replace("detail:", ""))
        }
        // res.success ? toast.success(res.detail) : toast.error(res.detail.replace("detail:", ""));
        // setModal(false)
        // console.log(values)
    }

    return (
        <Modal
            isOpen={modal}
            // onAfterOpen={afterOpenModal}
            // onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Docket Request Modal"
        >
            <div className="container-fluid">

                <div className="d-flex justify-content-between">
                    <h5 className="modal-title text-center text-info">New Maint. Docket Request!</h5>
                    <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setModal(false); setCurrentData(null); }}></button>
                </div>
                <div className="modal-body" style={{ width: '100%' }}>

                    <MaintDocketRequestForm
                        setModal={setModal}
                        isLoading={docketRequestIsLoading}
                        handleSubmit={handleDocketRequest}
                    />
                </div>
            </div>
        </Modal>
    )
}
