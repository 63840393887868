import React, { useState } from 'react'
import { Link } from 'react-router-dom'
// import { PROJECT_TITLE } from '../../config'
import { useResetPassword, useSendOTP } from '../../hooks/user';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import SendOTPForm from '../../components/Forms/UserForm/ForgotPaswordForm';
import ResetPaswordForm from '../../components/Forms/UserForm/ResetPaswordForm';


export default function ForgotPasswordPage() {
    let navigate = useNavigate();
    const { handleSendOTP, isOTPSending } = useSendOTP();
    const { handleSetPassword, isLoadingPassword } = useResetPassword();
    const [isOtpSent, setOtpSent] = useState(false)
    const [mobile, setMobile] = useState(null)

    const handleOTPSendSubmit = async (values, { resetForm }) => {
        const res = await handleSendOTP(values);
        if (res.success) {
            setOtpSent(true)
            setMobile(res.mobile)
            toast.success(res.detail)
        } else {
            toast.error(res.detail);
        }
    }
    const handleSetPasswordSubmit = async (values, { resetForm }) => {
        const res = await handleSetPassword(values);
        console.log(res)
        if (res.success) {
            setOtpSent(true)
            toast.success(res.detail)
            navigate(`/account/login`);
        } else {
            toast.error(res.detail);
        }
    }
    return (
        <>
            <Helmet>
                <title>Forgot Password</title>
            </Helmet>
            {/* <div className="app app-auth-sign-in align-content-stretch d-flex flex-wrap justify-content-end">
                <div className="app-auth-background" style={{ background: 'url(/assets/images/backgrounds/sign-in.svg) center center / 60% no-repeat #e8ecf8', height: '100vh' }}>

                </div>
                <div className="app-auth-container">
                    <div className="logo">
                        <Link to='/'>{PROJECT_TITLE}</Link>
                    </div>
                    <p className="auth-description">Reset password using OTP.</p>
                    {!isOtpSent && <SendOTPForm
                        isLoading={isOTPSending}
                        handleSubmit={handleOTPSendSubmit}
                        btnLabel='Reset Password'
                    />}
                    {isOtpSent && <ResetPaswordForm
                        isLoading={isLoadingPassword}
                        handleSubmit={handleSetPasswordSubmit}
                        btnLabel='Set Password'
                        mobile={mobile}
                        setOtpSent={setOtpSent}
                    />}

                    <div className="divider"></div>
                    <div className="auth-alts">
                    <a href="#" className="auth-alts-google"></a>
                    <a href="#" className="auth-alts-facebook"></a>
                    <a href="#" className="auth-alts-twitter"></a>
                </div>
                </div>
            </div> */}

            <div className="login-7">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="form-section">
                                <div className="logo">
                                    <Link to="/">
                                      <img src="/assets/images/logo.png" alt="logo" className='img-fluid' />  
                                    </Link>
                                </div>
                                <h3>Reset password using OTP.</h3>
                                <div className="login-inner-form">
                                    {!isOtpSent && <SendOTPForm
                                        isLoading={isOTPSending}
                                        handleSubmit={handleOTPSendSubmit}
                                        btnLabel='Reset Password'
                                    />}
                                    {isOtpSent && <ResetPaswordForm
                                        isLoading={isLoadingPassword}
                                        handleSubmit={handleSetPasswordSubmit}
                                        btnLabel='Set Password'
                                        mobile={mobile}
                                        setOtpSent={setOtpSent}
                                    />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
