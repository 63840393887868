import React, { useState } from 'react'
import Modal from 'react-modal'
import { useUser } from '../../hooks/user';
import { formatDateTime } from '../../utils/shortcuts';
import { useNavigate } from 'react-router-dom';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '700px',
    },
    overlay: { zIndex: 1000 }
};
export default function MyAccountModal({ modal, setModal }) {
    let navigate = useNavigate();
    const { user } = useUser();
    return (
        <Modal
            isOpen={modal}
            style={customStyles}
            contentLabel="My account modal"
        >
            <div className="container-fluid">

                <div className="d-flex justify-content-between">
                    <h5 className="modal-title mb-3 text-center text-info">My Account</h5>
                    <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={() => setModal(false)}></button>
                </div>
                <div className="modal-body" style={{ width: '100%' }}>
                    <h3>{user?.account_name}</h3>
                    <hr />
                    <p>Role: {user?.user_role}</p>
                    <p>Email: {user?.email ?? 'Not updated'} {user?.is_email_verified ? <span className="material-icons text-success"> check_circle_outline</span> : <span className="material-icons text-danger"> highlight_off</span>}</p>
                    <p>Username: {user?.username}</p>
                    <p>Mobile: {user?.mobile ?? 'Not updated'} {user?.is_mobile_verified ? <span className="material-icons text-success"> check_circle_outline</span> : <span className="material-icons text-danger"> highlight_off</span>}</p>
                    <p>Last Login: {formatDateTime(user?.last_login, 'MMMM Do YYYY, h:mm a') ?? 'Not logged'}</p>
                    <button onClick={() => {
                        setModal(false)
                        navigate(`/account/me`);
                    }} className='btn btn-primary'>My account</button>
                </div>
            </div>
        </Modal>
    )
}
