import React from 'react';
import DataTable from 'react-data-table-component';
import Checkbox from '@mui/material/Checkbox';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const sortIcon = <KeyboardArrowDownIcon />;
const selectProps = { indeterminate: isIndeterminate => isIndeterminate };


const customStyles = {
    rows: {
        style: {
            minHeight: '100px', // override the row height
            fontSize: '14px',
            padding: '5px'

        },
        stripedStyle: {
            color: "#fff",
            backgroundColor: "#ff6347"
        }
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            backgroundColor: '#2269f4',
            color: '#f1f1f1',
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};
function DataTableBase(props) {
    return (
        <DataTable
            pagination
            selectableRowsComponent={Checkbox}
            selectableRowsComponentProps={selectProps}
            sortIcon={sortIcon}
            
            highlightOnHover
		    pointerOnHover
            dense
            {...props}
            customStyles={customStyles}
            fixedHeader
            fixedHeaderScrollHeight="70vh"
            paginationRowsPerPageOptions={[20, 50, 100, 200, 500, 1000, 5000]}
            defaultPageSize={20}
            
        />
    );
}

export default DataTableBase;