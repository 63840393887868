export const SMS_TEMPLATES = [
    { label: 'NAME & ADDRESS TEMPLATE', value: 'You are our valuable customer, Name:{#var#}{#var#}, Address:{#var#}{#var#} with longitude & Latitude {#var#} From Meghbela Broadband.', content_id: '1707169961702047230' },
    { label: 'NAME & NO. TEMPLATE', value: 'You are our valuable customer {#var#},this is your your Docket No. {#var#}; against your call received from this Phone no.{#var#}From Meghbela Broadband.', content_id: '1707169925410644516' },
    { label: 'PENDING TEMPLATE', value: 'Alert Pending Docket Created Date & Time : {#var#} Docket No : {#var#} From Meghbela Broadband', content_id: '1707169761606387734'},
    { label: 'HOLD TEMPLATE', value: 'Alert Hold Docket Created Date & Time : {#var#} Docket No : {#var#} From Meghbela Broadband', content_id: '1707169761616691763' },
    { label: 'CONFIRM TEMPLATE', value: "Dear {#var#} \nDocket No : {#var#} Please check & confirm the present link's status. call 033-40291140.Meghbela Broadband.", content_id: '1707170315710307261' },


    // TEST TEMPLATES
    // { label: 'Docket complaint resolved', value: "Your Docket No : {#var#} Closing Date & Time : {#var#} has been Resolved now, From Meghbela Broadband.", content_id: '1707170063972556095' },
    // { label: 'Alert Pending Docket Created by server', value: "Alert Pending Docket Created Date & Time {#var#} Docket No : {#var#} Docet Owner {#var#}.From Meghbela Broadband", content_id: '1707170322412029249' },
    // { label: 'DOCKETED BY TEMPLATE', value: "DOCKET NO. :{#var#} \nLOCATION A :{#var#} \nLOCATION B :{#var#} \nPROBLEM :{#var#}{#var#}  \nDOCKET DATE &TIME :{#var#} \nMeghbela", content_id: '1707168076286357246' },
    // { label: 'FIBER TL & TECHNICIAN TEPLMATE', value: "DOCKET NO. :{#var#} \nLOCATION A :{#var#} \nLOCATION B :{#var#} \nPROBLEM :{#var#}{#var#} \nDOCKET DATE &TIME :{#var#} \nDOCKETED BY NAME & NO. :{#var#}{#var#} \nTECHNICIAN NAME & NO. :{#var#}{#var#} \nMeghbela", content_id: '1707168076287947228' },
]
