import React from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Button, Typography } from '@mui/material';
import { useLCOProblemsList } from '../../../hooks/lco/dockets';
import SelectField from '../../Common/Fields/SelectField';
import { formatSelectOptionsList } from '../../../hooks/circuit';
import TextField from '../../Common/Fields/TextField';


export default function CommentForm({ isLoading, handleSubmit, setModal, btnLabel = 'Raise Docket' }) {
    const { problemsData, problemsIsLoading } = useLCOProblemsList();
    const validate = Yup.object({
       
        comment: Yup.string().required('Reason is required.'),
    })
    return (
        <Formik
            initialValues={{
                problem: '',
                comment: '',
            }}
            validationSchema={validate}
            onSubmit={handleSubmit}
        >
            {formik => (

                <Form>
                    <div className='row'>
                        <div className="col-md-12 mb-4">
                            <TextField label='Comment *' type="text" name='comment' placeholder='Reason for Cancel' sx={{ mt: 2 }} />
                        </div>
                        {/* {console.log(formik.values.problem)} */}
                        {/* {formik.submitCount >= 1 && formik.errors && Object.entries(formik.errors).map(([key, value]) =>
                            <Alert severity="error" sx={{ mt: 1, mb: 1 }} key={key}>{value}</Alert>
                        )} */}
                    </div>
                    <LoadingButton
                        loading={isLoading}
                        type="submit"
                        variant="contained"
                        disableElevation
                    >
                        {btnLabel}
                    </LoadingButton>
                    <Button variant="outlined" sx={{ ml: 1 }} disableElevation onClick={() => setModal(false)}>Close</Button>
                </Form>
            )}
        </Formik>
    )
}
