import React from 'react'
import Modal from 'react-modal'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDocket, useDocketHoldRequest, useDocketUpdate } from '../../hooks/docket';
import DocketUpdateForm from '../Forms/DocketUpdateForm';
import DocketHoldRequestForm from '../Forms/DocketHoldRequestForm';
import DocketCancelRequestForm from '../Forms/DocketCancelRequestForm';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '700px',
    },
    overlay: { zIndex: 1000 }
};
export default function DocketCancelRequestModal({ modal, setModal, currentDocketData, setCurrentDocketData }) {
    let navigate = useNavigate();
    // const { handleDocketAction, docketActionIsLoading } = useDocket('PATCH');
    // const { handleDocketUpdate, isDocketUpdating } = useDocketUpdate(); // Using for File Uploading (Axios)
    const { handleDocketHoldReq, isRequestUpdating } = useDocketHoldRequest(); // Using for File Uploading (Axios)
    // console.log('currentDocketData', currentDocketData)

    const handleSubmit = async (values, { resetForm }) => {
        const { file } = values

        const formData = new FormData();
        file && formData.append("file", file[0]);
        for (let key in values) {
            if (key === 'file') { continue; }
            formData.append(key, `${values[key]}`);
        }

        formData.append("status", 'Pending');
        formData.append("docket", currentDocketData.id);
        formData.append("update_type", 'Cancel');
        // const res = await handleDocketUpdate(formData, currentDocketData.code)
        const res = await handleDocketHoldReq(formData, currentDocketData.code)
        // console.log('save res', res)
        if (res.success) {
            toast.success(`${currentDocketData?.code} - Cancel request has sent.`);
            setModal(false)
            setCurrentDocketData({});
        } else {
            toast.error(res.detail)
        }
        // console.log('data --> ', res);
    }
    return (
        <Modal
            isOpen={modal}
            // onAfterOpen={afterOpenModal}
            // onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <div className="container-fluid">

                <div className="d-flex justify-content-between">
                    <h5 className="modal-title mb-3 text-info">Create cancel request for [{currentDocketData?.code}] {currentDocketData.alt_status === 'Waiting for Confirmation' && <code>Waiting for Confirmation</code>} </h5>
                    <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setModal(false); setCurrentDocketData({}); }}></button>
                </div>
                <div className="modal-body" style={{ width: '100%' }}>

                    <DocketCancelRequestForm
                        isLoading={isRequestUpdating}
                        handleSubmit={handleSubmit}
                        data={currentDocketData}
                        setModal={setModal}
                    />
                </div>
            </div>
        </Modal>
    )
}
