import React from 'react'
import TextField from '../../Common/Fields/TextField';
import { HMSToSeconds, minusTAT, secondsToHMS } from '../../../utils';
// import MaskField from '../../Common/Fields/MaskField';
import DocketHoldTable from '../../Docket/DocketHoldTable';

export default function TatCalc({ formik, disabled, data }) {
    // console.log('formik data', formik.values)
    // console.log('Data ', data)
    const renderTotalComp = (hData) => {
        // console.log('renderTotalComp', hData)
        var durationTotal = hData.reduce((accum, item) => accum + parseFloat(item.duration), 0)
        return <div className='col-12'>

            <h6>Hold: {secondsToHMS(durationTotal)}</h6>

            <h6 className='text-warning'>Customer Access Issue: - {`${String(formik.values.ch_hh).padStart(2, 0) ?? '00'}:${String(formik.values.ch_mm).padStart(2, 0) ?? '00'}:${String(formik.values.ch_ss).padStart(2, 0) ?? '00'}`} &emsp; - &emsp; Others Issue: - {`${String(formik.values.oh_hh).padStart(2, 0) ?? '00'}:${String(formik.values.oh_mm).padStart(2, 0) ?? '00'}:${String(formik.values.oh_ss).padStart(2, 0) ?? '00'}`}</h6>
            <hr />
            <h6 className='text-dark'>Total TAT: {secondsToHMS(data?.docket?.get_total_tat)} &emsp; - &emsp; Actual TAT: {secondsToHMS(data?.docket?.get_actual_tat)}
            </h6>
            {/* <h6 className='text-dark'>Actual TAT: {minusTAT(secondsToHMS(data?.docket?.get_actual_tat, formik.values.ch_hh || 0, formik.values.ch_mm || 0, formik.values.ch_ss || 0))}</h6> */}


            {/* <hr /> */}
            <h6 className='text-primary'>After Deduction: {secondsToHMS((data?.docket?.get_total_tat - HMSToSeconds(`${formik.values.ch_hh | 0}:${formik.values.ch_mm | 0}:${formik.values.ch_ss | 0}`) - HMSToSeconds(`${formik.values.oh_hh | 0}:${formik.values.oh_mm | 0}:${formik.values.oh_ss | 0}`)).toFixed(2))}</h6>


        </div>
    }
    return (
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className='col-12'>
                        <h3 className='text-center display-6 mb-4 text-primary' style={{ 'fontSize': '22px' }}>TAT CALCULATOR</h3>
                    </div>
                    <DocketHoldTable data={data} />
                    <hr />
                    {data.docket_hold_data && renderTotalComp(data.docket_hold_data)}
                    <hr />

                    <div className="col-md-6">
                        {/* <TextField label='Customer Hold' type="number" name='customer_hold' placeholder='HH:MM:SS' sx={{ mt: 2.8 }} /> */}
                        {/* <MaskField label='Customer Hold (HH:MM:SS)' name='customer_hold' sx={{ mt: 2.8 }} /> */}
                        <div className='row'>
                            <label className='mt-3 text-muted'>Customer Access Issue (HH:MM:SS)</label>
                            <div className='col-3'>
                                <TextField label='HH' type="number" name='ch_hh' placeholder='HH' />
                            </div>
                            <div className='col-3'>
                                <TextField label='MM' type="number" name='ch_mm' placeholder='MM' />
                            </div>
                            <div className='col-3'>
                                <TextField label='SS' type="number" name='ch_ss' placeholder='SS' />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        {/* <TextField label='Others Hold' type="number" name='others_hold' placeholder='HH:MM:SS' sx={{ mt: 2.8 }} /> */}
                        {/* <MaskField label='Others Hold (HH:MM:SS)' name='others_hold' sx={{ mt: 2.8 }} /> */}
                        <div className='row'>
                            <label className='mt-3 text-muted'>Others Issue</label>
                            <div className='col-3'>
                                <TextField label='HH' type="number" name='oh_hh' placeholder='HH' />
                            </div>
                            <div className='col-3'>
                                <TextField label='MM' type="number" name='oh_mm' placeholder='MM' />
                            </div>
                            <div className='col-3'>
                                <TextField label='SS' type="number" name='oh_ss' placeholder='SS' />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <TextField label='Others Hold Reson' name='others_hold_reason' placeholder='Reason' sx={{ mt: 2.8 }} />
                    </div>
                </div>
            </div>
        </div>

    )
}
