/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import PageBreadcrumbs from '../../components/Common/Breadcrumbs';
import { useCloseDocket, useClosedDocketList } from '../../hooks/docket';
import { Grid } from '@mui/material';
import Loader from '../../components/Common/Loader';
import CloseDocketInfo from '../../components/Docket/CloseDocketInfo';
import Alert from '../../components/Common/Alert';
import { formatDateTime } from '../../utils/shortcuts';
import { toast } from 'react-toastify';
import DocketInfoModal from '../../components/Models/DocketInfoModal';
import { useUser } from '../../hooks/user';
import DocketCloseForm from '../../components/Forms/DocketCloseUpdateForm';

export default function DocketMaterialUpdate() {
    const { user } = useUser();
    const { id } = useParams();
    let navigate = useNavigate();
    const [apiEnable, setApiEnable] = useState(false)
    const { handleDocketCloseAction, docketCloseActionIsLoading } = useCloseDocket('PATCH');
    const { closedDocketsData, closedDocketsIsLoading, handleParam } = useClosedDocketList(null, apiEnable);
    const [modal, setInfoModal] = useState(false)

    useEffect(() => {
        setApiEnable(true)
        handleParam(`/${id}`)
    }, [id])

    const handleSubmit = async (values, { resetForm }) => {
        const data = closedDocketsData?.data
        // console.log('Close Docket Form Submit: ', values)
        const { technician, technician2, technician3, materials } = values;
        // console.log('materials: ', materials)
        if (materials.filter(function (e) { !e.value && toast.error(`${e.field} material value is required.`); return e.value === ''; }).length > 0) {
            // IT WILL EXECUTE ONLY ONCE ON VALIDATIOn ERROR OF MATERIALAS +
            return;
        }

        const res = await handleDocketCloseAction({
            ...values,
            ch_hh: values.ch_hh | 0,
            ch_mm: values.ch_mm | 0,
            ch_ss: values.ch_ss | 0,
            oh_hh: values.oh_hh | 0,
            oh_mm: values.oh_mm | 0,
            oh_ss: values.oh_ss | 0,
            docket: {
                technician,
                technician2,
                technician3,
                status: 'Resolved',
            },
            id: data.docket.code,
            action: 'update',
            remarks: 'Updated Materials.'
        })
        // console.log('res -> ', res)

        if (res.success) {
            toast.success(`#${data.docket.code} - ${res.detail}`);
            navigate(`/docket/${data.docket.code}`);
            // setInfoModal(true)
        } else {
            toast.error(res.detail)
        }
    }

    const renderDocketClose = (data) => {
        if (data.docket.status === 'Closed') {
            if (user?.permissions?.account?.is_admin) {
                return <DocketCloseForm
                    data={closedDocketsData?.data}
                    isLoading={docketCloseActionIsLoading}
                    handleSubmit={handleSubmit}
                    submitBtnLabel='Update Docket'
                />
            } else {
                return <>
                    <Alert
                        title='Docket already Closed!'
                        text={`Docket ${data.docket.code} has been closed on ${formatDateTime(data.created_at, 'DD-MM-YYYY')}`}
                        type='primary'
                    />
                    <Link className='btn btn-info' to={`/docket/${data.docket.code}`}>View Docket</Link>
                </>
            }

        } else {
            return <DocketCloseForm
                data={closedDocketsData?.data}
                isLoading={docketCloseActionIsLoading}
                handleSubmit={handleSubmit}
                submitBtnLabel='Update Docket'
            />
        }
    }

    return (
        <>
            <PageBreadcrumbs
                title='Update Material'
                history={[{ name: 'Docket', to: '/docket' }, { name: id, to: `/docket/${id}` }]}
                RightComp={() =>
                    <>
                        <Link to={`/docket/${id}`} className='btn btn-outline-success'>Back to Docket</Link>
                    </>
                }
            />

            <Grid container>
                <Grid item xs={12} sx={{ p: 1 }}>
                    {closedDocketsIsLoading && <Loader />}
                    {!closedDocketsData?.success && !closedDocketsIsLoading && <Alert
                        title='Docket Not Found!'
                        text={`Entered a Invalid Docket Id.`}
                        type='danger'
                    />}
                </Grid>
                {user?.permissions?.dockets?.view && <Grid item xs={4} sx={{ p: 1 }}>
                    {closedDocketsData?.data && closedDocketsData?.success && !closedDocketsIsLoading && <CloseDocketInfo data={closedDocketsData?.data} />}
                </Grid>}
                <Grid item xs={8} sx={{ p: 2 }}>
                    {/* {closedDocketsData?.data && closedDocketsData?.success && !closedDocketsIsLoading && <CloseDocketAction data={closedDocketsData?.data} />} */}
                    {user?.permissions?.dockets?.change ? <>
                        {closedDocketsData?.data && closedDocketsData?.success && !closedDocketsIsLoading && renderDocketClose(closedDocketsData?.data)}
                    </> :
                        <Alert
                            title='Permission Error!'
                            text='You do not have permission to perform this action.'
                            type='danger'
                        />
                    }
                </Grid>
            </Grid>

            <div className='container'>
                <div className="row ">
                    <div className="col mt-2">
                        {closedDocketsData && !closedDocketsIsLoading && <DocketInfoModal
                            modal={modal}
                            setModal={setInfoModal}
                            docData={closedDocketsData.data.docket}
                        />}
                    </div>
                </div>
            </div>

        </>
    )
}
