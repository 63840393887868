import React from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert } from '@mui/material';
import "yup-phone";
import MaterialDetail from '../DocketCloseForm/MaterialDetail';
import { useUser } from '../../../hooks/user';

export default function DocketCloseForm({ data, isLoading, handleSubmit, submitBtnLabel }) {
    const { user } = useUser();  

    const validate = Yup.object({
        // Basic Docket
        rfo: Yup.string().required('RFO is Required.').nullable(),
        workdone_by: Yup.string().required('Workdone by is Required.').nullable(),
        confirmed_by: Yup.string().required('Confirmed by is Required.'),
        comments: Yup.string(),


        // Tat Calc
        ch_hh: Yup.number().integer().min(0).max(24).label('Hour'),
        ch_mm: Yup.number().integer().min(0).max(60).label('Minutes'),
        ch_ss: Yup.number().integer().min(0).max(60).label('Seconds'),

        oh_hh: Yup.number().integer().min(0).max(24).label('Hour'),
        oh_mm: Yup.number().integer().min(0).max(60).label('Minutes'),
        oh_ss: Yup.number().integer().min(0).max(60).label('Seconds'),

        others_hold_reason: Yup.string().when(['oh_hh', 'oh_mm', 'oh_ss'], (oh_hh, oh_mm, oh_ss, schema) => {
            // console.log(oh_hh, oh_mm, oh_ss)
            return oh_hh > 0 | oh_mm > 0 | oh_ss > 0 ? schema.required('Others Hold Reason is Required.') : schema
        }),

        // Vehicle Validation
        vehicle1: Yup.string().required('Vehicle 1 is required.').nullable(),
        vehicle1_start_km: Yup.number().required('Start KM is required.'),
        vehicle1_end_km: Yup.number().required('End KM is required.'),
        vehicle2_start_km: Yup.number().required('Start KM is required.'),
        vehicle2_end_km: Yup.number().required('End KM is required.'),
        vehicle3_start_km: Yup.number().required('Start KM is required.'),
        vehicle3_end_km: Yup.number().required('End KM is required.'),
    })
    return (
        <Formik
            initialValues={{
                // Basic Docket
                "technician": data.docket.technician ?? null,
                "technician2": data.docket.technician2 ?? null,
                "technician3": data.docket.technician3 ?? null,
                "rfo": data.rfo ?? null,
                "workdone_by": data.workdone_by ?? null,
                "confirmed_by": data.confirmed_by ?? '',
                "comments": data.comments ?? '',

                // Material Detail.
                "materials": data.materials ?? [{
                    field: '',
                    field_id: null,
                    subOptions: [],
                    sub_field: '',
                    sub_field_id: null,
                    type: 'number',
                    value: 'new',
                    unit: '',
                    editable: true,
                }],
                // Additional Material Detail.
                "materials_additionals": data.materials_additionals ?? [{
                    id: null,
                    fiber_type: '',
                    fiber_type_id: null,
                    company_name: '',
                    drum_no: '',
                    start_mt: 0,
                    end_mt: 0
                }],

                // TatCalc
                "ch_hh": data.ch_hh | 0,
                "ch_mm": data.ch_mm | 0,
                "ch_ss": data.ch_ss | 0,
                "customer_hold": '',

                "oh_hh": data.oh_hh | 0,
                "oh_mm": data.oh_mm | 0,
                "oh_ss": data.oh_ss | 0,
                "others_hold": '',
                "others_hold_reason": data.others_hold_reason ?? '',

                // Vehicle States
                vehicle1: data.vehicle1 ?? null,
                vehicle1_start_km: data.vehicle1_start_km | 0,
                vehicle1_end_km: data.vehicle1_end_km | 0,
                vehicle2: data.vehicle2 ?? null,
                vehicle2_start_km: data.vehicle2_start_km | 0,
                vehicle2_end_km: data.vehicle2_end_km | 0,
                vehicle3: data.vehicle3 ?? null,
                vehicle3_start_km: data.vehicle3_start_km | 0,
                vehicle3_end_km: data.vehicle3_end_km | 0,
            }}
            validationSchema={validate}
            onSubmit={handleSubmit}
        >
            {formik => (
                <>
                    <Form>
                        {/* RENDER FORMS */}
                        <MaterialDetail formik={formik} />

                        {/* SHOWING ERRORS */}
                        {formik.submitCount >= 1 && formik.errors && Object.entries(formik.errors).map(([key, value]) => <Alert severity="error" sx={{ mt: 1, mb: 1 }} key={key}>{value}</Alert>)}


                        {user?.permissions?.dockets?.can_update_docket_materials && <LoadingButton
                            loading={isLoading}
                            variant="contained"
                            disableElevation
                            type="submit"
                            size="large"
                            color="info"
                        >
                            {submitBtnLabel}
                        </LoadingButton>}

                    </Form>
                </>
            )}
        </Formik>
    )
}
