import React from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import TextField from '../Common/Fields/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button } from '@mui/material';
import "yup-phone";
import { SMS_TEMPLATES } from './Options/SMSTemplates';
import { formatUserOptionsDetailList, useUsersListLite } from '../../hooks/user';
import SelectField from '../Common/Fields/SelectField';
import { Typography } from '@mui/material';

export default function SMSForm({ isLoading, handleSubmit, setModal }) {
    const docketed_by = useUsersListLite('');  // Users List
    const validate = Yup.object({
        mobile: Yup.string()
            .phone("IN", true)
            .required('Mobile Number is Required.'),
        text: Yup.string()
            .min(50).required('Text is Required.')
    })
    return (
        <Formik
            initialValues={{
                mobile: '',
                text: '',
                content_id: ''
            }}
            validationSchema={validate}
            onSubmit={handleSubmit}
        >


            {/* <TextField label='Mobile *' type='text' name='mobile' /> */}
            {/* <TextField label='Text *' type='text' name='text' sx={{ mb: 3 }} /> */}
            {formik => (
                <Form>
                    {/* {console.log(formik)} */}
                    <div className='row'>
                        <div className='col-md-12 mb-3'>
                            <Typography>Search </Typography>
                            <SelectField
                                name='docketed_by_not_using'
                                options={docketed_by?.usersData?.data?.map(formatUserOptionsDetailList)}
                                value={docketed_by?.usersData?.data?.map(formatUserOptionsDetailList).filter(function (option) {
                                    return option.account_name === formik.values.docketed_by;
                                })}
                                onChange={(e) => {
                                    formik.setFieldValue('mobile', e.mobile)
                                }}
                                isLoading={docketed_by?.usersIsLoading}
                            />
                        </div>
                        <div className='col-md-6'>
                            <label className="form-label">Mobile Number</label>
                            <input
                                type='number'
                                className='form-control'
                                placeholder='Mobile Number *'
                                value={formik.values.mobile}
                                onChange={(e) => formik.setFieldValue('mobile', e.target.value)}></input>
                        </div>
                        <div className='col-md-6'>
                            <label className="form-label">Template</label>

                            <select className="form-select" required="true" aria-required="true" onChange={(e) => { formik.setFieldValue('text', e.target.value); formik.setFieldValue('content_id', e.target.selectedOptions[0].getAttribute('content_id')); }}>

                                <option value=''>Select</option>
                                {SMS_TEMPLATES.map((data, i) =>
                                    <option value={data.value} content_id={data.content_id} key={i}>{data.label}</option>
                                )}

                            </select>
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col-md-12'>
                            <textarea
                                className='form-control'
                                placeholder='Select Template'
                                value={formik.values.text}
                                onChange={(e) => formik.setFieldValue('text', e.target.value)}
                                rows={3}
                            />
                        </div>

                    </div>

                    <LoadingButton
                        loading={isLoading}
                        variant="contained"
                        type="submit"
                    >
                        Send SMS
                    </LoadingButton>
                    <Button variant="outlined" sx={{ ml: 1 }} disableElevation onClick={() => setModal(false)}>Close</Button>

                </Form>
            )
            }



        </Formik >
    )
}
