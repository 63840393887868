import { Grid, Paper } from '@mui/material'
import React from 'react'
import { capitalize } from '../../utils'
import moment from 'moment'

export default function CircuitHistory({ data, historyTab, setHistoryTab }) {
    const renderIcon = (action) => {
        if (action === 'Update') {
            return <i className="material-icons align-middle text-primary">campaign</i>
        } else {
            return <i className="material-icons align-middle text-warning">priority_high</i>
        }
    }
    return (
        <Grid item xs={12} sx={{ p: 0.5 }} className={`historyDiv ${historyTab && 'showHistory'}`}>
            <button className="btn btn-danger btn-style-light headBtn" onClick={() => setHistoryTab(false)}>
                <i className="material-icons">close</i>{historyTab ? 'Hide History' : 'Show History'}
            </button>
            <div className="card widget widget-stats-large">
                <div className="widget-stats-large-info-container">
                    <div className="card-header">
                        <h5 className="card-title">History<span className="badge badge-info badge-style-light">Updated {moment(data.updated_at).utc().endOf('minutes').fromNow()}</span></h5>
                    </div>
                    <Paper style={{ maxHeight: '90vh', overflow: 'auto' }}>
                        <div className="card-body">
                            <ul className="list-group list-group-flush">
                                {data.history.map((history, i) =>
                                    <li className="list-group-item" key={i}>
                                        <em>{history.user} </em> <br />

                                        <span className="badge badge-dark badge-style-light">{moment(history.created_at).format('MMMM Do YYYY, h:mm:ss a')}</span>
                                        <br />
                                        <div className="badge bg-primary text-wrap fs-6">
                                            {history.reason ?? '-'}
                                        </div>
                                        <span className="float-end text-dark font-monospace fw-bold">{capitalize(history.action)} {renderIcon(history.action)}</span>
                                    </li>

                                )}
                            </ul>
                        </div>
                    </Paper>
                </div>
            </div>
        </Grid>
    )
}
