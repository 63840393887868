import { formatDateTime, latLongToGmap } from "./shortcuts";

export const handleWhatsappShare = (dataCir, dataDoc) => {
    // console.log('Circuit: ', dataCir)
    // console.log('Docket: ', dataDoc)
    let location_a_gmap = latLongToGmap(dataCir.latitude_a, dataCir.longitude_a)
    let location_b_gmap = latLongToGmap(dataCir.latitude_b, dataCir.longitude_b)
    
    let message = `*New ${dataCir.is_maintenance && 'Maintenance'} Docket Raised For ${dataCir.get_hirer.name}* \n`;
    message += `--------------------------------------- \n`
    message += `*Docket ID:* ${dataDoc.code} \n`
    message += `*Circuit ID:* ${dataCir.is_maintenance ? 'N/A' : dataCir.code} \n` 
    message += `*Customer Code:* ${dataCir.is_maintenance ? 'N/A' : dataCir.customer_id} \n`
    message += dataDoc.third_party_docket_no && `*3rd Party Docket ID: ${dataDoc.third_party_docket_no} \n`
    message += `*LC (Local Customer):* ${dataCir.lc_contact_persion} @ ${dataCir.lc_mobile_no} \n`
    message += `*Location A (BTS-POP-HUB):* ${dataCir.location_a} ${location_a_gmap && `\n(${location_a_gmap})`} \n`
    message += `*Location B (Customer End):* ${dataCir.location_b} ${location_b_gmap && `\n(${location_b_gmap})`} \n`
    message += `*Problem:* ${dataDoc?.get_problem?.title} \n`
    message += `*Docket Created At:* ${formatDateTime(dataDoc.created_at, 'Do MMMM YYYY, h:mm a')} \n`
    message += `*Docketed By:* ${dataDoc.docketed_by} @ ${dataDoc.docketed_by_contact}\n`
    message += `*Team Leader:* ${dataDoc.get_fiber_tl.name} @ ${dataDoc.get_fiber_tl.mobile ?? 'Not available'}\n`
    message += `*Technician:* ${dataDoc.get_technician ? `${dataDoc.get_technician.name} @ ${dataDoc.get_technician.mobile ?? 'Not available'}` : 'N/A' }\n`
    message += `--------------------------------------- \n`
    message += `*Remarks:* ${dataDoc.comment ?? '-'}\n`

    let url = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}&app_absent=1`;
    window.open(url, '_blank', 'location=no,toolbar=no,titlebar=no,height=300,width=300,scrollbars=no,status=no');

    // console.log(message)
}