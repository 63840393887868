export const IS_ACTIVE_FILTER_OPTIONS = [
    { 'label': 'Yes', value: 'true' },
    { 'label': 'No', value: 'false' },
]
export const IS_ACTIVE_FILTER_OPTIONS_BOOL = [
    { 'label': 'Yes', value: true },
    { 'label': 'No', value: false },
]

export const USER_ROLE_OPTIONS = [
    { 'value': '-', label: '-' },
    { 'value': 'TI', label: 'Territory Incharge' },
    { 'value': 'HI', label: 'Hub Incharge' },
    { 'value': 'TE', label: 'Technician' },
    { 'value': 'AM', label: 'Account Manager' },
    { 'value': 'FTL', label: 'Fiber Team Leader' },
    { 'value': 'FT', label: 'Fiber Technician' },
    { 'value': 'EX', label: 'Executive' },
    { 'value': 'EXP', label: 'Executive Plus' },
    { 'value': 'HR', label: 'HR' },
    { 'value': 'LC', label: 'Local Customer' },
]