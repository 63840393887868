import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Typography } from '@mui/material';
import SelectField from '../Common/Fields/SelectField';
import { DAYS_OPTIONS, LIMIT_OPTIONS, ORDERING_OPTIONS } from './Options/Common';
import Loader2 from '../Common/Loader2';


export default function SortRecordsForm({ isLoading, handleSubmit, setModal, resetFrom, searchParams }) {
    const [params, setParams] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const currentParams = Object.fromEntries([...searchParams]);
        setParams(currentParams)
        setLoading(false)
    }, [searchParams])

    // console.log('params', params)

    const validate = Yup.object({
        ordering: Yup.string(),
        limit: Yup.string(),
        created_on: Yup.string(),
        updated_on: Yup.string(),
    })

    if (loading) {
        return <Loader2 />
    }
    return (
        <Formik
            initialValues={{
                "ordering": params.ordering ?? '-id',
                "limit": params.limit ?? '20',
                "created_on": params.created_on ?? '',
                "updated_on": params.updated_on ?? '',
            }}
            validationSchema={validate}
            onSubmit={handleSubmit}
        >
            {formik => (

                <Form>
                    <div className='row'>
                        <div className="col-md-6 mb-4">
                            <Typography variant='caption'>Order By </Typography>
                            <SelectField
                                name='ordering'
                                options={ORDERING_OPTIONS}
                                value={ORDERING_OPTIONS.filter(function (option) {
                                    return option.value === formik.values.ordering;
                                })}
                                onChange={(e) => formik.setFieldValue('ordering', e.value)}
                            />
                        </div>
                        <div className="col-md-6 mb-4">
                            <Typography variant='caption'>Limit records</Typography>
                            <SelectField
                                name='limit'
                                options={LIMIT_OPTIONS}
                                value={LIMIT_OPTIONS.filter(function (option) {
                                    return option.value === formik.values.limit;
                                })}
                                onChange={(e) => formik.setFieldValue('limit', e.value)}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-md-6 mb-4">
                            <Typography variant='caption'>Created on </Typography>
                            <SelectField
                                name='created_on'
                                options={DAYS_OPTIONS}
                                value={DAYS_OPTIONS.filter(function (option) {
                                    return option.value === formik.values.created_on;
                                })}
                                onChange={(e) => formik.setFieldValue('created_on', e.value)}
                            />
                        </div>
                        <div className="col-md-6 mb-4">
                            <Typography variant='caption'>Updated on</Typography>
                            <SelectField
                                name='updated_on'
                                options={DAYS_OPTIONS}
                                value={DAYS_OPTIONS.filter(function (option) {
                                    return option.value === formik.values.updated_on;
                                })}
                                onChange={(e) => formik.setFieldValue('updated_on', e.value)}
                            />
                        </div>
                    </div>

                    <LoadingButton
                        loading={isLoading}
                        type="submit"
                        variant="contained"
                        disableElevation
                    >
                        Apply
                    </LoadingButton>
                    <Button variant="outlined" sx={{ ml: 1 }} disableElevation onClick={() => resetFrom(formik)}>Reset</Button>
                    <Button variant="outlined" sx={{ ml: 1 }} disableElevation onClick={() => setModal(false)}>Close</Button>
                </Form>
            )}
        </Formik>
    )
}
