import { Typography } from '@mui/material';
import React from 'react'
import { formatSelectOptionsList, useHirerList } from '../../../hooks/circuit';
// import Loader2 from '../../Common/Loader2';
import SelectField from '../../Common/Fields/SelectField';
import TextField from '../../Common/Fields/TextField';
import { CIRCUIT_TYPE_OPTIONS, LINK_TYPE_OPTIONS, STATUS_OPTIONS } from '../Options/CircuitForm';
import { useProviderList } from '../../../hooks/core/provider';

export default function CircuitDetail({ formik, disabled }) {
    const { hirerData, HirerIsLoading } = useHirerList();
    const { providersData, providerIsLoading } = useProviderList()
    // console.log(formik)
    return (
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className='col-12'>
                        <h3 className='text-center display-6 mb-4 text-primary' style={{ 'fontSize': '22px' }}>CIRCUIT DETAILS</h3>

                    </div>
                    <div className="col-md-2 mb-4">
                        <Typography>Docket For <span className='req'>*</span></Typography>

                        {/* {providersData && !providerIsLoading ? <SelectField
                            name='provider'
                            options={providersData?.data.map(formatSelectOptionsList)}
                            value={providersData?.data.map(formatSelectOptionsList).filter(function (option) {
                                return option.value === formik.values.provider;
                            })}
                            onChange={(e) => formik.setFieldValue('provider', e.value)}
                            isDisabled={disabled}

                        /> : <Loader2 color="inherit"/>} */}
                        <SelectField
                            name='provider'
                            options={providersData?.data.map(formatSelectOptionsList)}
                            value={providersData?.data.map(formatSelectOptionsList).filter(function (option) {
                                return option.value === formik.values.provider;
                            })}
                            onChange={(e) => formik.setFieldValue('provider', e.value)}
                            isLoading={providerIsLoading}
                            isDisabled={disabled}
                        />
                    </div>
                    <div className="col-md-3 mb-4">
                        <Typography>Hirer Name <span className='req'>*</span></Typography>

                        {/* {hirerData && !HirerIsLoading ? <SelectField
                            name='hirer_name'
                            options={hirerData.map(formatSelectOptionsList)}
                            value={hirerData.map(formatSelectOptionsList).filter(function (option) {
                                return option.value === formik.values.hirer_name;
                            })}
                            onChange={(e) => formik.setFieldValue('hirer_name', e.value)}
                            isDisabled={disabled}

                        /> : <Loader2 color="inherit"/>} */}
                        <SelectField
                            name='hirer_name'
                            options={hirerData?.data?.map(formatSelectOptionsList)}
                            value={hirerData?.data?.map(formatSelectOptionsList).filter(function (option) {
                                return option.value === formik.values.hirer_name;
                            })}
                            onChange={(e) => formik.setFieldValue('hirer_name', e.value)}
                            isLoading={HirerIsLoading}
                            isDisabled={disabled}
                        />
                    </div>
                    <div className="col-md-3 mb-4">
                        {/* <TextField label='Circuit Type *' type="text" name='circuit_type' placeholder='circuit_type' sx={{ mb: 3 }} /> */}
                        <Typography>Circuit Type <span className='req'>*</span></Typography>
                        <SelectField
                            name='circuit_type'
                            options={CIRCUIT_TYPE_OPTIONS}
                            value={CIRCUIT_TYPE_OPTIONS.filter(function (option) {
                                return option.value === formik.values.circuit_type;
                            })}
                            onChange={(e) => formik.setFieldValue('circuit_type', e.value)}
                            isDisabled={disabled}
                        />

                    </div>
                    <div className="col-md-2 mb-4">
                        {/* <TextField label='Link Type *' type="text" name='link_type' placeholder='link_type' sx={{ mb: 3 }} /> */}
                        <Typography>Link Type <span className='req'>*</span></Typography>
                        <SelectField
                            name='link_type'
                            options={LINK_TYPE_OPTIONS}
                            isSearchable={false}
                            value={LINK_TYPE_OPTIONS.filter(function (option) {
                                return option.value === formik.values.link_type;
                            })}
                            onChange={(e) => formik.setFieldValue('link_type', e.value)}
                            isDisabled={disabled}
                        />
                    </div>
                    <div className="col-md-2 mb-4">
                        <Typography>Status <span className='req'>*</span></Typography>
                        <SelectField
                            name='status'
                            options={STATUS_OPTIONS}
                            isSearchable={false}
                            value={STATUS_OPTIONS.filter(function (option) {
                                return option.value === formik.values.status;
                            })}
                            onChange={(e) => formik.setFieldValue('status', e.value)}
                            isDisabled={disabled}
                        />
                    </div>
                    
                    <div className="col-md-4">
                        <TextField label='Customer ID *' disabled={disabled} type="text" name='customer_id' placeholder='customer_id' sx={{ mb: 3 }} />
                    </div>

                    <div className="col-md-4">
                        <TextField label='3rd Party Circuit ID' disabled={disabled} type="text" name='third_party_circuit_id' placeholder='third_party_circuit_id' sx={{ mb: 3 }} />
                    </div>
                </div>
            </div>
        </div>
    )
}
