import React from 'react'
import { formatVehicleOptionsList, useVehiclesList } from '../../hooks/core/vehicle';
import TextField from '../Common/Fields/TextField';
import { Alert, Button, Typography } from '@mui/material';
import SelectField from '../Common/Fields/SelectField';
import CheckboxField from '../Common/Fields/CheckboxField';
export default function VehiclesForm({ formik, hideEndKM }) {
    const { vehiclesData, vehiclesIsLoading } = useVehiclesList();
    return (
        <>
            <div className='row mt-2'>
                <div className={`${hideEndKM ? 'col-6' : 'col-12'} col-md-6`}>
                    <Typography variant='caption'>Vehicle 1 <span className='req'>*</span></Typography>

                    <SelectField
                        name='vehicle1'
                        options={vehiclesData?.data.map(formatVehicleOptionsList)}
                        value={vehiclesData?.data.map(formatVehicleOptionsList).filter(function (option) {
                            return option.value === formik.values.vehicle1;
                        })}
                        onChange={(e) => formik.setFieldValue('vehicle1', e.value)}
                        isLoading={vehiclesIsLoading}
                    />
                </div>
                <div className="col-md-3 col-6 mt-3">
                    <TextField label='Start KM *' type="number" name='vehicle1_start_km' placeholder='Start KM' sx={{ mt: 2 }} />
                </div>
                {!hideEndKM && <div className="col-md-3 col-6 mt-3">
                    <TextField label='End KM *' type="number" name='vehicle1_end_km' placeholder='End KM' sx={{ mt: 2 }} />
                </div>}
                <div className="col-md-1">
                    {formik.values.vehicle1 && !hideEndKM && <span className="badge text-white bg-primary"><small>Vehicle 1: </small> {formik.values.vehicle1_end_km - formik.values.vehicle1_start_km} KM</span>}
                </div>
                {formik.values.vehicle1 && <div className="col-md-12">
                    <CheckboxField label='Back to POP' name='vehicle1_to_pop' options={['Back to POP']} />
                </div>}
            </div>
            <div className='row'>
                <div className={`${hideEndKM ? 'col-6' : 'col-12'} col-md-6`}>
                    <Typography variant='caption'>Vehicle 2</Typography>

                    <SelectField
                        name='vehicle2'
                        options={vehiclesData?.data.map(formatVehicleOptionsList)}
                        value={vehiclesData?.data.map(formatVehicleOptionsList).filter(function (option) {
                            return option.value === formik.values.vehicle2;
                        })}
                        onChange={(e) => formik.setFieldValue('vehicle2', e.value)}
                        isLoading={vehiclesIsLoading}
                    />
                </div>
                <div className="col-md-3 col-6 mt-3">
                    <TextField label='Start KM *' type="number" name='vehicle2_start_km' placeholder='Start KM' sx={{ mt: 2 }} />
                </div>
                {!hideEndKM && <div className="col-md-3 col-6 mt-3">
                    <TextField label='End KM *' type="number" name='vehicle2_end_km' placeholder='End KM' sx={{ mt: 2 }} />
                </div>}
                <div className="col-md-1">
                    {formik.values.vehicle2 && !hideEndKM && <span className="badge text-white bg-primary"><small>Vehicle 2: </small> {formik.values.vehicle2_end_km - formik.values.vehicle2_start_km} KM</span>}
                </div>
                {formik.values.vehicle2 && <div className="col-md-12">
                    <CheckboxField label='Back to POP' name='vehicle2_to_pop' options={['Back to POP']} />
                </div>}
            </div>
        </>
    )
}
