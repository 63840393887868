import React, { useContext } from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert } from '@mui/material';
import CircuitDetail from './CircuitDetail';
import ComplaintDetail from './ComplaintDetail';
// import "yup-phone";
import providerContext from '../../../context/provider/providerContext';
import LocationDetail from '../CircuitForm/LocationDetail';


export default function DocketMaintenanceAltForm({ data, isLoading, handleSubmit, btnLabel }) {
    const { currentProvider } = useContext(providerContext);
    // console.log('exiitt ', data)
    // const phoneSchema = Yup.string().phone('IN', true);
    const validate = Yup.object({
        // CIRCUIT DETAILS 
        circuit_type: Yup.string().required('circuit: Select Circuit Type'),
        link_type: Yup.string().required(),

        // LOCATION DETAILS
        "location_a": Yup.string().required('Location A is required.'),
        "port_details_a": Yup.string(),
        "address_a": Yup.string(),

        "location_b": Yup.string().required('Location B is required.'),
        "port_details_b": Yup.string(),
        "address_b": Yup.string(),
        // "area": Yup.string(),

        // CONTACT DETAILS
        // "lc_contact_persion": Yup.string().min(3, 'LC Contact person must be at least 3 characters.'),
        // lc_mobile_no: Yup.string().test('test-phone', 'Mobile not valid.', (value) => {
        //     if (value) return phoneSchema.isValidSync(value);
        //     return true;
        // }),

        // OTHER DETAILS
        // customer_type: Yup.string().required(),
        // type_of_connectivity: Yup.string().required(),

        // FIBER DETAILS
        // "ofc_color": Yup.string().required('Select OFC Color'),

        // COMPLAINT DETAILS
        "docketed_by": Yup.string().label('Docketed by name').min(3, 'Docketed by must be at least 3 characters.').required(),
        "docketed_by_contact": Yup.string().phone("IN", true).required('Docketed by contact is required.').label('Docketed by contact'),
        "fiber_team_leader": Yup.number().required('Select Fiber Team Leader').transform((value) => (isNaN(value) ? undefined : value)).nullable(),
        "hirer_name": Yup.number().required('Select Hirer').transform((value) => (isNaN(value) ? undefined : value)).nullable(),
        "problem": Yup.number().required('Select Problem').transform((value) => (isNaN(value) ? undefined : value)).nullable(),
    }).shape({
        pin_a: Yup.string()
            .length(6)
            .matches(/^[0-9]{5}/)
            .label('Pin A'),
        pin_b: Yup.string()
            .length(6)
            .matches(/^[0-9]{5}/)
            .label('Pin B'),
    })

    const conditionalFormComponent = (formik) => {
        return <>
            <CircuitDetail formik={formik} disabled={false} />
            <LocationDetail formik={formik} disabled={false} />
            <ComplaintDetail formik={formik} disabled={false} />
        </>

    };
    return (
        <Formik
            initialValues={{
                "provider": data.provider ?? currentProvider?.id,
                "circuit_type": data.get_circuit?.circuit_type ?? '',
                "link_type": data.get_circuit?.link_type ?? 'Primary',
                "order_id": data.order_id,

                // Location A
                "location_a": data.get_circuit?.location_a ?? '',
                "pin_a": data.get_circuit?.pin_a ?? '',
                "mobile_a": data.get_circuit?.mobile_a ?? '',
                "port_details_a": data.get_circuit?.port_details_a ?? '',
                "address_a": data.get_circuit?.address_a ?? '',
                "city_a": data.get_circuit?.city_a ?? '',
                "post_a": data.get_circuit?.post_a ?? '',
                "area_a": data.get_circuit?.area_a ?? '',
                "district_a": data.get_circuit?.district_a ?? '',
                "latitude_a": data.get_circuit?.latitude_a ?? '',
                "longitude_a": data.get_circuit?.longitude_a ?? '',

                // Location B
                "location_b": data.get_circuit?.location_b ?? '',
                "pin_b": data.get_circuit?.pin_b ?? '',
                "mobile_b": data.get_circuit?.mobile_b ?? '',
                "port_details_b": data.get_circuit?.port_details_b ?? '',
                "address_b": data.get_circuit?.address_b ?? '',
                "city_b": data.get_circuit?.city_b ?? '',
                "post_b": data.get_circuit?.post_b ?? '',
                "area_b": data.get_circuit?.area_b ?? '',
                "district_b": data.get_circuit?.district_b ?? '',
                "latitude_b": data.get_circuit?.latitude_b ?? '',
                "longitude_b": data.get_circuit?.longitude_b ?? '',

                // "area": data.get_circuit?.area ?? '',
                // "district": data.get_circuit?.district ?? null,

                // CONTACT DETAILS
                // "lc_contact_persion": data.get_circuit?.lc_contact_persion ?? '',
                // "lc_mobile_no": data.get_circuit?.lc_mobile_no ?? '',

                // OTHER DETAILS
                // "customer_type": data.get_circuit?.customer_type ?? 'option1',
                // "type_of_connectivity": data.get_circuit?.type_of_connectivity ?? 'option1',

                // FIBER DETAILS
                // "ofc_color": data.get_circuit?.ofc_color ?? null,
                // "ofc_distance": data.get_circuit?.ofc_distance ?? '',
                // "latitude": data.get_circuit?.latitude ?? '',
                // "longitude": data.get_circuit?.longitude ?? '',

                // Docket Create
                "fiber_team_leader": data.fiber_team_leader ?? null,
                "technician": data.technician ?? null,
                "third_party_docket_no": data.third_party_docket_no ?? '',
                "problem": data.problem ?? null,
                "hirer_name": data.hirer_name ?? null,
                "comment": data.comment ?? '',
                "docketed_by": data.docketed_by ?? '',
                "docketed_by_contact": data.docketed_by_contact ?? '',
                "status": data.status ?? 'Pending',
            }}
            validationSchema={validate}
            onSubmit={handleSubmit}
        >
            {formik => (
                <>
                    <Form>
                        {/* RENDER FORM */}
                        {conditionalFormComponent(formik)}

                        {/* SHOWING ERRORS */}
                        {formik.submitCount >= 1 && formik.errors && Object.entries(formik.errors).map(([key, value]) => {
                            if (value.includes("circuit:")) {
                                // setCurrentStep(1);
                            }
                            return <Alert severity="error" sx={{ mt: 1, mb: 1 }} key={key}>{value.replace('circuit:', '')}</Alert>

                        })}

                        <LoadingButton
                            loading={isLoading}
                            variant="contained"
                            disableElevation
                            type="submit"
                            size="large"
                            color="success"
                        >
                            {btnLabel}
                        </LoadingButton>

                    </Form>
                </>
            )}
        </Formik>
    )
}
