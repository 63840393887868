import { useQuery } from "@tanstack/react-query";
import { getTokens } from "../../utils/shortcuts";
import { fetchJson } from "../../libs/api";
import { IP_FETCH_API_HOST } from "../../config";

const IP_QUERY_KEY = 'client_ip'


export function useClient() {
    const { isLoading, data } = useQuery([IP_QUERY_KEY], async () => {
        try {
            const { accessToken } = getTokens();
            const data = await fetchJson(`${IP_FETCH_API_HOST}`, { }, true)
            // console.log('IP -> ',await data.text())
            return {
                success: true,
                ...await data.json()
            }
        } catch (err) {
            return {
                success: false,
                detail: err
            };
        }
    }, {
        cacheTime: Infinity,
        staleTime: 30_000, // ms
    })
    return { getClient: data, isClientLoading: isLoading }
}

