// import moment from 'moment';
import React from 'react'
import Modal from 'react-modal'
import { Link, useNavigate } from 'react-router-dom';
import { useSendDocketEmail, useSendDocketSMS } from '../../hooks/docket/send';
import { toast } from 'react-toastify';
// import { handleWhatsappShare } from '../../utils/whatsappShare';
import { formatDateTime, getCorporateDocketTAT, showUser } from '../../utils/shortcuts';
// import { secondsToHMS } from '../../utils';
import { useUser } from '../../hooks/user';
import { useState } from 'react';
import { useNewDocket } from '../../hooks/lco/dockets';
import { useCorporateDocket } from '../../hooks/docket/corporate';
// import EmailPreviewModal from './EmailPreviewModal';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '700px',
        width: '100%'
    },
    overlay: { zIndex: 1000 }
};
export default function CorporateDocketInfoModal({ modal, setModal, docData, setDocData }) {
    const { user } = useUser();
    const { handleNewDocketAction, isCreatingNewDocket } = useNewDocket('POST')
    const { handleNewCorporateDocket, isCreatingCorporateDocket } = useCorporateDocket('PATCH')
    // console.log('user ->', user)
    let navigate = useNavigate();
    const { handleSendDocketSMS, isSendingDocketSMS } = useSendDocketSMS()
    const { handleSendDocketEmail, isSendingDocketEmail } = useSendDocketEmail()
    const [emailPreviewModal, setEmailPreviewModal] = useState(false)
    // console.log('docData', docData)
    const handleMoveToFiberDocket = async () => {
        // eslint-disable-next-line no-restricted-globals
        var result = confirm("Are you sure?");
        if (result) {
            // console.log('post', docData)
            const res = await handleNewDocketAction({
                problem: docData.problem,
                comment: 'Docket moved from corporate to fiber.',
                circuit: docData.circuit,
                third_party_docket_no: docData.code
            })
            const res1 = await handleNewCorporateDocket({
                id: docData.code,
                action: 'update',
                comment: 'Docket moved to fiber.',
                is_moved_fiber: true,
                fiber_docket_id: res?.data?.code
                // status: 'Closed',
            })
            if (res1.success) {

                setDocData(res1?.data)
            }

            // console.log(res)
            res.success ? toast.success(res.detail) : toast.error(res.detail.replace("detail:", ""));
            setModal(false)
            navigate(`/docket/${res?.data?.code}`)
        }

    }

    // Handle Send Docket SMS
    const handleDocketSMS = async () => {
        const res = await handleSendDocketSMS(docData.code)
        res.success ? toast.success(res.detail) : toast.error(res.detail);
    }
    const handleDocketEmail = async () => {
        const res = await handleSendDocketEmail(docData.code)
        res.success ? toast.success(res.detail) : toast.error(res.detail);
    }
    return (
        <Modal
            isOpen={modal}
            // onAfterOpen={afterOpenModal}
            // onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <div className="container-fluid">

                <div className="d-flex justify-content-between">
                    <h5 className="modal-title mb-3 text-center text-info">Corp. {docData?.get_circuit?.is_maintenance && 'Maintenance'} Docket Information</h5>
                    <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setModal(false); }}></button>

                </div>

                <div className="modal-body" style={{ width: '100%' }}>
                    <div className="d-flex justify-content-between">
                        <div>
                            <p className="display-6 text-dark m-0">Corp. Docket ID:</p>
                        </div>
                        <div>
                            <h3 className="display-6 text-danger m-0">{docData.code}</h3>
                        </div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-center align-items-center mt-3">
                        <div>
                            {/* {user?.user_role === 'EXP' && <div className='d-flex gap-2'> */}
                            {/* <button className='btn btn-primary' onClick={handleDocketSMS} disabled={isSendingDocketSMS}><i className="fa fa-comment" aria-hidden="true"></i></button> */}
                            {/* <button className='btn btn-primary' onClick={handleDocketEmail} disabled={isSendingDocketEmail}> <i className="fa fa-envelope" aria-hidden="true"></i>{isSendingDocketEmail ? '...' : ''}</button> */}
                            {/* <button className='btn btn-success' onClick={() => handleWhatsappShare(docData.get_circuit, docData)}> <i className="fa fa-share" aria-hidden="true"></i></button> */}
                            {/* <button className='btn btn-info' onClick={() => setEmailPreviewModal(true)}> <i className="fa fa-eye" aria-hidden="true"></i></button> */}
                            {user?.permissions?.dockets?.can_transfer_fiber_docket && docData?.get_circuit && <button className='btn btn-danger' onClick={() => handleMoveToFiberDocket()} disabled={isCreatingNewDocket || docData?.is_moved_fiber}> <i className="material-icons" aria-hidden="true">move_up</i> {docData?.is_moved_fiber ? 'Moved to Fiber' : 'Move to Fiber'}</button>}

                            {/* <EmailPreviewModal
                                    modal={emailPreviewModal}
                                    setModal={setEmailPreviewModal}
                                    docData={docData}
                                /> */}
                            {/* </div>}  */}
                        </div>
                        <div></div>
                    </div>
                    <hr />
                    <table className='table m-0'>
                        <tbody>
                            <tr>
                                <td className='fw-bold text-dark text-center' colSpan={2}>
                                    <p className='text-center'>New Docket Raised @ {docData?.get_problem?.title}</p>
                                </td>
                            </tr>
                            <tr>
                                <th>Status:</th>
                                <td className='fw-bold text-dark'>{docData.status} {docData.alt_status && docData.status === 'Pending' ? ` - ${docData.alt_status}` : ''}</td>
                            </tr>
                            <tr>
                                <th>Problem:</th>
                                <td className='text-dark'>{docData?.get_problem?.title ?? 'NA'}</td>
                            </tr>
                            {!docData?.is_manual_docket && <>
                                <tr>
                                    <th>LC Details:</th>
                                    <td>{docData?.get_circuit?.lc_contact_persion || '-'} @ {docData?.get_circuit?.lc_mobile_no || 'NOT AVAILABLE'}</td>
                                </tr>
                                {docData?.get_circuit?.ofc_distance && <tr>
                                    <th>OFC Distance:</th>
                                    <td>{docData?.get_circuit?.ofc_distance || '-'} </td>
                                </tr>}
                                <tr>
                                    <th>Location A:</th>
                                    <td>{docData?.get_circuit?.location_a}</td>
                                </tr>
                                <tr>
                                    <th>Location B:</th>
                                    <td>{docData?.get_circuit?.location_b}</td>
                                </tr>
                            </>}
                            {docData?.is_manual_docket && <>
                                {docData?.case_title && (
                                    <tr>
                                        <th scope="col">Case Title:</th>
                                        <td>{docData?.case_title} </td>
                                    </tr>
                                )}
                                {docData?.customer_name && (
                                    <tr>
                                        <th scope="col">Customer name:</th>
                                        <td>{docData?.customer_name} </td>
                                    </tr>
                                )}
                            </>}
                            {docData.get_team && (
                                <tr>
                                    <th scope="col">Team:</th>
                                    <td>{showUser(docData?.get_team)} </td>
                                </tr>
                            )}
                        
                            {user?.user_role !== 'CU' && <tr>
                                <th scope="col">Alt Team:</th>
                                <td>{showUser(docData?.alt_team)}</td>
                            </tr>}
                            {user?.user_role !== 'CU' && <tr>
                                <th scope="col">Field Team:</th>
                                <td>{showUser(docData?.field_team)}</td>
                            </tr>}
                            <tr>
                                <th>Docketed By:</th>
                                <td>{showUser(docData?.get_created_by)}</td>
                            </tr>
                            <tr>
                                <th>Closed By:</th>
                                <td>{showUser(docData?.get_closed_by)}</td>
                            </tr>

                            {docData.is_moved_fiber && <tr>
                                <th scope="col">Moved Fiber Docket:</th>
                                <td>{docData?.fiber_docket_id || '-'}</td>
                            </tr>}
                            <tr>
                                <th>TAT:</th>
                                <td>{getCorporateDocketTAT(docData?.closed_at, docData.created_at)}</td>
                            </tr>
                            <tr>
                                <th>Comments:</th>
                                <td>{docData?.comment || 'NA'}
                                </td>
                            </tr>
                            <tr>
                                <th>Docket Opening Time:</th>
                                <td>{formatDateTime(docData.created_at, 'LLLL')}</td>
                            </tr>
                            <tr>
                                <th>Docket Closing Time:</th>
                                <td>{formatDateTime(docData?.closed_at, 'LLLL')}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="d-flex justify-content-between align-items-center mt-3">
                        <div>
                        </div>
                        <div>
                            <button className='btn btn-dark' onClick={() => { setModal(false); }}> <i className="fa fa-times" aria-hidden="true"></i>Close</button>
                        </div>
                    </div>

                </div>
            </div>
        </Modal>
    )
}
