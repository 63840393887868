// import moment from 'moment';
import React, { useState } from 'react'
import Modal from 'react-modal'
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { formatDateTime, showUser } from '../../utils/shortcuts';
// import { useUser } from '../../hooks/user';
import { useDocketRequest } from '../../hooks/lco/dockets';
import moment from 'moment';
import DocketReviewPermissionModal from './DocketReviewPermissionModal';
import { useUser } from '../../hooks/user';
import { APPROVAL_REQUIRED_PROBLEM_IDS } from '../../config';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '700px',
        width: '100%'
    },
    overlay: { zIndex: 1000 }
};
export default function DocketRequestModal({ modal, setModal, data, setCurrentDocketReqData }) {
    const { user } = useUser();
    // console.log('req data ->', data)
    let navigate = useNavigate();
    const [emailPreviewModal, setEmailPreviewModal] = useState(false)
    const { handleDocketRequestAction, docketRequestIsLoading } = useDocketRequest('PATCH')
    const [remarks, setRemarks] = useState(null)
    const [showRemarks, setShowRemarks] = useState(false)
    const handleDocketRequest = async (action) => {
        if (action !== 'Accept' && !remarks) {
            setShowRemarks(true)
            toast.info('Please enter remarks.')
            return;
        }
        const res = await handleDocketRequestAction({
            remarks: remarks,
            status: action,
            id: data?.id
        })
        if (res.success) {
            // console.log('dock req ->', res)
            setModal(false)
            setShowRemarks(false)
            if (action === 'Accept') {
                if (data.is_maint_docket) {
                    navigate(`/docket/maintenance/add?provider=${data?.provider}&location_a=${data?.location_a}&location_b=${data?.location_b}&comment=${data?.comment}&problem=${data?.maint_problem}&circuit_type=Maintenance&docketed_by=${data?.get_user?.name}&docketed_by_contact=${data?.get_user?.mobile}`)
                } else {
                    toast.info('Please fill all the docket info.')
                    navigate(`/docket/add?circuit=${data.circuit}&provider=${data?.provider}&docketed_by=${data?.get_user?.name}&docketed_by_contact=${data?.get_user?.mobile}&comment=${data?.comment}&problem=${data?.problem}`)
                }

            } else {
                toast.error('Docket Request Cancelled.')
            }
        }
    }

    return (
        <Modal
            isOpen={modal}
            // onAfterOpen={afterOpenModal}
            onRequestClose={() => setShowRemarks(false)}
            style={customStyles}
            contentLabel="Docket Request Modal"
        >
            <div className="container-fluid">

                <div className="d-flex justify-content-between">
                    <h5 className="modal-title mb-3 text-center text-info">{data?.get_circuit?.code} Docket Request</h5>
                    <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setModal(false); setCurrentDocketReqData(null); setShowRemarks(false); }}></button>
                </div>
                <div className="modal-body" style={{ width: '100%' }}>
                    {data?.is_maint_docket ? <p className="display-6 text-danger m-0">Maintenance Docket Request</p> : <div className="d-flex justify-content-between">
                        <div>
                            <p className="display-6 text-dark m-0">Circuit ID:</p>
                        </div>
                        <div>
                            <h3 className="display-6 text-danger m-0">#{data?.get_circuit?.code}</h3>
                        </div>

                    </div>}
                    <hr />
                    <table className='table m-0'>
                        <tbody>
                            <tr>
                                <th>Problem:</th>
                                <td className='text-dark'><strong>{data?.get_problem ? data?.get_problem?.title : data?.get_maint_problem?.title} </strong></td>
                            </tr>
                            {data?.is_maint_docket ? <>
                                <tr>
                                    <th>Provider:</th>
                                    <td className='text-dark'>{data?.get_provider?.title}</td>
                                </tr>
                                <tr>
                                    <th>Location A:</th>
                                    <td className='text-dark'>{data?.location_a}</td>
                                </tr>
                                <tr>
                                    <th>Location B:</th>
                                    <td className='text-dark'>{data?.location_b}</td>
                                </tr>


                            </> : <>
                                <tr>
                                    <th>Preview:</th>
                                    <td className='text-dark'>
                                        <Link to={`/circuit/${data?.get_circuit?.id}`} rel="noreferrer" target={'_blank'} >Preview in a new tab</Link>
                                    </td>
                                </tr>

                            </>}
                            <tr>
                                <th>Comment:</th>
                                <td className='text-dark'>{data?.comment ?? 'NA'}</td>
                            </tr>

                            <tr>
                                <th>Requested By:</th>
                                <td>{showUser(data?.get_user)}</td>
                            </tr>
                            <tr>
                                <th>Request Time:</th>
                                <td>{formatDateTime(data.created_at, 'LLLL')}</td>
                            </tr>
                        </tbody>
                    </table>
                    {/* {console.log(moment().tz('Asia/Kolkata').diff(data.created_at, 'hours'))} */}

                    {showRemarks ? <div className='row'>
                        <div className='col-md-12'>
                            <input type="text" name="remarks" className='form-control mt-2' placeholder='Remarks *' onChange={(e) => setRemarks(e.target.value)} />
                            <button className='btn btn-danger mt-2' disabled={docketRequestIsLoading} onClick={() => handleDocketRequest('Cancel')}>Cancel Request</button>
                        </div>
                    </div> :
                        data.status === 'Pending' | moment().tz('Asia/Kolkata').diff(data.created_at, 'hours') <= 2 ? <div className='mt-3 d-flex gap-1'>
                            <button className='btn btn-primary' disabled={docketRequestIsLoading} onClick={() => handleDocketRequest('Accept')}>Create Docket</button>
                            <button className='btn btn-danger' disabled={docketRequestIsLoading} onClick={() => handleDocketRequest('Cancel')}>Cancel Request</button>
                            
                            {/* {user?.user_role === 'EXP' && data?.get_problem?.id === PERSONAL_DOCKET_PROBLEM_ID && <> <button className='btn btn-info' onClick={() => setEmailPreviewModal(true)}> <i className="fa fa-eye" aria-hidden="true"></i>Approval Format</button> */}
                            {user?.user_role === 'EXP' && APPROVAL_REQUIRED_PROBLEM_IDS.includes(data?.get_problem?.id) && <> <button className='btn btn-info' onClick={() => setEmailPreviewModal(true)}> <i className="fa fa-eye" aria-hidden="true"></i>Approval Format</button>
                                <DocketReviewPermissionModal
                                    modal={emailPreviewModal}
                                    setModal={setEmailPreviewModal}
                                    docData={data}
                                />
                            </>}
                        </div> : ''}
                </div>
            </div>
        </Modal>
    )
}
