import React, { useContext, useEffect, useState } from 'react';
import DataTableBase from '../../../components/Common/DataTableBase';
import { formatDateTime } from '../../../utils/shortcuts';
import { useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useUser } from '../../../hooks/user';
import PageBreadcrumbs from '../../../components/Common/Breadcrumbs';
import Alert from '../../../components/Common/Alert';
import { DOCKET_LIST_COLUMNS } from '../../../config';
import { getLocal, setLocal } from '../../../utils/localStorage';
import { isObjectEmpty, truncate } from '../../../utils';
import DocketReviewModal from '../../../components/Models/DocketReviewModal';
import FiberTLChangeModal from '../../../components/Models/FiberTLChangeModal';
import MobileDocketReviews from '../../../components/MobileTables/MobileDocketReviews';
import Loader from '../../../components/Common/Loader';
import providerContext from '../../../context/provider/providerContext';
import coreContext from '../../../context/core/coreContext';

export default function UnderReviewListPage() {
    const { currentProvider } = useContext(providerContext);
    const { user } = useUser();
    const [enableApi, setAPI] = useState(false)
    // const { docketsUnderReviewData, docketsUnderReviewIsLoading, handleParam } = useDocketUnderReviewList("", false);
    const {  data: { review: {docketsUnderReviewData, docketsUnderReviewIsLoading, handleParam} } } = useContext(coreContext);
    const [searchParams, setSearchParams] = useSearchParams();
    // Handling Docket Requests
    const [docData, setDocData] = useState(null);
    const [fiberTLChangeModal, setfiberTLChangeModal] = useState(false);
    const [docInfoModal, setDocInfoModal] = useState(false)
    const [visibleCols, setVisibleCols] = useState(JSON.parse(getLocal(DOCKET_LIST_COLUMNS)) ?? {})
    useEffect(() => {
        const data = JSON.parse(getLocal(DOCKET_LIST_COLUMNS))
        if (!data || isObjectEmpty(data)) {
            setVisibleCols({
                ftl: false,
                technician: false,
                problem: true,
                hirer: false,
                third_party_docket_no: false,
                ofc_distance: false,

                circuit_id: true,
                location_a: true,
                address_a: false,
                location_b: true,
                address_b: false,
                customer_id: false,
                customer_id_alt: false,

                tat_total: false,
                total_km: false,

                created_at: true,
                updated_at: true,
                status: true,
                actions: true,

                select_all: false,
            })
            setLocal(DOCKET_LIST_COLUMNS, JSON.stringify(visibleCols))
        }
    }, [])


    const columns = [
        {
            name: 'Docket ID',
            wrap: true,
            selector: row => row.code,
            sortable: true,
            width: '150px !important',
        },

        {
            name: 'Circuit ID',
            wrap: true,
            selector: row => row?.get_circuit?.is_maintenance ? '-' : row?.get_circuit?.code,
            sortable: true,
            omit: false,
            width: '150px !important',
        },
        {
            name: 'Customer ID 1',
            wrap: true,
            selector: row => row?.get_circuit?.customer_id,
            sortable: true,
            omit: !visibleCols.customer_id,
            width: '150px !important',
        },
        {
            name: 'Customer ID 2',
            wrap: true,
            selector: row => row?.get_circuit?.customer_id_alt,
            sortable: true,
            omit: !visibleCols.customer_id_alt,
            width: '150px !important',
        },
        {
            name: 'Location A',
            wrap: true,
            selector: row => row?.get_circuit?.location_a,
            sortable: true,
            omit: !visibleCols.location_a
        },
        {
            name: 'Location A Addr.',
            wrap: true,
            selector: row => row?.get_circuit?.address_a,
            sortable: true,
            omit: !visibleCols.address_a
        },
        {
            name: 'Location B',
            wrap: true,
            selector: row => row?.get_circuit?.location_b,
            sortable: true,
            omit: !visibleCols.location_b
        },
        {
            name: 'Location B Addr.',
            wrap: true,
            selector: row => row?.get_circuit?.address_b,
            sortable: true,
            omit: !visibleCols.address_b
        },
        {
            name: 'Ofc. Distance',
            wrap: true,
            selector: row => row?.get_circuit?.ofc_distance || 'N/A',
            // sortable: true,
            omit: !visibleCols.ofc_distance
        },
        {
            name: 'Fiber TL',
            wrap: true,
            selector: row => row?.get_fiber_tl?.name ?? '-',
            sortable: false,
            // omit: !visibleCols.ftl
            omit: false
        },
        {
            name: 'Technician',
            wrap: true,
            selector: row => row?.get_circuit?.get_fe_rf_team?.name ?? '-',
            sortable: false,
            omit: false
        },
        {
            name: 'Problem',
            wrap: true,
            selector: row => row?.get_problem?.title ? truncate(row?.get_problem?.title, 50) : '-',
            sortable: true,
            // omit: !visibleCols.problem
            omit: false
        },
        {
            name: '3rd Party Docket',
            wrap: true,
            selector: row => row?.third_party_docket_no,
            sortable: true,
            omit: !visibleCols.third_party_docket_no
        },
        {
            name: 'Hirer',
            wrap: true,
            selector: row => truncate(row?.get_hirer?.name, 50),
            sortable: true,
            omit: !visibleCols.hirer,
            width: '150px !important',
        },
        {
            name: 'Status',
            wrap: true,
            selector: row => row.status,
            sortable: true,
            // omit: !visibleCols.status,
            omit: true,
            width: '100px !important',
        },

        {
            name: 'Last Updated',
            wrap: true,
            // selector: row => moment(row.updated_at).utc().endOf('minutes').fromNow(),
            // selector: row => formatDateTime(row.updated_at, 'DD/MM/YY hh:mm A'),
            selector: row => formatDateTime(row.updated_at, 'D-M-YY h:ma'),
            sortable: true,
            // omit: !visibleCols.updated_at
            omit: true
        },
        {
            name: 'Created at',
            wrap: true,
            // selector: row => formatDateTime(row.created_at, 'DD/MM/YY hh:mm A'),
            selector: row => formatDateTime(row.created_at, 'D-M-YY h:ma'),
            // selector: row => moment(row.created_at).utc().endOf('minutes').fromNow(),
            sortable: true,
            // omit: !visibleCols.created_at
            omit: false
        },
        {
            name: 'Actions',
            width: '100%',
            sortable: true,
            selector: (row) => <div className='d-flex gap-1'>
                {/* <div className='col-12 '> */}
                <button className="btn btn-dark btn-style-light pb-0" onClick={() => { setDocData(row); setDocInfoModal(true) }} title='Quick Info' ><span className="material-icons-sharp">info</span></button>


                {user?.permissions?.dockets?.change && <>


                    {user?.permissions?.dockets?.can_change_tl | user?.permissions?.dockets?.can_change_tech ? <button className="btn btn-info btn-style-light pb-0" onClick={() => { setDocData(row); setfiberTLChangeModal(true); }} title='Change Fiber Team Leader ' ><span className="material-icons-sharp">manage_accounts</span></button> : ''}
                </>}
                {/* </div> */}


            </div>
            ,
        },
    ];
    const conditionalRowStyles = [


    ];

    // Filter Data 
    useEffect(() => {
        const currentParams = Object.fromEntries([...searchParams]);
        let params = ''
        params = new URLSearchParams(currentParams).toString() || 'limit=20';
        
        if (enableApi && params) {
            
            try {
                handleParam(`?${params}&provider=${currentProvider?.id}&limit=20`);
            } catch (error) {
                // Handle errors here
                console.log('err->', error)
                setAPI(false);
            } finally {
                 // Reset API call indicator
            }
        }
        setAPI(true)
    }, [searchParams])
    return (
        <>
            <Helmet>
                <title>Docket Review - List</title>
            </Helmet>
            <PageBreadcrumbs
                title='All Docket Review'
                alt='Docket Review'
                RightComp={() =>
                    <div className='d-flex gap-1'> </div>
                }
            />
            <div className='col-md-12'>
                {docketsUnderReviewIsLoading && <Loader />}
                {docketsUnderReviewData && !docketsUnderReviewIsLoading && !docketsUnderReviewData.success && <Alert
                    title='Info!'
                    text={docketsUnderReviewData.detail}
                    type='info'
                />}
                {user?.permissions?.dockets?.can_accept_docket && docketsUnderReviewData && !docketsUnderReviewIsLoading && docketsUnderReviewData.success  ? <>
                    <div className='docketsDesktop'>

                        <DataTableBase
                            columns={columns}
                            data={docketsUnderReviewData?.data}
                            direction="auto"
                            fixedHeaderScrollHeight="300px"
                            pagination
                            responsive
                            subHeaderAlign="right"
                            subHeaderWrap
                            progressPending={docketsUnderReviewIsLoading}
                            defaultPageSize={10}
                            conditionalRowStyles={conditionalRowStyles}
                        />
                    </div>
                    <div className='docketsMobile'>
                        <MobileDocketReviews
                            data={docketsUnderReviewData?.data}
                            user={user}
                            setDocData={setDocData}
                            setDocInfoModal={setDocInfoModal}
                            setfiberTLChangeModal={setfiberTLChangeModal}
                        />
                    </div>

                </> :
                    <></>
                }
            </div>

            {docData && <DocketReviewModal
                modal={docInfoModal}
                setModal={setDocInfoModal}
                // cirData={circuitData?.data}
                docData={docData}
            />}
            {docData && <FiberTLChangeModal
                modal={fiberTLChangeModal}
                setModal={setfiberTLChangeModal}
                docketCode={docData?.code}
                data={docData}
                setPending={true}
            />}
        </>
    )
}
