import React from 'react'
import { Formik, Form } from 'formik';
import DocketColumns from './VisiblityFilterForms/DocketColumns';


export default function DocketColumnForm({ isLoading, handleSubmit, setModal, visibleCols }) {
    return (
        <Formik
            initialValues={visibleCols}
            onSubmit={handleSubmit}
        >
            {formik => (

                <Form>
                    <DocketColumns
                        formik={formik}
                        isLoading={isLoading}
                        setModal={setModal}
                        visibleCols={visibleCols}
                    />
                </Form>
            )}
        </Formik>
    )
}
