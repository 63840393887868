import React, { useState } from 'react'
import VehicleSelectionForm from './Comp/VehicleSelectionForm'
import { useSearchParams } from 'react-router-dom';
import PageBreadcrumbs from '../../../components/Common/Breadcrumbs';
import { useVehiclesKMReport } from '../../../hooks/reports';
import moment from 'moment';
import { serializeQueryStrings } from '../../../utils';
import VehiclesKMReportDownload from './Comp/VehiclesKMReportDownload';

export default function VehicleKmReportPage() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [enableApi, setAPI] = useState(false);
    const { vehiclesKmData, isKMDataLoading, filterVehiclesReport } = useVehiclesKMReport('', enableApi);
    const [showResult, setShowResult] = useState(false);

    const fetchData = (data) => {
        const params = new URLSearchParams({ ...data }).toString();
        setAPI(true)
        params && filterVehiclesReport(`?${params}`)
        setShowResult(true)
    }

    const handleSubmit = (values) => {
        const data = {
            ...values,
            vehicle_ids: String(`[${values?.vehicle_ids}]`),
            date_from: moment(values?.date_from).format('YYYY-MM-DD'),
            date_to: moment(values?.date_to).format('YYYY-MM-DD'),
        }
        setSearchParams(serializeQueryStrings(data))
        fetchData(data)
    }
    return (
        <div>
            <PageBreadcrumbs
                title='Vehicle KM Report'
                alt='Vehicle KM Report'
                RightComp={() =>
                    <div className='d-flex justify-content-end'>
                        {/* <Link to='/reports/vehicle/km-report' className='btn btn-primary'>Reset</Link> */}
                    </div>
                }
            />
            <hr />
            <div className='card px-4 py-4 bg-light'>
                {!showResult && <VehicleSelectionForm
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                    handleSubmit={handleSubmit}
                />}


                {showResult && <VehiclesKMReportDownload
                    currentParams={Object.fromEntries([...searchParams])}
                    data={vehiclesKmData}
                    isLoading={isKMDataLoading}
                    setShowResult={setShowResult}
                />}
            </div>
        </div>
    )
}
