import React, { useEffect, useState } from 'react'
import CheckboxField from '../../Common/Fields/CheckboxField'
import { LoadingButton } from '@mui/lab'
import { Button } from '@mui/material'
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function CircuitColumns({ isLoading, setModal, formik }) {
    const [select, updateSelect] = useState(false)
    // useEffect(() => {
    //     if (formik.initialValues.select_all !== formik.values.select_all) {
    //         Object.entries(formik.values).forEach(values => {
    //             values[0] !== 'select_all' && formik.setFieldValue(values[0], formik.values.select_all)
    //         });
    //     }
    // }, [formik.values.select_all])

    const handleSelectAll = () => {
        updateSelect(!select)
        if (!select) {
            Object.entries(formik.values).forEach(values => {
                formik.setFieldValue(values[0], true)
            });
        } else {
            Object.entries(formik.values).forEach(values => {
                formik.setFieldValue(values[0], false)
            });
        }
    }

    return (
        <>
            <div className='row'>
                <div className="col-md-3">
                    <CheckboxField label='Location A' name='location_a' options={['Location A']} />
                    <CheckboxField label='Location B' name='location_b' options={['Location B']} />
                </div>
                <div className="col-md-3">
                    <CheckboxField label='Address A' name='address_a' options={['Address A']} />
                    <CheckboxField label='Address B' name='address_b' options={['Address B']} />
                </div>
                <div className="col-md-3">
                    <CheckboxField label='Mobile A' name='mobile_a' options={['Mobile A']} />
                    <CheckboxField label='Mobile B' name='mobile_b' options={['Mobile B']} />
                </div>
                <div className="col-md-3">
                    <CheckboxField label='Pin A' name='pin_a' options={['Pin A']} />
                    <CheckboxField label='Pin B' name='pin_b' options={['Pin B']} />
                </div>
                <div className="col-md-3">
                    {/* <CheckboxField label='Area' name='area' options={['Area']} /> */}
                    <CheckboxField label='Hirer Name' name='hirer' options={['Hirer Name']} />

                    <CheckboxField label='Ofc. Dist.' name='ofc_distance' options={['OFC Distance']} />
                </div>
                <div className="col-md-3">
                    <CheckboxField label='Address' name='address' options={['Address']} />

                </div>
                <div className="col-md-3">
                    <CheckboxField label='CustID 1' name='customer_id' options={['CustID 1']} />
                </div>
                <div className="col-md-3">
                    <CheckboxField label='CustID 2' name='customer_id_alt' options={['CustID 2']} />
                </div>
            </div>
            <hr />
            <div className='row'>
                <div className="col-md-4">
                    <CheckboxField label='Circuit Type' name='circuit_type' options={['Circuit Type']} />
                    <CheckboxField label='3rd Party Circuit' name='third_party_circuit_id' options={['3rd Party Circuit']} />
                </div>
                <div className="col-md-4">
                    <CheckboxField label='Type of Connectivity' name='type_of_connectivity' options={['Type of Connectivity']} />
                    <CheckboxField label='Customer Type' name='customer_type' options={['Customer Type']} />
                </div>
                <div className="col-md-4">
                    <CheckboxField label='Office Name' name='office_name' options={['Office Name']} />
                    <CheckboxField label='Provider' name='provider' options={['Provider']} />
                </div>
            </div>
            <hr />
            <div className='row mb-4'>
                <div className="col-md-4">
                    <CheckboxField label='Territory Incharge' name='territory_incharge' options={['Territory Incharge']} />
                </div>
                <div className="col-md-4">
                    <CheckboxField label='Hub Incharge' name='hub_incharge' options={['Hub Incharge']} />
                </div>
                <div className="col-md-4">
                    <CheckboxField label='Fiber TL' name='fiber_vendor_team_leader' options={['Fiber TL']} />
                </div>


            </div>
            <hr />
            <div className='row mb-4'>
                {/* <div className="col-md-6">
                    <CheckboxField label='Dockets Count' name='total_dockets' options={['Dockets Count']} />
                </div> */}
                <div className="col-md-3">
                    <CheckboxField label='Created At' name='created_at' options={['Created At']} />
                </div>
                <div className="col-md-3">

                    <CheckboxField label='Last Updated' name='updated_at' options={['Last Updated']} />
                </div>
                <div className="col-md-3">
                    <CheckboxField label='Status' name='status' options={['Status']} />
                </div>
                <div className="col-md-3">

                    <CheckboxField label='Actions' name='actions' options={['Actions']} />
                </div>

            </div>
            <div className='row mb-4'>
                <div className="col-md-6">
                    {/* <CheckboxField label='Select All' name='select_all' options={['Select All']} /> */}
                    <FormControlLabel
                        control={<Checkbox checked={select} />}
                        label={'Select All'}
                        name='select_all'
                        value={true}
                        onChange={handleSelectAll}
                    />
                </div>
            </div>

            <LoadingButton
                loading={isLoading}
                type="submit"
                variant="contained"
                disableElevation
            >
                Apply
            </LoadingButton>
            <Button variant="outlined" sx={{ ml: 1 }} disableElevation onClick={() => setModal(false)}>Close</Button>
        </>
    )
}
