import { Card, CardContent } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { formatDateTime } from '../../utils/shortcuts'

export default function CloseDocketInfo(props) {
    const data = props.data.docket
    // const closeData = props.data
    // console.log(data)
    return (
        <>
            {/* <div className="d-flex justify-content-between">
                <div>
                    <h3 className="display-6">Circuit ID: {data.get_circuit.code}</h3>

                    <p className="lead"><em>Last updated at {moment(data.updated_at).fromNow()}...</em></p>

                </div>
                <div>
                    <h3 className="display-6">Docket ID: {data.code}</h3>
                    <p className="lead">Status: {data.status}</p>
                </div>
            </div> */}
            <Card>
                <CardContent>
                    <table className="table">
                        <thead>
                            <tr>
                                <td colSpan={2}><em>DOCKET DETAILS <small>(Docket ID: {data.code})</small></em></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="col">Circuit ID:</th>
                                <td className='text-dark'>
                                    <Link to={`/circuit/${data.get_circuit.id}`}>{data.get_circuit.code}</Link>
                                </td>
                            </tr>
                            <tr>
                                <th scope="col">Location A:</th>
                                <td>{data.get_circuit.location_a}</td>
                            </tr>
                            <tr>
                                <th scope="col">Address A:</th>
                                <td>{data?.get_circuit?.address_a}</td>
                            </tr>
                            <tr>
                                <th scope="col">Location B:</th>
                                <td>{data.get_circuit.location_b}</td>
                            </tr>
                            <tr>
                                <th scope="col">Address B:</th>
                                <td>{data?.get_circuit?.address_b}</td>
                            </tr>
                            <tr>
                                <th>LC Details:</th>
                                <td>{data?.get_circuit?.lc_contact_persion || '-'} @ {data?.get_circuit?.lc_mobile_no || 'NOT AVAILABLE'}</td>
                            </tr>
                            <tr>
                                <th>OFC Distance:</th>
                                <td>{data?.get_circuit?.ofc_distance || '-'}</td>
                            </tr>
                            <tr>
                                <th scope="col">Problem:</th>
                                <td>{data?.get_problem?.title}</td>
                            </tr>
                            {data.third_party_docket_no && <tr>
                                <th scope="col">Third Party Docket No:</th>
                                <td>{data.third_party_docket_no}</td>
                            </tr>}
                            <tr>
                                <th scope="col">Docketed By:</th>
                                <td>{data.docketed_by} @ {data.docketed_by_contact}</td>
                            </tr>
                            <tr>
                                <th scope="col">Fiber Team Leader:</th>
                                <td>{data.get_fiber_tl.name} @ {data.get_fiber_tl.mobile}</td>
                            </tr>
                            {data.get_circuit.customer_id && <tr>
                                <th scope="col">Customer ID:</th>
                                <td>{data.get_circuit.customer_id} </td>
                            </tr>}
                            {data.comment && <tr>
                                <th scope="col">Comment:</th>
                                <td>{data.comment || '-'}</td>
                            </tr>}
                            <tr>
                                <th scope="col">Current Status:</th>
                                <td>{data.status}</td>
                            </tr>
                            <tr>
                                <th scope="col">Docket Date / Time:
                                    <br />
                                    <em>
                                        <small>
                                            last updated at:
                                        </small>
                                    </em>
                                </th>
                                <td>
                                    {formatDateTime(data.created_at, 'Do MMMM YYYY, h:mm:ss a')}
                                    <br />
                                    <em>
                                        <small>
                                            {formatDateTime(data.updated_at, 'Do MMMM YYYY, h:mm:ss a')}
                                        </small>
                                    </em>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </CardContent>
            </Card>
        </>
    )
}
