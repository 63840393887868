// import moment from 'moment';
import React from 'react'
import Modal from 'react-modal'
import { useNavigate } from 'react-router-dom';
import { serializeQueryStrings } from '../../utils';
import DocketFilterForm from '../Forms/DocketFilterForm';
// import { toast } from 'react-toastify';
// import { useFindCircuit } from '../../hooks/circuit';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '700px',
    },
    overlay: { zIndex: 1000 }
};
export default function DocketFilterModal({ modal, setModal, searchParams, setSearchParams }) {
    let navigate = useNavigate();
    // const { handleFindCircuit, isFindCircuitLoading } = useFindCircuit();
    // console.log('currentDocketData', currentDocketData)

    const handleSubmit = async (values) => {
        // console.log('result --> ', serializeQueryStrings(values));
        // navigate(`/docket?${serializeQueryStrings(values)}`)
        setSearchParams(serializeQueryStrings(values))
        // console.log('submitted')
        setModal(false)
    }
    const resetFrom = (formik) => {
        formik.resetForm()
        navigate(`/docket`)
        setModal(false)
    }
    return (
        <Modal
            isOpen={modal}
            // onAfterOpen={afterOpenModal}
            // onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <div className="container-fluid">

                <div className="d-flex justify-content-between">
                    <h5 className="modal-title mb-3 text-center text-info">Filter Dockets</h5>
                    <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={() => setModal(false)}></button>
                </div>
                <div className="modal-body" style={{ width: '100%' }}>
                    <DocketFilterForm
                        isLoading={false}
                        handleSubmit={handleSubmit}
                        setModal={setModal}
                        resetFrom={resetFrom}
                        searchParams={searchParams}
                    />
                </div>
            </div>
        </Modal>
    )
}
