import { formatDateTime, getCorporateDocketTAT } from "./shortcuts"

export const export_corp_docket_columns = (data) => {

    return data?.map((item) => {
        // console.log('Item ->', item)

        
    

        return {
            "Docket Id": item.code,
            "Circuit Id": item?.get_circuit?.code,
            "Problem": item?.get_problem?.title,
            "Comment": item?.comment,
            "Team": item?.get_team?.name,
            "Alt. Team": item?.alt_team?.name,
            "Field Team": item?.field_team?.name,

            "Case Title": item?.case_title,
            "Customer Name": item?.customer_name,
            "Case Reason": item?.case_reason,
            "Priority": item?.priority,
            "Case Origin": item?.case_origin,
            "Partner": item?.partner,
            "Contact Person": item?.contact_person,
            "Zone": item?.zone,
            "Next Followup Date": item?.next_followup_date,
            "Next Followup Time": item?.next_followup_time,
            "Add Item": item?.add_item,
            "Attend by Team": item?.attend_by_team,
            "Attend by Team Remarks": item?.attend_by_team_remarks,
            "Confirmed by": item?.confirmed_by,
            "Confirmed by Remarks": item?.confirmed_by_remarks,

            "RFO": item?.get_rfo?.name,
            "Work Done by": item?.work_done_by?.name,
            "Manual Docket": item.is_manual_docket,
            "Moved Fiber Docket": item.is_moved_fiber,
            "Total TAT": getCorporateDocketTAT(item?.closed_at, item.created_at),
            "Close Comment": item.close_comment,
            "Status": item.status,
            "Created By": item?.get_created_by?.name,
            "Created at": formatDateTime(item.created_at, 'DD-MM-YYYY HH:mm:ss'),
            "Closed at": formatDateTime(item.closed_at, 'DD-MM-YYYY HH:mm:ss'),
        }
    })
}